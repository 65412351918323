import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation.tsx';
import { CHAT_ICON } from 'constants/public-icons';
import { COMMON } from 'constants/translation-keys.ts';

import { Content } from './Content.tsx';

interface OpenCommentsButtonProps {
  entityId: string;
  claimId: string | null;
}

function OpenCommentsButton({ entityId, claimId }: OpenCommentsButtonProps) {
  const { t } = useTranslationRoot();
  const [open, setOpen] = useState(false);

  const openPanel = () => setOpen(true);
  const closePanel = () => setOpen(false);

  return (
    <WithTranslationRoot namespace={COMMON}>
      <IconButtonAnimate
        isOpen={open}
        aria-label={t('comments.title') as string}
        onClick={openPanel}
        size="large"
        data-testid="open-comments-button"
      >
        <SvgIconStyle src={CHAT_ICON} />
      </IconButtonAnimate>
      <Drawer
        anchor="right"
        open={open}
        onClose={closePanel}
        PaperProps={{ sx: { maxWidth: 'lg', width: '452px' } }}
      >
        <Content entityId={entityId} claimId={claimId} setOpen={setOpen} />
      </Drawer>
    </WithTranslationRoot>
  );
}

export { OpenCommentsButton };
