import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import equal from 'fast-deep-equal';

import { Column } from './AdvancedTable';

interface BodyRowProps {
  columns: Column[];
  row: any;
  handleRowClick: (row: any) => void;
}

function BodyRow({ columns, row, handleRowClick }: BodyRowProps) {
  const [isEditable, setIsEditable] = useState(false);
  const [inputs, setInputs] = useState(row);
  const hasChanged = !equal(row, inputs);

  function toggleIsEditable() {
    setIsEditable((prev) => !prev);
  }

  function updateInputs(key: string, value: string) {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function resetState() {
    setInputs(row);
  }

  return (
    <TableRow
      data-testid="list-table-row"
      role="checkbox"
      tabIndex={-1}
      sx={{
        cursor: 'pointer',
        '&:hover th': {
          backgroundColor: ({ palette }) => `${palette.grey[200]} !important`,
        },
      }}
      onClick={() => handleRowClick(row)}
      hover
    >
      {columns.map((col, index: number) => {
        const value = row[col.id] ?? null;

        return (
          <TableCell
            key={`${col.id}-${index}`}
            align={col.align}
            component={index === 0 ? 'th' : 'td'}
            scope={index === 0 ? 'row' : undefined}
            sx={{
              ...col.rowSx,
              whiteSpace: 'nowrap',
            }}
          >
            {col.renderCell
              ? col.renderCell({
                  row,
                  isEditable,
                  toggleIsEditable,
                  inputs,
                  updateInputs,
                  resetState,
                  hasChanged,
                })
              : col.valueFormatter
                ? col.valueFormatter({ value })
                : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export { BodyRow };
