import { useEffect } from 'react';
import type { Document } from 'types/Documents.ts';
import { useHitLContext } from 'pages/HitL/useHitLContext.ts';
import { useLockDocument, useUnlockDocument } from 'state/queries/documents.ts';
import { generalConfig } from 'config.ts';

import { addToUnlockQueue, flushUnlockQueue } from './unlock-queue.ts';

interface UseLockMechanism {
  currentUserEmail: string;
  document: Document;
}

const LOCKING_OCURRENCE = 1000 * 60; // 1 minute

function useLockMechanism({ currentUserEmail, document }: UseLockMechanism) {
  const { incrementLockedDocument, decrementLockedDocument } = useHitLContext();

  const { enableOpeningHilInNewTab } = generalConfig;
  const { id, lastLockedBy, locked } = document;
  const isLockedByCurrentUser = lastLockedBy === currentUserEmail;

  const lockDocumentAction = useLockDocument();
  const unlockDocumentAction = useUnlockDocument();

  // on first load lock the document
  useEffect(() => {
    if (id && !locked) {
      lockDocumentAction.mutate(id);

      addToUnlockQueue(id, () => {
        unlockDocumentAction.mutate({ id });
      });

      return () => {
        flushUnlockQueue();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if the document is locked by the current user, keep it locked
  useEffect(() => {
    if (locked && isLockedByCurrentUser) {
      const timeout = setInterval(() => {
        lockDocumentAction.mutate(id);
      }, LOCKING_OCURRENCE);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLockedBy, locked]);

  // if user is leaving the page, unlock the document
  useEffect(
    () => {
      const unlockDocument = () => {
        flushUnlockQueue();
      };

      // AXA doesn't want it to unlock...
      if (!enableOpeningHilInNewTab) {
        window.addEventListener('beforeunload', unlockDocument);

        return () => {
          window.removeEventListener('beforeunload', unlockDocument);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // handle lock mechanism in LocalStorageAPI
  useEffect(function lockedDocumentLocalStorage() {
    const decrementLock = () => {
      decrementLockedDocument(id);
    };

    if (enableOpeningHilInNewTab) {
      if (!locked && !isLockedByCurrentUser) {
        incrementLockedDocument(id);
      }
      window.addEventListener('beforeunload', decrementLock);

      return () => {
        decrementLockedDocument(id);
        window.removeEventListener('beforeunload', decrementLock);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export { useLockMechanism };
