import { useEffect, useRef } from 'react';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';

function useKeyboardShortcuts() {
  const disableEnterKeyFocus = useFeatureFlag(
    'disableHILEnterKeyFocusNextInput'
  );

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    function getForm() {
      return formRef.current;
    }

    function focusInput(inputs: (Element | null)[], index: number) {
      const input = inputs[index] as HTMLInputElement;
      input.focus();
    }

    function getActiveElement(inputs: (Element | null)[]) {
      return inputs.indexOf(window.document.activeElement);
    }

    function getInputs() {
      const form = getForm();

      if (!form) {
        return [];
      }

      return Array.prototype.slice.call(
        form.querySelectorAll('input[tabindex="0"]')
      );
    }

    function scrollBackToTop() {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }

    function keyboardShortcuts(event: globalThis.KeyboardEvent) {
      const { key } = event;

      const enterKeyCondition = disableEnterKeyFocus
        ? key === 'ArrowDown'
        : key === 'ArrowDown' || key === 'Enter';

      if (enterKeyCondition) {
        event.preventDefault();
        const inputs = getInputs();
        const index = getActiveElement(inputs);
        const nextIndex = index + 1;

        if (nextIndex < inputs.length) {
          focusInput(inputs, nextIndex);
        }
      }

      if (disableEnterKeyFocus && key === 'Enter') {
        event.preventDefault();
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        const inputs = getInputs();
        const index = getActiveElement(inputs);
        const prevIndex = index - 1;

        if (prevIndex >= 0) {
          focusInput(inputs, prevIndex);
        }

        if (prevIndex === 0) {
          scrollBackToTop();
        }
      }
    }

    const form = getForm();

    if (form) {
      form.addEventListener('keydown', keyboardShortcuts, false);
    }

    return () => {
      if (form) {
        form.removeEventListener('keydown', keyboardShortcuts, false);
      }
    };
  }, [disableEnterKeyFocus]);

  return formRef;
}

export { useKeyboardShortcuts };
