import { Layout } from 'components/CommonTool/Layout.tsx';
import { Header } from 'components/CommonTool/Header.tsx';
import { CanvasToolV2 } from 'components/CanvasToolV2';

import type { FormContentProps } from './types.ts';
import { Actions } from './Actions.tsx';
import { Fields } from './Fields.tsx';
import { BodyLayout } from './BodyLayout.tsx';
import { getPageNumberRefs } from './utils/get-page-number-refs.ts';

function FormContent({
  isSubmitting,
  thumbnails,
  urls,
  ...props
}: FormContentProps) {
  const { claim, isLockedByCurrentUser } = props;
  const pageNumberRefs = getPageNumberRefs(thumbnails);

  const getPageRef = (observationId: string, page: number) => {
    const key = `${observationId}-${page}`;
    return pageNumberRefs[key];
  };

  return (
    <Layout
      header={
        <Header
          leftColumn={<div />}
          rightColumn={
            <Actions
              claimId={claim.id}
              isSubmitting={isSubmitting}
              isLockedByCurrentUser={isLockedByCurrentUser}
            />
          }
        />
      }
      body={
        <BodyLayout
          canvas={
            <CanvasToolV2
              urls={urls}
              bottomOffset={1}
              canvasProps={{
                sx: {
                  p: 0,
                  pl: 2,
                  '& > div': { backgroundColor: 'background.paper' },
                },
              }}
              headerProps={{
                sx: {
                  backgroundColor: 'background.paper',
                  height: 'auto',
                  p: 1,
                },
              }}
              order={{
                thumbnails: 1,
                canvas: 2,
              }}
              hasThumbnails
              hasPageCounter={false}
              thumbnails={thumbnails.map(({ observationId, title, url }) => ({
                documentId: observationId,
                documentGroup: title,
                url,
              }))}
            />
          }
          fields={<Fields {...props} getPageRef={getPageRef} />}
        />
      }
    />
  );
}

export { FormContent };
