import { Avatar } from 'components/Avatar';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useGetCurrentUser } from 'state/queries/current-user';
import { createAvatar } from 'utils/create-avatar';

const AVATAR_STYLES = {
  mr: 1,
  width: 32,
  height: 32,
  fontSize: '14px',
};

function UserAvatar() {
  const { data, isPending, isError } = useGetCurrentUser();

  return isPending ? (
    <LoadingIcon />
  ) : isError ? (
    <Avatar sx={AVATAR_STYLES} />
  ) : (
    <Avatar
      alt={data.firstName}
      color={createAvatar(data.firstName).color}
      sx={AVATAR_STYLES}
    >
      {createAvatar(data.firstName).name}
    </Avatar>
  );
}

export { UserAvatar };
