import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Layout } from 'components/CommonTool/Layout.tsx';

import { BodyLayout } from './BodyLayout.tsx';

function LoadingUI() {
  return (
    <Layout
      header={
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            height: 1,
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <div />
          <Skeleton variant="rounded" width={195} height={36} />
        </Stack>
      }
      body={
        <BodyLayout
          canvas={null}
          fields={
            <Stack spacing={2} sx={{ p: 2 }}>
              <Skeleton variant="rounded" width={223} height={44} />
              <Skeleton variant="rounded" width="100%" height={400} />
            </Stack>
          }
        />
      }
    />
  );
}

export { LoadingUI };
