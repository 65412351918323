import { type ReactNode, useEffect } from 'react';
import { useLayoutEffect, useRef } from 'react';
import debounce from '@mui/utils/debounce';

interface ScrollableContainerProps {
  children: ReactNode;
  offset?: number;
}

function ScrollableContainer({
  children,
  offset = 0,
}: ScrollableContainerProps) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = ref.current;

    if (container) {
      const rect = container.getBoundingClientRect();
      const height = window.innerHeight - rect.top - offset - 1;
      container.style.height = `${height}px`;
    }
  }, [offset]);

  useEffect(() => {
    const container = ref.current;

    if (container?.parentElement) {
      const callback = debounce(() => {
        const rect = container.getBoundingClientRect();
        const height = window.innerHeight - rect.top - offset - 1;
        container.style.height = `${height}px`;
      }, 100);
      const observer = new ResizeObserver(callback);

      observer.observe(container.parentElement as Element);

      return () => {
        observer.disconnect();
      };
    }
  }, [offset]);

  return (
    <div ref={ref} style={{ overflowY: 'auto', minHeight: '100%' }}>
      {children}
    </div>
  );
}

export { ScrollableContainer };
