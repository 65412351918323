import { useEffect, useState } from 'react';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { TypographyWithTranslation } from 'components/with-translation.tsx';

import type {
  CanvasPDFToolProps,
  LoadedPDF,
  Thumbnail,
  UrlList,
} from './types.ts';
import { CanvasToolV2 } from './index.tsx';
import { loadPDF } from './load-pdf.ts';
import { calculateRelativePageOffset, getPDFSnapShot } from './pdf-utils.ts';

type State = {
  isLoading: boolean;
  thumbnails: Thumbnail[];
  urls: UrlList;
};

function CanvasPDFTool({ files }: CanvasPDFToolProps) {
  const [{ isLoading, urls, thumbnails }, setState] = useState<State>({
    isLoading: true,
    thumbnails: [],
    urls: [],
  });

  useEffect(() => {
    const extractPDFData = async (url: string) => {
      try {
        const { getPage, numPages } = await loadPDF(url);

        if (getPage) {
          const pages = await Promise.all(
            Array.from({ length: numPages }, async (_, i) => {
              return await getPage(i);
            })
          );

          return { pages, numPages, url };
        }
      } catch (e) {
        return {
          pages: [],
          numPages: 0,
          url,
        };
      }
    };

    const loadPDFData = async () => {
      // get only PDF files and extract data
      const promises = files
        .map(({ fileType, url }) => {
          if (fileType.includes('pdf')) {
            return extractPDFData(url);
          }

          return null;
        })
        .filter(Boolean);

      // resolve all promises and create a dictionary
      const pdfs = (await Promise.all(promises)) as LoadedPDF[];
      const pdfMap = new Map(pdfs.map((pdf) => [pdf.url, pdf]));

      const urls = files.reduce((acc, file) => {
        const { fileType, url } = file;

        if (fileType.includes('pdf') && pdfMap.has(url)) {
          const { numPages } = pdfMap.get(url) as LoadedPDF;
          const urls = Array.from({ length: numPages }, () => ({
            fileType: 'pdf',
            url,
          }));
          return [...acc, ...urls];
        }

        return [...acc, { fileType: 'jpg', url }];
      }, [] as UrlList);

      const thumbnailsPromises = urls.map(async (item, index) => {
        const obj = {
          documentId: item.url,
          documentGroup: '',
          url: item.url,
        };

        if (item.fileType.includes('pdf') && pdfMap.has(item.url)) {
          const { pages } = pdfMap.get(item.url) as LoadedPDF;

          if (!pages.length) {
            return obj;
          }

          const page =
            pages[calculateRelativePageOffset(urls, item.url, index)];

          try {
            const result = await getPDFSnapShot(page, 0.1);

            if (result) {
              obj.url = result;
            } else {
              throw new Error('Failed to get PDF snapshot');
            }
          } catch (e) {
            obj.url = '';
          }
        }

        return obj;
      });
      const thumbnails = await Promise.all(thumbnailsPromises);

      setState({ isLoading: false, urls, thumbnails });
    };

    void loadPDFData();
  }, [files]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (urls.length) {
    return (
      <CanvasToolV2
        key="loaded"
        loadPDFFn={loadPDF}
        urls={urls}
        hasThumbnails
        thumbnails={thumbnails}
      />
    );
  }

  return <TypographyWithTranslation i18nKey="failedToLoadImage" />;
}

export { CanvasPDFTool };
