import { useRef, useState } from 'react';
import { DialogModal } from 'components/dialog/Modal/DialogModal';
import { cancelButton, header, text } from 'components/dialog/generators';

interface LockedDialogProps {
  lastLockedBy: string | null;
  lockedByCurrentUser: boolean;
  locked: boolean;
}

function LockedDialog({
  lockedByCurrentUser,
  locked,
  lastLockedBy,
}: LockedDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const lockedModalShown = useRef<boolean>(false);
  const closeModal = () => setIsOpen(false);

  if (!lockedModalShown.current && locked && !lockedByCurrentUser) {
    setIsOpen(true);
    lockedModalShown.current = true;
  }

  return (
    <DialogModal
      getDialog={{
        index() {
          return [
            header('lockedDialog.title', {
              i18nOptions: { email: lastLockedBy },
            }),
            text('lockedDialog.description'),
            cancelButton({
              action: closeModal,
              icon: null,
              label: 'lockedDialog.closeButton',
            }),
          ];
        },
      }}
      handleClose={closeModal}
      isOpen={isOpen}
    />
  );
}

export { LockedDialog };
