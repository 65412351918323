import type {
  AllDocumentTypes,
  Document,
  DocumentsPage,
  DocumentStageCount,
  DocumentTypeCount,
  DocumentValidation,
  TasksCount,
} from 'types/Documents';
import { transformPage } from 'api/transformers/page';
import { toCamelCase } from 'utils/string';
import { transformKeys } from 'utils/object';

import type {
  DocumentApiResponse,
  DocumentHistoryApiResponse,
  DocumentStageCountApiResponse,
  DocumentsTypesCountApiResponse,
  DocumentTypesApiResponse,
  DocumentValidationApiResponse,
  TasksCountApiResponse,
} from './types/documents';
import type { PaginationApiResponse } from './types/pagination.ts';

export function transformDocumentResponse({
  document_id,
  pages,
  ...document
}: DocumentApiResponse): Document {
  return {
    id: document_id,
    ...transformKeys(document, toCamelCase),
  };
}

export function transformDocumentsResponse(
  response: PaginationApiResponse<DocumentApiResponse>
): DocumentsPage {
  return transformPage(transformDocumentResponse)(response);
}

export function transformDocumentHistoryResponse({
  history,
}: DocumentHistoryApiResponse): Document[] {
  return history.map(transformDocumentResponse);
}

export function transformDocumentHistoryVersionResponse(
  document: DocumentApiResponse
): Document {
  return transformDocumentResponse(document);
}

export function transformDocumentTypesResponse({
  client_document_types,
  document_types,
}: DocumentTypesApiResponse): AllDocumentTypes {
  return {
    clientDocumentTypes: client_document_types,
    documentTypes: transformKeys(document_types, toCamelCase),
  };
}

export function transformDocumentsStageCountResponse({
  stages,
  total,
}: DocumentStageCountApiResponse): DocumentStageCount {
  return {
    stages,
    total,
  };
}

export function transformDocumentsTypesCountResponse({
  document_types,
  total,
}: DocumentsTypesCountApiResponse): DocumentTypeCount {
  return {
    documentTypes: document_types.map(({ document_type, count }) => ({
      documentType: document_type,
      count,
    })),
    total,
  };
}

export function transformDocumentsValidationResponse({
  fields,
  validation_results,
  stage,
}: DocumentValidationApiResponse): DocumentValidation {
  return {
    fields,
    validationResults: validation_results,
    stage,
  };
}

export function transformTasksCountResponse(
  response: TasksCountApiResponse
): TasksCount {
  return transformKeys(response, toCamelCase);
}
