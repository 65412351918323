import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation.tsx';
import { generateUuid } from 'utils/generate-uuid.ts';

import type { LineItemsGroupProps, List, Resources } from './types.ts';
import { LineItem } from './LineItem.tsx';
import { DELETED_INPUT_SUFFIX, INVALID, VALID } from './constants.ts';
import { LineSingleItem } from './LineSingleItem.tsx';

function LineItemsGroup({
  filterItemsBy,
  groupedLineItems,
  historicalResources,
  lastLineIdx,
  lineValidity,
  resources,
  title,
  updateStore,
}: LineItemsGroupProps) {
  const { getValues, setValue } = useFormContext();

  const addLineItem = () => {
    const lastLineItemsIds = groupedLineItems.get(lastLineIdx) || [];
    const newLineIdx = lastLineIdx + 1;

    const { newList, newResources } = lastLineItemsIds.reduce(
      (acc, id) => {
        const item = resources[id];
        const newId = generateUuid();
        const resource = {
          ...item,
          id: newId,
          lineIdx: newLineIdx,
          valid: false,
          value: '',
        };

        return {
          newList: [...acc.newList, newId],
          newResources: { ...acc.newResources, [newId]: resource },
        };
      },
      { newList: [] as List, newResources: {} }
    );

    updateStore(newList, newResources as Resources);
    newList.forEach((id) => setValue(id, ''));
  };

  const deleteLineItem = (lineIdx: number) => {
    const ids = groupedLineItems.get(lineIdx);

    if (!ids) {
      return;
    }

    ids.forEach((id) => {
      const deletedId = `${id}${DELETED_INPUT_SUFFIX}`;
      const value = getValues(deletedId);

      setValue(deletedId, !value);
    });
  };

  const lines = Array.from(groupedLineItems.entries());
  const filteredLines = lines.filter((_, i) => {
    if (filterItemsBy === VALID) {
      return lineValidity.get(i);
    }

    if (filterItemsBy === INVALID) {
      return !lineValidity.get(i);
    }

    return true;
  });

  return (
    <div>
      <TypographyWithTranslation
        variant="h6"
        i18nKey={title ? 'form.lineItemsWithTitle' : 'form.lineItems'}
        options={{
          title,
        }}
        sx={{
          fontSize: '0.75rem !important',
          pt: 2,
          textTransform: 'uppercase',
        }}
      />

      <Stack>
        {filteredLines.map(([key, value], index) => {
          const LineItemComponent =
            value.length === 1 ? LineSingleItem : LineItem;

          return (
            <LineItemComponent
              deleteLineItem={deleteLineItem}
              key={key}
              historicalResources={historicalResources}
              index={index}
              isValid={lineValidity.get(key)}
              lineIdx={key}
              list={value}
              resources={resources}
              size={groupedLineItems.size}
            />
          );
        })}
      </Stack>

      <Box sx={{ display: 'flex', width: 1, mt: 1 }}>
        <ButtonWithTranslation
          variant="contained"
          i18nKey="form.addLineItemButton"
          sx={{ margin: '0 auto' }}
          onClick={addLineItem}
        />
      </Box>
    </div>
  );
}

export { LineItemsGroup };
