import Stack from '@mui/material/Stack';
import type { LineLevelContent } from 'types/Documents.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';

import type { AllFieldLineItemsProps } from './types.ts';
import { getLineClientKey } from './utils/get-line-clientkey.ts';
import { InputField } from './InputField.tsx';

function AllFieldLineItems({
  getPageRef,
  list,
  observationId,
  resources,
}: AllFieldLineItemsProps) {
  const { t } = useTranslationRoot();

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      {list.map((id) => {
        const item = resources[id];
        const label = getLineClientKey(
          t('form.line'),
          (item as LineLevelContent).lineIdx + 1,
          item.clientKey
        );
        const pageIdx = getPageRef(observationId, item.pageIdx || 0);

        return (
          <InputField
            key={id}
            {...item}
            id={id}
            label={label}
            pageIdx={pageIdx}
          />
        );
      })}
    </Stack>
  );
}

export { AllFieldLineItems };
