import { useState } from 'react';
import type { ClaimsValidateResult } from 'types/Claims.ts';

import type { InternalState as State } from './types.ts';

enum ActionType {
  INIT = 'INIT',
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_VALIDATION_RESULTS = 'UPDATE_VALIDATION_RESULTS',
}

type Action =
  | {
      type: ActionType.INIT;
      initialState: State;
    }
  | {
      type: ActionType.UPDATE_FIELD;
      fieldId: string;
      value: string;
    }
  | {
      type: ActionType.UPDATE_VALIDATION_RESULTS;
      validationResults: ClaimsValidateResult[];
    };

export function updateField(fieldId: string, value: string): Action {
  return {
    type: ActionType.UPDATE_FIELD,
    fieldId,
    value,
  };
}

export function updateValidationResults(
  validationResults: ClaimsValidateResult[]
): Action {
  return {
    type: ActionType.UPDATE_VALIDATION_RESULTS,
    validationResults,
  };
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.INIT:
      return {
        ...state,
        ...action.initialState,
      };
    case ActionType.UPDATE_FIELD: {
      const { fieldId, value } = action;
      const { fieldsListIds } = state;
      const observationId = Object.keys(fieldsListIds).find((observationId) =>
        fieldsListIds[observationId].includes(fieldId)
      );

      if (observationId) {
        const newState = { ...state };

        const fieldIndex = fieldsListIds[observationId].findIndex(
          (id) => id === fieldId
        );

        if (fieldIndex !== -1) {
          newState.observations[observationId].content.fields[
            fieldIndex
          ].value = value;
        }

        return newState;
      }

      return state;
    }
    case ActionType.UPDATE_VALIDATION_RESULTS: {
      const { validationResults } = action;
      const newState = { ...state };

      newState.claim.validationResults = validationResults;

      return newState;
    }
    default:
      return state;
  }
}

export function useInternalState(initialState: State) {
  const [state, setState] = useState<State>(initialState);

  const dispatch = (action: Action) => {
    setState((prevState) => reducer(prevState, action));
  };

  const getState = () => state;

  return { dispatch, getState };
}
