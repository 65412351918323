import type { UserManagementPage } from 'types/UserManagement.ts';
import { transformPage } from 'api/transformers/page.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import type { PaginationApiResponse } from './types/pagination.ts';
import type { UserManagementApiResponse } from './types/user-management.ts';

export function transformUserManagementUserResponse(
  res: UserManagementApiResponse
) {
  return transformKeys(res, toCamelCase);
}

export function transformUserManagementResponse(
  response: PaginationApiResponse<UserManagementApiResponse>
): UserManagementPage {
  return transformPage(transformUserManagementUserResponse)(response);
}
