import type { MouseEvent } from 'react';
import type { LinearProgressProps } from '@mui/material/LinearProgress';
import type { Theme } from '@mui/material/styles';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TypographyWithTranslation } from 'components/with-translation';
import { round } from 'utils/numbers';

type Props = Omit<LinearProgressProps, 'color'>;

interface Claims extends Props {
  color: (theme: Theme) => string;
  name: string;
  quantity: number;
}

interface ProgressChartProps {
  data: Claims[];
}

function Legend({ data }: ProgressChartProps) {
  const theme = useTheme();

  return (
    <>
      <TypographyWithTranslation
        variant="body2"
        sx={{
          fontSize: 12,
          mb: 1,
        }}
        i18nKey="claimsPerformance.totalClaimsDoneIn"
      />
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        {data.map(({ color, name }) => (
          <Grid key={name}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                mr: 1.5,
              }}
            >
              <Box
                component="span"
                // @ts-ignore
                sx={{
                  display: 'block',

                  backgroundColor: (theme) =>
                    color(theme) || theme.palette.grey,

                  borderRadius: '4px',
                  displayPrint: 'none',
                  height: '12px',
                  width: '12px',
                }}
              />
              <SvgIcon
                height={12}
                width={12}
                viewBox="0 0 12 12"
                sx={{
                  display: 'none',
                  displayPrint: 'block',
                  height: '12px',
                  m: '0 !important',
                  width: '12px',
                }}
              >
                <rect
                  height={12}
                  width={12}
                  // @ts-ignore
                  fill={color ? color(theme) : theme.palette.grey}
                  rx="4"
                />
              </SvgIcon>
              <TypographyWithTranslation
                variant="body2"
                sx={{
                  fontSize: 12,
                }}
                i18nKey={name}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function ProgressChart({ data, ...props }: ProgressChartProps) {
  const [popover, setPopover] = useState<{
    fill: (theme: Theme) => string;
    left: number;
    top: number;
    index: undefined | number;
  }>({
    fill: () => '',
    left: 0,
    top: 0,
    index: undefined,
  });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  function handleOpen(index: number) {
    return (e: MouseEvent) => {
      const element = e.currentTarget;
      const bar = element.firstChild as Element;
      const rect = bar!.getBoundingClientRect();

      setAnchorEl(element);

      if (popover.left !== rect.right || popover.top !== rect.top) {
        setPopover({
          fill: data[index].color,
          left: Math.ceil(rect.right),
          top: Math.ceil(rect.top),
          index,
        });
      }
    };
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function backgroundColor(theme: Theme) {
    return typeof popover.fill !== 'undefined'
      ? popover.fill(theme)
      : theme.palette.common.white;
  }

  const open = Boolean(anchorEl);

  return (
    <Grid
      container
      sx={{
        paddingTop: 3,
      }}
    >
      <Grid size={12}>
        <Stack spacing={3} sx={{ mb: 5 }}>
          {data.map(({ color, name, value }, index) => (
            <LinearProgress
              variant="determinate"
              key={name}
              value={value}
              sx={{
                bgcolor: 'grey.50016',
                height: 8,
                [`.${linearProgressClasses.bar}`]: {
                  background: (theme) => color(theme),
                },
              }}
              onMouseEnter={handleOpen(index)}
              onMouseLeave={handleClose}
            />
          ))}
        </Stack>
        <Popover
          anchorEl={anchorEl}
          anchorPosition={{ left: popover.left, top: popover.top }}
          anchorReference="anchorPosition"
          disableRestoreFocus
          id="mouse-over-popover"
          open={open}
          sx={{
            pointerEvents: 'none',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            sx: {
              background: 'transparent',
              border: 'none',
              boxShadow: 'none',
              overflow: 'hidden',
            },
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              aspectRatio: '1',
              backgroundColor,
              borderRadius: '50%',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: 'common.white',
                fontWeight: 'bold',
                p: 1,
                minWidth: '44px',
              }}
            >
              {typeof popover.index === 'number'
                ? round(Number(data[popover.index].value), 0)
                : null}
              {`%`}
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              mb: '12px',
              '&::before': {
                backgroundColor,
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 22,
                height: 22,
                top: -17,
                transform: 'rotate(45deg)',
                left: 'calc(50% - 11px)',
                zIndex: '-1',
              },
            }}
          />
        </Popover>
      </Grid>
      <Grid size={12}>
        <Legend data={data} {...props} />
      </Grid>
    </Grid>
  );
}

export { ProgressChart };
