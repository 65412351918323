import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { RoleSelectorWithController } from 'components/RoleSelectorWithController';
import { USER_MANAGEMENT_CREATE_USER_PAGE } from 'constants/translation-keys';
import {
  LoadingButtonWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation';

export type CreateUserFormValues = {
  email: string;
  givenName: string;
  familyName: string;
  signupUserRole?: string;
};

interface CreateClientFormProps {
  isLoadingButton?: boolean;
  onSubmit: (values: CreateUserFormValues, onSubmitSuccess: () => void) => void;
}

const schema = object({
  email: string().required('createUserForm.emailRequired'),
  givenName: string().required('createUserForm.givenNameRequired'),
  familyName: string().required('createUserForm.familyNameRequired'),
});

export function CreateUserForm({
  isLoadingButton,
  onSubmit,
}: CreateClientFormProps) {
  const { t } = useTranslation(USER_MANAGEMENT_CREATE_USER_PAGE);
  const methods = useForm<CreateUserFormValues>({
    defaultValues: {
      email: '',
      givenName: '',
      familyName: '',
      signupUserRole: undefined,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = useFormState({
    control: methods.control,
  });

  function handleFormSubmit(values: CreateUserFormValues) {
    onSubmit(values, () => {
      methods.reset();
    });
  }

  return (
    <Container maxWidth="sm" disableGutters sx={{ ml: 0 }}>
      <Card
        sx={{
          padding: 3,
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
              <TypographyWithTranslation
                i18nKey="createUserForm.title"
                variant="h5"
              />
              <ControllerWithTextField
                label={t('createUserForm.email')}
                name="email"
                type="email"
              />
              <ControllerWithTextField
                label={t('createUserForm.givenName')}
                name="givenName"
                type="text"
              />
              <ControllerWithTextField
                label={t('createUserForm.familyName')}
                name="familyName"
                type="text"
              />
              <RoleSelectorWithController name="signupUserRole" />
            </Stack>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButtonWithTranslation
                type="submit"
                variant="contained"
                loading={isSubmitting || isLoadingButton}
                i18nKey="createUserForm.submitButton"
              />
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Container>
  );
}
