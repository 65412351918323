import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { DOCUMENT_SEARCH_ICON } from 'constants/public-icons';
import { RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CONSISTENCY_CHECK_PAGE } from 'constants/translation-keys';

const path = `/cc/:${RESOURCE_ID}`;
const ConsistencyCheckPage = loadable(
  lazy(() => import('./ConsistencyCheck.tsx'))
);

const consistencyCheckRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  icon: DOCUMENT_SEARCH_ICON,
  element: <ConsistencyCheckPage />,
  permission: ROUTE_PERMISSIONS.consistencyCheck,
  i18nNamespace: CONSISTENCY_CHECK_PAGE,
};

export default consistencyCheckRoute;
