import { useState } from 'react';

import type { FilterTypes } from './types.ts';
import { ALL, INVALID, VALID } from './constants.ts';

function useFilterItems() {
  const [filterItemsBy, setFilterItemsBy] = useState<FilterTypes>(ALL);
  const filterItemsByInvalid = filterItemsBy === INVALID;
  const filterItemsByValid = filterItemsBy === VALID;

  return {
    filterItemsBy,
    filterItemsByInvalid,
    filterItemsByValid,
    setFilterItemsBy,
  };
}

export { useFilterItems };
