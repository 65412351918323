import { useLocales } from 'locales/useLocales';
import { useTooltip } from 'components/customHooks/useTooltip';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { LanguageIcon } from 'components/icons/LanguageIcon';

import { LanguageTooltip } from './LanguageTooltip.tsx';

function LanguageButton() {
  const { currentLanguage } = useLocales();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  return (
    <>
      <IconButtonAnimate
        isOpen={isOpen}
        onClick={handleOpen}
        data-testid="language-selector-button"
      >
        <LanguageIcon
          colors={currentLanguage.colors}
          text={currentLanguage.value}
          sx={{
            borderRadius: '50%',
            height: 40,
            width: 40,
          }}
        />
      </IconButtonAnimate>

      <LanguageTooltip
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        element={element}
        handleClose={handleClose}
        isOpen={isOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      />
    </>
  );
}

export { LanguageButton };
