import type { Comments } from 'types/Comments.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import { CommentsApiResponse } from './types/comments';

export function transformCommentsResponse(
  response: CommentsApiResponse
): Comments {
  return transformKeys(response, toCamelCase);
}
