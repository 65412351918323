import type { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

interface RadioOptionsProps {
  name: string;
  options: { label: string; value: string | number }[];
}

function StageOptions({ name, options }: RadioOptionsProps) {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        const { name, value, onChange, ...rest } = field;

        const handleChange = (event: SyntheticEvent) => {
          onChange([(event.target as HTMLInputElement).value]);
        };

        return (
          <RadioGroup data-testid="stage-group-radio" name={name}>
            {options.map((option) => (
              <FormControlLabel
                {...rest}
                checked={value?.includes(option.value)}
                data-testid="stage-option-radio"
                key={option.value}
                control={<Radio />}
                label={option.label}
                value={option.value}
                onChange={handleChange}
              />
            ))}
          </RadioGroup>
        );
      }}
      name={name}
      control={control}
    />
  );
}

export { StageOptions };
