import { interpolate, interpolateRgbBasis } from 'd3-interpolate';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ARROW_UP_BOLD_ICON } from 'constants/public-icons.ts';

import type { InputDropDownOptionProps } from './types.ts';

function InputDropDownOption({
  option,
  optionRenderValues,
  optionProps,
}: InputDropDownOptionProps) {
  const theme = useTheme();
  const foundOption = option;
  const colourRange = interpolateRgbBasis([
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.success.main,
  ]);
  const angle = interpolate(180, 0);

  if (foundOption) {
    const { score, ...rest } = foundOption;
    const text = Object.keys(rest)
      .filter((key) =>
        optionRenderValues.length ? optionRenderValues.includes(key) : true
      )
      .map((key) => rest[key])
      .join(', ');
    const confidence = score ? Number(score) : 0;

    return (
      <Stack
        direction="row"
        spacing={1}
        component="li"
        {...optionProps}
        sx={{ alignItems: 'center' }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex', flex: '0 0 20px' }}>
          {confidence > 0 && (
            <SvgIconStyle
              height={20}
              width={20}
              src={ARROW_UP_BOLD_ICON}
              sx={{
                color: colourRange(confidence),
                transform: `rotate(${angle(confidence)}deg)`,
              }}
            />
          )}
        </Box>
        <Box>
          <Typography variant="body2">{text}</Typography>
        </Box>
      </Stack>
    );
  }

  return null;
}

export { InputDropDownOption };
