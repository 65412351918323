import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  EDIT_ICON,
  RETRY_ICON,
  TRASH_ICON,
  CHECK_MARK_ICON,
  CLOSE_ICON,
} from 'constants/public-icons';
import { useTranslationRoot } from 'components/with-translation';
import { DialogModal } from 'components/dialog/Modal/DialogModal';
import {
  cancelButton,
  deleteButton,
  header,
  text,
} from 'components/dialog/generators';

interface UserManagementActions {
  handleResendPassword: () => void;
  handleEditUser: () => void;
  handleDeleteUser: () => void;
  handleSave: () => void;
  handleCancel: () => void;
  isEditingMode: boolean;
  hasChanged: boolean;
}

function UserManagementActions({
  handleResendPassword,
  handleEditUser,
  handleDeleteUser,
  handleSave,
  handleCancel,
  isEditingMode = false,
  hasChanged,
}: UserManagementActions) {
  const { t } = useTranslationRoot();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return isEditingMode ? (
    <Box
      sx={{
        gap: 1,
      }}
    >
      <Tooltip title={t('tooltip.save')}>
        <span>
          <IconButton
            type="submit"
            onClick={handleSave}
            disabled={!hasChanged}
            sx={{
              cursor: !hasChanged ? 'pointer' : 'not-allowed',
            }}
          >
            <SvgIconStyle src={CHECK_MARK_ICON} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('tooltip.cancel')}>
        <IconButton onClick={handleCancel}>
          <SvgIconStyle src={CLOSE_ICON} />
        </IconButton>
      </Tooltip>
    </Box>
  ) : (
    <Box
      sx={{
        gap: 1,
      }}
    >
      <Tooltip
        title={t('tooltip.resendPassword')}
        data-testid="resend-password"
      >
        <IconButton onClick={handleResendPassword}>
          <SvgIconStyle src={RETRY_ICON} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.edit')} data-testid="edit-user">
        <IconButton onClick={handleEditUser}>
          <SvgIconStyle src={EDIT_ICON} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tooltip.delete')} data-testid="delete-user">
        <IconButton onClick={() => setOpen(true)}>
          <SvgIconStyle src={TRASH_ICON} />
        </IconButton>
      </Tooltip>
      <DialogModal
        ariaDescribedBy="delete-dialog"
        getDialog={{
          index: () => [
            header(t('dialog.deleteTitle')),
            text('dialog.deleteMessage'),
            cancelButton({
              action: handleClose,
              label: 'dialog.cancelButton',
            }),
            deleteButton({
              action: () => {
                handleDeleteUser();
                handleClose();
              },
              label: 'dialog.deleteButton',
              ['data-testid']: 'confirm-delete-button',
            }),
          ],
        }}
        handleClose={handleClose}
        isOpen={open}
      />
    </Box>
  );
}

export { UserManagementActions };
