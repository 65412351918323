import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { PAGE_ID, RESOURCE_ID } from 'constants/route-keys';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { DOCUMENT_CORRECTION_PAGE } from 'constants/translation-keys';

const path = `/d/:${RESOURCE_ID}`;
const DocumentCorrectionPage = loadable(
  lazy(() => import('./DocumentCorrection.tsx'))
);

const historyPath = `/d/:${RESOURCE_ID}/history/:${PAGE_ID}`;
const documentCorrectionHistoryRoute = {
  path: historyPath,
  createPath: (resourceId: string, pageId?: string) =>
    generatePath(historyPath, { resourceId, pageId }),
  permission: ROUTE_PERMISSIONS.hitl,
  i18nNamespace: DOCUMENT_CORRECTION_PAGE,
  preload: () => {
    return import('./DocumentCorrection.tsx');
  },
};

const documentCorrectionRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  element: <DocumentCorrectionPage />,
  permission: ROUTE_PERMISSIONS.hitl,
  children: [documentCorrectionHistoryRoute],
  i18nNamespace: DOCUMENT_CORRECTION_PAGE,
  preload: () => {
    return import('./DocumentCorrection.tsx');
  },
};

export default documentCorrectionRoute;
export { documentCorrectionHistoryRoute };
