import type { Column } from 'components/table/AdvancedTable';
import Card from '@mui/material/Card';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { CHECK_MARK_ICON, CLOSE_ICON } from 'constants/public-icons';
import { AdvancedSearch } from 'components/table/AdvancedSearch';
import { useCurrentUserEmail } from 'state/queries/current-user';
import {
  resendPassword,
  useDeleteUser,
  useGetUserManagementData,
  useGetUserManagementTotal,
  useRecreateUser,
} from 'state/queries/user-management';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation';
import { RoleSelector } from 'components/RoleSelector';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';
import { useAdvancedSearch } from 'components/customHooks/useAdvancedSearch.ts';
import { USER_MANAGEMENT_PAGE } from 'constants/translation-keys.ts';

import { MANAGE_USERS_TAB } from './UserManagement.tsx';
import { UserManagementActions } from './UserManagementActions.tsx';
import { UsersTable } from './UsersTable.tsx';

const EMAIL = 'email';
const GIVEN_NAME = 'given_name';
const FAMILY_NAME = 'family_name';

function UsersList() {
  const { t } = useTranslationRoot(USER_MANAGEMENT_PAGE);
  const pagination = useAdvancedTablePagination({});
  const { page, rowsPerPage } = pagination as {
    page: number;
    rowsPerPage: number;
  };
  const { handleSearch, searchValue, searchField } = useAdvancedSearch();

  const params = {
    page,
    rowsPerPage,
    searchValue,
    searchField,
  };

  const usersQuery = useGetUserManagementData(params);

  const usersDataTotal = useGetUserManagementTotal(params);
  const recreateUserMutation = useRecreateUser(params);
  const deleteUserMutation = useDeleteUser(params);
  const currentUserEmailQuery = useCurrentUserEmail();

  const onSave = (data) => {
    recreateUserMutation.mutate({
      email: data.email,
      givenName: data.givenName,
      familyName: data.familyName,
      signupUserRole: data.signupUserRole,
    });
  };

  const handleResendPassword = (email) => {
    toast.promise(resendPassword(email), {
      loading: t('userManagement.resendPasswordLoading'),
      success: t('userManagement.resendPasswordSuccess'),
      error: t('userManagement.resendPasswordError'),
    });
  };

  const handleDeleteUser = (email) => {
    deleteUserMutation.mutate(email);
  };

  const advancedSearchMarkup = (
    <AdvancedSearch
      defaultValue={searchValue}
      handleSearch={handleSearch}
      hasSuperAdminPermission
      sanitiseSearchValue={false}
      searchField={searchField || 'email'}
      menuItems={[
        { value: EMAIL, label: t('userManagement.email') },
        { value: GIVEN_NAME, label: t('userManagement.givenName') },
        { value: FAMILY_NAME, label: t('userManagement.familyName') },
      ]}
    />
  );

  return (
    <TabPanel
      key="manageUsers"
      value={MANAGE_USERS_TAB}
      data-testid="users-list"
    >
      <Card>
        <UsersTable
          toolbarEndAdornment={advancedSearchMarkup}
          columns={
            [
              {
                id: 'email',
                i18nKey: 'userManagement.email',
              },
              {
                id: 'givenName',
                i18nKey: 'userManagement.givenName',
                renderCell: ({ row, isEditable, inputs, updateInputs }) => {
                  return isEditable ? (
                    <TextField
                      name="givenName"
                      value={inputs.givenName}
                      onChange={(e) =>
                        updateInputs('givenName', e.target.value)
                      }
                      slotProps={{
                        htmlInput: {
                          sx: { fontSize: 14, padding: 1 },
                        },
                      }}
                    />
                  ) : (
                    row.givenName
                  );
                },
                rowSx: {
                  py: 1,
                  '&:first-of-type': {
                    pl: 2,
                  },
                },
              },
              {
                id: 'familyName',
                i18nKey: 'userManagement.familyName',
                renderCell: ({ isEditable, row, inputs, updateInputs }) => {
                  return isEditable ? (
                    <TextField
                      name="familyName"
                      value={inputs.familyName}
                      onChange={(e) =>
                        updateInputs('familyName', e.target.value)
                      }
                      slotProps={{
                        htmlInput: {
                          sx: { fontSize: 14, padding: 1 },
                        },
                      }}
                    />
                  ) : (
                    row.familyName
                  );
                },
                rowSx: {
                  py: 1,
                },
              },
              {
                id: 'signupUserRole',
                i18nKey: 'userManagement.role',
                renderCell: ({ isEditable, row, inputs, updateInputs }) => {
                  return isEditable ? (
                    <RoleSelector
                      name="signupUserRole"
                      defaultRole={inputs.signupUserRole}
                      onChange={updateInputs}
                    />
                  ) : row.signupUserRole ? (
                    <Chip
                      label={row.signupUserRole}
                      size="small"
                      variant="outlined"
                    />
                  ) : (
                    <Typography variant="body2" color="text.disabled">
                      {t('userManagement.noRole')}
                    </Typography>
                  );
                },
                rowSx: {
                  p: 1,
                },
              },
              {
                id: 'externalProvider',
                i18nKey: 'userManagement.externalProvider',
                align: 'center',
                renderCell: ({ row }) =>
                  row.externalProvider ? (
                    <SvgIconStyle
                      src={CHECK_MARK_ICON}
                      sx={{
                        color: 'primary.main',
                      }}
                    />
                  ) : (
                    <SvgIconStyle
                      src={CLOSE_ICON}
                      sx={{
                        color: 'error.main',
                      }}
                    />
                  ),
              },
              {
                id: 'actions',
                align: 'right',
                renderCell: ({
                  row,
                  isEditable,
                  toggleIsEditable,
                  inputs,
                  resetState,
                  hasChanged,
                }) => {
                  return (
                    row.email !== currentUserEmailQuery.data &&
                    !row.externalProvider && (
                      <UserManagementActions
                        handleDeleteUser={() => handleDeleteUser(row.email)}
                        handleEditUser={toggleIsEditable}
                        handleResendPassword={() => {
                          handleResendPassword(row.email);
                        }}
                        handleSave={() => {
                          onSave(inputs);
                          toggleIsEditable();
                        }}
                        handleCancel={() => {
                          resetState();
                          toggleIsEditable();
                        }}
                        hasChanged={hasChanged}
                        isEditingMode={isEditable}
                      />
                    )
                  );
                },
                rowSx: {
                  width: '100%',
                  padding: 0,
                  maxHeight: '62px',
                },
              },
            ] as Column[]
          }
          rows={usersQuery.data || []}
          rowIdentifier="email"
          isPending={usersQuery.isPending}
          totalCount={usersDataTotal.data || 0}
          noItemsLabeli18nKey="userManagement.noItems"
          pagination={pagination}
          isError={usersQuery.isError}
          refetch={usersQuery.refetch}
          errorMessage="userManagement.error"
          retryButton="userManagement.retryButton"
        />
      </Card>
    </TabPanel>
  );
}

export default UsersList;
