import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { AppLogo, AppLogoIcon } from 'components/AppLogo.tsx';
import { LinkWithRef } from 'components/Link.tsx';

import dashboardRoute from './dashboard.route.tsx';

interface NavBarLogoProps {
  isMiniNavbar?: boolean;
}

function NavBarLogo({ isMiniNavbar = false }: NavBarLogoProps) {
  return (
    <Link
      component={LinkWithRef}
      to={dashboardRoute.createPath()}
      sx={{ width: 1 }}
    >
      <Stack
        direction="row"
        sx={[
          {
            alignItems: 'center',
            justifyContent: isMiniNavbar ? 'center' : 'space-between',
          },
          isMiniNavbar ? { p: 2, flexShrink: 0 } : { flexShrink: 0, pt: 0.5 },
        ]}
      >
        {isMiniNavbar ? <AppLogoIcon /> : <AppLogo />}
      </Stack>
    </Link>
  );
}

export { NavBarLogo };
