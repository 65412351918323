import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Layout } from 'components/CommonTool/Layout.tsx';

function LoadingUI() {
  return (
    <Layout
      header={<Skeleton animation="wave" variant="rounded" height="100%" />}
      body={
        <Stack
          sx={{
            flexDirection: 'row',
            height: '100%',
          }}
          gap={2}
        >
          <Box
            sx={{
              flex: '1',
            }}
          >
            <Skeleton variant="rounded" animation="wave" height="100%" />
          </Box>
          <Box
            sx={{
              flex: '0 0 388px',
            }}
          >
            <Skeleton variant="rounded" animation="wave" height="100%" />
          </Box>
        </Stack>
      }
    />
  );
}

export { LoadingUI };
