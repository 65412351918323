import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { LoadingButtonWithTranslation } from 'components/with-translation.tsx';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { SAVE_ICON } from 'constants/public-icons';

import type { SaveButtonProps } from './types.ts';

function SaveButton({ disabled, loading, onSave }: SaveButtonProps) {
  const showButton = useFeatureFlag('enableHILSaveDocument');

  if (showButton) {
    return (
      <LoadingButtonWithTranslation
        data-testid="save-document-button"
        disabled={disabled}
        loading={loading}
        loadingPosition="start"
        variant="contained"
        color="info"
        startIcon={<SvgIconStyle height={17} src={SAVE_ICON} width={17} />}
        onClick={onSave}
        i18nKey="form.saveDocumentBtn"
      />
    );
  }

  return null;
}

export { SaveButton };
