import type { ReactNode } from 'react';
import { ErrorBoundary } from '@rollbar/react';
import { useNavigate, useParams } from 'react-router-dom';
import type { CodeMapping } from 'components/ConsistencyCheckTool/types.ts';
import { transformAppConfigCodeMappingsResponse } from 'api/transformers/appconfig.ts';
import { Page } from 'components/Page';
import { GenericErrorFallback } from 'components/GenericErrorFallback';
import {
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import { ConsistencyCheckTool } from 'components/ConsistencyCheckTool';
import { useNextClaimParams } from 'components/customHooks/useNextClaimParams.ts';
import { toast } from 'components/toast';
import { LoadingUI } from 'components/ConsistencyCheckTool/LoadingUI.tsx';
import { FallbackUI } from 'components/ConsistencyCheckTool/FallbackUI.tsx';
import { RESOURCE_ID } from 'constants/route-keys';
import { CONSISTENCY_CHECK_PAGE } from 'constants/translation-keys';
import { CONSISTENCY_CHECK_TABLE } from 'constants/table-pagination.ts';
import consistencyChecksRoute from 'pages/ConsistencyChecks/consistency-checks.route.tsx';
import { useGetClaim, useGetClaimObservations } from 'state/queries/claims';
import { useGetCurrentUser } from 'state/queries/current-user';
import { useGetAppConfig } from 'state/queries/appconfig.ts';

import consistencyCheckRoute from './consistency-check.route.tsx';

function ConsistencyCheck() {
  const { t } = useTranslationRoot(CONSISTENCY_CHECK_PAGE);
  const params = useParams();
  const navigate = useNavigate();
  const claimId = params[RESOURCE_ID] as string;

  const claimQuery = useGetClaim(claimId);
  const observationsQuery = useGetClaimObservations(claimId);
  const currentUserQuery = useGetCurrentUser();
  const getNextClaim = useNextClaimParams(CONSISTENCY_CHECK_TABLE);
  const appConfigQuery = useGetAppConfig({
    name: 'code_mapping',
    select: transformAppConfigCodeMappingsResponse,
  });

  if (
    claimQuery.isPending ||
    observationsQuery.isPending ||
    currentUserQuery.isPending
  ) {
    return (
      <Wrapper>
        <LoadingUI />
      </Wrapper>
    );
  }

  if (
    claimQuery.isError ||
    observationsQuery.isError ||
    currentUserQuery.isError
  ) {
    return (
      <Wrapper>
        <FallbackUI error={claimQuery.error} resetError={claimQuery.refetch} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ConsistencyCheckTool
        claim={claimQuery.data}
        codeMappings={
          appConfigQuery?.data?.codeMappings as unknown as CodeMapping[]
        }
        currentUser={currentUserQuery.data}
        observations={observationsQuery.data}
        onSuccess={async () => {
          try {
            const nextClaim = await getNextClaim(true);

            if (nextClaim) {
              toast.success(t('common.nextClaimLoaded'));
              const to = consistencyCheckRoute.createPath(nextClaim.id);
              navigate(to);
            } else {
              throw new Error('No next claim found');
            }
          } catch (e) {
            navigate(consistencyChecksRoute.createPath());
          }
        }}
      />
    </Wrapper>
  );
}

function Wrapper({ children }: { children: ReactNode }) {
  return (
    <WithTranslationRoot namespace={CONSISTENCY_CHECK_PAGE}>
      <ErrorBoundary fallbackUI={GenericErrorFallback}>
        <Page i18nKeyTitle="meta.title">{children}</Page>
      </ErrorBoundary>
    </WithTranslationRoot>
  );
}

export default ConsistencyCheck;
