import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import type { Arrow } from 'components/MenuPopover.tsx';
import { useLocales } from 'locales/useLocales.ts';
import { MenuPopover } from 'components/MenuPopover.tsx';
import { LanguageIcon } from 'components/icons/LanguageIcon.tsx';

interface LanguageTooltipProps {
  arrow?: Arrow;
  anchorOrigin?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'center' | 'bottom';
  };
  disableArrow?: boolean;
  element: null | HTMLElement;
  handleClose: VoidFunction;
  isOpen: boolean;
  transformOrigin?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'center' | 'bottom';
  };
}

function LanguageTooltip({
  arrow,
  anchorOrigin,
  disableArrow = false,
  element,
  handleClose,
  isOpen,
  transformOrigin,
}: LanguageTooltipProps) {
  const { allLanguages, currentLanguage, onChangeLanguage } = useLocales();

  return (
    <MenuPopover
      anchorEl={element}
      arrow={arrow}
      anchorOrigin={anchorOrigin}
      disabledArrow={disableArrow}
      transformOrigin={transformOrigin}
      onClose={handleClose}
      open={isOpen}
      sx={{
        mt: 1,
        ml: 0.25,
        width: 180,
        [`& .${menuItemClasses.root}`]: {
          px: 1,
          typography: 'body2',
          borderRadius: 0.75,
        },
      }}
    >
      <Stack spacing={0.5}>
        {allLanguages.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLanguage.value}
            onClick={() => {
              onChangeLanguage(option.value);
              handleClose();
            }}
            sx={{ height: 40, borderRadius: 1, px: 1 }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                maxHeight: 28,
                maxWidth: 28,
                mr: 2,
              }}
            >
              <LanguageIcon
                colors={option.colors}
                text={option.value}
                sx={{
                  borderRadius: '50%',
                  height: 28,
                  width: 28,
                }}
              />
            </Box>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Stack>
    </MenuPopover>
  );
}

export { LanguageTooltip };
