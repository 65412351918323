import { useState } from 'react';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';

function useValidationMessageBoolean() {
  const enableAlwaysOnValidationText = useFeatureFlag(
    'enableHILAlwaysOnValidationText'
  );

  const [isValidationMessageOpen, setOpen] = useState(
    enableAlwaysOnValidationText ?? false
  );

  const openValidationMessage = () => {
    setOpen(true);
  };

  const closeValidationMessage = () => {
    setOpen(false);
  };

  return {
    openValidationMessage,
    closeValidationMessage,
    isValidationMessageOpen,
  };
}

export { useValidationMessageBoolean };
