import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { USER_MANAGEMENT_CREATE_USER_PAGE } from 'constants/translation-keys';

const path = '/configuration/user-management/create';
const UserManagementCreateUser = loadable(
  lazy(() => import('./UserManagementCreateUser'))
);

const userManagementCreateUserRoute = {
  path,
  createPath: () => generatePath(path),
  permission: ROUTE_PERMISSIONS.miCreateUser,
  i18nNamespace: USER_MANAGEMENT_CREATE_USER_PAGE,
  element: <UserManagementCreateUser />,
};

export default userManagementCreateUserRoute;
