import type { Claim } from 'types/Claims.ts';
import { getClaimValidationResults } from 'state/selectors/claims.ts';

import type { FieldsListIds, Resources } from '../types.ts';
import { getLineItemPath } from './get-line-item-path.ts';

export function getConsistencyResults({
  claim,
  fieldsListIds,
  resources,
}: {
  claim: Claim;
  fieldsListIds: FieldsListIds;
  resources: Resources;
}) {
  const validationResults = getClaimValidationResults(claim);

  if (!validationResults) {
    return [];
  }

  // @ts-ignore
  return validationResults.reduce((acc, rule) => {
    if (rule.sources?.length > 1) {
      // @ts-ignore
      const { result, sources, title } = rule;
      const ids = sources.map(({ observationId, path }) =>
        fieldsListIds[observationId].find((id) => {
          const item = resources[id];
          const isLineItem = typeof item.lineIdx === 'number';

          if (isLineItem) {
            const { level, lineIdx, sproutaiKey } = getLineItemPath(path);

            return (
              item.sproutaiKey === sproutaiKey &&
              item.lineIdx === parseInt(lineIdx) &&
              item.level === level
            );
          }

          return item.sproutaiKey === path;
        })
      );

      return [...acc, [title, result, ids]];
    }

    return acc;
  }, []) as unknown as [string, 'passed' | 'failed', string[]][];
}
