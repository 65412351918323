import { useBoolean } from 'components/customHooks/useBoolean.ts';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { LoadingButtonWithTranslation } from 'components/with-translation.tsx';
import { SQUARE_MINUS_ICON } from 'constants/public-icons';

import type { RejectButtonProps } from './types.ts';
import { RejectDialog } from './RejectDialog.tsx';

function RejectButton({ disabled, handleReject, loading }: RejectButtonProps) {
  const [isOpen, openModal, closeRejectModal] = useBoolean();

  return (
    <>
      <LoadingButtonWithTranslation
        data-testid="reject-document-button"
        disabled={disabled}
        loading={loading}
        variant="contained"
        color="error"
        startIcon={
          <SvgIconStyle height={17} src={SQUARE_MINUS_ICON} width={17} />
        }
        onClick={openModal}
        i18nKey="form.rejectDocumentBtn"
      />

      <RejectDialog
        closeRejectModal={closeRejectModal}
        handleReject={handleReject}
        isOpen={isOpen}
        loading={loading}
      />
    </>
  );
}

export { RejectButton };
