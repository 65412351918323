import Typography from '@mui/material/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslationRoot } from 'components/with-translation';

function CharCounter() {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();
  const text = useWatch({ control, name: 'text' });

  return (
    <Typography
      aria-label={t('comments.charCounter', { count: text.length }) as string}
      variant="caption"
      sx={{
        flexGrow: 1,
        textAlign: 'right',
      }}
    >
      {text.trim().length}
    </Typography>
  );
}

export { CharCounter };
