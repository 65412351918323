import type { PDFPageProxy } from 'pdfjs-dist';
import type { Canvas as CanvasType } from 'fabric';
import {
  Canvas,
  FabricObject,
  FabricImage,
  FabricText,
  Point,
  util,
} from 'fabric';

import type {
  AddAndScaleImage,
  AddErrorText,
  AdjustCanvasDimensions,
  BoundingBox,
  CalculateScale,
  CanvasWithPage,
  CreateImageObject,
  FabricObjectWithRelationship,
  InitApp,
  LoadNewImage,
  UrlList,
  Urls,
} from './types.ts';
import { calculateRelativePageOffset, getPDFSnapShot } from './pdf-utils.ts';

const PDFMap = new Map<string, string>();

export const findImage = (obj: FabricObject) => obj.isType('image');
export const findRect = (obj: FabricObject) => obj.isType('rect');

function formatUrlObject(url: string) {
  return { url, fileType: 'jpg' };
}

export function getImageList(urls: Urls): UrlList {
  if (Array.isArray(urls)) {
    // if array only contains strings
    if (typeof urls[0] === 'string') {
      return (urls as string[]).map(formatUrlObject);
    }

    return urls as UrlList;
  }

  if (typeof urls === 'object') {
    return Object.values(urls).map(formatUrlObject);
  }

  return [];
}

export function getCurrentUrl(urls: UrlList, page: number) {
  return urls.length ? urls[page] : { url: '', fileType: 'jpg' };
}

export function loadImage(url: string) {
  return FabricImage.fromURL(url, undefined, {
    hasBorders: false,
    hasControls: false,
    originX: 'center',
    originY: 'center',
    snapAngle: 90,
  });
}

export async function createImageObject({
  loadPDFFn,
  page,
  urlList,
}: CreateImageObject) {
  if (urlList.length) {
    const { url, fileType } = getCurrentUrl(urlList, page);

    if (fileType === 'pdf') {
      if (loadPDFFn && typeof loadPDFFn === 'function') {
        // there could be images or other pdfs in the list
        // so calculate the relative page number to get the correct page
        const relativePage = calculateRelativePageOffset(urlList, url, page);

        if (PDFMap.has(`${url}-${relativePage}`)) {
          return loadImage(PDFMap.get(`${url}-${relativePage}`) as string);
        }

        const { getPage } = await loadPDFFn(url);

        if (getPage) {
          const pdfPage = (await getPage(relativePage)) as PDFPageProxy;
          const snapshot = await getPDFSnapShot(pdfPage);

          if (snapshot) {
            // memoize the snapshot
            PDFMap.set(`${url}-${relativePage}`, snapshot);
            return loadImage(snapshot);
          }
        }
      } else {
        console.error('No loadPDF function provided');
        return loadImage(url);
      }
    }

    return loadImage(url);
  }

  throw new Error('No URLs provided');
}

export async function loadNewImage({
  bottomOffset,
  canvas,
  container,
  errorFn,
  imageLoadFinishEvent,
  oImg,
  page,
}: LoadNewImage) {
  canvas.clear();
  canvas.page = page;
  canvas.fire('page:updated');

  try {
    imageLoadFinishEvent?.();

    const img = await oImg;

    if (img && container) {
      const containerRect = container.getBoundingClientRect();

      const opts = {
        bottomOffset,
        canvas,
        containerTop: containerRect.top,
        containerWidth: containerRect.width,
        img,
      };
      const scale = calculateScale(opts);
      adjustCanvasDimensions({
        ...opts,
        scale,
      });
      addAndScaleImage({ canvas, img, scale });

      return img;
    }
  } catch (error) {
    errorFn(error as unknown as Error);
  }
}

export async function initApp(opts: InitApp) {
  opts.canvas.page = 0;
  const oImg = createImageObject(opts);
  const img = await loadNewImage({ ...opts, oImg });

  if (img) {
    attachEventsToImg(opts.canvas, img);
  }

  opts.container.style.opacity = '1';
}

export function attachEventsToImg(canvas: CanvasWithPage, img: FabricImage) {
  img.on('moving', () => updateBoundObjects(canvas));
}

export function isPortrait(img: FabricImage) {
  return img.height > img.width;
}

function getMaxHeight(containerTop = 0, bottomOffset = 0) {
  return Math.floor(window.innerHeight - containerTop - bottomOffset);
}

export function calculateScale({
  bottomOffset,
  canvas,
  containerTop,
  containerWidth,
  img,
  showFullHeight = false,
}: CalculateScale) {
  if (showFullHeight) {
    return containerWidth / img.width;
  }

  const maxHeight = getMaxHeight(containerTop, bottomOffset);

  if (isPortrait(img)) {
    const scale = containerWidth / img.width;
    const height = img.height * scale;
    const isTallerThanContainer = height > maxHeight;
    return isTallerThanContainer ? maxHeight / img.height : scale;
  }

  return canvas.width / img.width;
}

export function addAndScaleImage({ canvas, img, scale }: AddAndScaleImage) {
  img.scale(scale);
  img.setCoords();
  canvas.add(img);
  canvas.centerObject(img);
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  canvas.setActiveObject(img);
}

export function adjustCanvasDimensions({
  bottomOffset,
  canvas,
  containerTop,
  containerWidth,
  img,
  scale,
  showFullHeight = false,
}: AdjustCanvasDimensions) {
  const maxHeight = getMaxHeight(containerTop, bottomOffset);

  if (showFullHeight) {
    canvas.setDimensions({
      width: containerWidth,
      height: Math.floor(img.height * scale),
    });
  } else {
    const isTallerThanContainer = img.height * scale > maxHeight;

    if (isTallerThanContainer) {
      canvas.setDimensions({
        width: containerWidth,
        height: Math.floor(maxHeight),
      });
    } else {
      canvas.setDimensions({
        width: containerWidth,
        height: Math.floor(img.height * scale),
      });
    }
  }
}

export function addErrorText({
  canvas,
  content,
  error,
  onImageLoadError,
}: AddErrorText) {
  onImageLoadError?.(error as Error);

  const text = new FabricText(content, {
    top: 8,
    left: 8,
    fontFamily: 'Public Sans,sans-serif',
    fontSize: 16,
  });
  canvas.add(text);
}

export function getBoundingBox({ topLeft, bottomRight }: BoundingBox) {
  const [left, top] = topLeft;
  const [right, bottom] = bottomRight;

  return {
    bottom,
    left,
    right,
    top,
  };
}

export function bindObjectsToImage(canvas: Canvas) {
  const objects = canvas.getObjects() as FabricObjectWithRelationship[];
  const img = objects.find(findImage);
  const rects = objects.filter(findRect);

  if (img && rects) {
    const imageTransform = img.calcTransformMatrix();
    const imageTransformInverse = util.invertTransform(imageTransform);

    rects.forEach((rect) => {
      rect.relationship = util.multiplyTransformMatrices(
        imageTransformInverse,
        rect.calcTransformMatrix()
      );
    });
  }
}

export function updateBoundObjects(canvas: Canvas) {
  const objects = canvas.getObjects() as FabricObjectWithRelationship[];
  const img = objects.find(findImage);
  const rects = objects.filter(findRect);

  if (img && rects) {
    rects.forEach((rect) => {
      const { relationship } = rect;
      const newTransform = util.multiplyTransformMatrices(
        img.calcTransformMatrix(),
        relationship
      );
      const opt = util.qrDecompose(newTransform);
      rect.set({
        flipX: false,
        flipY: false,
      });
      rect.setPositionByOrigin(
        new Point({
          x: opt.translateX,
          y: opt.translateY,
        }),
        'center',
        'center'
      );
      rect.set(opt);
      rect.setCoords();
    });
  }
}

export function zoomToCrop(
  canvas: CanvasType,
  object: FabricObject,
  overrideZoom?: number
) {
  canvas.setZoom(1);
  const [, , , , x, y] = object.calcTransformMatrix();
  const zoom = canvas.getZoom();
  const vpw = (canvas.width || 0) / zoom;
  const vph = (canvas.height || 0) / zoom;
  const pan = new Point({ x: x + -(vpw / 2), y: y + -(vph / 2) });
  const point = new Point({ x: vpw / 2, y: vph / 2 });
  canvas.absolutePan(pan);

  // zoom to fit object
  const zoomX = canvas.width / object.width;
  const zoomY = canvas.height / object.height;
  const zoomDiff = zoomX < zoomY ? zoomX : zoomY;
  let newZoom = zoomDiff > 2 ? 2 : zoomDiff - 0.1;

  if (overrideZoom) {
    newZoom = overrideZoom;
  }

  canvas.zoomToPoint(point, newZoom);
}

export function getLatestScale(canvas: CanvasWithPage, object: FabricImage) {
  const angle = object.get('angle');
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  const objectHeight = object.get('height');
  const objectWidth = object.get('width');
  return angle % 180 === 0
    ? isPortrait(object)
      ? canvasHeight / objectHeight
      : canvasWidth / objectWidth
    : isPortrait(object)
      ? canvasWidth / objectHeight
      : canvasHeight / objectWidth;
}
