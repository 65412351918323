const queue = new Map();

function addToFunctionQueue(id: string, callback: VoidFunction) {
  if (!queue.has(id)) {
    queue.set(id, callback);
  }
}

function flushFunctionQueue() {
  if (queue.size) {
    queue.forEach((callback) => {
      if (typeof callback === 'function') callback();
    });
    queue.clear();
  }
}

function removeFromFunctionQueue(id: string) {
  if (queue.has(id)) {
    queue.delete(id);
  }
}

export { addToFunctionQueue, flushFunctionQueue, removeFromFunctionQueue };
