import type { ReactNode } from 'react';
import type { ButtonProps } from '@mui/material/Button';
import { SvgIconStyle } from 'components/SvgIconStyle';
import {
  CLOSE_ICON,
  COPY_ICON,
  TRASH_ICON,
  UNLOCK_ICON,
} from 'constants/public-icons';

export interface ActionButtonProps {
  action?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  isSevere?: boolean;
  label: string;
  namespace?: string;
}

export interface DialogDividerProps {
  borderStyle: string;
}

export interface DialogLinkProps {
  href: string;
  icon: ReactNode;
  label: string;
  namespace?: string;
}

export enum ComponentType {
  ACTION_BUTTON = 'ACTION_BUTTON',
  COMPONENT = 'COMPONENT',
  DIVIDER = 'DIVIDER',
  HEADER = 'HEADER',
  LINK = 'LINK',
  SEVERE_BUTTON = 'SEVERE_BUTTON',
  TEXT = 'TEXT',
}

export function filterActionComponentTypes(components: any[]) {
  return components.filter(
    ({ type }) =>
      type === ComponentType.ACTION_BUTTON ||
      type === ComponentType.SEVERE_BUTTON
  );
}

export function filterOtherComponentTypes(components: any[]) {
  return components.filter(
    ({ type }) =>
      type !== ComponentType.ACTION_BUTTON &&
      type !== ComponentType.SEVERE_BUTTON
  );
}

export function actionButton(props: ActionButtonProps) {
  return { type: ComponentType.ACTION_BUTTON, ...props };
}

export function errorButton(props: ActionButtonProps) {
  return { type: ComponentType.SEVERE_BUTTON, ...props };
}

interface AlternateButtonProps extends ButtonProps {
  action?: () => void;
  'data-testid'?: string;
  icon?: ReactNode;
  label?: string;
  namespace?: string;
}

export function deleteButton(props?: AlternateButtonProps) {
  return errorButton({
    label: 'dialogTooltip.delete',
    icon: <SvgIconStyle height={15} width={15} src={TRASH_ICON} />,
    ...props,
  });
}

export function cancelButton(props?: AlternateButtonProps) {
  return actionButton({
    label: 'dialogTooltip.cancel',
    icon: <SvgIconStyle height={15} width={15} src={CLOSE_ICON} />,
    ...props,
  });
}

export function copyButton(props?: AlternateButtonProps) {
  return actionButton({
    label: 'dialogTooltip.copy',
    icon: <SvgIconStyle height={15} width={15} src={COPY_ICON} />,
    ...props,
  });
}

export function unlockButton(props?: AlternateButtonProps) {
  return actionButton({
    label: 'dialogTooltip.unlock',
    icon: <SvgIconStyle height={15} width={15} src={UNLOCK_ICON} />,
    ...props,
  });
}

export function header(content: string, options?: NonNullable<unknown>) {
  return {
    type: ComponentType.HEADER,
    content,
    ...options,
  };
}

export function link(props: DialogLinkProps) {
  return {
    type: ComponentType.LINK,
    ...props,
  };
}

export function divider(props?: DialogDividerProps) {
  return {
    type: ComponentType.DIVIDER,
    ...props,
  };
}

export function text(content: string) {
  return {
    type: ComponentType.TEXT,
    content,
  };
}

export function renderComponent(props = {}) {
  return {
    type: ComponentType.COMPONENT,
    ...props,
  };
}

export function renderDialogComponent(renderers: any) {
  return function renderComponent(
    { type, ...props }: { type: ComponentType },
    i: number
  ) {
    const renderer = renderers[type];
    return renderer ? renderer(props, i) : null;
  };
}
