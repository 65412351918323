import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { WithTranslationRoot } from 'components/with-translation.tsx';
import { CLASSIFICATION_PAGE } from 'constants/translation-keys.ts';
import { Page } from 'components/Page.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ClassificationTool } from 'components/ClassificationTool';
import { LoadingUI } from 'components/ClassificationTool/LoadingUI.tsx';
import { RETRY_ICON } from 'constants/public-icons.ts';
import { RESOURCE_ID } from 'constants/route-keys.ts';
import { useGetNextPage, useGetPage } from 'state/queries/pages.ts';
import { toast } from 'components/toast';
import performanceRoute from 'pages/Performance/performance.route.tsx';

import classificationRoute from './classification.route';

function Classification() {
  const { t } = useTranslationRoot(CLASSIFICATION_PAGE);
  const navigate = useNavigate();
  const params = useParams();
  const pageId = params[RESOURCE_ID];
  // TODO: we should preload the data into the cache first
  const { data, isError, isPending, refetch } = useGetPage(pageId);
  const getNextPage = useGetNextPage();

  if (isError) {
    return (
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">{t('form.failedToLoadPage')}</Typography>
        <Button
          onClick={() => refetch()}
          startIcon={<SvgIconStyle src={RETRY_ICON} />}
          variant="contained"
          sx={{ mt: 1 }}
        >
          {t('form.tryAgain')}
        </Button>
      </Box>
    );
  }

  if (isPending) {
    return (
      <Box>
        <LoadingUI />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="body2">
          {t('form.noMorePagesToClassify')}
        </Typography>
      </Box>
    );
  }

  const onSuccess = () => {
    setTimeout(async () => {
      const data = await getNextPage();
      if (data) {
        // navigate to the next page
        navigate(classificationRoute.createPath(data.id));
      } else {
        toast.warning(t('form.noMorePagesToClassify'));
        navigate(performanceRoute.createPath());
      }
    }, 2000);
  };

  return (
    <WithTranslationRoot namespace={CLASSIFICATION_PAGE}>
      <Box>
        <Page i18nKeyTitle="meta.title">
          <ClassificationTool key={data.id} page={data} onSuccess={onSuccess} />
        </Page>
      </Box>
    </WithTranslationRoot>
  );
}

export default Classification;
