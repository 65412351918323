import type { DocumentContent } from 'types/Documents.ts';
import {
  HIL_TOOL_END,
  HIL_TOOL_PATCH_END,
  HIL_TOOL_PATCH_START,
  HIL_TOOL_START,
  HIL_TOOL_SUBMIT_END,
  HIL_TOOL_SUBMIT_START,
} from 'analytics/events.ts';
import { getEventTimestamp, sendAnalyticEvent } from 'analytics/utils.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { toast } from 'components/toast';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { useHitLContext } from 'pages/HitL/useHitLContext.ts';
import {
  useRejectDocument,
  useSetDocumentType,
  useSubmitDocument,
  useDocumentValidation,
} from 'state/queries/documents.ts';
import { getIsDocumentValidationStageCompleted } from 'state/selectors/documents.ts';
import { generalConfig } from 'config.ts';

import type { HilToolV3Props } from './types.ts';
import { HilForm } from './HilForm.tsx';
import { insertNewContentToDocument } from './utils.ts';
import { DocumentLockedDialog } from './DocumentLockedDialog.tsx';
import { useLockMechanism } from './useLockMechanism.ts';
import { removeFromUnlockQueue } from './unlock-queue.ts';

/**
 * TODO: how to handle line items on multipage documents?
 * should I just display the page number alongside the client key?
 */

function HilToolV3({
  currentUserEmail,
  document,
  documentHistory,
  onFailure,
  onGetNextDocument,
  onImageLoadComplete,
  onSuccess,
}: HilToolV3Props) {
  // feature flags
  const enableValidationResultsEndpoint = useFeatureFlag(
    'enableHILValidationResultsEndpoint'
  );

  const { enableOpeningHilInNewTab } = generalConfig;
  const { t } = useTranslationRoot();
  const { removeDocumentIdFromLocalStorage } = useHitLContext();
  const { id, lastLockedBy, locked } = document;
  const lockedByCurrentUser = lastLockedBy === currentUserEmail;

  useLockMechanism({ currentUserEmail, document });

  // apis
  const saveDocument = useSubmitDocument({
    isSave: true,
    meta: {
      loadingMessage: t('form.saveDocumentLoading') as string,
      successMessage: t('form.saveDocumentSuccess') as string,
      errorMessage: (error) => {
        const errorMsg = typeof error === 'string' ? error : error.message;
        return t('form.saveDocumentFail', { errorMsg }) as string;
      },
    },
  });
  const validateDocument = useDocumentValidation(id, {
    loadingMessage: t('form.validationLoading') as string,
    errorMessage: t('form.validationError') as string,
  });
  const submitDocument = useSubmitDocument({
    meta: {
      loadingMessage: t('form.submitDocumentLoading') as string,
      successMessage: t('form.submitDocumentSuccess') as string,
      errorMessage: (error) => {
        const errorMsg = typeof error === 'string' ? error : error.message;
        return t('form.submitDocumentFail', { errorMsg }) as string;
      },
      gtmEventStart: HIL_TOOL_PATCH_START,
      gtmEventEnd: HIL_TOOL_PATCH_END,
      documentId: document.id,
    },
  });
  const rejectDocument = useRejectDocument();
  const setDocumentType = useSetDocumentType({
    meta: {
      loadingMessage: t('form.submitDocumentLoading') as string,
      successMessage: t('form.submitDocumentSuccess') as string,
      errorMessage: t('form.submitDocumentFail') as string,
    },
  });

  const submitEndEvent = () => {
    sendAnalyticEvent({
      event: HIL_TOOL_SUBMIT_END,
      performance:
        (Date.now() - getEventTimestamp(HIL_TOOL_SUBMIT_START)) / 1000,
      documentId: document.id,
    });

    sendAnalyticEvent({
      event: HIL_TOOL_END,
      performance: (Date.now() - getEventTimestamp(HIL_TOOL_START)) / 1000,
      documentId: document.id,
      sendToPosthog: true,
    });
  };

  const onReject = (reason: string) => {
    rejectDocument.mutate(
      { id, reason },
      {
        onSuccess: (response) => {
          removeFromUnlockQueue(id);
          onSuccess('reject', response);
        },
        onError: (error) => {
          onFailure?.('reject', document, error);
        },
      }
    );
  };

  const onSave = (content: DocumentContent[]) => {
    const newDocument = insertNewContentToDocument(document, content);

    saveDocument.mutate(newDocument, {
      onSuccess: (response) => {
        submitEndEvent();
        removeFromUnlockQueue(id);

        if (enableOpeningHilInNewTab) {
          removeDocumentIdFromLocalStorage(id);
        }

        onSuccess('save', response);
      },
      onError: (error) => {
        onFailure?.('save', document, error);
      },
    });
  };

  const onSubmit = (content: DocumentContent[]) => {
    if (enableValidationResultsEndpoint) {
      validateDocument.mutate(
        { id, content },
        {
          onSuccess: (response) => {
            toast.dismiss();
            removeFromUnlockQueue(id);

            if (getIsDocumentValidationStageCompleted(response)) {
              const newDocument = insertNewContentToDocument(document, content);

              submitDocument.mutate(newDocument, {
                onSuccess: (response) => {
                  submitEndEvent();

                  if (enableOpeningHilInNewTab) {
                    removeDocumentIdFromLocalStorage(id);
                  }

                  onSuccess('submit', response);
                },
                onError: (error) => {
                  onFailure?.('submit', document, error);
                },
              });
            } else {
              toast.error(t('form.reviewValidationErrors'));
            }
          },
        }
      );
    } else {
      const newDocument = insertNewContentToDocument(document, content);

      submitDocument.mutate(newDocument, {
        onSuccess: (response) => {
          submitEndEvent();
          removeFromUnlockQueue(id);

          if (enableOpeningHilInNewTab) {
            removeDocumentIdFromLocalStorage(id);
          }

          onSuccess('submit', response);
        },
        onError: (error) => {
          onFailure?.('submit', document, error);
        },
      });
    }
  };

  const onDocumentTypeChange = (documentType: string) => {
    setDocumentType.mutate(
      { id, documentType },
      {
        onSuccess: (response) => {
          removeFromUnlockQueue(id);
          onSuccess('documentTypeChange', response);
        },
        onError: (error) => {
          onFailure?.('documentTypeChange', document, error);
        },
      }
    );
  };

  return (
    <>
      <HilForm
        currentUserEmail={currentUserEmail}
        document={document}
        documentHistory={documentHistory}
        lockedByCurrentUser={locked && lockedByCurrentUser}
        onDocumentTypeChange={onDocumentTypeChange}
        onImageLoadComplete={onImageLoadComplete}
        onNextDocument={onGetNextDocument}
        onReject={onReject}
        onSave={onSave}
        onSubmit={onSubmit}
        isRejecting={rejectDocument.isPending}
        isSaving={saveDocument.isPending}
        isSubmitting={submitDocument.isPending}
      />

      <DocumentLockedDialog
        lastLockedBy={lastLockedBy}
        lockedByCurrentUser={lockedByCurrentUser}
        locked={document.locked}
      />
    </>
  );
}

export default HilToolV3;
export { HilToolV3 };
