import type { ReactNode } from 'react';
import type { SxProps, Theme } from '@mui/material/styles';
import { useLayoutEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import {
  TableCellWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';

import { ErrorFallback } from './ErrorFallback';
import { Loading } from './Loading';
import { NoItems } from './NoItems';
import { BodyRow } from './BodyRow';

interface AdvancedTableProps {
  columns: Column[];
  isPending: boolean;
  handleRowClick?: (row: any) => void;
  noItemsComponent?: ReactNode;
  noItemsLabeli18nKey: string;
  pagination: ReturnType<typeof useAdvancedTablePagination>;
  totalCount: number;
  rows: any;
  rowIdentifier?: string;
  toolbarEndAdornment?: ReactNode;
  toolbarStartAdornment?: ReactNode;
  isError: boolean;
  refetch: () => void;
  errorMessage: string;
  retryButton: string;
}

export interface Column {
  id: string;
  i18nKey: string;
  width?: number;
  align?: 'right';
  format?: (value: number) => string;
  renderCell?: (params: any) => ReactNode;
  sortable?: boolean;
  valueFormatter?: (value: any) => string;
  columnSx?: SxProps<Theme>;
  rowSx?: SxProps<Theme>;
  custom?: boolean;
}

function AdvancedTable({
  columns = [],
  rows,
  isPending,
  noItemsComponent,
  noItemsLabeli18nKey,
  pagination,
  totalCount,
  rowIdentifier = 'id',
  handleRowClick = () => {},
  toolbarEndAdornment,
  toolbarStartAdornment,
  isError,
  refetch,
  errorMessage,
  retryButton,
}: AdvancedTableProps) {
  const { t } = useTranslationRoot();
  const {
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    toggleSort,
    sort,
  } = pagination;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const rect = tableContainer.getBoundingClientRect();

      tableContainer.style.height = `${window.innerHeight - rect.top - 64 - 27}px`;
    }
  }, []);

  return isError ? (
    <ErrorFallback
      errorMessage={errorMessage}
      retryButton={retryButton}
      refetch={refetch}
    />
  ) : (
    <>
      {toolbarStartAdornment || toolbarEndAdornment ? (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: toolbarStartAdornment
              ? 'space-between'
              : 'flex-end',
            px: 2,
            pt: 3,
            pb: 0,
          }}
        >
          {toolbarStartAdornment ? toolbarStartAdornment : null}
          {toolbarEndAdornment ? (
            <Box
              sx={{
                flex: '0 1 30%',
              }}
            >
              {toolbarEndAdornment}
            </Box>
          ) : null}
        </Stack>
      ) : null}
      <TableContainer ref={tableContainerRef} sx={{ minWidth: 500, mt: 3 }}>
        <Table
          data-testid="list-table"
          stickyHeader
          sx={{
            position: 'relative',
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((col) =>
                col.sortable ? (
                  <TableCell
                    key={col.id}
                    align={col.align}
                    style={{ width: col.width }}
                    sx={{
                      ...col.columnSx,
                      textTransform: 'capitalize',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <TableSortLabel
                      onClick={toggleSort}
                      direction={sort.toLowerCase() as 'desc' | 'asc'}
                    >
                      {t(col.i18nKey)}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCellWithTranslation
                    key={col.id}
                    i18nKey={col.i18nKey}
                    sx={{
                      ...col.columnSx,
                      textTransform: 'capitalize',
                      whiteSpace: 'nowrap',
                    }}
                    style={{ width: col.width ? `${col.width}px` : 'auto' }}
                  />
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isPending ? (
              <Loading columnLength={columns.length} />
            ) : rows.length ? (
              rows.map((row, idx) => (
                <BodyRow
                  key={`${row[rowIdentifier]}-${idx}`}
                  columns={columns}
                  row={row}
                  handleRowClick={handleRowClick}
                />
              ))
            ) : noItemsComponent ? (
              noItemsComponent
            ) : (
              <NoItems
                columnLength={columns.length}
                noItemsLabeli18nKey={noItemsLabeli18nKey}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        showFirstButton
        showLastButton
      />
    </>
  );
}

export { AdvancedTable };
