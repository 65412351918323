import { useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  LoadingButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { useBoolean } from 'components/customHooks/useBoolean.ts';
import { CLOSE_ICON, TICK_ICON } from 'constants/public-icons.ts';

import type { FormValues, SkipCheckAllProps } from './types.ts';
import { SkipCheckbox } from './SkipCheckbox.tsx';
import { StartIcon } from './StartIcon.tsx';
import { getAllSkippedIds } from './utils.ts';

function SkipCheckAll({ setAllSkipped }: SkipCheckAllProps) {
  const { t } = useTranslationRoot();
  const [isOpen, openModal, closeModal] = useBoolean();
  const [checked, setChecked] = useState(false);
  const { control } = useFormContext();
  const { defaultValues } = useFormState();

  const toggle = () => setChecked((prev) => !prev);

  const handleCheckAll = () => {
    if (!checked) {
      openModal();
    } else {
      setAllSkipped(false);
      toggle();
    }
  };

  const onConfirm = () => {
    if (defaultValues) {
      setAllSkipped(true);
      toggle();
    }

    closeModal();
  };

  const result = useWatch({
    control,
    name: getAllSkippedIds(defaultValues as FormValues),
  });

  if (
    result.length &&
    result.every((value) => typeof value === 'boolean' && value)
  ) {
    if (!checked) setChecked(true);
  } else {
    if (checked) setChecked(false);
  }

  return (
    <>
      <SkipCheckbox
        data-testid="skip-all-checkbox"
        title={t('form.checkAllTooltipLabel')}
        checked={checked}
        onChange={handleCheckAll}
      />
      <Dialog
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="warning-dialog-title"
      >
        <DialogTitleWithTranslation
          id="warning-dialog-title"
          i18nKey="checkAllWarningDialog.title"
        />
        <DialogContent>
          <DialogContentTextWithTranslation
            id="warning-dialog-description"
            sx={{ mb: 2 }}
            i18nKey="checkAllWarningDialog.description"
          />
        </DialogContent>
        <DialogActions>
          <LoadingButtonWithTranslation
            variant="contained"
            color="success"
            startIcon={<StartIcon height={17} src={TICK_ICON} width={17} />}
            onClick={onConfirm}
            autoFocus
            i18nKey="checkAllWarningDialog.confirmButton"
          />
          <LoadingButtonWithTranslation
            variant="outlined"
            color="inherit"
            startIcon={<StartIcon height={17} src={CLOSE_ICON} width={17} />}
            onClick={closeModal}
            i18nKey="checkAllWarningDialog.cancelButton"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export { SkipCheckAll };
