import { useSearchParams } from 'react-router-dom';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { STAGE } from 'constants/route-keys.ts';
import { toCamelCase } from 'utils/string.ts';

interface UseStageFilterProps {
  namespace: string;
  stages: string[];
}

function useStageFilter({ namespace, stages }: UseStageFilterProps) {
  const { t } = useTranslationRoot(namespace);
  const [searchParams] = useSearchParams();

  const options = stages.map((stage) => ({
    label: t(`stage.${toCamelCase(stage.toLowerCase())}`),
    value: stage,
  }));

  const defaultValue = [searchParams.get(STAGE)].filter((s) => s != null);

  return {
    label: 'stage',
    options,
    filters: defaultValue,
    defaultValues: { stage: defaultValue },
  };
}

export { useStageFilter };
