import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { USER_MANAGEMENT_PAGE } from 'constants/translation-keys';
import userManagementCreateUserRoute from 'pages/UserManagementCreateUser/userManagementCreateUser.route';

const path = `/configuration/user-management`;
const UserManagementLayout = loadable(lazy(() => import('./UserManagement')));
const UsersList = loadable(lazy(() => import('./UsersList')));

const userManagementRoute = {
  path,
  createPath: () => generatePath(path),
  element: <UserManagementLayout />,
  permission: ROUTE_PERMISSIONS.configuration,
  i18nNamespace: USER_MANAGEMENT_PAGE,
  children: [
    {
      index: true,
      permission: ROUTE_PERMISSIONS.configuration,
      i18nNamespace: USER_MANAGEMENT_PAGE,
      element: <UsersList />,
    },
    userManagementCreateUserRoute,
  ],
};

export default userManagementRoute;
