import type { ReactNode } from 'react';
import type { TextFieldProps } from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslationRoot } from 'components/with-translation.tsx';

interface ControllerWithDatePickerV2Props {
  helperTextFn?: (message?: string) => ReactNode;
  label: string;
  name: string;
  textfieldProps?: TextFieldProps;
}

dayjs.extend(utc);

function ControllerWithDatePickerV2(props: ControllerWithDatePickerV2Props) {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();
  const { helperTextFn, label, name, textfieldProps } = props;

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { value, ...restField } = field;
        const { error, invalid } = fieldState;
        const defaultValue = value ? dayjs.utc(value).format('YYYY-MM-DD') : '';
        const helperText =
          helperTextFn && typeof helperTextFn === 'function'
            ? helperTextFn(error?.message)
            : typeof textfieldProps?.helperText === 'string'
              ? textfieldProps.helperText
              : error?.message && t(error.message);

        return (
          <TextField
            {...restField}
            {...textfieldProps}
            aria-label={label}
            aria-invalid={invalid}
            defaultValue={defaultValue}
            fullWidth
            helperText={helperText}
            label={label}
            type="date"
            InputProps={{
              ...textfieldProps?.InputProps,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...textfieldProps?.inputProps,
              placeholder:
                textfieldProps?.inputProps?.placeholder?.toUpperCase(),
              'data-testid': name,
            }}
            onBlur={(e) => {
              field.onBlur();
              textfieldProps?.onBlur?.(e);
            }}
          />
        );
      }}
    />
  );
}

export { ControllerWithDatePickerV2 };
export type { ControllerWithDatePickerV2Props };
