import {
  DefinedInitialDataOptions,
  QueryKey,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api, { makeApiLink } from 'api/api';
import { pathToUrl } from 'utils/url';
import { DefaultTFuncReturn } from 'i18next';

export type Meta = {
  errorMessage?: string | ((error: Error) => string) | DefaultTFuncReturn;
  loadingMessage?: string | DefaultTFuncReturn;
  successMessage?: string | DefaultTFuncReturn;
};

function getRequest(apiVersion = 0) {
  return <TData>({ queryKey }: { queryKey: QueryKey }): Promise<TData> => {
    const [, url, params] = queryKey;
    const endpoint = pathToUrl(url as string, params as object);
    return api.get(makeApiLink(endpoint, apiVersion));
  };
}

export function useGet<T, S>({
  url,
  params,
  prefix,
  apiVersion,
  ...config
}: {
  url: string;
  params?: object;
  prefix?: string;
  apiVersion?: number;
} & Partial<DefinedInitialDataOptions<T, Error, S, QueryKey>>): UseQueryResult<
  S,
  Error
> {
  const queryKey: QueryKey = prefix ? [prefix, url, params] : ['', url, params];
  // const endpoint = pathToUrl(url, params);
  const options = {
    queryKey,
    queryFn: getRequest(apiVersion),
    enabled: !!url,
    ...config,
  };

  return useQuery(options);
}

export function useOptimisticMutation<T, S>({
  fn,
  url,
  params,
  updater,
  onSettled,
  prefix = '',
  ...config
}: {
  fn: (payload: S) => Promise<S>;
  url?: string | ((data: S) => string);
  params?: NonNullable<unknown>;
  updater?: ((oldData: T | undefined, newData: S) => T | undefined) | undefined;
  onSettled?: VoidFunction;
  prefix?: string;
  meta?: Meta;
}) {
  const queryClient = useQueryClient();

  const genQueryKey = (data: S | undefined) => {
    const newUrl = typeof url === 'function' && data ? url(data) : url;
    return [prefix, newUrl!, params ?? null];
  };

  return useMutation({
    mutationFn: fn,
    onMutate: async (data) => {
      const queryKey = genQueryKey(data);
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (oldData) =>
        updater ? updater(oldData as T, data) : data
      );

      return previousData;
    },
    onError: (_err, data, context) => {
      const queryKey = genQueryKey(data);
      queryClient.setQueryData(queryKey, context);
    },
    onSettled: async (data) => {
      const queryKey = genQueryKey(data);
      await queryClient.invalidateQueries({ queryKey });
      onSettled && onSettled();
    },
    ...config,
  });
}
