import { useState } from 'react';
import Stack from '@mui/material/Stack';

import type { LineItemProps } from './types.ts';
import { LineItemView } from './LineItemView.tsx';
import { LineNumber } from './LineNumber.tsx';
import { LineItemEdit } from './LineItemEdit.tsx';

function LineItem({
  deleteLineItem,
  historicalResources,
  index,
  isValid = false,
  lineIdx,
  list,
  resources,
  size,
}: LineItemProps) {
  const [isEditMode, setEditMode] = useState(false);

  const openEditMode = () => {
    setEditMode(true);
  };

  const closeEditMode = () => {
    setEditMode(false);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
      }}
    >
      <LineNumber lineIdx={lineIdx} isValid={isValid} />
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{
          borderBottomWidth: index === size - 1 ? 0 : 1,
          borderBottomStyle: 'solid',
          borderColor: 'grey.300',
          flex: 1,
        }}
      >
        {isEditMode ? (
          <LineItemEdit
            closeEditMode={closeEditMode}
            historicalResources={historicalResources}
            list={list}
            resources={resources}
          />
        ) : (
          <LineItemView
            list={list}
            lineIdx={lineIdx}
            resources={resources}
            deleteLineItem={deleteLineItem}
            openEditMode={openEditMode}
          />
        )}
      </Stack>
    </Stack>
  );
}

export { LineItem };
