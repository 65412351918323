import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LinkWithRef } from 'components/Link.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import claimRoute from 'pages/Claim/claim.route.tsx';

interface GoBackToClaimButtonProps {
  claimId: string | null;
}

function GoBackToClaimButton({ claimId }: GoBackToClaimButtonProps) {
  const { t } = useTranslationRoot();

  if (claimId) {
    return (
      <Box>
        <Button
          component={LinkWithRef}
          to={claimRoute.createPath(claimId)}
          variant="outlined"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {t('common.goBackToClaim')}
        </Button>
      </Box>
    );
  }

  return null;
}

export { GoBackToClaimButton };
