import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { useTranslationRoot } from 'components/with-translation.tsx';

import type { FilteringProps } from './types.ts';
import { FilterType } from './types.ts';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

function Filtering({ value, onChange }: FilteringProps) {
  const { t } = useTranslationRoot();

  return (
    <Box sx={{ display: 'flex', p: 2 }}>
      <Paper>
        <StyledToggleButtonGroup
          value={value}
          exclusive
          onChange={onChange}
          aria-label={t('form.filteringAriaLabel') as string}
          size="small"
          color="primary"
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          <ToggleButton value={FilterType.CONSISTENCY}>
            {t('form.consistencyResults')}
          </ToggleButton>
          <ToggleButton value={FilterType.ALL}>
            {t('form.allFields')}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </Box>
  );
}

export { Filtering };
