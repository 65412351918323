import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { CLASSIFICATION_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLASSIFICATIONS_PAGE } from 'constants/translation-keys';

const path = '/cl';
const ClassificationsPage = loadable(
  lazy(() => import('./ClassificationV2.tsx'))
);

const classificationsRoute = {
  path,
  createPath: () => createPath({ pathname: path }),
  icon: CLASSIFICATION_ICON,
  element: <ClassificationsPage />,
  permission: ROUTE_PERMISSIONS.classification,
  i18nNamespace: CLASSIFICATIONS_PAGE,
};

export default classificationsRoute;
