import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { BaseFilter } from 'types/Filters.ts';
import {
  ButtonWithTranslation,
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';

import type { DataMap, DocumentTypeFilters, ValueReference } from './types.ts';
import { Category } from './Category.tsx';
import { FilterOptions } from './FilterOptions.tsx';
import { RadioOptions } from './RadioOptions.tsx';
import { DatePicker } from './DatePicker.tsx';
import { DateRangeFilter } from './DateRangeFilter.tsx';

interface MenuProps {
  data: BaseFilter[];
  dataMap: DataMap;
  docTypeFilters?: DocumentTypeFilters;
  filters?: string | null;
  onSubmit: (data: { [key: string]: string[] }) => void;
  resetFilters: () => void;
  stageFilters?: DocumentTypeFilters;
}

function Menu({
  data,
  dataMap,
  docTypeFilters,
  filters,
  onSubmit,
  resetFilters,
  stageFilters,
}: MenuProps) {
  const { t } = useTranslationRoot();

  // get default values for filter param
  const filterValues = useMemo(() => {
    const getValueReference = ({ key, value, dataMap }: ValueReference) => {
      if (dataMap[key]?.type === 'radio' && dataMap[key]?.range) {
        // join the array to get the string value then findIndex
        // to get the index of the value in the availableValues array
        return dataMap[key]?.availableValues.findIndex(
          (item) => (item as string[]).join('–') === value.join('–')
        );
      }

      return value;
    };

    const transformValue = ({ key, value, dataMap }: ValueReference) => {
      // this currently only handles radio buttons with range values
      if (
        dataMap[key]?.type === 'radio' &&
        dataMap[key]?.range &&
        value?.length
      ) {
        // re-add 'None' to the array if there's an empty value
        const newValue = value.map((item) => {
          if (!item) {
            return 'None';
          }
          return item;
        });

        return [getValueReference({ key, value: newValue, dataMap })];
      }

      return value;
    };

    if (!data) {
      return {};
    }

    // convert the filters string to an object
    const propFiltersMap = filters
      ? filters
          .split(';')
          .reduce((acc: { [key: string]: string[] }, str: string) => {
            const object = { ...acc };
            const [key, values] = str.split('=');

            object[key] = values.split(',');

            return object;
          }, {})
      : {};

    // always apply the filters, if any, to the default values
    // as the filters come from the URL search string
    return data.reduce(
      (acc, { name }) => ({
        ...acc,
        [name]:
          transformValue({ key: name, value: propFiltersMap[name], dataMap }) ||
          [],
      }),
      {} as { [key: string]: any[] }
    );
  }, [data, dataMap, filters]);

  const methods = useForm({
    defaultValues: {
      ...filterValues,
      ...docTypeFilters?.defaultValues,
      ...stageFilters?.defaultValues,
    },
  });

  const onReset = () => {
    const values = methods.getValues();

    // clear all values
    const newValues = Object.keys(values).reduce(
      (acc, key) => {
        acc[key] = [];
        return acc;
      },
      {} as { [key: string]: string[] }
    );

    methods.reset(newValues);
    resetFilters();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          sx={{
            '&>div:not(:last-of-type)': {
              '&>div': {
                borderRight: '1px solid',
                borderRightColor: 'divider',
              },
            },
          }}
        >
          {stageFilters?.markup}
          {docTypeFilters?.markup}
          {data?.length ? (
            data.map(({ name, availableValues, type, multi, range }) => {
              if (type === 'date' && !multi) {
                if (range) {
                  return (
                    <Box
                      key={name}
                      sx={{
                        py: 2,
                      }}
                    >
                      <Category name={name}>
                        <DateRangeFilter name={name} />
                      </Category>
                    </Box>
                  );
                }

                return (
                  <Box
                    key={name}
                    sx={{
                      py: 2,
                    }}
                  >
                    <Category name={name}>
                      <DatePicker name={name} />
                    </Category>
                  </Box>
                );
              }

              if (type === 'radio') {
                if (range) {
                  const formatLabel = (value: string[]) => {
                    const [first, second] = value;

                    if (first === 'None') {
                      return t('table.filterUpToNumber', {
                        number: second,
                      });
                    }

                    if (second === 'None') {
                      return t('table.filterNumberAndAbove', {
                        number: first,
                      });
                    }

                    return value.join('–');
                  };
                  const opts = availableValues.map((value) => ({
                    label: formatLabel(value as string[]),
                    value,
                  }));

                  return (
                    <Box
                      key={name}
                      sx={{
                        py: 2,
                      }}
                    >
                      <Category name={name}>
                        <RadioOptions name={name} options={opts} />
                      </Category>
                    </Box>
                  );
                }
                // TODO: implement radio buttons for non-range values
              }

              if (type === 'option') {
                const options = availableValues.map((value) => ({
                  label: value,
                  value: String(value),
                }));

                return (
                  <Box
                    key={name}
                    sx={{
                      py: 2,
                    }}
                  >
                    <Category name={name}>
                      <FilterOptions filtersKey={name} options={options} />
                    </Category>
                  </Box>
                );
              }
            })
          ) : !docTypeFilters ? (
            <TypographyWithTranslation i18nKey="table.noFilters" />
          ) : null}
        </Stack>
        {data?.length || docTypeFilters ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'flex-end' }}
          >
            <ButtonWithTranslation
              variant="contained"
              i18nKey="table.filterApplyButton"
              type="submit"
              sx={{
                minWidth: '200px',
              }}
            />
            <ButtonWithTranslation
              i18nKey="table.clearFiltersButton"
              onClick={onReset}
              variant="outlined"
              color="error"
            />
          </Stack>
        ) : null}
      </form>
    </FormProvider>
  );
}

export { Menu };
