import type { Theme } from '@mui/material/styles';

import Accordion from './Accordion';
import Alert from './Alert';
import Avatar from './Avatar';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Card from './Card';
import Checkbox from './Checkbox';
import ControlLabel from './ControlLabel';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import Drawer from './Drawer';
import Input from './Input';
import LoadingButton from './LoadingButton';
import Link from './Link';
import Lists from './List';
import Menu from './Menu';
import Pagination from './Pagination';
import Paper from './Paper';
import Popover from './Popover';
import Progress from './Progress';
import Radio from './Radio';
import Select from './Select';
import SvgIcon from './SvgIcon';
import Table from './Table';
import Tabs from './Tabs';
import TextField from './TextField.ts';
import Timeline from './Timeline';
// import ToggleButton from './ToggleButton';
import Tooltip from './Tooltip';
import Typography from './Typography';

export default function componentsOverrides(theme: Theme) {
  return Object.assign(
    Accordion(theme),
    Alert(theme),
    Avatar(theme),
    Breadcrumbs(theme),
    Button(theme),
    ButtonGroup(theme),
    Card(theme),
    Checkbox(theme),
    ControlLabel(theme),
    CssBaseline(),
    Dialog(theme),
    Drawer(theme),
    Input(theme),
    Link(),
    Lists(theme),
    LoadingButton(),
    Menu(theme),
    Pagination(theme),
    Paper(theme),
    Popover(theme),
    Progress(theme),
    Radio(theme),
    Select(),
    SvgIcon(),
    Table(theme),
    Tabs(theme),
    TextField(),
    Timeline(theme),
    // ToggleButton(theme),
    Tooltip(theme),
    Typography(theme)
  );
}
