import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonWithTranslation } from 'components/with-translation.tsx';

import type { LineItemEditProps } from './types.ts';
import { InputField } from './InputField.tsx';

function LineItemEdit({
  closeEditMode,
  historicalResources,
  list,
  resources,
}: LineItemEditProps) {
  const { getValues, setValue } = useFormContext();
  const prevValues = useRef(getValues(list));

  const updateValues = (values: string[]) => {
    values.forEach((value, index) => {
      setValue(list[index], value);
    });
  };

  const handleSave = () => {
    const values = getValues(list);
    updateValues(values);
    closeEditMode();
  };

  const handleCancel = () => {
    updateValues(prevValues.current);
    closeEditMode();
  };

  return (
    <Stack spacing={2} sx={{ py: 1, width: 1 }}>
      <Stack direction="row" flexWrap="wrap">
        {list.map((id) => (
          <Box key={id} sx={{ flex: '0 0 calc(33% - 8px)', mr: 1, mt: 1 }}>
            <InputField
              defaultSpacing={0.5}
              historicalResources={historicalResources}
              id={id}
              isLineItem
              resources={resources}
              sx={{
                pb: 0.5,
              }}
            />
          </Box>
        ))}
      </Stack>
      <Stack direction="row" spacing={1}>
        <ButtonWithTranslation
          i18nKey="form.saveChangesButton"
          variant="outlined"
          type="button"
          onClick={handleSave}
        />
        <ButtonWithTranslation
          variant="outlined"
          color="inherit"
          type="button"
          sx={{ color: 'grey.500' }}
          onClick={handleCancel}
          i18nKey="form.cancelButton"
        />
      </Stack>
    </Stack>
  );
}

export { LineItemEdit };
