import { useMemo } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { FiltersClaims } from 'types/Filters.ts';
import { useTooltip } from 'components/customHooks/useTooltip';
import { ButtonWithTranslation } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { MenuPopover } from 'components/MenuPopover';
import { FILTER_LIST_ICON } from 'constants/public-icons';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';

import type { DocumentTypeFilters } from './types.ts';
import { Menu } from './Menu.tsx';

// import { DateRangeFilter } from './DateRangeFilter';

type DataMap = { [key: string]: FiltersClaims };

interface AdvancedFilteringProps {
  filters?: string | null;
  filtersQuery: any;
  updateFilters: (filters: string) => void;
  docTypeFilters?: DocumentTypeFilters;
  updateDocTypeFilters?: (filters: string) => void;
  stageFilters?: any;
  updateStageFilters?: (filters: string) => void;
}

function AdvancedFiltering({
  filters,
  filtersQuery,
  updateFilters,
  docTypeFilters,
  stageFilters,
  updateDocTypeFilters,
  updateStageFilters,
}: AdvancedFilteringProps) {
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const { data, isPending, isError } = filtersQuery;

  const dataMap = useMemo(() => {
    if (!data) {
      return {};
    }

    return data.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: item,
      }),
      {} as DataMap
    );
  }, [data]);

  const onSubmit = (values: { [key: string]: string[] }) => {
    const transformValue = (key: string, value: FormDataEntryValue[]) => {
      if (dataMap[key]?.type === 'radio' && dataMap[key]?.range) {
        const target = dataMap[key]?.availableValues[
          Number(value[0])
        ] as string[];
        return target.map((item) => {
          if (item === 'None') {
            return undefined;
          }
          return item;
        });
      }

      return String(value);
    };

    // convert object to array of strings
    const result = Object.entries(values)
      .reduce(
        (acc, [key, value]) =>
          value?.length
            ? [...acc, `${key}=${transformValue(key, value)}`]
            : acc,
        [] as string[]
      )
      .filter((item) => {
        const [key] = item.split('=');
        return Object.keys(dataMap).includes(key);
      });

    // update filters with an array as it is expecting an array
    const newFilters = result.length ? result.join(';') : '';
    updateFilters(newFilters);

    // handle special filters
    if (docTypeFilters) {
      if (values.documentType.length) {
        updateDocTypeFilters?.(values.documentType.join(','));
      } else {
        updateDocTypeFilters?.('');
      }
    }

    if (stageFilters) {
      if (values.stage.length) {
        updateStageFilters?.(values.stage[0]);
      } else {
        updateStageFilters?.('');
      }
    }

    handleClose();
  };

  const resetFilters = () => {
    if (docTypeFilters) {
      updateDocTypeFilters?.('');
      updateStageFilters?.('');
    }
    updateFilters('');
  };

  return (
    <Box>
      <ButtonWithTranslation
        data-testid="filter-button"
        color={filters ? 'primary' : 'inherit'}
        variant="outlined"
        i18nKey="table.filterButton"
        onClick={handleOpen}
        endIcon={<SvgIconStyle src={FILTER_LIST_ICON} />}
        size="small"
        sx={{
          whiteSpace: 'nowrap',
          backgroundColor: ({ palette }) =>
            filters
              ? alpha(palette.primary.main, palette.action.selectedOpacity)
              : 'inherit',
          textWrap: 'nowrap',
        }}
      />
      <MenuPopover
        data-testid="filters-popover"
        arrow="top-left"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={element}
        open={isOpen}
        onClose={handleClose}
        sx={{
          px: 2,
          mt: 1,
          maxWidth: '80vw',
        }}
      >
        {isError ? (
          <TypographyWithTranslation i18nKey="table.filtersError" />
        ) : isPending ? (
          <LoadingIcon size={32} />
        ) : (
          <Stack
            sx={{
              py: 1,
            }}
          >
            <Menu
              data={data}
              dataMap={dataMap}
              onSubmit={onSubmit}
              resetFilters={resetFilters}
              docTypeFilters={docTypeFilters}
              stageFilters={stageFilters}
              filters={filters}
            />
          </Stack>
        )}
      </MenuPopover>
    </Box>
  );
}

export { AdvancedFiltering };
