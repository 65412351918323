/**
 * ideally we should only have one BarChart component
 * but I'll do this when I return from pat leave!
 */
import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import * as d3 from 'd3';
import { useRect } from 'components/customHooks/useRect';

import type {
  HandleLegendMouseEnter,
  HandleLegendMouseLeave,
} from './useChartInteractivityHandlers';
import { useChartBaseOption } from './useChartBaseOption';
import { useChartInteractivityHandlers } from './useChartInteractivityHandlers';
import { ChartTooltip } from './ChartTooltip';

type Data = {
  color?: string;
  name: string;
  value: number;
};
interface BarChartProps {
  colors?: string[];
  containerHeight?: number;
  containerWidth?: number;
  data: Data[];
  legend?: string[];
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  ratio?: number;
}

type BarDimensions = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface BarProps extends BarDimensions {
  fill: string;
  index: number;
  onClick: () => void;
  onMouseLeave: (e: MouseEvent) => void;
  onMouseMove?: (e: MouseEvent) => void;
  opacity?: number;
  selected: number | undefined;
}

interface LegendProps {
  colors: (string | undefined)[];
  data: Data[];
  handleOnClick: (i: number) => VoidFunction;
  legend?: string[];
  handleLegendMouseEnter: HandleLegendMouseEnter;
  handleLegendMouseLeave: HandleLegendMouseLeave;
}

const Y_AXIS_ADJUSTMENT = 60;
const Y_AXIS_TEXT_ADJUSTMENT = 3;
const BAND_HEIGHT = 40;
const BAND_PADDING = 0.7;
const FONT_SIZE = 12;
const PADDING_LEFT = 10;
const Y_TICK_WIDTH = 10;

function Legend({
  colors,
  data,
  handleOnClick,
  legend,
  handleLegendMouseEnter,
  handleLegendMouseLeave,
}: LegendProps) {
  const hasColors = colors?.length;
  const hasLegend = legend?.length;
  const hasData = data.length;
  const sameLength = legend?.length === data.length;

  if (hasLegend && hasData && sameLength && hasColors) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
          py: 3,

          '@media print': {
            px: 1,
            py: 2,
          },
        }}
      >
        {data.map((value, index) => (
          <Stack
            key={`${value}-${index}`}
            direction="row"
            onClick={handleOnClick(index)}
            onMouseEnter={handleLegendMouseEnter(index)}
            onMouseLeave={handleLegendMouseLeave}
            spacing={1}
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              mr: 1.5,
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'block',
                backgroundColor: colors[index],
                borderRadius: '50%',
                height: '12px',
                width: '12px',
                displayPrint: 'none',
              }}
            />
            <SvgIcon
              height={12}
              width={12}
              viewBox="0 0 12 12"
              sx={{
                display: 'none',
                displayPrint: 'block',
                height: '12px',
                m: '0 !important',
                width: '12px',
              }}
            >
              <circle cx="6" cy="6" fill={colors[index]} r="6" />
            </SvgIcon>
            <Typography
              variant="body2"
              sx={{
                fontSize: 12,
              }}
            >
              {legend[index]}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  }

  return null;
}

// function toFixed(num: number, fractionDigit = 4) {
//   return num % 1 ? num.toFixed(fractionDigit) : num;
// }

// function drawCubicCurve(
//   dx1: number,
//   dy1: number,
//   dx2: number,
//   dy2: number,
//   dx: number,
//   dy: number
// ) {
//   return `${toFixed(dx1)} ${toFixed(dy1)} ${toFixed(dx2)} ${toFixed(
//     dy2
//   )} ${toFixed(dx)} ${toFixed(dy)}`;
// }
// function drawRect(width: number, height: number) {
//   const radius = width / 2;
//   const halfRadius = radius / 2;
//
//   // there's a few of these width ? x : 0
//   // purely for animating the cubic curve
//   const topLeft = drawCubicCurve(
//     0,
//     width ? halfRadius : 0,
//     width ? halfRadius : 0,
//     0,
//     radius,
//     0
//   );
//   const topRight = drawCubicCurve(
//     width - halfRadius,
//     0,
//     width,
//     halfRadius,
//     width,
//     radius
//   );
//
//   return `M0 ${radius} C${topLeft} V0 C${topRight} V${height} H0 V${radius} Z`;
// }

// function drawRect(width: number, height: number) {
//   const radius = width / 2;
//   const halfRadius = radius / 2;
//
//   // there's a few of these width ? x : 0
//   // purely for animating the cubic curve
//   const topLeft = drawCubicCurve(
//     0,
//     width ? halfRadius : 0,
//     width ? halfRadius : 0,
//     0,
//     radius,
//     0
//   );
//   const topRight = drawCubicCurve(
//     width - halfRadius,
//     0,
//     width,
//     halfRadius,
//     width,
//     radius
//   );
//
//   return `M0 ${height} V${height} H${width} V0 H0 Z${height}`;
// }

function Bar({
  fill: propFill,
  index,
  onClick,
  onMouseMove,
  onMouseLeave,
  opacity,
  selected,
  x: propX,
  width: propWidth,
  height: propHeight,
}: BarProps) {
  const { strokeColor } = useChartBaseOption();
  const pathRef = useRef<SVGRectElement>(null);
  const [{ fill, height, width, x }, setState] = useState({
    fill: 'transparent',
    height: 0,
    width: propWidth,
    x: propX,
  });
  const isSelected = selected === index;

  useEffect(
    function initialAnimation() {
      const path = d3.select(pathRef.current);
      // const pathDefinition = getPathDefinition(width, height);

      path
        .style('fill', propFill)
        .transition()
        .delay(index * 75) // can use arbitrary number of duration / data.length
        .ease(d3.easeBackOut)
        .duration(450)
        .attr('height', propHeight)
        .on('end', () => {
          if (height !== propHeight || width !== propWidth || x !== propX) {
            setState(() => ({
              fill: propFill,
              height: Math.ceil(propHeight),
              width: Math.ceil(propWidth),
              x: Math.ceil(propX),
            }));
          }
        });

      return () => {
        path.interrupt();
      };
    },
    [width] // eslint-disable-line
  );

  return (
    <rect
      ref={pathRef}
      width={width}
      height={height}
      fill={fill}
      fillOpacity={isSelected ? 1 : typeof selected !== 'number' ? 1 : 0.6}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      opacity={opacity}
      stroke={isSelected ? strokeColor : 'none'}
      strokeWidth={2}
      x={x}
      y={0}
    />
  );
}

function VerticalBarChart({
  colors,
  data,
  legend,
  margin = {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  ratio = 0.5625,
}: BarChartProps) {
  const [containerRect, containerRef] = useRect<HTMLDivElement>();
  const [yAxisRect, yAxisRef] = useRect();
  const { defaultColorRange, strokeColor, textColor } = useChartBaseOption();
  const {
    handleChartOnClick,
    handleChartMouseEnter,
    handleChartMouseLeave,
    handleLegendMouseEnter,
    handleLegendMouseLeave,
    isHovering,
    open,
    parentRef,
    popover,
    selected,
  } = useChartInteractivityHandlers();

  const totalBarWidth = 30 * data.length;
  const marginLeft = Math.ceil(yAxisRect?.width || 0) + PADDING_LEFT;
  const width = containerRect?.width || 0; // default to 1:1 ratio
  const height = width * ratio || data.length * BAND_HEIGHT;
  const colorRange = colors || defaultColorRange;
  const maxValue = d3.max(data, ({ value }) => value) as number;
  const containerWidth = width - margin.right;
  const containerHeight = height - margin.bottom;
  const linearDomain = maxValue == 0 ? [0, 1] : [0, maxValue];
  const scaleDomain = data.map(({ name }) => name);
  const xRange = [marginLeft, totalBarWidth];
  const yRange = [containerHeight, margin.bottom];

  const linearScale = d3.scaleLinear(linearDomain, yRange);
  const bandScale = d3
    .scaleBand(scaleDomain, xRange)
    .paddingInner(BAND_PADDING);

  function getYAxis(ref: SVGGElement) {
    yAxisRef.current = ref;
    const xAxis = d3.axisLeft(linearScale).ticks(3);

    d3.select(ref)
      .call(xAxis)
      .call((g) => g.selectAll('.domain').attr('stroke', 'none'))
      .call((g) =>
        g
          .selectAll('.tick line')
          .attr('x2', containerWidth - Y_AXIS_ADJUSTMENT - 10)
          .attr('x', 0)
          .attr('stroke', strokeColor)
          .attr('stroke-dasharray', 4)
      )
      // .call((g) => g.selectAll('.tick line').attr('width', containerWidth))
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('font-size', FONT_SIZE)
          .attr('x', -Y_TICK_WIDTH)
          .attr('y', -Y_AXIS_TEXT_ADJUSTMENT)
          .attr('fill', textColor)
      );
  }

  const bars = useMemo(
    () =>
      data.map((d, i) => ({
        data: d,
        x: bandScale(d.name) || 0,
        y: linearScale(d.value),
        width: bandScale.bandwidth(),
        height: containerHeight - linearScale(d.value),
        fill: d.color || colorRange[i],
      })),
    [data, bandScale, linearScale, containerHeight, colorRange]
  );

  return (
    <Stack ref={containerRef} sx={{ position: 'relative' }}>
      <Box ref={parentRef} sx={{ position: 'relative' }}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <g>
            <g
              style={{
                cursor: 'pointer',
                transform: `scaleY(-1) translateX(${
                  containerWidth / 2 - totalBarWidth / 2
                }px) translateY(${-containerHeight}px)`,
              }}
            >
              {bars.map((bar, i) => (
                <Bar
                  key={bar.data.name}
                  {...bar}
                  index={i}
                  onClick={handleChartOnClick(i)}
                  onMouseMove={handleChartMouseEnter(i)}
                  onMouseLeave={handleChartMouseLeave}
                  opacity={isHovering(i) ? 0.2 : undefined}
                  selected={selected}
                />
              ))}
            </g>
            <g
              ref={getYAxis}
              transform={`translate(${Y_AXIS_ADJUSTMENT}, 0)`}
            />
          </g>
        </svg>
      </Box>

      <Legend
        data={data}
        legend={legend}
        colors={colorRange}
        handleOnClick={handleChartOnClick}
        handleLegendMouseEnter={handleLegendMouseEnter}
        handleLegendMouseLeave={handleLegendMouseLeave}
      />

      {data.length ? (
        <ChartTooltip
          left={popover.left}
          top={popover.top}
          horizontalAlignment="center"
          verticalAlignment="bottom"
          sx={{
            backgroundColor: popover.fill,
            color: 'common.white',
            opacity: Number(open),
          }}
        >
          {String(data[popover.index].value)}
        </ChartTooltip>
      ) : null}
    </Stack>
  );
}

export { VerticalBarChart };
