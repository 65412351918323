import TabContext from '@mui/lab/TabContext';

import type { FieldsProps } from './types.ts';
import { Filtering } from './Filtering.tsx';
import { useFiltering } from './useFiltering.ts';
import { AllFields } from './AllFields.tsx';
import { ConsistencyResults } from './ConsistencyResults.tsx';

function Fields(props: FieldsProps) {
  const { filter, handleChange } = useFiltering();

  return (
    <TabContext value={filter}>
      <Filtering value={filter} onChange={handleChange} />
      <AllFields {...props} filter={filter} />
      <ConsistencyResults {...props} />
    </TabContext>
  );
}

export { Fields };
