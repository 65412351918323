import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { WARNING_ICON } from 'constants/public-icons.ts';

import { InputField } from './InputField.tsx';
import type { ContentWithId } from './types.ts';

interface InputDropDownFallbackProps {
  error: Error | null;
  resetError: () => void;

  id: string;
  item: ContentWithId;
  label: string;
  pageIdx: number;
}

function InputDropDownFallback({
  resetError,

  id,
  item,
  label,
  pageIdx,
}: InputDropDownFallbackProps) {
  const { t } = useTranslationRoot();

  return (
    <InputField
      {...item}
      id={id}
      label={label}
      pageIdx={pageIdx}
      endAdornment={
        <Tooltip title={t('form.initiateCodeMappingFail')}>
          <Box
            sx={{ color: 'warning.main', display: 'flex', ml: 0.5 }}
            role="button"
            onClick={resetError}
          >
            <SvgIconStyle src={WARNING_ICON} height={20} width={20} />
          </Box>
        </Tooltip>
      }
    />
  );
}

export { InputDropDownFallback };
