import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { TRIANGLE_ICON } from 'constants/public-icons.ts';

import type { ControlledAccordionProps } from './types.ts';

function ControlledAccordion({
  children,
  defaultExpanded = true,
  title = '',
}: ControlledAccordionProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      disableGutters
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<SvgIconStyle height={7} src={TRIANGLE_ICON} width={7} />}
      >
        <TypographyWithTranslation
          i18nKey={title}
          component="span"
          variant="subtitle2"
        />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export { ControlledAccordion };
