import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { metadataRenderers } from './metadataRenderers.tsx';
import type { MetadataAction } from './metadataGenerators.tsx';

interface MetadataProps {
  getContent: MetadataAction[];
}

function Metadata({ getContent }: MetadataProps) {
  return (
    <Stack direction="row" spacing={1.5} sx={{ alignItems: 'stretch' }}>
      {getContent.map((content, i) => {
        const { type, ...rest } = content;
        const renderer = metadataRenderers[type];

        return (
          <Fragment key={i}>
            {i !== 0 && <Divider orientation="vertical" flexItem />}
            <Stack
              key={i}
              spacing={1.5}
              sx={{
                justifyContent: 'center',
              }}
            >
              {renderer(rest as any)}
            </Stack>
          </Fragment>
        );
      })}
    </Stack>
  );
}

export { Metadata };
