import Stack from '@mui/material/Stack';
import { OpenCommentsButton } from 'components/Comments/OpenCommentsButton.tsx';
import { SubmitButton } from 'components/form/SubmitButton.tsx';

import type { ActionsProps } from './types.ts';
// import { SaveButton } from './SaveButton.tsx';

function Actions({
  claimId,
  isLockedByCurrentUser,
  isSubmitting,
}: ActionsProps) {
  return (
    <Stack direction="row" spacing={1}>
      <OpenCommentsButton entityId={claimId} claimId={claimId} />
      {/*<SaveButton disabled={true} loading={false} />*/}
      <SubmitButton disabled={!isLockedByCurrentUser} loading={isSubmitting} />
    </Stack>
  );
}

export { Actions };
