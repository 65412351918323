import type { LabelColor } from 'components/Label';

// status
export const AWAITING_ENRICHMENT = 'AWAITING_ENRICHMENT';
export const AWAITING_OCR_RESULTS = 'AWAITING_OCR_RESULTS';
export const AWAITING_INFO = 'AWAITING_INFO';
export const AWAITING_REVIEW = 'AWAITING_REVIEW';
export const CLOSED = 'CLOSED';
export const COMPLETED = 'COMPLETED';
export const ERROR = 'ERROR';
export const PROCESSING = 'PROCESSING';
export const REJECTED = 'REJECTED';

export const ALL_STAGES = [
  AWAITING_ENRICHMENT,
  AWAITING_OCR_RESULTS,
  AWAITING_INFO,
  AWAITING_REVIEW,
  CLOSED,
  COMPLETED,
  ERROR,
  PROCESSING,
  REJECTED,
];

export const statusTranslation: { [key: string]: string } = {
  [AWAITING_ENRICHMENT]: 'stage.awaitingEnrichment',
  [AWAITING_OCR_RESULTS]: 'stage.awaitingOcrResults',
  [AWAITING_INFO]: 'stage.awaitingInfo',
  [AWAITING_REVIEW]: 'stage.awaitingReview',
  [CLOSED]: 'stage.closed',
  [COMPLETED]: 'stage.completed',
  [ERROR]: 'stage.error',
  [PROCESSING]: 'stage.processing',
  [REJECTED]: 'stage.rejected',
};

export const STAGE_LABEL_COLOR_MAP: { [key: string]: LabelColor } = {
  [AWAITING_ENRICHMENT]: 'primary',
  [AWAITING_OCR_RESULTS]: 'secondary',
  [AWAITING_INFO]: 'default',
  [AWAITING_REVIEW]: 'info',
  [CLOSED]: 'default',
  [COMPLETED]: 'success',
  [ERROR]: 'error',
  [PROCESSING]: 'default',
  [REJECTED]: 'error',
};

const CLAIM_CREATED = 'CLAIM_CREATED';
const CLAIM_PROPERTIES_RECEIVED = 'CLAIM_PROPERTIES_RECEIVED';
const CLAIM_SENT = 'CLAIM_SENT';
const DOCUMENT_EXTRACTED = 'DOCUMENT_EXTRACTED';
const DOCUMENT_REVIEW_SUBMITTED = 'DOCUMENT_REVIEW_SUBMITTED';
const DOCUMENT_OCR_FINISHED = 'DOCUMENT_OCR_FINISHED';
const DOCUMENT_UPDATED_IN_CLAIM_REVIEW = 'DOCUMENT_UPDATED_IN_CLAIM_REVIEW';
const ENRICHMENT_RECEIVED = 'ENRICHMENT_RECEIVED';
const FILE_RECEIVED = 'FILE_RECEIVED';
const FILES_READY_TO_PROCESS = 'FILES_READY_TO_PROCESS';
const HIL_DOCUMENT_CORRECTED = 'HIL_DOCUMENT_CORRECTED';
const OBSERVATION_CREATED = 'OBSERVATION_CREATED';
const OBSERVATION_UPDATED = 'OBSERVATION_UPDATED';

export const auditEventTranslation: { [key: string]: string } = {
  [CLAIM_CREATED]: 'audit.claimCreated',
  [CLAIM_PROPERTIES_RECEIVED]: 'audit.claimPropertiesReceived',
  [CLAIM_SENT]: 'audit.claimSent',
  [DOCUMENT_EXTRACTED]: 'audit.documentExtracted',
  [DOCUMENT_REVIEW_SUBMITTED]: 'audit.documentReviewSubmitted',
  [DOCUMENT_OCR_FINISHED]: 'audit.documentOcrFinished',
  [DOCUMENT_UPDATED_IN_CLAIM_REVIEW]: 'audit.documentUpdatedInClaimReview',
  [ENRICHMENT_RECEIVED]: 'audit.enrichmentReceived',
  [FILE_RECEIVED]: 'audit.fileReceived',
  [FILES_READY_TO_PROCESS]: 'audit.filesReadyToProcess',
  [HIL_DOCUMENT_CORRECTED]: 'audit.hilDocumentCorrected',
  [OBSERVATION_CREATED]: 'audit.observationCreated',
  [OBSERVATION_UPDATED]: 'audit.observationUpdated',
};

// observation types
export enum ObservationType {
  DOCUMENT = 'DOCUMENT',
  ENRICHMENT = 'ENRICHMENT',
  PROPERTIES = 'PROPERTIES',
}

// claim types
export const REIMBURSEMENT = 'reimbursement';
export const PROVIDER = 'provider';

// feature types
export const ITEMS = 'items';

// Features List Ids
export const CLAIM_FEATURES = 'features';
export const MAIN_LIST = 'main-list';
export const LINES_ITEM_IDS = 'item-ids';
export const LINES_KEYS = 'lines-keys';
export const makeLinesListKey = (prefix: string) => `${prefix}-ids`;
export const makeItemIdList = (prefix: string, index: number) =>
  `${prefix}-${index}`;
export const makeItemFieldId = (prefix: string, index: number, key: string) =>
  `${prefix}-${index}-${key}`;
