import type { ReactNode } from 'react';
import type { TextFieldProps } from '@mui/material/TextField';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import Alert, { alertClasses } from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { ControllerWithTextFieldV2 } from 'components/form/ControllerWithTextFieldV2.tsx';
import { ControllerWithDatePickerV2 } from 'components/form/ControllerWithDatePickerV2.tsx';
import { ControllerWithRadioGroup } from 'components/form/ControllerWithRadioGroup.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { LIST } from 'constants/field-type.ts';
import { BOOL, DATE, FLOAT } from 'constants/value-type.ts';

import type { InputFieldProps, Resources } from './types.ts';
import {
  getForcefullyDisabledItems,
  getInputProps,
  getItemValidity,
  getVisuallyHiddenItem,
} from './utils.ts';
import { SkipCheckbox } from './SkipCheckbox.tsx';
import { InfoTooltip } from './InfoTooltip.tsx';
import { ALL, SKIP_INPUT_SUFFIX } from './constants.ts';
import { useValidationMessageBoolean } from './useValidationMessageBoolean.ts';

function InputField({
  defaultSpacing = 2,
  deleteComponent,
  filterItemsBy = ALL,
  historicalResources,
  id,
  isLineItem = false,
  resources,
  sx,
}: InputFieldProps) {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();
  const { errors } = useFormState();
  const validationMessageBooleanState = useValidationMessageBoolean();

  // item props
  const item = resources[id];
  const { boundingBox, fieldType, pageIdx, sproutaiKey, value } = item;
  const { invalid, valid } = getItemValidity(item);
  const isVisuallyHidden = getVisuallyHiddenItem(item, filterItemsBy);
  const historicalItem = historicalResources?.[id];
  const validationMessage = errors[id]?.message;
  const startAdornment =
    historicalItem?.value || value || validationMessage ? (
      <Box sx={{ cursor: 'help', mt: 2, width: 32 }}>
        <InfoTooltip
          ocrValue={historicalItem?.value || value}
          // validationMessage={validationMessage as string}
        />
      </Box>
    ) : null;

  // skip feature
  const skipped = useWatch({ control, name: `${id}${SKIP_INPUT_SUFFIX}` });
  const enableSkip = !getForcefullyDisabledItems(sproutaiKey) && invalid;

  const handleFocus = () => {
    canvasCustomEvents.addCrop({
      boundingBox,
      id,
      pageIdx: pageIdx || 0,
      valid,
    });
  };

  return (
    <Stack
      data-testid={`${id}-parent-node`}
      direction="row"
      spacing={defaultSpacing}
      sx={{
        alignItems: 'flex-start',
        display: isVisuallyHidden ? 'none' : 'flex',
        justifyContent: 'space-between',
        pb: 2,
        ...sx,
      }}
    >
      {fieldType === LIST ? (
        <ListTypeInput
          {...validationMessageBooleanState}
          handleFocus={handleFocus}
          historicalResources={historicalResources}
          id={id}
          isVisuallyHidden={isVisuallyHidden}
          resources={resources}
          skipped={skipped}
          startAdornment={startAdornment}
        />
      ) : (
        <SingleTypeInput
          {...validationMessageBooleanState}
          handleFocus={handleFocus}
          historicalResources={historicalResources}
          id={id}
          isVisuallyHidden={isVisuallyHidden}
          resources={resources}
          skipped={skipped}
          startAdornment={startAdornment}
        />
      )}

      {deleteComponent}
      <Stack
        sx={{
          alignItems: 'center',
          // if isLineItem and item is valid then hide skip checkbox
          display: isLineItem && !enableSkip ? 'none' : 'flex',
          flex: `0 0 36px`,
          justifyContent: 'center',
          pt: 0.75,
        }}
      >
        {enableSkip && (
          <Controller
            control={control}
            name={`${id}${SKIP_INPUT_SUFFIX}`}
            render={({ field }) => {
              const { value, ...fieldProps } = field;

              return (
                <SkipCheckbox
                  title={t('form.skipLabel')}
                  {...fieldProps}
                  checked={value}
                />
              );
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}

export interface BaseProps {
  historicalResources: Resources;
  id: string;
  isVisuallyHidden: boolean;
  resources: Resources;
  skipped: boolean;
  startAdornment: ReactNode;
}

interface SingleTypeInputProps extends BaseProps {
  handleFocus: () => void;
  openValidationMessage: () => void;
  closeValidationMessage: () => void;
  isValidationMessageOpen: boolean;
}

interface ListTypeInputProps extends BaseProps {
  handleFocus: () => void;
  openValidationMessage: () => void;
  closeValidationMessage: () => void;
  isValidationMessageOpen: boolean;
}

function helperText(open: boolean) {
  const component = (message?: string) => {
    if (!message) {
      return null;
    }

    return (
      <Collapse in={open}>
        <Alert
          severity="error"
          sx={{
            py: 0,
            px: 1.5,
            fontSize: 12,
            mb: 0.5,
            mt: 1,
            [`& .${alertClasses.icon}`]: {
              mr: 1,
              '& svg': {
                width: 20,
                height: 20,
              },
            },
          }}
        >
          {message}
        </Alert>
      </Collapse>
    );
  };

  return component;
}

function SingleTypeInput({
  closeValidationMessage,
  handleFocus,
  id,
  isValidationMessageOpen,
  isVisuallyHidden,
  openValidationMessage,
  resources,
  skipped,
  startAdornment,
}: SingleTypeInputProps) {
  const item = resources[id];
  const { clientKey, valueType } = item;
  const baseProps: TextFieldProps = {
    ...getInputProps({ isVisuallyHidden, item, skipped, startAdornment }),
    onFocus: () => {
      handleFocus();
      openValidationMessage();
    },
    onBlur: () => {
      closeValidationMessage();
    },
  };

  switch (valueType) {
    case BOOL:
      return (
        <ControllerWithRadioGroup
          name={id}
          disabled={skipped}
          label={clientKey}
          getOptionLabel={['form.true', 'form.false']}
          radioProps={{
            sx: {
              color: `${baseProps.color}.main`,
              '&.Mui-checked': {
                color: `${baseProps.color}.main`,
              },
            },
          }}
        />
      );
    case DATE:
      return (
        <ControllerWithDatePickerV2
          helperTextFn={helperText(isValidationMessageOpen)}
          label={clientKey}
          name={id}
          textfieldProps={baseProps}
          data-testid="date-picker-input"
        />
      );
    default:
      return (
        <ControllerWithTextFieldV2
          name={id}
          helperTextFn={helperText(isValidationMessageOpen)}
          textfieldProps={{
            ...baseProps,
          }}
        />
      );
  }
}

function ListTypeInput({
  closeValidationMessage,
  handleFocus,
  isValidationMessageOpen,
  isVisuallyHidden,
  id,
  openValidationMessage,
  resources,
  skipped,
  startAdornment,
}: ListTypeInputProps) {
  const item = resources[id];
  const { valueType } = item;
  const baseProps: TextFieldProps = {
    ...getInputProps({ isVisuallyHidden, item, skipped, startAdornment }),
    onFocus: () => {
      handleFocus();
      openValidationMessage();
    },
    onBlur: () => {
      closeValidationMessage();
    },
  };

  switch (valueType) {
    case BOOL:
    case DATE:
    case FLOAT:
      return null;
    default:
      return (
        <ControllerWithTextFieldV2
          name={id}
          helperTextFn={helperText(isValidationMessageOpen)}
          textfieldProps={{
            ...baseProps,
          }}
        />
      );
  }
}

export { InputField };
