import type { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslationRoot } from 'components/with-translation.tsx';

import type { FilterFieldsProps, FilterTypes } from './types';
import { ALL, OPTIONS } from './constants.ts';

function FilterFields({ handleChange, value = ALL }: FilterFieldsProps) {
  const { t } = useTranslationRoot();

  function onChange(e: SelectChangeEvent) {
    handleChange(e.target.value as FilterTypes);
  }

  return (
    <FormControl sx={{ minWidth: 140 }} size="small">
      <InputLabel id="filter-items">{t('form.filterItemsBy')}</InputLabel>
      <Select
        label={t('form.filterItemsBy')}
        labelId="filter-items"
        onChange={onChange}
        value={value}
      >
        {OPTIONS.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { FilterFields };
