import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Avatar } from 'components/Avatar';
import type { Comment } from 'types/Comments.ts';
import { createAvatar } from 'utils/create-avatar.ts';
import { timeSince } from 'utils/date';

interface CommentProps {
  comment: Comment;
}

function Comment({ comment }: CommentProps) {
  const { content, createdBy, createdAt } = comment;
  const time = new Date(createdAt);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
      }}
    >
      <Avatar
        alt={createdBy}
        color={createAvatar(createdBy).color}
        sx={{
          mr: 1,
          width: 32,
          height: 32,
          fontSize: '14px',
        }}
      >
        {createAvatar(createdBy).name}
      </Avatar>
      <Box sx={{ flex: 1 }}>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space',
            gap: 1,
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Typography variant="subtitle2">{createdBy}</Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'text.disabled',
            }}
          >
            {timeSince(time)}
          </Typography>
          {/* <Box
            sx={{
              ml: 'auto',
            }}
          >
            <Actions
              handleEdit={() => {}}
              handleCopy={() => {}}
              handleDelete={() => {}}
              handleSave={() => {}}
              handleCancel={() => {}}
              isEditable={false}
            />
          </Box> */}
        </Stack>
        <Typography
          variant="body2"
          sx={{
            wordBreak: 'break-word',
          }}
        >
          {content}
        </Typography>
      </Box>
    </Stack>
  );
}

export { Comment };
