// namespaces
export const CLIENT_PORTAL_PAGE = 'client-portal-page';
export const COMMON = 'common';
export const LOGIN_PAGE = 'login-page';
export const DASHBOARD_PAGE = 'dashboard-page';
export const DOCS_PAGE = 'docs-page';
export const DOCUMENT_CORRECTIONS_PAGE = 'document-corrections-page';
export const DOCUMENT_CORRECTION_PAGE = 'document-correction-page';
export const HITL_PAGE = 'hitl-page';
export const HITL_TOOL_PAGE = 'hitl-tool-page';
export const HITL_GALLERY_PAGE = 'hitl-gallery-page';
export const CLAIMS_PAGE = 'claims-page';
export const CLAIM_PAGE = 'claim-page';
export const CLAIM_HIL_TOOL_PAGE = 'claim-hil-tool-page';
export const CONSISTENCY_CHECKS_PAGE = 'consistency-checks-page';
export const CONSISTENCY_CHECK_PAGE = 'consistency-check-page';
export const PERFORMANCE_PAGE = 'performance-page';
export const PERFORMANCE_MANAGE_USERS_PAGE = 'performance-manage-users-page';
export const PERFORMANCE_CREATE_USER_PAGE = 'performance-create-user-page';
export const POV_PAGE = 'pov-page';
export const HEALTH_PAGE = 'health-page';
export const FEATURE_FLAGS_PAGE = 'feature-flags';
export const CREATE_CLAIM_PAGE = 'create-claim-page';
export const UPLOAD_DOCUMENT_PAGE = 'upload-document-page';
export const FILE_UPLOAD_PAGE = 'file-upload-page';
export const FILES_PAGE = 'files-page';
export const CONFIGURATION_PAGE = 'configuration-page';
export const CLASSIFICATION_PAGE = 'classification-page';
export const CLASSIFICATIONS_PAGE = 'classifications-page';
export const PAGES_PAGE = 'pages-page';
export const REDACTIONS_PAGE = 'redactions-page';
export const REDACTION_PAGE = 'redaction-page';
export const SPLIT_PAGE = 'split-page';
export const SPLITS_PAGE = 'splits-page';
export const USER_MANAGEMENT_PAGE = 'user-management-page';
export const USER_MANAGEMENT_CREATE_USER_PAGE =
  'user-management-create-user-page';
