import Stack from '@mui/material/Stack';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { Header as HeaderLayout } from 'components/CommonTool/Header.tsx';
import { OpenCommentsButton } from 'components/Comments/OpenCommentsButton.tsx';

import type { HeaderProps } from './types.ts';
import { SubmitButton } from './SubmitButton.tsx';
import { RejectButton } from './RejectButton.tsx';
import { SaveButton } from './SaveButton.tsx';
import { MetadataTooltip } from './MetadataTooltip.tsx';
import { DocumentTypeChanger } from './DocumentTypeChanger.tsx';
import { NextButton } from './NextButton.tsx';

function Header({
  submitDisabled,
  document,
  isRejecting,
  isSaving,
  isSubmitting,
  list,
  onDocumentTypeChange,
  onNext,
  onReject,
  onSave,
  rejectDisabled,
  resources,
  saveDisabled,
}: HeaderProps) {
  const showClientDocumentChanger = useFeatureFlag(
    'enableHILDocumentTypeChanger'
  );

  return (
    <HeaderLayout
      leftColumn={<div> </div>}
      rightColumn={
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', height: 1, justifyContent: 'center' }}
        >
          <MetadataTooltip document={document} />
          <OpenCommentsButton
            entityId={document.claimId || document.id}
            claimId={document.claimId}
          />
          {showClientDocumentChanger && (
            <DocumentTypeChanger
              document={document}
              list={list}
              onSubmit={onDocumentTypeChange}
              resources={resources}
            />
          )}
          <SaveButton
            disabled={saveDisabled}
            loading={isSaving}
            onSave={onSave}
          />
          <RejectButton
            disabled={rejectDisabled}
            handleReject={onReject}
            loading={isRejecting}
          />
          <SubmitButton disabled={submitDisabled} loading={isSubmitting} />
          <NextButton onNext={onNext} />
        </Stack>
      }
    />
  );
}

export { Header };
