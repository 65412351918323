import { useCallback, useMemo } from 'react';
import { ErrorBoundary } from '@rollbar/react';
import type { LineLevelContent } from 'types/Documents.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { isLineLevelItem } from 'state/selectors/documents.ts';

import type { ConsistencyResultItemProps } from './types.ts';
import { InputField } from './InputField.tsx';
import { getLineClientKey } from './utils/get-line-clientkey.ts';
import { InputDropDown } from './InputDropDown.tsx';
import { getItem, getDocumentGroup } from './utils/get-item.ts';
import { InputDropDownFallback } from './InputDropDownFallback.tsx';

function ConsistencyResultItem({
  codeMappings,
  fieldsListIds,
  getPageRef,
  id,
  observations,
  resources,
}: ConsistencyResultItemProps) {
  const { t } = useTranslationRoot();

  const genLabelWithTitle = useCallback(
    (title: string, key: string) => `${key} (${title})`,
    []
  );

  const observationId = useMemo(
    () =>
      Object.keys(fieldsListIds).find((key) =>
        fieldsListIds[key].includes(id)
      ) as string,
    [fieldsListIds, id]
  );
  const observation = observations[observationId];

  if (observation) {
    const title = observation.title;
    const item = resources[id];
    const isLineItem = isLineLevelItem(item);
    const label = isLineItem
      ? getLineClientKey(
          t('form.line'),
          (item as LineLevelContent).lineIdx + 1,
          item.clientKey
        )
      : item.clientKey;
    const labelWithTitle = genLabelWithTitle(title, label);
    const pageIdx = getPageRef(observationId, item.pageIdx || 0);
    const documentGroupItemId = fieldsListIds[observationId].find(
      getItem(resources)(getDocumentGroup)
    );

    if (documentGroupItemId) {
      const documentGroup = resources[documentGroupItemId].value;
      const codeMapping = codeMappings?.[item.sproutaiKey];

      if (codeMapping?.documentGroups?.includes(documentGroup)) {
        return (
          <ErrorBoundary
            fallbackUI={(props) => (
              <InputDropDownFallback
                {...props}
                id={id}
                item={item}
                label={labelWithTitle}
                pageIdx={pageIdx}
              />
            )}
          >
            <InputDropDown
              codeMapping={codeMappings[item.sproutaiKey]}
              currentObservationListIds={fieldsListIds[observationId]}
              fieldsListIds={fieldsListIds}
              resources={resources}
              id={id}
              label={labelWithTitle}
              boundingBox={item.boundingBox}
              pageIdx={pageIdx}
              valid={item.valid}
            />
          </ErrorBoundary>
        );
      }
    }

    return (
      <InputField {...item} id={id} label={labelWithTitle} pageIdx={pageIdx} />
    );
  }

  return null;
}

export { ConsistencyResultItem };
