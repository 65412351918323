import type { ReactElement, Ref } from 'react';
import type { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LazyImage } from 'components/Image';
import { Page } from 'components/Page';
import { useBoolean } from 'components/customHooks/useBoolean';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { PAGE_ID, RESOURCE_ID } from 'constants/route-keys';
import { CLOSE_ICON } from 'constants/public-icons';
import { HITL_GALLERY_PAGE } from 'constants/translation-keys';
import hitlToolRoute from 'pages/HitLTool/hitlTool.route';
import { useGetDocument } from 'state/queries/documents';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { TypographyWithTranslation } from 'components/with-translation';
import { useLocales } from 'locales/useLocales';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

function HitLGallery() {
  const { t } = useTranslation(HITL_GALLERY_PAGE);
  const { resolvedLanguage } = useLocales();
  const navigate = useNavigate();
  const params = useParams();
  const documentId = params[RESOURCE_ID] as string;
  const pageId = params[PAGE_ID] as string;
  const result = useGetDocument(documentId, resolvedLanguage);
  const [isOpen, openModal, closeModal] = useBoolean();

  function handleCloseModal() {
    closeModal();
    navigate(hitlToolRoute.createPath(documentId));
  }

  useEffect(() => {
    openModal();
  }, []); // eslint-disable-line

  if (result.isPending) {
    return <LoadingIcon />;
  }

  if (result.isSuccess) {
    return (
      <Page i18nKeyTitle="meta.title" namespace={HITL_GALLERY_PAGE}>
        <Dialog
          fullScreen
          open={isOpen}
          onClose={handleCloseModal}
          TransitionComponent={Transition}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          PaperProps={{
            sx: {
              bgcolor: 'common.black',
            },
          }}
        >
          <DialogTitle
            id="modal-title"
            sx={{
              color: 'primary.contrastText',
            }}
          >
            {t('common.heading', { documentId: result.data?.id })}
            <IconButton
              aria-label={t('common.closeButtonText') as string}
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: ({ spacing }) => spacing(2),
                top: ({ spacing }) => spacing(2),
              }}
            >
              <SvgIconStyle src={CLOSE_ICON} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <LazyImage
              alt=""
              src={result.data.urls[pageId]}
              sx={{ maxWidth: '100%', width: '100%' }}
              style={{ width: '100%' }}
            />
          </DialogContent>
        </Dialog>
      </Page>
    );
  }

  if (result.isError) {
    return (
      <TypographyWithTranslation
        i18nKey="common.loadDocumentError"
        namespace={HITL_GALLERY_PAGE}
        variant="body1"
      />
    );
  }

  return null;
}

export default HitLGallery;
