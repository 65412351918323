import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TabPanel from '@mui/lab/TabPanel';
import { ScrollableContainer } from 'components/ScrollableContainer.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';

import { type ConsistencyResultsProps, FilterType } from './types.ts';
import { ConsistencyResult } from './ConsistencyResult.tsx';

function ConsistencyResults(props: ConsistencyResultsProps) {
  const { t } = useTranslationRoot();
  const { consistencyListIds } = props;

  return (
    <TabPanel value={FilterType.CONSISTENCY}>
      <Box sx={{ px: 2 }}>
        <ScrollableContainer offset={16}>
          <Stack spacing={2} sx={{ pb: 1 }}>
            {consistencyListIds.length ? (
              <ConsistencyResult {...props} />
            ) : (
              <Paper
                elevation={2}
                sx={{ backgroundColor: 'background.default', p: 2 }}
              >
                <Alert severity="warning">{t('form.noValidationRules')}</Alert>
              </Paper>
            )}
          </Stack>
        </ScrollableContainer>
      </Box>
    </TabPanel>
  );
}

export { ConsistencyResults };
