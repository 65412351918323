const tssus = {
  palette: {
    primary: {
      light: '#00489a',
      main: '#002b5c',
      dark: '#00152c',
    },
    secondary: {
      light: '#cd423f',
      main: '#a92a27',
      dark: '#781513',
    },
    chart: {
      primary: ['#005aa8', '#0063a8', '#0090da', '#60c0ff'],
      secondary: ['#699d1a', '#729d1a', '#a4ce4e', '#d8ff7f'],
      blue: ['#3645f3', '#4957de', '#5d6dff', '#7e8aff'],
      yellow: ['#fdd835', '#ffeb3b', '#fceb55', '#fef075'],
      green: ['#00c64a', '#00ab2d', '#008000', '#005000'],
      red: ['#c0802e', '#c0632e', '#c0442e', '#a9412f'],
    },
  },
};

export default tssus;
