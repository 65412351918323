import {
  matchPath,
  matchRoutes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useBoolean } from 'components/customHooks/useBoolean';
import { SiteLayout } from 'components/SiteLayout';
import { Toaster } from 'components/toast';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useLocalStorage } from 'components/customHooks/useLocalStorage';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { SiteLayoutV2 } from 'components/SiteLayoutV2.tsx';
import { FeatureFlagsProvider } from 'components/FeatureFlags.tsx';
import { generalConfig } from 'config';
import {
  ADMIN,
  CLAIM,
  CLAIM_ADMIN,
  CLAIM_REVIEW,
  CLIENT_ADMIN,
  DOCUMENT_ADMIN,
  HITL,
  HITL_AND_MI,
  MI,
  POV,
  SUPER_ADMIN,
} from 'constants/roles';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { STAGE } from 'constants/route-keys';
import { AWAITING_REVIEW } from 'constants/claims';
import performanceRoute from 'pages/Performance/performance.route';
import hitlRoute from 'pages/HitL/hitl.route';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import { SomethingWentWrong } from 'pages/Site/SomethingWentWrong';
import claimsRoute from 'pages/Claims/claims.route';
import loginRoute from 'pages/Login/login.route';
import Splash from 'pages/Site/Splash.tsx';
import { routes } from 'routes/routes';
import { useCurrentUserRole } from 'state/queries/current-user';
import type { CustomRouteObject } from 'routes/types';

import { CookieBanner } from './CookieBanner.tsx';
import { DashboardHeader } from './DashboardHeader.tsx';
import { DashboardNavBar } from './DashboardNavBar.tsx';
import { PostHogConfig } from './PostHogConfig.tsx';
import { NavBarV2 } from './NavBarV2.tsx';

const roleRedirectPathMap: {
  [k: string]: string;
} = {
  [ADMIN]: performanceRoute.path,
  [CLAIM]: claimsRoute.path,
  [CLAIM_REVIEW]: claimsRoute.createPath({
    search: `?${STAGE}=${AWAITING_REVIEW}`,
  }),
  [HITL]: hitlRoute.path,
  [HITL_AND_MI]: hitlRoute.path,
  [MI]: performanceRoute.path,
  [POV]: fileUploadRoute.path,
  [SUPER_ADMIN]: performanceRoute.path,
  [CLAIM_ADMIN]: fileUploadRoute.path,
  [DOCUMENT_ADMIN]: fileUploadRoute.path,
  [CLIENT_ADMIN]: fileUploadRoute.path,
};

function getPermissions(pathname: string) {
  const matchedRoutes = matchRoutes(routes, pathname);

  if (matchedRoutes?.length === 1) {
    return (matchedRoutes[0].route as CustomRouteObject).permission;
  }

  return (matchedRoutes?.slice(1)[0].route as CustomRouteObject).permission;
}

function getRedirectPath(role: string) {
  return roleRedirectPathMap[role];
}

function DashboardLayout() {
  const { pathname } = useLocation();
  const { t } = useTranslationRoot(DASHBOARD_PAGE);
  const [isOpen, onOpenSidebar, onCloseSidebar] = useBoolean();
  const [localStore, setLocalStore] = useLocalStorage(
    generalConfig.miniNavbarStore,
    false
  );
  const [isMiniNavBarOpen, , , toggleMiniNavbar] = useBoolean(localStore);
  const {
    data: currentUserRole,
    isError,
    isPending,
    isSuccess,
  } = useCurrentUserRole();
  const permissions = getPermissions(pathname);

  if (isPending) {
    return (
      <Splash>
        <TypographyWithTranslation i18nKey="loading" />
      </Splash>
    );
  }

  if (isError) {
    return (
      <SomethingWentWrong
        error={new Error(t('common.loginFailedApi') as string)}
      />
    );
  }

  if (matchPath('/*', pathname)) {
    if (
      isSuccess &&
      currentUserRole &&
      !permissions?.includes(currentUserRole)
    ) {
      return (
        <SomethingWentWrong
          error={new Error(t('common.loginFailedPermissions') as string)}
        />
      );
    }
  }

  if (isSuccess && matchPath('/', pathname)) {
    if (currentUserRole) {
      return <Navigate to={getRedirectPath(currentUserRole)} />;
    } else {
      return <Navigate to={loginRoute.createPath()} />;
    }
  }

  return (
    <FeatureFlagsProvider>
      <CookieBanner>
        <PostHogConfig>
          <Dashboard
            isMiniNavBarOpen={isMiniNavBarOpen}
            onOpenSidebar={onOpenSidebar}
            isOpen={isOpen}
            onCloseSidebar={onCloseSidebar}
            setLocalStore={setLocalStore}
            toggleMiniNavbar={toggleMiniNavbar}
          />
        </PostHogConfig>
      </CookieBanner>
      <Toaster />
    </FeatureFlagsProvider>
  );
}

function Dashboard({
  isMiniNavBarOpen,
  onOpenSidebar,
  isOpen,
  onCloseSidebar,
  setLocalStore,
  toggleMiniNavbar,
}: {
  isMiniNavBarOpen: boolean;
  onOpenSidebar: () => void;
  isOpen: boolean;
  onCloseSidebar: () => void;
  setLocalStore: <T>(value: T) => void;
  toggleMiniNavbar: () => void;
}) {
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');

  return (
    <>
      {enableNewSiteLayout ? (
        <SiteLayoutV2
          isMiniNavBarOpen={isMiniNavBarOpen}
          navbar={
            <NavBarV2
              isMiniNavBarOpen={isMiniNavBarOpen}
              setLocalStore={setLocalStore}
              toggleMiniNavbar={toggleMiniNavbar}
            />
          }
        >
          <Outlet />
        </SiteLayoutV2>
      ) : (
        <SiteLayout
          isMiniNavBarOpen={isMiniNavBarOpen}
          header={
            <DashboardHeader
              isMiniNavBarOpen={isMiniNavBarOpen}
              onOpenSidebar={onOpenSidebar}
            />
          }
          navbar={
            <DashboardNavBar
              isMiniNavBarOpen={isMiniNavBarOpen}
              isOpenSidebar={isOpen}
              onCloseSidebar={onCloseSidebar}
              setLocalStore={setLocalStore}
              toggleMiniNavbar={toggleMiniNavbar}
            />
          }
        >
          <Outlet />
        </SiteLayout>
      )}
    </>
  );
}

export default DashboardLayout;
