import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'components/toast';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { DocumentValidation } from 'types/Documents';
import { HIL_VALIDATION_FAIL } from 'analytics/events';
import { sendAnalyticEvent } from 'analytics/utils';
import { ScrollableContainer } from 'components/ScrollableContainer.tsx';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { HITL_TOOL_PAGE } from 'constants/translation-keys';
import { useDocumentValidation } from 'state/queries/documents';
import {
  getIsDocumentValidationStageCompleted,
  getIsDocumentValidationStageError,
  isNotValidItem,
} from 'state/selectors/documents';
import { HEADER } from 'theme/layout';
import { generateUuid } from 'utils/generate-uuid';
import { unique } from 'utils/array';

import type { HitLFormProps, HitLFormValues, List } from './types';
import { FormButtons } from './FormButtons';
import { ItemAccordion } from './ItemAccordion';
import { LineItemAccordion } from './LineItemAccordion';
import { ToolLayout } from './ToolLayout';
import {
  getLastLineIdx,
  initialiseForm,
  normaliseValidationResults,
  SKIP_INPUT_SUFFIX,
  transformFormValuesToState,
} from './utils';

function HitLForm({
  canvas,
  disableSubmit,
  document,
  isDocumentTypeValid,
  isLockedByCurrentUser,
  leftColumn,
  list,
  lockDocument,
  onReject,
  onSave,
  onSkip,
  onSubmit,
  resources,
  updateList,
  updateResources,
  validationResults,
}: HitLFormProps) {
  const enableValidationResults = useFeatureFlag('enableHILValidationResults');
  const enableValidationResultsEndpoint = useFeatureFlag(
    'enableHILValidationResultsEndpoint'
  );
  const enableHILValidFieldValidationText = useFeatureFlag(
    'enableHILValidFieldValidationText'
  );
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation(HITL_TOOL_PAGE);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [skippedIds, setSkippedIds] = useState<string[]>([]);
  const validateDocument = useDocumentValidation(document.id);
  const { id } = document;
  /**
   * arrange the items, define a schema and get default values
   */
  const {
    defaultValues,
    schema,
    identifiedItems,
    identifiedLineItems,
    unidentifiedItems,
    unidentifiedLineItems,
  } = useMemo(() => initialiseForm(list, resources), [list, resources]);
  const initialErrors = useMemo(() => {
    if (!enableValidationResults || !validationResults) {
      return undefined;
    }
    return Object.entries(validationResults).reduce((acc, [key, value]) => {
      if (isNotValidItem(resources[key])) {
        return {
          ...acc,
          [key]: { message: unique(value).join('\n'), type: 'custom' },
        };
      }

      return acc;
    }, {});
  }, [enableValidationResults, resources, validationResults]);
  const methods = useForm<HitLFormValues>({
    defaultValues,
    errors: initialErrors,
    resolver: yupResolver(schema),
  });
  const { setError } = methods;
  const { errors, isSubmitting } = useFormState({
    control: methods.control,
  });

  function setErrorsWithValidationResults(
    normalisedValidationResults: Record<string, string[]>
  ) {
    if (enableValidationResults && normalisedValidationResults) {
      Object.keys(normalisedValidationResults).forEach((key) => {
        const item = resources[key];
        const condition = enableHILValidFieldValidationText
          ? item
          : isNotValidItem(item);

        if (condition) {
          setError(key, {
            message: unique(normalisedValidationResults[key]).join('\n'),
            type: 'custom',
          });
        }
      });
    }
  }

  useEffect(
    function reapplyValidationResultsErrorOnSubmit() {
      if (enableValidationResults && errors) {
        setErrorsWithValidationResults(validationResults);
      }
    },
    // eslint-disable-next-line
    [errors]
  );

  /**
   * Something wrong with RHF setFocus() function
   * Need to investigate further
   * But using this as a workaround to scroll to error field
   */
  useEffect(
    function scrollToError() {
      // const firstError = (
      //   Object.keys(errors) as Array<keyof typeof errors>
      // ).reduce<keyof typeof errors | null>((field, a) => {
      //   const fieldKey = field as keyof typeof errors;
      //   return errors[fieldKey] ? fieldKey : a;
      // }, null);

      if (Object.keys(errors).length) {
        const errorElements =
          formRef.current?.getElementsByClassName('Mui-error');

        if (errorElements?.length) {
          const rect = errorElements[0]?.getBoundingClientRect();

          if (rect) {
            window.scrollTo({
              behavior: 'smooth',
              top: rect.top - HEADER.MAIN_DESKTOP_HEIGHT - 85,
            });
          }
        }
      }
    },
    [errors]
  );

  useEffect(
    function bindLockDocumentToInputs() {
      function bindOnBlur(name: string) {
        methods.register(name, { onBlur: () => lockDocument() });
      }

      function bindItems(list: List, onEvent = bindOnBlur) {
        list.forEach((id) => {
          if (id && !Array.isArray(id)) {
            onEvent(id);
          }
        });
      }

      bindItems(list);
    },
    [list] // eslint-disable-line
  );

  function handleValidationError(response: DocumentValidation) {
    if (getIsDocumentValidationStageError(response)) {
      throw new Error(t('form.validationError') as string);
    }
  }

  async function handleFormSubmit(values: HitLFormValues) {
    if (enableValidationResultsEndpoint) {
      if (onSubmit && !disableSubmit) {
        const content = transformFormValuesToState(list, resources)(values);
        let validationToastId = '';

        try {
          validationToastId = toast.loading(t('form.validationLoading'));
          const response = await validateDocument.mutateAsync({ id, content });

          handleValidationError(response);

          if (getIsDocumentValidationStageCompleted(response)) {
            toast.dismiss(validationToastId);
            onSubmit(values);
            return;
          }

          const failedValidations = response.validationResults.filter(
            ({ result }) => result === 'failed'
          );
          toast.error(t('form.reviewValidationErrors'), {
            id: validationToastId,
          });
          const normalisedValidationResults = normaliseValidationResults({
            validationResults: failedValidations,
            list,
            resources,
          });
          setErrorsWithValidationResults(normalisedValidationResults);
          sendAnalyticEvent({
            event: HIL_VALIDATION_FAIL,
            documentId: id,
          });
        } catch (error) {
          toast.error(t('form.validationError'), {
            id: validationToastId,
          });
        }
      }
    } else {
      onSubmit(values);
      return;
    }
  }

  function handleFormSave() {
    if (onSave && !disableSubmit) {
      onSave(methods.getValues());
    }
  }

  function handleReject(reason: string) {
    onReject(reason);
  }

  function toggleSkippedItems(value: boolean) {
    [...unidentifiedItems, ...unidentifiedLineItems].forEach((id) => {
      methods.setValue(`${id}${SKIP_INPUT_SUFFIX}`, value);
    });
  }

  function toggleCheckAll() {
    setIsCheckAll((checkAll) => !checkAll);
    /**
     * This should work as we're only using unidentifiedDocumentItems
     * But it feels quite brittle as I'm assuming all items will have a skip
     * boolean
     */
    setSkippedIds([...unidentifiedItems, ...unidentifiedLineItems]);
    toggleSkippedItems(true);

    if (isCheckAll) {
      setSkippedIds([]);
      toggleSkippedItems(false);
    }
  }

  function updateIsCheckList(id: string, checked: boolean) {
    setSkippedIds([...skippedIds, id]);

    if (!checked) {
      setSkippedIds(skippedIds.filter((item) => item !== id));
      setIsCheckAll(false);
    }
  }

  function addLineItem() {
    const lastLineIdx = getLastLineIdx(list, resources, canvas?.getPage() || 0);
    const lastLineItems = list.filter(
      (id) => resources[id].lineIdx === lastLineIdx
    );

    lastLineItems.forEach((id) => {
      const item = resources[id];
      const newId = generateUuid();
      const value = '';
      const resource = {
        ...item,
        lineIdx: lastLineIdx + 1,
        pageIdx: canvas?.getPage() || 0,
        valid: false,
        value,
      };

      updateList(newId);
      updateResources(newId, resource);

      methods.setValue(newId, value);
    });
  }

  function deleteLineItem(list: List) {
    list.forEach((id) => {
      const item = resources[id];
      updateResources(id, {
        ...item,
        deleted: !item.deleted,
      });
    });
  }

  const hasUnidentifiedItems = !!unidentifiedItems.length;
  const hasUnidentifiedLineItems = !!unidentifiedLineItems.length;
  const hasIdentifiedItems = !!identifiedItems.length;

  if (hasIdentifiedItems || hasUnidentifiedItems || hasUnidentifiedLineItems) {
    const rightColumn = (
      <Stack>
        <FormButtons
          disableSubmit={disableSubmit}
          isDocumentTypeValid={isDocumentTypeValid}
          isSubmitting={isSubmitting}
          isNotLockedByCurrentUser={!isLockedByCurrentUser}
          handleReject={handleReject}
          handleNextDocument={onSkip}
          isCheckAll={isCheckAll}
          toggleCheckAll={toggleCheckAll}
          handleSaveDocument={handleFormSave}
        />
        <ScrollableContainer offset={24}>
          <ItemAccordion
            defaultExpanded={hasUnidentifiedItems}
            heading={t('form.unidentifiedItems')}
            list={unidentifiedItems}
            resources={resources}
            isCheck={skippedIds}
            updateIsCheckList={updateIsCheckList}
          />
          <LineItemAccordion
            defaultExpanded={hasUnidentifiedLineItems}
            heading={t('form.unidentifiedLineItems')}
            list={unidentifiedLineItems}
            resources={resources}
            lockDocument={lockDocument}
            addLineItem={addLineItem}
            deleteLineItem={deleteLineItem}
            isCheck={skippedIds}
            updateIsCheckList={updateIsCheckList}
          />
          <ItemAccordion
            defaultExpanded={!hasUnidentifiedItems && !hasUnidentifiedLineItems}
            heading={t('form.identifiedItems')}
            list={identifiedItems}
            resources={resources}
            isUnidentifiedItems={false}
          />
          <LineItemAccordion
            defaultExpanded={!hasUnidentifiedItems && !hasUnidentifiedLineItems}
            heading={t('form.identifiedLineItems')}
            list={identifiedLineItems}
            resources={resources}
            lockDocument={lockDocument}
            isUnidentifiedItems={false}
            deleteLineItem={deleteLineItem}
          />
        </ScrollableContainer>
      </Stack>
    );

    return (
      <WithTranslationRoot namespace={HITL_TOOL_PAGE}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)} ref={formRef}>
            <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />
          </form>
        </FormProvider>
      </WithTranslationRoot>
    );
  }

  return (
    <Alert severity="error" sx={{ m: 2 }}>
      <TypographyWithTranslation
        i18nKey="form.noItems"
        variant="body1"
        namespace={HITL_TOOL_PAGE}
      />
    </Alert>
  );
}

export { HitLForm };
