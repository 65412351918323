import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Avatar } from 'components/Avatar.tsx';
import { IconButtonAnimate } from 'components/IconButtonAnimate.tsx';
import { useTooltip } from 'components/customHooks/useTooltip.ts';
import {
  useCurrentUserEmail,
  useCurrentUserFirstName,
  useCurrentUserFullName,
} from 'state/queries/current-user.ts';
import { createAvatar } from 'utils/create-avatar.ts';

import { AccountTooltip } from './AccountTooltip.tsx';

interface AccountButtonV2Props {
  mini?: boolean;
}

function AccountButtonV2({ mini }: AccountButtonV2Props) {
  const fullNameQuery = useCurrentUserFullName();
  const firstNameQuery = useCurrentUserFirstName();
  const emailQuery = useCurrentUserEmail();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  const button = (
    <IconButtonAnimate isOpen={isOpen} onClick={handleOpen}>
      {firstNameQuery.isSuccess && emailQuery.isSuccess ? (
        <Avatar
          alt={fullNameQuery.isSuccess ? fullNameQuery.data : ''}
          color={
            emailQuery.data
              ? 'default'
              : createAvatar(firstNameQuery.data).color
          }
          src={''}
        >
          {createAvatar(firstNameQuery.data).name}
        </Avatar>
      ) : null}
    </IconButtonAnimate>
  );

  return (
    <>
      {mini ? (
        button
      ) : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          role="button"
          onClick={handleOpen}
          sx={{
            borderRadius: 24,
            cursor: 'pointer',
            transition: ({ transitions }) =>
              transitions.create('background-color', {
                duration: transitions.duration.shortest,
              }),
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          {button}
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {fullNameQuery.data}
          </Typography>
        </Stack>
      )}
      <AccountTooltip
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        disableArrow
        element={element}
        handleClose={handleClose}
        isOpen={isOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      />
    </>
  );
}

export { AccountButtonV2 };
