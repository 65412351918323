import { useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import {
  ButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useAddComment } from 'state/queries/comments';

import { UserAvatar } from './UserAvatar';
import { CharCounter } from './CharCounter';

interface NewCommentProps {
  entityId: string;
  claimId: string | null;
}

function NewComment({ entityId, claimId }: NewCommentProps) {
  const { t } = useTranslationRoot();
  const composing = useRef(false);
  const schema = object({
    text: string().trim().required().min(1),
  });
  const methods = useForm({
    defaultValues: {
      text: '',
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, control } = methods;
  const { isSubmitting, isValid } = useFormState({
    control,
  });
  const addComment = useAddComment({
    entityId,
    claimId,
    meta: {
      errorMessage: t('comments.failedToAdd'),
      loadingMessage: t('comments.addingComment'),
      successMessage: t('comments.addedComment'),
    },
  });

  function handleFormSubmit({ text }: { text: string }) {
    const payload = {
      entityId,
      claimId,
      content: text,
    };
    addComment.mutate(payload, {
      onSuccess: () => {
        reset();
      },
    });
  }

  return (
    <Stack direction="row" alignItems="center">
      <FormProvider {...methods}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleSubmit(handleFormSubmit)();
          }}
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              !event.shiftKey &&
              event.keyCode !== 229 &&
              !composing.current // fix to detect IME input
            ) {
              event.preventDefault();
              event.stopPropagation();
              handleSubmit(handleFormSubmit)();
            }
          }}
          style={{ width: '100%' }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mb: 1,
            }}
          >
            <UserAvatar />
            <ControllerWithTextField
              name="text"
              placeholder={t('comments.commentPlaceholder') as string}
              variant="outlined"
              multiline
              maxRows={2}
              fullWidth
              required
              disabled={isSubmitting}
              helperText=""
              onCompositionStart={() => {
                composing.current = true;
              }}
              onCompositionEnd={() => {
                composing.current = false;
              }}
            />
          </Box>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              flexGrow: 1,
              pl: 4,
            }}
          >
            {/* <IconButton onClick={() => {}} size="small" aria-label="Paste">
              <SvgIconStyle src={PASTE_ICON} width={18} height={18} />
            </IconButton> */}
            <CharCounter />
            <ButtonWithTranslation
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid}
              i18nKey="comments.submitButton"
              sx={{
                ml: 'auto',
              }}
            />
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
}

export { NewComment };
