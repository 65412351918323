import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  isLineLevelItem,
  isNotLineLevelItem,
} from 'state/selectors/documents.ts';

import type { AllFieldProps } from './types.ts';
import { InputField } from './InputField.tsx';
import { getItems } from './utils/get-items.ts';
import { AllFieldLineItems } from './AllFieldLineItems.tsx';

function AllField({
  getPageRef,
  ids,
  observationId,
  resources,
  title,
}: AllFieldProps) {
  const { documentLevelListIds, lineLevelListIds } = useMemo(() => {
    const findDocumentItemIds = getItems(isNotLineLevelItem);
    const findLineItemIds = getItems(isLineLevelItem);

    return {
      documentLevelListIds: findDocumentItemIds({ list: ids, resources }),
      lineLevelListIds: findLineItemIds({ list: ids, resources }),
    };
  }, [ids, resources]);

  return (
    <Paper elevation={2} sx={{ backgroundColor: 'background.default', p: 2 }}>
      <Typography variant="subtitle1" sx={{ color: 'text.primary', mb: 3 }}>
        {title}
      </Typography>
      {!!documentLevelListIds.length && (
        <Stack spacing={2}>
          {documentLevelListIds.map((id) => {
            const item = resources[id];
            const label = item.clientKey;
            const pageIdx = getPageRef(observationId, item.pageIdx || 0);

            return (
              <InputField
                key={id}
                {...item}
                id={id}
                label={label}
                pageIdx={pageIdx}
              />
            );
          })}
        </Stack>
      )}
      {!!lineLevelListIds.length && (
        <AllFieldLineItems
          getPageRef={getPageRef}
          list={lineLevelListIds}
          observationId={observationId}
          resources={resources}
        />
      )}
    </Paper>
  );
}

export { AllField };
