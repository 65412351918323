import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { DOCUMENT_SEARCH_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CONSISTENCY_CHECKS_PAGE } from 'constants/translation-keys';
import consistencyCheckRoute from 'pages/ConsistencyCheck/consistency-check.route.tsx';

import ConsistencyChecksLayout from './ConsistencyChecksLayout.tsx';

const path = '/cc';
const ConsistencyCheckPage = loadable(
  lazy(() => import('./ConsistencyChecks.tsx'))
);

const consistencyChecksRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: DOCUMENT_SEARCH_ICON,
  element: <ConsistencyChecksLayout />,
  children: [
    {
      element: <ConsistencyCheckPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.consistencyCheck,
    },
    consistencyCheckRoute,
  ],
  permission: ROUTE_PERMISSIONS.consistencyCheck,
  i18nNamespace: CONSISTENCY_CHECKS_PAGE,
};

export default consistencyChecksRoute;
