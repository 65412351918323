import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { LineNumberProps } from './types.ts';

function LineNumber({ deleted, lineIdx, isValid }: LineNumberProps) {
  return (
    <Box
      sx={{
        backgroundColor: isValid ? 'success.dark' : 'grey.300',
        borderRadius: '50%',
        color: isValid ? 'common.white' : 'grey.600',
        display: 'block',
        height: 20,
        position: 'relative',
        textAlign: 'center',
        width: 20,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: 12,
          fontWeight: 'bold',
          lineHeight: '20px',
        }}
      >
        {lineIdx + 1}
      </Typography>
      {deleted && (
        <Box
          sx={{
            backgroundColor: 'error.main',
            height: 4,
            position: 'absolute',
            top: 8,
            left: -2,
            width: 24,
          }}
        />
      )}
    </Box>
  );
}

export { LineNumber };
