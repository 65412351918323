import type { LabelColor } from 'components/Label';

export const COMPLETED = 'COMPLETED';
export const CLOSED = 'CLOSED';
export const ERROR = 'ERROR';
export const AWAITING_REVIEW = 'AWAITING_REVIEW';
export const PENDING = 'PENDING';
export const PROCESSING = 'PROCESSING';
export const REJECTED = 'REJECTED';
export const REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS';

export const ALL_STAGES = [
  AWAITING_REVIEW,
  REVIEW_IN_PROGRESS,
  PROCESSING,
  COMPLETED,
  ERROR,
  REJECTED,
  CLOSED,
];

export const STAGE_TRANSLATION_MAP: { [k: string]: string } = {
  [COMPLETED]: 'stage.completed',
  [CLOSED]: 'stage.closed',
  [AWAITING_REVIEW]: 'stage.awaitingReview',
  [PENDING]: 'stage.pending',
  [PROCESSING]: 'stage.processing',
  [REJECTED]: 'stage.rejected',
  [ERROR]: 'stage.error',
  [REVIEW_IN_PROGRESS]: 'stage.reviewInProgress',
};

export const STAGE_LABEL_COLOR_MAP: { [key: string]: LabelColor } = {
  [AWAITING_REVIEW]: 'info',
  [REVIEW_IN_PROGRESS]: 'warning',
  [CLOSED]: 'default',
  [COMPLETED]: 'success',
  [ERROR]: 'error',
  [PENDING]: 'default',
  [PROCESSING]: 'default',
  [REJECTED]: 'error',
};
