import type { ReactNode } from 'react';
import type { ThemeOptions } from '@mui/material/styles';
import { useMemo } from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSettings } from 'components/customHooks/useSettings';

import breakpoints from './breakpoints';
import componentsOverrides from './overrides';
import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

interface ThemeProviderProps {
  children: ReactNode;
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      direction: 'ltr',
      palette: isLight ? palette.light : palette.dark,
      shadows: isLight ? shadows.light : shadows.dark,
      shape: { borderRadius: 8 },
      typography,
    }),
    [isLight]
  );
  const theme = createTheme(themeOptions, { zIndex: { fullscreen: 1600 } });
  theme.components = componentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            '@keyframes mui-auto-fill': { from: { display: 'block' } },
            '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
          }}
        />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
