import type { ContentWithId, FieldsListIds, Resources } from '../types.ts';

function isLineItem(item: ContentWithId) {
  return item.level !== 'document' && typeof item.lineIdx === 'number';
}

type GetRelatedItems = {
  listIds: string[];
  resources: Resources;
  condition: (listItem: ContentWithId, currentItem: ContentWithId) => boolean;
};

type GetRelatedField = {
  listsIds: FieldsListIds;
  resources: Resources;
  sproutaiKey: string;
};

function getRelatedItems({ listIds, resources, condition }: GetRelatedItems) {
  return (triggerItem: ContentWithId) =>
    listIds.find((fieldId) => {
      const listItem = resources[fieldId];
      return condition(listItem, triggerItem);
    });
}

function matchSproutAiKey(resources: Resources, sproutaiKey: string) {
  return (id: string) => resources[id].sproutaiKey === sproutaiKey;
}

export function getRelatedField({
  listsIds,
  resources,
  sproutaiKey,
}: GetRelatedField) {
  for (const observationId in listsIds) {
    const ids = listsIds[observationId];
    const foundId = ids.find(matchSproutAiKey(resources, sproutaiKey));

    if (foundId) {
      return foundId;
    }
  }

  return null;
}

type GetRelatedFieldId = {
  currentObservationListIds: string[];
  resources: Resources;
  triggerItem: ContentWithId;
  allObservationListIds: FieldsListIds;
};

export function getRelatedFieldId({
  currentObservationListIds,
  resources,
  triggerItem,
  allObservationListIds,
}: GetRelatedFieldId) {
  return (sproutaiKey: string) => {
    const { level, lineIdx } = triggerItem;

    // Sorry, I couldn't think of a better way of writing this
    // search for line items first and within the same observation
    let result = getRelatedItems({
      listIds: currentObservationListIds,
      resources,
      condition: (listItem, currentItem) => {
        if (isLineItem(currentItem)) {
          return (
            listItem.level === level &&
            listItem.lineIdx === lineIdx &&
            listItem.sproutaiKey === sproutaiKey
          );
        }

        return listItem.sproutaiKey === sproutaiKey;
      },
    })(triggerItem);

    const findRelatedDocumentItems = (listIds: string[]) =>
      getRelatedItems({
        listIds,
        resources,
        condition: (listItem) => listItem.sproutaiKey === sproutaiKey,
      });

    // if not found, search for document item in the same observation
    if (!result) {
      result = findRelatedDocumentItems(currentObservationListIds)(triggerItem);
    }

    // if still not found, search in other observations
    if (!result) {
      for (const observationId in allObservationListIds) {
        const ids = allObservationListIds[observationId];

        result = findRelatedDocumentItems(ids)(triggerItem);

        if (result) {
          // stop loop
          break;
        }
      }
    }

    return result;
  };
}
