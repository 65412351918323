import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { useTranslationRoot } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { TRASH_ICON } from 'constants/public-icons';

import type { DeleteFieldProps } from './types';

function DeleteField({ active, disabled, onDelete }: DeleteFieldProps) {
  const { t } = useTranslationRoot();

  return (
    <Tooltip title={t('form.deleteField')} placement="top" arrow>
      <Paper
        square
        variant="outlined"
        sx={{
          alignItems: 'center',
          background: ({ palette }) => palette.background.neutral,
          borderColor: 'grey.50012',
          borderRadius: '50%',
          display: 'flex',
          height: 36,
          justifyContent: 'center',
          width: 36,
        }}
      >
        <IconButton
          aria-label={t('form.deleteField') as string}
          disabled={disabled}
          onClick={onDelete}
          tabIndex={-1}
        >
          <SvgIconStyle
            color={active ? 'error.main' : 'grey.500'}
            height={20}
            width={20}
            src={TRASH_ICON}
          />
        </IconButton>
      </Paper>
    </Tooltip>
  );
}

export { DeleteField };
