import type { SxProps, Theme } from '@mui/material/styles';
import type { MouseEvent, ReactNode } from 'react';
import { alpha } from '@mui/material/styles';
import type { Claim } from 'types/Claims';
import type { BaseFilter } from 'types/Filters';
import type { Order } from 'constants/sort';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { generalConfig } from 'config';
import {
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import { Page } from 'components/Page';
import { CLAIMS_PAGE } from 'constants/translation-keys';
import {
  FILTERS,
  PAGE,
  QUERY,
  PAGE_ID,
  ROWS_PER_PAGE,
  SEARCH_FIELD,
  SORT,
  STAGE,
} from 'constants/route-keys';
import { useGetClaims } from 'state/queries/claims';
import {
  useIsClaimAdminRole,
  useIsSuperAdminRole,
} from 'state/queries/current-user';
import { LinkWithRef } from 'components/Link';
import { Label } from 'components/Label';
import {
  ALL_STAGES,
  AWAITING_ENRICHMENT,
  AWAITING_OCR_RESULTS,
  AWAITING_REVIEW,
  STAGE_LABEL_COLOR_MAP,
  statusTranslation,
} from 'constants/claims';
import claimRoute from 'pages/Claim/claim.route';
import {
  getClaimsTotal,
  getIsClaimAwaitingReview,
  getIsClaimClosed,
  selectClaimsMetadataSortedColumns,
  selectClaimsPageData,
} from 'state/selectors/claims';
import { formatDocumentDate, isValidISODateString } from 'utils/date';
import { AdvancedFiltering } from 'components/table/AdvancedFiltering';
import { AdvancedFilterChips } from 'components/table/AdvancedFilterChips';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag';
import { useGetFiltersClaims } from 'state/queries/filters';
import { CLAIM_TABLE } from 'constants/table-pagination';
import { useRestoreFilters } from 'components/customHooks/useRestoreFilters.ts';
import {
  convertSnakeCaseToCamelCase,
  toCamelCase,
  toTitleCase,
} from 'utils/string';
import { NoItems } from 'components/table/NoItems.tsx';
import { useStageFilter } from 'components/table/useStageFilter.ts';
import { Category } from 'components/table/Category.tsx';
import { StageOptions } from 'components/table/StageOptions.tsx';

import { TableTooltip } from './TableTooltip';
import { ClaimV2Table } from './ClaimV2Table';

interface Column {
  id: string;
  i18nKey: string;
  width?: number;
  align?: 'right';
  format?: (value: number) => string;
  renderCell?: (params: any) => ReactNode;
  sortable?: boolean;
  valueFormatter?: (value: any) => string;
  columnSx?: SxProps<Theme>;
  rowSx?: SxProps<Theme>;
  custom?: boolean;
}

function ClaimsV2() {
  // hooks
  const { i18n } = useTranslationRoot(CLAIMS_PAGE);
  const navigate = useNavigate();
  useRestoreFilters(CLAIM_TABLE);

  // feature flags
  const enableClaimsAdvancedFiltering = useFeatureFlag(
    'enableClaimsAdvancedFiltering'
  );
  const enableCloseClaimButton = useFeatureFlag('enableCloseClaimButton');
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');
  const isClaimsEnrichmentAvailable = useFeatureFlag('enableClaimsEnrichment');

  // stage filters
  const stages = ALL_STAGES.filter((stage) => {
    if (isClaimsEnrichmentAvailable) {
      return true;
    }

    return ![
      AWAITING_ENRICHMENT,
      AWAITING_OCR_RESULTS,
      AWAITING_REVIEW,
    ].includes(stage);
  });
  const stageFilters = useStageFilter({
    namespace: CLAIMS_PAGE,
    stages: stages,
  });

  const columnHeading = (column: string) => {
    return i18n.exists(`tableSorted.${toCamelCase(column)}`, {
      ns: CLAIMS_PAGE,
    })
      ? `tableSorted.${toCamelCase(column)}`
      : toTitleCase(column);
  };

  const getCanCloseClaim = (claim: Claim) => !getIsClaimClosed(claim);

  const genAdvancedTableColumns =
    (
      linkHandler: (e: MouseEvent<any>) => void,
      {
        isSuperAdmin,
        isClaimAdmin,
      }: { isSuperAdmin: boolean; isClaimAdmin: boolean }
    ) =>
    (sortedColumns: string[] = []): Column[] => [
      ...sortedColumns.map((id) => {
        const columnObject: Column = {
          id: convertSnakeCaseToCamelCase(id),
          i18nKey: columnHeading(id),
          custom: true,
        };

        switch (columnObject.id) {
          case 'claimId':
            columnObject.renderCell = ({ row }) => {
              const { id } = row;
              const to =
                getIsClaimAwaitingReview(row) &&
                !generalConfig.disableClaimReviewScreen
                  ? `${claimRoute.createPath(id)}?${PAGE_ID}=review`
                  : claimRoute.createPath(id);

              return (
                <Link
                  data-testid="claim-id-link"
                  component={LinkWithRef}
                  to={to}
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    position: 'sticky',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={linkHandler}
                >
                  {id.substring(row.id.length - 6)}
                </Link>
              );
            };
            columnObject.columnSx = {
              borderRight: ({ palette }: Theme) =>
                `3px solid ${palette.background.default}`,
              left: 0,
              position: 'sticky',
              zIndex: (theme: Theme) => theme.zIndex.appBar + 3,
            };
            columnObject.rowSx = {
              backgroundColor: 'background.paper',
              borderRight: ({ palette }) => `3px solid ${palette.divider}`,
              fontWeight: 'bold',
              left: 0,
              position: 'sticky',
              zIndex: ({ zIndex }) => zIndex.appBar + 2,
            };
            break;
          case 'clientClaimId':
            columnObject.width = 300;
            break;
          case 'stage':
            columnObject.renderCell = ({ row }) => (
              <Label
                i18nKey={
                  row.locked ? 'stage.locked' : statusTranslation[row.stage]
                }
                variant="ghost"
                color={
                  row.locked ? 'warning' : STAGE_LABEL_COLOR_MAP[row.stage]
                }
              />
            );
            break;
          case 'sentOn':
            columnObject.valueFormatter = ({ value }) => {
              return isValidISODateString(value)
                ? formatDocumentDate(value)
                : value;
            };
            break;
          case 'claimDate':
            columnObject.valueFormatter = ({ value }) => {
              return isValidISODateString(value)
                ? formatDocumentDate(value)
                : value;
            };
            break;
          case 'lastUpdatedOn':
            columnObject.sortable = true;
            columnObject.valueFormatter = ({ value }) => {
              return isValidISODateString(value)
                ? formatDocumentDate(value)
                : value;
            };
            break;
          default:
            break;
        }

        return columnObject;
      }),
      {
        id: 'actions',
        i18nKey: '',
        align: 'right',
        renderCell: ({ row }) => {
          const hasCloseClaim = getCanCloseClaim(row);

          if (
            isSuperAdmin ||
            (enableCloseClaimButton && isClaimAdmin && hasCloseClaim)
          ) {
            return (
              <Box
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <TableTooltip
                  id={row.id}
                  isSuperAdmin={hasSuperAdminPerm}
                  isClaimAdmin={hasClaimAdminPerm}
                  hasCloseClaim={hasCloseClaim}
                />
              </Box>
            );
          }

          return null;
        },
        rowSx: {
          width: '100%',
        },
      },
    ];

  // search params
  const [searchParams] = useSearchParams();
  const stageParam = searchParams.get(STAGE);
  const query = searchParams.get(QUERY);
  const searchFieldParam = searchParams.get(SEARCH_FIELD);
  const filtersParam = searchParams.get(FILTERS);
  const initialPage = searchParams.get(PAGE) ?? '0';
  const initialRowsPerPage = searchParams.get(ROWS_PER_PAGE) ?? '10';
  const initialSort = searchParams.get(SORT) as Order;

  const navigateToClaim = (row: Claim) => {
    const to = getIsClaimAwaitingReview(row)
      ? `${claimRoute.createPath(row.id)}?${PAGE_ID}=review`
      : claimRoute.createPath(row.id);
    navigate(to);
  };

  const pagination = useAdvancedTablePagination({
    initialFilters: filtersParam ? filtersParam : '',
    initialPage,
    initialRowsPerPage,
    initialSort,
  });

  const {
    page,
    rowsPerPage,
    sort,
    updateFiltersAndUrl,
    updateStageFiltersAndUrl,
  } = pagination;

  const filtersQuery = useGetFiltersClaims(stageParam) as {
    data: BaseFilter[];
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
  };

  const claimsQuery = useGetClaims({
    filters: filtersParam,
    order: sort,
    searchValue: query,
    searchField: searchFieldParam,
    start: page,
    stage: stageParam,
    size: rowsPerPage,
  });
  const isSuperAdminQuery = useIsSuperAdminRole();
  const hasSuperAdminPerm =
    (isSuperAdminQuery.isSuccess && isSuperAdminQuery.data) || false;
  const isClaimAdminQuery = useIsClaimAdminRole();
  const hasClaimAdminPerm =
    (isClaimAdminQuery.isSuccess && isClaimAdminQuery.data) || false;

  const linkHandler = (e: MouseEvent<any>) => {
    e.stopPropagation();
    claimsQuery.refetch();
  };
  const sortedColumns = claimsQuery.isSuccess
    ? selectClaimsMetadataSortedColumns(claimsQuery.data)
    : [];

  const advancedFilteringMarkup = (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: 2,
        flex: '1 1 auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {enableClaimsAdvancedFiltering ? (
        <AdvancedFiltering
          filters={filtersParam}
          updateFilters={updateFiltersAndUrl}
          filtersQuery={filtersQuery}
          updateStageFilters={updateStageFiltersAndUrl}
          stageFilters={
            enableNewSiteLayout
              ? {
                  ...stageFilters,
                  markup: (
                    <Box
                      sx={{
                        backgroundColor: ({ palette }) =>
                          alpha(palette.primary.main, 0.08),
                        borderRadius: 1,
                        py: 2,
                        mb: 2,
                      }}
                    >
                      <Category name={STAGE}>
                        <StageOptions
                          name={STAGE}
                          options={stageFilters.options}
                        />
                      </Category>
                    </Box>
                  ),
                }
              : null
          }
        />
      ) : null}
      <AdvancedFilterChips
        filters={filtersParam}
        filtersQueryData={filtersQuery.data || []}
        setFilters={updateFiltersAndUrl}
      />
    </Stack>
  );

  const advancedColumns = genAdvancedTableColumns(linkHandler, {
    isSuperAdmin: hasSuperAdminPerm,
    isClaimAdmin: hasClaimAdminPerm,
  })(sortedColumns);

  return (
    <WithTranslationRoot namespace={CLAIMS_PAGE}>
      <Page
        i18nKeyTitle="meta.title"
        sx={{
          py: enableNewSiteLayout ? 3 : 0,
        }}
      >
        <Card>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <ClaimV2Table
              columns={advancedColumns}
              rows={
                claimsQuery.data ? selectClaimsPageData(claimsQuery.data) : []
              }
              rowIdentifier="id"
              isPending={claimsQuery.isPending}
              totalCount={
                claimsQuery.data ? getClaimsTotal(claimsQuery.data) : 0
              }
              noItemsComponent={
                <NoItems
                  columnLength={advancedColumns.length}
                  noItemsLabeli18nKey={
                    query ? 'table.noClaimsFoundWith' : 'table.noClaimsFound'
                  }
                  options={{ searchValue: query }}
                />
              }
              noItemsLabeli18nKey="table.noClaimsFound"
              handleRowClick={navigateToClaim}
              pagination={pagination}
              toolbarStartAdornment={advancedFilteringMarkup}
              isError={claimsQuery.isError}
              errorMessage="table.error"
              retryButton="table.retry"
              refetch={claimsQuery.refetch}
            />
          </Paper>
        </Card>
      </Page>
    </WithTranslationRoot>
  );
}

export default ClaimsV2;
