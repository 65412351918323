import type { HTMLAttributes, MouseEvent, ReactNode } from 'react';
import type { Claim, ClaimObservation } from 'types/Claims.ts';
import type { DocumentContent } from 'types/Documents.ts';
import type { CurrentUser } from 'types/CurrentUser.ts';
import type { BoundingBox } from 'components/CanvasToolV2/types.ts';

export type ListIds = string[];
export type FormValues = { [key: string]: string };
export type ContentWithId = DocumentContent & { id: string };
export type FieldsListIds = Record<string, ListIds>;
export type ConsistencyListIds = [string, 'passed' | 'failed', string[]][];
export type Resources = Record<string, ContentWithId>;
export type Observations = Record<string, ClaimObservation>;
export type Errors = Record<string, { message: string[]; type: string }>;
export type Thumbnail = {
  observationId: string;
  title: string;
  url: string;
};
export type GetPageRef = (observationId: string, page: number) => number;
export type CodeMapping = {
  autopopulate: Record<string, string>;
  documentGroups: string[];
  mappingType: string;
  otherFields: Record<string, string>;
  searchKey: string;
  triggerField: string;
  dropdown: string[];
};
export type CodeMappingsDict = Record<string, CodeMapping>;

export interface InternalState {
  claim: Claim;
  documents: Record<string, Record<string, string>>;
  fieldsListIds: FieldsListIds;
  observations: Observations;
  resources: Resources;
}

export interface BaseProps extends InternalState {
  allFieldsErrors: Errors;
  consistencyListIds: ConsistencyListIds;
  consistencyResultsErrors: Errors;
}

export interface ConsistencyCheckToolProps {
  claim: Claim;
  codeMappings?: CodeMapping[];
  currentUser: CurrentUser;
  observations: ClaimObservation[];
  onSuccess: (action: 'submit' | 'save', claimId: string) => void;
  onFailure?: (error: Error) => void;
}

export interface FormProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  defaultValues: FormValues;
  isLockedByCurrentUser: boolean;
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
  thumbnails: Thumbnail[];
  urls: string[];
}

export interface FormContentProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  isLockedByCurrentUser: boolean;
  isSubmitting: boolean;
  thumbnails: Thumbnail[];
  urls: string[];
}

export interface ActionsProps {
  claimId: string;
  isLockedByCurrentUser: boolean;
  isSubmitting: boolean;
}

export interface SubmitButtonProps {
  disabled: boolean;
  loading: boolean;
}

export interface SaveButtonProps {
  disabled: boolean;
  loading: boolean;
}

export enum FilterType {
  ALL = 'all',
  CONSISTENCY = 'consistency',
}

export interface FilteringProps {
  value: FilterType;
  onChange: (event: MouseEvent<HTMLElement>, value: FilterType) => void;
}

export interface FieldsProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  getPageRef: GetPageRef;
}

export interface AllFieldsProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  filter: FilterType;
  getPageRef: GetPageRef;
}

export interface AllFieldProps {
  getPageRef: GetPageRef;
  ids: string[];
  observationId: string;
  resources: Resources;
  title: string;
}

export interface ConsistencyResultsProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  getPageRef: GetPageRef;
}

export interface ConsistencyResultProps extends BaseProps {
  codeMappings: CodeMappingsDict;
  getPageRef: GetPageRef;
}

export interface ConsistencyResultWithErrorHandlingProps {
  codeMappings: CodeMappingsDict;
  ids: string[];
  title: string;
  fieldsListIds: FieldsListIds;
  getPageRef: GetPageRef;
  observations: Observations;
  resources: Resources;
  result: 'passed' | 'failed';
}

export interface ConsistencyResultItemProps {
  codeMappings: CodeMappingsDict;
  fieldsListIds: FieldsListIds;
  getPageRef: GetPageRef;
  id: string;
  observations: Observations;
  resources: Resources;
}

export interface InputFieldProps {
  boundingBox?: BoundingBox;
  endAdornment?: ReactNode;
  fieldType: string;
  id: string;
  label: string;
  pageIdx: number;
  startAdornment?: ReactNode;
  valid: boolean | null;
}

export interface InputDropDownProps {
  boundingBox?: BoundingBox;
  codeMapping: CodeMapping;
  currentObservationListIds: string[];
  fieldsListIds: FieldsListIds;
  id: string;
  label: string;
  pageIdx: number;
  resources: Resources;
  valid: boolean | null;
}

export interface ControlledAccordionProps {
  children: ReactNode;
  defaultExpanded?: boolean;
  title: string;
}

export interface AllFieldLineItemsProps {
  getPageRef: GetPageRef;
  list: ListIds;
  observationId: string;
  resources: Resources;
}

export interface InputDropDownOptionProps {
  option: Record<string, string>;
  optionRenderValues: string[];
  optionProps: HTMLAttributes<HTMLLIElement>;
}
