import type { ClaimObservation } from 'types/Claims.ts';
import {
  convertArrayToListAndDict,
  convertArrayToObject,
} from 'utils/array.ts';
import { generateUuid } from 'utils/generate-uuid.ts';

function getContent(observation: ClaimObservation) {
  return observation.content;
}

function getFields(observation: ClaimObservation) {
  return getContent(observation).fields;
}

function getUrls(observation: ClaimObservation) {
  return getContent(observation).urls;
}

function getObservationType(observation: ClaimObservation) {
  return observation.type;
}

function isObservationTypeDocument(observation: ClaimObservation) {
  return getObservationType(observation) === 'DOCUMENT';
}

function normaliseFields(observations: ClaimObservation[]) {
  return observations.reduce(
    (acc, observation) => {
      if (!isObservationTypeDocument(observation)) {
        return acc;
      }

      const fields = getFields(observation);
      const fieldsWithIds = fields.map((field) => ({
        ...field,
        id: generateUuid(),
      }));
      const [list, resources] = convertArrayToListAndDict(fieldsWithIds, 'id');

      return {
        ...acc,
        fieldsListIds: { ...acc.fieldsListIds, [observation.id]: list },
        resources: {
          ...acc.resources,
          ...resources,
        },
      };
    },
    { fieldsListIds: {}, resources: {} }
  );
}

function normaliseUrls(observations: ClaimObservation[]) {
  return observations.reduce((acc, observation) => {
    if (!isObservationTypeDocument(observation)) {
      return acc;
    }

    const urls = getUrls(observation);

    return {
      ...acc,
      [observation.id]: urls,
    };
  }, {});
}

export function initialiseState(observations: ClaimObservation[]) {
  const { fieldsListIds, resources } = normaliseFields(observations);
  const documents = normaliseUrls(observations);
  const observationsMap = convertArrayToObject(observations, 'id');

  return {
    fieldsListIds,
    resources,
    observations: observationsMap,
    documents,
  };
}
