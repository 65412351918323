import type { MouseEvent } from 'react';
import { useState } from 'react';

import { FilterType } from './types.ts';

function useFiltering(defaultValue = FilterType.CONSISTENCY) {
  const [filter, setFilter] = useState(defaultValue);

  const handleChange = (_: MouseEvent<HTMLElement>, value: FilterType) => {
    if (value !== null) {
      setFilter(value);
    }
  };

  return { filter, handleChange };
}

export { useFiltering, FilterType };
