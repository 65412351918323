import Stack from '@mui/material/Stack';

import type { ItemFiltersToolbarProps } from './types.ts';
import { FilterFields } from './FilterFields.tsx';
import { SkipCheckAll } from './SkipCheckAll.tsx';

function ItemFiltersToolbar({
  filterItemsBy,
  setFilterItemsBy,
  setAllSkipped,
}: ItemFiltersToolbarProps) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2 }}
    >
      <FilterFields handleChange={setFilterItemsBy} value={filterItemsBy} />
      <SkipCheckAll setAllSkipped={setAllSkipped} />
    </Stack>
  );
}

export { ItemFiltersToolbar };
