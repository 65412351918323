import { useGetNextClaim } from 'state/queries/claims';
import {
  FILTERS,
  // ORDER,
  QUERY,
  SEARCH_FIELD,
  SORT,
} from 'constants/route-keys';
import { CLAIM_TABLE } from 'constants/table-pagination';

export function useNextClaimParams(localStorageKey = CLAIM_TABLE) {
  const claimsSearchParams = window.localStorage.getItem(localStorageKey);
  const nextClaimSearchParams = new URLSearchParams(claimsSearchParams || '');

  return useGetNextClaim({
    // order: nextClaimSearchParams.get(ORDER),
    searchField: nextClaimSearchParams.get(SEARCH_FIELD),
    searchValue: nextClaimSearchParams.get(QUERY),
    order: nextClaimSearchParams.get(SORT),
    filters: nextClaimSearchParams.get(FILTERS),
  });
}
