import { type Ref, useImperativeHandle } from 'react';
import type { FabricImage } from 'fabric';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { COMMON } from 'constants/translation-keys.ts';
import { wrap } from 'utils/math.ts';

import type {
  CanvasToolImperativeHandle,
  CanvasToolProps,
  CanvasWithPage,
} from './types.ts';
import { Canvas } from './Canvas.tsx';
import { Controls } from './Controls.tsx';
import { Navigation } from './Navigation.tsx';
import {
  addErrorText,
  adjustCanvasDimensions,
  calculateScale,
  createImageObject,
  findImage,
  getImageList,
  initApp,
  loadNewImage,
  updateBoundObjects,
} from './canvas-utils.ts';
import { getControls } from './getControls.ts';
import { useKeyboardShortcuts } from './useKeyboardShortcuts.ts';
import { useResizeCanvas } from './useResizeCanvas.ts';
import { useMouseEvents } from './useMouseEvents.ts';
import { usePage } from './usePage.ts';
import { Thumbnails } from './Thumbnails.tsx';
import { useCropListener } from './useCropListener.ts';
import { usePageListener } from './usePageListener.ts';

const CanvasToolV2 = forwardRef(function CanvasToolV2(
  {
    bottomOffset,
    canvasProps,
    headerProps,
    hasThumbnails,
    hasPageCounter = true,
    imageLoadFinishEvent,
    loadPDFFn,
    order,
    onImageLoadError,
    thumbnails,
    urls,
    useCtrlZoom,
  }: CanvasToolProps,
  ref: Ref<CanvasToolImperativeHandle>
) {
  const { t } = useTranslationRoot(COMMON);
  const [hasLoaded, setHasLoaded] = useState(false);

  const canvasRef = useRef<CanvasWithPage>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const urlList = getImageList(urls);
  const totalPage = urlList.length;
  const { page, nextPage, previousPage, updatePage } = usePage({
    initialPage: 0,
    totalPage,
  });

  const getCanvas = () => canvasRef.current as CanvasWithPage;
  const getContainer = () => containerRef.current as HTMLDivElement;

  const onLoad = useCallback(async (canvas: CanvasWithPage) => {
    if (containerRef.current) {
      void initApp({
        bottomOffset,
        canvas,
        container: containerRef.current,
        errorFn: (error: Error) =>
          addErrorText({
            canvas,
            content: t('failedToLoadImage'),
            error,
            onImageLoadError,
          }),
        imageLoadFinishEvent,
        loadPDFFn,
        page: 0,
        urlList,
      });
    }

    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSlide = (newPage: number, prevPage?: number) => {
    const canvas = getCanvas();

    if (typeof prevPage === 'number' && prevPage === newPage) {
      const img = canvas.getObjects().find(findImage) as FabricImage;
      return Promise.resolve(img);
    }

    return loadNewImage({
      bottomOffset,
      canvas,
      container: getContainer(),
      errorFn: (error: Error) =>
        addErrorText({
          canvas,
          content: t('failedToLoadImage'),
          error,
          onImageLoadError,
        }),
      oImg: createImageObject({ loadPDFFn, page: newPage, urlList }),
      page: newPage,
    });
  };

  const nextSlide = () => {
    const canvas = getCanvas();
    const newPage = wrap(0, totalPage, canvas.page + 1);

    onSlide(newPage, page).then(() => {
      nextPage();
    });
  };

  const previousSlide = () => {
    const canvas = getCanvas();
    const newPage = wrap(0, totalPage, canvas.page - 1);

    onSlide(newPage, page).then(() => {
      previousPage();
    });
  };

  useKeyboardShortcuts({
    hasLoaded,
    nextPage: nextSlide,
    previousPage: previousSlide,
  });

  useResizeCanvas({
    callbackFn: (containerRect) => {
      const canvas = getCanvas();

      if (canvas) {
        const img = canvas.getObjects().find(findImage) as FabricImage;

        if (img) {
          const scale = calculateScale({
            bottomOffset,
            canvas,
            containerTop: containerRect.top,
            containerWidth: containerRect.width,
            img,
          });
          adjustCanvasDimensions({
            bottomOffset,
            canvas,
            containerTop: containerRect.top,
            containerWidth: containerRect.width,
            img,
            scale,
          });
          img.scale(scale);
          canvas.centerObject(img);
          updateBoundObjects(canvas);
        }
      }
    },
    canvas: getCanvas(),
    element: getContainer(),
    hasLoaded,
  });

  useMouseEvents({ getCanvas, hasLoaded, useCtrlZoom });

  usePageListener({ onSlide, updatePage });

  useCropListener({ canvas: getCanvas(), onSlide, updatePage });

  useImperativeHandle(
    ref,
    () => ({
      getPage() {
        const canvas = getCanvas();
        return canvas.page;
      },
    }),
    []
  );

  const canvasComponent = (
    <Box
      sx={{
        flex: '1 1 calc(100% - 136px)',
        order: order?.canvas,
        overflow: 'hidden',
        ...canvasProps?.sx,
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          bgcolor: ({ palette }) => alpha(palette.background.neutral, 0.4),
          overflow: 'hidden',
          opacity: 0,
          width: 1,
        }}
      >
        <Canvas onLoad={onLoad} ref={canvasRef} />
      </Box>
    </Box>
  );
  const markup = hasThumbnails ? (
    <Stack
      direction="row"
      sx={{
        width: 1,
        height: 1,
      }}
    >
      {canvasComponent}

      <Box
        sx={{
          height: 1,
          flex: '0 0 136px',
          order: order?.thumbnails,
          overflow: 'hidden',
        }}
      >
        <Thumbnails
          thumbnails={thumbnails}
          activePage={page}
          position={order?.thumbnails === 1 ? 'right' : 'left'}
        />
      </Box>
    </Stack>
  ) : (
    <Box sx={{ height: 1, width: 1 }}>{canvasComponent}</Box>
  );

  return (
    <Stack sx={{ flex: 1, overflow: 'hidden' }}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          backgroundColor: ({ palette }) => palette.background.neutral,
          height: 40,
          justifyContent: 'space-between',
          ...headerProps?.sx,
        }}
      >
        <Controls {...getControls(getCanvas)} hasLoaded={hasLoaded} />

        <Navigation
          nextSlide={nextSlide}
          noOfImages={totalPage}
          page={page}
          previousSlide={previousSlide}
          hasPageCounter={hasPageCounter}
        />
      </Stack>

      {markup}
    </Stack>
  );
});

export { CanvasToolV2 };
