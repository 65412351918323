import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Page } from 'components/Page';
import { WithTranslationRoot } from 'components/with-translation';
import { USER_MANAGEMENT_PAGE } from 'constants/translation-keys';
import { usePages } from 'components/customHooks/usePages';
import userManagementCreateUserRoute from 'pages/UserManagementCreateUser/userManagementCreateUser.route';

import userManagementRoute from './userManagement.route';

export const MANAGE_USERS_TAB = 'manageUsers';
export const ADD_USER_TAB = 'addUser';

function UserManagement() {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleChangePage } = usePages();
  const [tab, setTab] = useState(
    location.pathname === userManagementCreateUserRoute.createPath()
      ? ADD_USER_TAB
      : MANAGE_USERS_TAB
  );

  const onChangePage = (event: SyntheticEvent, page: string) => {
    handleChangePage(event, page);
    setTab(page);

    const path =
      page === MANAGE_USERS_TAB
        ? userManagementRoute.createPath()
        : userManagementCreateUserRoute.createPath();

    navigate(path);
  };

  return (
    <WithTranslationRoot namespace={USER_MANAGEMENT_PAGE}>
      <Page i18nKeyTitle="userManagement.title">
        <Container maxWidth="xl">
          <TabContext value={tab}>
            <TabList
              onChange={onChangePage}
              aria-label="Document Upload Tabs"
              sx={{
                marginBottom: 4,
              }}
            >
              <Tab
                key="manageUsersTab"
                value={MANAGE_USERS_TAB}
                label="Manage Users"
              />
              <Tab key="addUserTab" value={ADD_USER_TAB} label="Add User" />
            </TabList>
            <Outlet />
          </TabContext>
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default UserManagement;
