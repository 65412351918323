import type { Thumbnail } from '../types.ts';

export function getPageNumberRefs(thumbnails: Thumbnail[]) {
  const { result } = thumbnails.reduce(
    (acc, item, index) => {
      const { observationId } = item;

      if (!acc.counts[observationId]) {
        acc.counts[observationId] = 0;
      }

      const key = `${observationId}-${acc.counts[observationId]}`;
      acc.result[key] = index;
      acc.counts[observationId]++;

      return acc;
    },
    { result: {}, counts: {} }
  );

  return result;
}
