import { useMemo } from 'react';
import { ADMIN, SUPER_ADMIN } from 'constants/roles';
import { useIsSuperAdminRole } from 'state/queries/current-user';
import { useGetUserManagementRoles } from 'state/queries/user-management';

function useRoles() {
  const { data: hasSuperAdminPermission, isSuccess } = useIsSuperAdminRole();
  const { data: roles, isSuccess: isRolesSuccess } =
    useGetUserManagementRoles();

  const items = useMemo(() => {
    if (!isRolesSuccess) return [];

    const transformedRoles = roles.map((role) => ({
      label: `roles.${role.toLocaleLowerCase()}`,
      value: role,
    }));

    if (isSuccess && !hasSuperAdminPermission) {
      return transformedRoles.filter(
        (role) => role.value !== SUPER_ADMIN && role.value !== ADMIN
      );
    }

    return transformedRoles;
  }, [hasSuperAdminPermission, isSuccess, roles, isRolesSuccess]);

  return items;
}

export { useRoles };
