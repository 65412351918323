import { enUS } from '@mui/material/locale';
import { ptBR } from '@mui/material/locale';
import en_US from 'rsuite/esm/locales/en_US';
import pt_BR from 'rsuite/esm/locales/pt_BR';

const languagesMetlifeBr = [
  {
    colors: ['#c9072a', '#001b69'],
    label: 'English',
    rsuiteLocale: en_US,
    systemValue: enUS,
    value: 'en',
  },
  {
    colors: ['#00953e', '#ffcc00', '#2c2182'],
    label: 'Português',
    rsuiteLocale: pt_BR,
    systemValue: ptBR,
    value: 'pt',
  },
];

export default languagesMetlifeBr;
