import { useTheme } from '@mui/material/styles';
import Alert, { alertClasses } from '@mui/material/Alert';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { ControllerWithTextFieldV2 } from 'components/form/ControllerWithTextFieldV2.tsx';
import { ControllerWithSingleCheckbox } from 'components/form/ControllerWithSingleCheckbox.tsx';
import { ControllerWithRadioGroup } from 'components/form/ControllerWithRadioGroup.tsx';
import { CHECKBOX, RADIO } from 'constants/field-type.ts';

import type { InputFieldProps } from './types.ts';

function InputField({
  boundingBox,
  endAdornment,
  fieldType,
  pageIdx,
  id,
  label,
  startAdornment,
  valid,
}: InputFieldProps) {
  const theme = useTheme();

  switch (fieldType) {
    case CHECKBOX: {
      return (
        <ControllerWithSingleCheckbox
          label={label}
          labelPlacement="end"
          checkboxProps={{
            size: 'small',
          }}
          name={id}
        />
      );
    }
    case RADIO: {
      return (
        <ControllerWithRadioGroup
          name={id}
          radioOptions={['true', 'false']}
          getOptionLabel={['form.true', 'form.false']}
          label={label}
        />
      );
    }
    default:
      return (
        <ControllerWithTextFieldV2
          name={id}
          helperTextFn={(message?: string) => {
            if (!message) {
              return null;
            }

            return (
              <Alert
                severity="error"
                sx={{
                  py: 0,
                  px: 1.5,
                  fontSize: 12,
                  mt: 0.5,
                  [`& .${alertClasses.icon}`]: {
                    mr: 1,
                    '& svg': {
                      width: 20,
                      height: 20,
                    },
                  },
                }}
              >
                {message}
              </Alert>
            );
          }}
          textfieldProps={{
            label,
            size: 'small',
            slotProps: {
              input: {
                endAdornment,
                onFocus: () => {
                  canvasCustomEvents.addCrop({
                    boundingBox,
                    id,
                    pageIdx,
                    valid: valid || valid === null,
                    overrideColor: theme.palette.info.main,
                  });
                },
                startAdornment,
              },
              inputLabel: { shrink: true },
              formHelperText: {
                component: 'div',
                sx: {
                  color: 'error.main',
                  mt: 0.5,
                  mb: 0,
                  mx: 0,
                  whiteSpace: 'break-spaces',
                },
              },
            },
          }}
        />
      );
  }
}

export { InputField };
