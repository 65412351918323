import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import { transformKeys } from 'utils/object.ts';
import { useGet } from 'utils/react-query';
import { generalConfig } from 'config';
import { selectUserManagementData } from 'state/selectors/user-management';
import {
  COGNITO_CREATE,
  COGNITO_ROLES,
  COGNITO_RECREATE,
  COGNITO,
  COGNITO_RESEND,
  COGNITO_DELETE,
  makeUsersList,
} from 'constants/api-endpoints';
import {
  UserManagementApiResponse,
  UserManagementRolesApiResponse,
} from 'api/transformers/types/user-management';
import {
  UserManagementUser,
  UserManagementList,
  type UserManagementPage,
} from 'types/UserManagement';
import api, { makeApiLink } from 'api/api';
import { toSnakeCase } from 'utils/string';
import { useOptimisticMutation } from 'utils/react-query';
import { pathToUrl } from 'utils/url';

const getUserManagementOptions = (
  start = generalConfig.defaultPaginationStart,
  size = generalConfig.defaultPaginationSize,
  searchValue = '',
  searchField = ''
) => {
  const { list, params } = makeUsersList({
    searchValue,
    searchField,
  });

  return {
    url: list,
    prefix: COGNITO,
    params: { start: start * size, size, ...params },
    placeholderData: keepPreviousData,
  };
};

export function useGetUserManagementData(params: {
  page: number;
  rowsPerPage: number;
  searchValue: string;
  searchField: string;
}) {
  const { page, rowsPerPage, searchValue, searchField } = params;
  return useGet<
    PaginationApiResponse<UserManagementApiResponse>,
    UserManagementList
  >({
    ...getUserManagementOptions(page, rowsPerPage, searchValue, searchField),
    select: selectUserManagementData,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
  });
}

export function useGetUserManagementTotal(params: {
  start?: number;
  size?: number;
  searchValue: string;
  searchField: string;
}) {
  const { start, size, searchValue, searchField } = params;
  return useGet<PaginationApiResponse<UserManagementApiResponse>, number>({
    ...getUserManagementOptions(start, size, searchValue, searchField),
    select: (data) => data.total,
  });
}

export function postCreateUser({
  email,
  givenName,
  familyName,
  signupUserRole,
}: {
  email: string;
  givenName: string;
  familyName: string;
  signupUserRole?: string;
}) {
  return api.post(
    makeApiLink(COGNITO_CREATE),
    transformKeys(
      {
        email,
        givenName,
        familyName,
        signupUserRole,
      },
      toSnakeCase
    )
  );
}

export function useGetUserManagementRoles() {
  return useGet<UserManagementRolesApiResponse, string[]>({
    url: COGNITO_ROLES,
    select: (data) => data.roles,
  });
}

async function recreateUser({
  email,
  givenName,
  familyName,
  signupUserRole,
}: UserManagementUser) {
  return await api.put(makeApiLink(COGNITO_RECREATE), {
    email: email,
    given_name: givenName,
    family_name: familyName,
    signup_user_role: signupUserRole,
  });
}

export function useRecreateUser(params: {
  page: number;
  rowsPerPage: number;
  searchValue: string;
  searchField: string;
}) {
  const queryClient = useQueryClient();

  return useOptimisticMutation<UserManagementPage, UserManagementUser>({
    fn: recreateUser,
    url: makeUsersList({
      searchValue: params.searchValue,
      searchField: params.searchField,
    }).list,
    prefix: COGNITO,
    meta: {
      errorMessage: 'Error updating user',
      loadingMessage: 'Updating user',
      successMessage: 'User data updated',
    },
    params: {
      size: params.rowsPerPage,
      start: params.page,
      search_value: params.searchValue || undefined,
      search_field: params.searchField || undefined,
    },
    updater: (state, newData) => {
      if (state) {
        const target = state.data.findIndex(
          (user) => user.email === newData.email
        );

        if (target !== -1) {
          const updatedData = [...state.data];
          updatedData[target] = newData;

          return { ...state, data: updatedData };
        }
      }

      return state;
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [COGNITO] });
    },
  });
}

export function resendPassword(email) {
  return api.post(makeApiLink(COGNITO_RESEND), {
    email,
  });
}

async function deleteUser(email) {
  return api.delete(
    makeApiLink(pathToUrl(COGNITO_DELETE, { email: encodeURIComponent(email) }))
  );
}

export function useDeleteUser(params: {
  page: number;
  rowsPerPage: number;
  searchValue: string;
  searchField: string;
}) {
  const queryClient = useQueryClient();
  const url = makeUsersList({
    searchValue: params.searchValue,
    searchField: params.searchField,
  }).list;

  return useOptimisticMutation<UserManagementPage, string>({
    fn: deleteUser,
    url,
    prefix: COGNITO,
    params: {
      size: params.rowsPerPage,
      start: params.page,
      search_value: params.searchValue || undefined,
      search_field: params.searchField || undefined,
    },
    meta: {
      errorMessage: 'Error deleting user',
      loadingMessage: 'Deleting user...',
      successMessage: 'User deleted',
    },
    updater: (state, newData) => {
      if (state) {
        const target = state.data.findIndex((user) => user.email === newData);
        if (target !== -1) {
          const updatedData = [...state.data];
          updatedData.splice(target, 1);

          return { ...state, data: updatedData };
        }
      }
      return state;
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [COGNITO],
      });
    },
  });
}
