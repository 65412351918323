import type { BaseSchema } from 'yup';
import { array, boolean, date, number, object, string } from 'yup';
import { LIST, SINGLE } from 'constants/field-type.ts';
import { isNotValidItem } from 'state/selectors/documents.ts';

import type { List, Resources } from './types.ts';
import { SKIP_INPUT_SUFFIX } from './constants.ts';

type SchemaMap = { [k: string]: BaseSchema };

const singleSchemaMap: SchemaMap = {
  bool: boolean()
    .nullable()
    .transform((v) => (typeof v === 'boolean' ? v : null)),
  date: date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  float: number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v)),
  int: number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v)),
  str: string(),
  array: array(),
};

// for field_type === LIST
const listSchemaMap: SchemaMap = {
  int: string()
    // matching letters and other special characters too in case
    // ocr doesn't pick up the values correctly
    .matches(/^[0-9.,;]+$/, { excludeEmptyString: true })
    .nullable(true),
  str: string(),
  array: array(),
};

const schemaMap: { [k: string]: SchemaMap } = {
  [SINGLE]: singleSchemaMap,
  [LIST]: listSchemaMap,
};

function generateSchema({
  list,
  resources,
}: {
  list: List;
  resources: Resources;
}) {
  let schema = {};

  for (let i = 0; i < list.length; i++) {
    const id = list[i];
    const item = resources[id];
    const { clientKey, fieldType, valueType } = item;
    let validator = schemaMap[fieldType][valueType] ?? string();

    // assume valid item is an identifiedItem
    if (!isNotValidItem(item)) {
      // accept empty values as per usual but still validate the value type
      validator = validator.nullable(true);
    } else {
      // unidentified items will have a skipped (bool) checkbox
      // so if the checkbox is true then validation is not required
      validator = validator.label(clientKey).when(`${id}${SKIP_INPUT_SUFFIX}`, {
        is: true,
        then: validator.notRequired(),
        otherwise: validator.required(),
      });
    }

    schema = {
      ...schema,
      [id]: validator,
    };
  }

  return object(schema);
}

export { generateSchema };
