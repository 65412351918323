import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TabPanel from '@mui/lab/TabPanel';
import { ScrollableContainer } from 'components/ScrollableContainer.tsx';

import { type AllFieldsProps, FilterType } from './types.ts';
import { AllField } from './AllField.tsx';
import { getObservationTitle } from './utils/get-observation-title.ts';

function AllFields({
  allFieldsErrors,
  fieldsListIds,
  filter,
  getPageRef,
  resources,
  observations,
}: AllFieldsProps) {
  const { clearErrors, setError } = useFormContext();

  useEffect(() => {
    if (filter === FilterType.ALL) {
      clearErrors();

      if (Object.keys(allFieldsErrors).length) {
        Object.entries(allFieldsErrors).forEach(([key, value]) => {
          setError(key, {
            type: value.type,
            message: value.message.join('\n'),
          });
        });
      }
    }
  }, [clearErrors, allFieldsErrors, filter, setError]);

  return (
    <TabPanel value={FilterType.ALL}>
      <Box sx={{ px: 2 }}>
        <ScrollableContainer offset={16}>
          <Stack spacing={4} sx={{ pb: 1 }}>
            {Object.entries(fieldsListIds).map(([observationId, ids]) => (
              <AllField
                key={observationId}
                getPageRef={getPageRef}
                ids={ids}
                observationId={observationId}
                resources={resources}
                title={getObservationTitle(observations[observationId])}
              />
            ))}
          </Stack>
        </ScrollableContainer>
      </Box>
    </TabPanel>
  );
}

export { AllFields };
