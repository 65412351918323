import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import type { LineLevelContent } from 'types/Documents.ts';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { EDIT_ICON, TRASH_ICON } from 'constants/public-icons.ts';

import { LineItemViewProps } from './types.ts';
import { LineItemValue } from './LineItemValue.tsx';

function LineItemView({
  deleteLineItem,
  lineIdx,
  list,
  openEditMode,
  resources,
}: LineItemViewProps) {
  const { t } = useTranslationRoot();

  return (
    <>
      <Stack direction="row" sx={{ flex: 1, flexWrap: 'wrap' }}>
        {list.map((id) => {
          const { clientKey } = resources[id] as LineLevelContent;

          return <LineItemValue id={id} key={id} clientKey={clientKey} />;
        })}
      </Stack>

      <Stack>
        <Tooltip placement="left" title={t('form.editLineItem')}>
          <IconButton onClick={openEditMode}>
            <SvgIconStyle height={15} width={15} src={EDIT_ICON} />
          </IconButton>
        </Tooltip>
        <Tooltip placement="left" title={t('form.deleteLineItem')}>
          <IconButton onClick={() => deleteLineItem(lineIdx)}>
            <SvgIconStyle height={15} width={15} src={TRASH_ICON} />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
}

export { LineItemView };
