import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

interface HeaderProps {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
}

function Header({ leftColumn, rightColumn }: HeaderProps) {
  return (
    <Stack
      direction="row"
      useFlexGap
      sx={{
        alignItems: 'center',
        height: 1,
        justifyContent: 'space-between',
        px: 2,
      }}
    >
      <Box>{leftColumn}</Box>
      <Box>{rightColumn}</Box>
    </Stack>
  );
}

export { Header };
