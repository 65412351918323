import type { BaseTextFieldProps } from '@mui/material/TextField';
import type { SelectChangeEvent } from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslationRoot } from 'components/with-translation';

interface ControllerWithDropDownProps extends BaseTextFieldProps {
  htmlFor: string;
  labelSize?: 'small' | 'normal';
  menuItems: {
    label: string;
    value: string;
  }[];
  name: string;
  namespace?: string;
  onChange?: (e: SelectChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

function ControllerWithDropDown({
  defaultValue,
  htmlFor,
  input,
  inputProps,
  label,
  labelSize,
  menuItems,
  menuItemsSx,
  name,
  namespace,
  onChange,
  SelectSx,
  size,
  sx,
  variant,
  ...props
}: ControllerWithDropDownProps) {
  const { control } = useFormContext();
  const { t } = useTranslationRoot(namespace);

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => {
        const { error, invalid } = fieldState;

        return (
          <FormControl fullWidth error={!!error} size={size} sx={sx}>
            {label ? (
              <InputLabel id={htmlFor} size={labelSize}>
                {label}
              </InputLabel>
            ) : null}
            <Select
              {...field}
              error={invalid}
              aria-label={props['aria-label']}
              aria-invalid={invalid}
              inputProps={inputProps}
              label={label}
              labelId={htmlFor}
              onChange={(e) => {
                field.onChange(e);
                onChange?.(e);
              }}
              variant={variant}
              sx={SelectSx}
              input={input}
            >
              {menuItems.map(({ label, value }, index) => (
                <MenuItem key={index} value={value} sx={menuItemsSx}>
                  {t(label)}
                </MenuItem>
              ))}
            </Select>
            {error?.message ? (
              <FormHelperText error>{t(error.message)}</FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
}

export { ControllerWithDropDown };
export type { ControllerWithDropDownProps };
