import { Fragment, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { Counter } from 'components/Counter.tsx';
import { DASHBOARD_PAGE } from 'constants/translation-keys.ts';
import splitsRoute from 'pages/Splits/splits.route.tsx';
import fileUploadRoute from 'pages/FileUpload/file-upload.route.tsx';
import classificationsRoute from 'pages/Classifications/classifications.route.tsx';
import redactionsRoute from 'pages/Redactions/redactions.route.tsx';
import hitlRoute from 'pages/HitL/hitl.route.tsx';
import consistencyChecksRoute from 'pages/ConsistencyChecks/consistency-checks.route.tsx';
import documentCorrectionsRoute from 'pages/DocumentCorrections/document-corrections.route.tsx';
import claimsRoute from 'pages/Claims/claims.route.tsx';
import pagesRoute from 'pages/Pages/pages.route.tsx';
import filesRoute from 'pages/Files/files.route.tsx';
import performanceRoute from 'pages/Performance/performance.route';
import performanceManageUsersRoute from 'pages/PerformanceManageUsers/performanceManageUsers.route';
import { useIsSuperAdminRole } from 'state/queries/current-user.ts';
import { useGetTasksCount } from 'state/queries/documents.ts';

import { ListSubheaderStyle } from './style.tsx';
import { NavListRoot, NavMiniRoot } from './NavItem';

interface NavListV2Props {
  isMiniNavbar?: boolean;
  removeDocuments: boolean;
}

type LinkProperty = {
  subheader: string;
  items: {
    title: string;
    shortTitle: string;
    path: string;
    icon: string;
    permission: string[];
    endComponentProps: NonNullable<unknown>;
  }[];
};

let isLoaded = false;

// dictionary of tasks
const tasks = {
  split: 'fileSplit',
  pageClassification: 'pageClassification',
  pageRedaction: 'pageRedaction',
  documentCorrection: 'documentCorrection',
  consistencyCheck: 'consistencyCheck',
  classification: 'pageClassification',
  redaction: 'pageRedaction',
};

function NavListV2({ isMiniNavbar }: NavListV2Props) {
  const [state, dispatch] = useReducer(reducer, links);
  const { t } = useTranslationRoot(DASHBOARD_PAGE);

  // feature flags
  const isSplitsPageEnabled = useFeatureFlag('enableSplitsPage');
  const isClassificationPageEnabled = useFeatureFlag(
    'enableClassificationPage'
  );
  const isRedactionPageEnabled = useFeatureFlag('enableRedactionPage');
  const isSuperAdminQuery = useIsSuperAdminRole();

  const tasksCountQuery = useGetTasksCount();

  if (!isLoaded) {
    if (!isSuperAdminQuery.data) {
      dispatch(removeResourcesAction());
    }
    isLoaded = true;
  }

  useEffect(() => {
    if (!isSplitsPageEnabled) {
      dispatch(removeLinkAction(splitsRoute.path));
    }
    if (!isClassificationPageEnabled) {
      dispatch(removeLinkAction(classificationsRoute.path));
    }
    if (!isRedactionPageEnabled) {
      dispatch(removeLinkAction(redactionsRoute.path));
    }
  }, [
    isClassificationPageEnabled,
    isRedactionPageEnabled,
    isSplitsPageEnabled,
  ]);

  useEffect(() => {
    if (tasksCountQuery.isSuccess) {
      const { data } = tasksCountQuery;
      const tasksCountData = data?.tasks;
      if (tasksCountData) {
        Object.keys(tasksCountData).forEach((key) => {
          dispatch(addCountAction(tasks[key], tasksCountData[key]));
        });
      }
    }
  }, [tasksCountQuery.isSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLargeLinks = ({ items, subheader }: LinkProperty) => (
    <Stack key={subheader} sx={{ width: 1 }}>
      <List key={subheader} disablePadding sx={{ width: 1 }}>
        {!!items.length && (
          <ListSubheaderStyle>{t(subheader)}</ListSubheaderStyle>
        )}
        {items?.map((route) => (
          <NavListRoot
            key={route.title}
            list={route}
            endComponentProps={route.endComponentProps}
          />
        ))}
      </List>
    </Stack>
  );

  const renderSmallLinks = ({ subheader, items }, index) => (
    <Fragment key={subheader}>
      {index !== 0 && items.length ? (
        <Box
          sx={{
            backgroundColor: 'grey.50024',
            mt: '12px !important',
            height: '1px',
            width: 24,
          }}
        />
      ) : null}
      {items.map((list) => (
        <NavMiniRoot key={list.title} list={list} />
      ))}
    </Fragment>
  );

  return (
    <Stack
      spacing={isMiniNavbar ? 0.5 : 0}
      sx={{
        alignItems: isMiniNavbar ? 'center' : 'flex-start',
        overflowY: 'auto',
        px: isMiniNavbar ? 0.5 : 0,
      }}
    >
      {isMiniNavbar
        ? navLinks(state).map(renderSmallLinks)
        : navLinks(state).map(renderLargeLinks)}
    </Stack>
  );
}

type State = {
  subheader: string;
  items: {
    name: string;
    route: typeof splitsRoute;
    search?: string;
    count?: number;
  }[];
}[];

const links = [
  {
    subheader: 'navV2.tasks',
    items: [
      {
        name: 'fileSplit',
        route: splitsRoute,
        search: '',
      },
      {
        name: 'pageClassification',
        route: classificationsRoute,
        search: '',
      },
      {
        name: 'pageRedaction',
        route: redactionsRoute,
        search: '',
      },
      {
        name: 'documentCorrection',
        route: documentCorrectionsRoute,
        search: '?stage=AWAITING_REVIEW&stage=REVIEW_IN_PROGRESS',
      },
      {
        name: 'consistencyCheck',
        route: consistencyChecksRoute,
        search: '?stage=AWAITING_REVIEW',
      },
    ],
  },
  {
    subheader: 'navV2.resources',
    items: [
      {
        name: 'files',
        route: filesRoute,
        search: '',
      },
      {
        name: 'claims',
        route: claimsRoute,
        search: '',
      },
      {
        name: 'pages',
        route: pagesRoute,
        search: '',
      },
      {
        name: 'documents',
        route: hitlRoute,
        search: '',
      },
      {
        name: 'performance',
        route: performanceRoute,
        search: '',
      },
      {
        name: 'userManagement',
        route: performanceManageUsersRoute,
        search: '',
      },
    ],
  },
  {
    subheader: 'navV2.clientPortal',
    items: [
      {
        name: 'fileUpload',
        route: fileUploadRoute,
      },
    ],
  },
] as State;

enum ActionType {
  ADD_COUNT = 'ADD_COUNT',
  REMOVE_LINK = 'REMOVE_LINK',
  REMOVE_RESOURCES = 'REMOVE_RESOURCES',
}

type Action =
  | {
      type: ActionType.REMOVE_LINK;
      path: string;
    }
  | {
      type: ActionType.REMOVE_RESOURCES;
    }
  | {
      type: ActionType.ADD_COUNT;
      name: string;
      count: number;
    };

function removeLinkAction(path: string): Action {
  return {
    type: ActionType.REMOVE_LINK,
    path,
  };
}

function removeResourcesAction(): Action {
  return {
    type: ActionType.REMOVE_RESOURCES,
  };
}

function addCountAction(name: string, count: number): Action {
  return {
    type: ActionType.ADD_COUNT,
    name,
    count,
  };
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.REMOVE_LINK:
      // remove object from items array if path matches action.path
      return state.map(({ subheader, items }) => ({
        subheader,
        items: items.filter(({ route }) => route.path !== action.path),
      }));
    case ActionType.REMOVE_RESOURCES:
      return state.filter(({ subheader }) => subheader !== 'navV2.resources');
    case ActionType.ADD_COUNT:
      return state.map(({ subheader, items }) => ({
        subheader,
        items: items.map((item) => {
          if (item.name === action.name) {
            return {
              ...item,
              count: action.count,
            };
          }
          return item;
        }),
      }));
    default:
      return state;
  }
}

const navLinks = (links) =>
  links.map(({ items, subheader }) => ({
    items: items.map(({ count, name, route, search }) => ({
      title: `navV2.${name}`,
      shortTitle: `navShortV2.${name}`,
      path: route.createPath({ search }),
      icon: route.icon,
      permission: route.permission,
      endComponentProps: count,
      endComponent: (count: number) => <Counter count={count} />,
    })),
    subheader,
  }));

export { NavListV2 };
