import type { ReactNode } from 'react';
import { useMemo } from 'react';
import {
  useGetDocumentsTypesDocumentTypes,
  useGetDocumentTypesCountDocumentTypes,
} from 'state/queries/documents.ts';
import { Counter } from 'components/Counter.tsx';
import { unique } from 'utils/array.ts';

interface UseRegularDocumentTypesFiltersProps {
  hasAdvancedFiltering: boolean;
  stageParam: string | null;
}

type FilterTypes = { label: string; value: any; endAdornment?: ReactNode };
type FilterTypeDict = { [key: string]: FilterTypes };

function useRegularDocumentTypesFilters({
  hasAdvancedFiltering,
  stageParam,
}: UseRegularDocumentTypesFiltersProps) {
  const documentTypesQuery = useGetDocumentsTypesDocumentTypes();
  const documentsTypesCount = useGetDocumentTypesCountDocumentTypes(
    !hasAdvancedFiltering && documentTypesQuery.isSuccess,
    stageParam
  );

  const documentTypes = useMemo(() => {
    const { data, isError, isPending } = documentTypesQuery;

    if (isError || isPending) {
      return {
        list: [],
        dict: {},
      };
    }

    return data.reduce(
      (acc, { documentGroup }) => {
        return {
          list: [...acc.list, documentGroup],
          dict: {
            ...acc.dict,
            [documentGroup]: {
              label: documentGroup,
              value: documentGroup,
            },
          },
        };
      },
      {
        list: [] as string[],
        dict: {} as FilterTypeDict,
      }
    );
  }, [documentTypesQuery]);

  return useMemo(() => {
    // TODO: remove this when we don't need the old filtering anymore
    if (!hasAdvancedFiltering) {
      if (documentsTypesCount.isError || documentsTypesCount.isPending) {
        return [];
      }

      documentsTypesCount.data.forEach(({ count, documentType }) => {
        if (documentType && documentTypes.dict[documentType]) {
          (documentTypes.dict[documentType] as FilterTypes).endAdornment = (
            <Counter count={count} />
          );
        }

        // need to hardcode this because documents with document_type=null
        // are known as 'None'
        if (documentType === null && documentTypes.dict['None']) {
          (documentTypes.dict['None'] as FilterTypes).endAdornment = (
            <Counter count={count} />
          );
        }
      });
    }

    return unique(documentTypes.list)
      .map((id) => documentTypes.dict[id])
      .filter(({ value }) => value);
  }, [
    hasAdvancedFiltering,
    documentTypes.list,
    documentTypes.dict,
    documentsTypesCount.isPending,
    documentsTypesCount.isError,
    documentsTypesCount.data,
  ]);
}

export { useRegularDocumentTypesFilters };
