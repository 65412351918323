import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControllerWithTextField } from 'components/form/ControllerWithTextField';
import { RoleSelectorWithController } from 'components/RoleSelectorWithController';
import { PERFORMANCE_CREATE_USER_PAGE } from 'constants/translation-keys';
import { LoadingButtonWithTranslation } from 'components/with-translation';

export type CreateUserFormValues = {
  email: string;
  role: string;
};

interface CreateClientFormProps {
  isLoadingButton?: boolean;
  onSubmit: (values: CreateUserFormValues) => void;
}

const schema = object({
  email: string().required('createUserForm.emailRequired'),
  role: string().required('roles.roleRequired'),
});

export function CreateUserForm({
  isLoadingButton,
  onSubmit,
}: CreateClientFormProps) {
  const { t } = useTranslation(PERFORMANCE_CREATE_USER_PAGE);
  const methods = useForm<CreateUserFormValues>({
    defaultValues: {
      email: '',
      role: undefined,
    },
    resolver: yupResolver(schema),
  });
  const { isSubmitting } = useFormState({ control: methods.control });

  function handleFormSubmit(values: CreateUserFormValues) {
    onSubmit(values);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <ControllerWithTextField
            label={t('createUserForm.email')}
            name="email"
            type="email"
          />
          <RoleSelectorWithController name="role" />
        </Stack>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButtonWithTranslation
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoadingButton}
            i18nKey="createUserForm.submitButton"
          />
        </Box>
      </form>
    </FormProvider>
  );
}
