import {
  keepPreviousData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import type { PaginationApiResponse } from 'api/transformers/types/pagination.ts';
import type { FileApiResponse } from 'api/transformers/types/files.ts';
import { CURRENT_USER, MakePage } from 'constants/api-endpoints.ts';
import type { Order } from 'constants/sort.ts';
import type { FilePage, SproutFile, SproutFilesList } from 'types/Files.ts';
import type { CurrentUser } from 'types/CurrentUser.ts';
import api, { makeApiLink } from 'api/api.ts';
import { generalConfig } from 'config.ts';
import { DESC } from 'constants/sort.ts';
import { EFileStage } from 'constants/file-stage.ts';
import {
  selectFileResponse,
  selectFilesData,
  selectFilesTotal,
  selectFileUrls,
  selectFilesMetadataSortedColumns,
} from 'state/selectors/files.ts';
import { useGet } from 'utils/react-query.ts';
import { pathToUrl } from 'utils/url.ts';
import { useTranslationRoot } from 'components/with-translation';

/**
 * Query Keys
 */
const FILES = 'files';
const FILES_LISTS =
  `${FILES}\\?start=:start&size=:size&order=:order&stage_filter=:stage` as const;
const FILE = `${FILES}/:id` as const;
const FILE_LOCK = `${FILE}/lock` as const;
const FILE_UNLOCK = `${FILE}/unlock\\?force=:force` as const;
const FILE_ARCHIVE = `${FILE}/archive` as const;

function makeFilesList({ order }: { order: Order }) {
  const page: MakePage = {
    list: FILES_LISTS,
    params: { order },
  };

  return page;
}

/**
 * Query Functions
 */

type FilesOptions = {
  order?: Order;
  start: number;
  size: number;
  stage?: EFileStage;
};

const getFilesOptions = ({
  start = generalConfig.defaultPaginationStart,
  size = generalConfig.defaultPaginationSize,
  order = DESC,
  stage = EFileStage.AWAITING_REVIEW,
}: FilesOptions) => ({
  url: makeFilesList({ order }).list,
  params: {
    stage,
    start: start * size,
    size,
    ...makeFilesList({ order }).params,
  },
  prefix: FILES,

  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
});

export function useGetFilesData(options: FilesOptions) {
  return useGet<PaginationApiResponse<FileApiResponse>, SproutFilesList>({
    ...getFilesOptions(options),
    refetchOnMount: 'always',
    select: selectFilesData,
  });
}

export function useGetFilesTotal(options: FilesOptions) {
  return useGet<PaginationApiResponse<FileApiResponse>, number>({
    ...getFilesOptions(options),
    select: selectFilesTotal,
  });
}

export function useGetFilesMetadataSortedColumns(options: FilesOptions) {
  return useGet<FileApiResponse, string[]>({
    ...getFilesOptions(options),
    select: selectFilesMetadataSortedColumns,
  });
}

const getFileOptions = (id = '') => ({
  url: FILE,
  params: { id },
  prefix: FILES,
});

export function useGetFile(id = '') {
  return useGet<FileApiResponse, SproutFile>({
    ...getFileOptions(id),
    refetchOnMount: 'always',
    select: selectFileResponse,
  });
}

export function useGetFileUrls(id = '') {
  return useGet<FileApiResponse, FilePage[]>({
    ...getFileOptions(id),
    refetchOnMount: 'always',
    select: selectFileUrls,
  });
}

export function lockFile(id: string) {
  return api.put(makeApiLink(pathToUrl(FILE_LOCK, { id })));
}

export function unlockFile(id: string) {
  return api.put(makeApiLink(pathToUrl(FILE_UNLOCK, { id })));
}

export function useFileLock() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: lockFile,
    onMutate: async (id: string) => {
      const queryKey = [FILES, FILE, { id }];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

      if (currentUser) {
        queryClient.setQueryData(queryKey, (oldData: SproutFile) => ({
          ...oldData,
          locked: true,
          last_locked_on: new Date().toISOString(),
          last_locked_by: currentUser.email,
        }));
      }

      return previousData;
    },
  });
}

export function useUnlockFile() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unlockFile,
    onMutate: async (id: string) => {
      const queryKey = [FILES, FILE, { id }];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

      if (currentUser) {
        queryClient.setQueryData(queryKey, (oldData: SproutFile) => ({
          ...oldData,
          lastLockedBy: null,
          lastLockedOn: null,
          locked: false,
        }));
      }

      return previousData;
    },
  });
}

function archiveFile({ id }: { id: string }) {
  return api.put(makeApiLink(pathToUrl(FILE_ARCHIVE, { id })), { id });
}

export function useArchiveFile() {
  const queryClient = useQueryClient();
  const { t } = useTranslationRoot();

  return useMutation({
    mutationFn: archiveFile,
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: [FILES] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [FILES] });
    },
    meta: {
      errorMessage: t('tooltip.archiveRejected'),
      loadingMessage: t('tooltip.archivePending'),
      successMessage: t('tooltip.archiveResolved'),
    },
  });
}
