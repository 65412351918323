import { matchPath } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { LinkWithRef } from 'components/Link.tsx';
import createClaimRoute from 'pages/CreateClaim/createClaim.route.tsx';
import uploadDocumentRoute from 'pages/UploadDocument/uploadDocument.route.tsx';
import featureFlagsRoute from 'pages/FeatureFlags/featureFlags.route.tsx';
import configurationRoute from 'pages/Configuration/configuration.route.tsx';
import docsRoute from 'pages/Docs/docs.route.tsx';
import { alpha } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import { pxToRem } from 'theme/typography.ts';

interface SproutDevLinksProps {
  handleClose: VoidFunction;
}

function SproutDevLinks({ handleClose }: SproutDevLinksProps) {
  const isActive = (path: string) =>
    !!matchPath(window.location.pathname, path);

  const items = [
    {
      name: 'Create Claim',
      selected: isActive(createClaimRoute.createPath()),
      to: createClaimRoute.createPath(),
    },
    {
      name: 'Upload Document',
      selected: isActive(uploadDocumentRoute.createPath()),
      to: uploadDocumentRoute.createPath(),
    },
    {
      name: 'Feature Flags',
      selected: isActive(featureFlagsRoute.createPath()),
      to: featureFlagsRoute.createPath(),
    },
    {
      name: 'Configuration',
      selected: isActive(configurationRoute.createPath()),
      to: configurationRoute.createPath(),
    },
    {
      name: 'API Documentation',
      selected: isActive(docsRoute.createPath()),
      to: docsRoute.createPath(),
    },
  ];

  return (
    <Box sx={{ my: 1.5, px: 2.5 }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
        Sprout Dev
      </Typography>

      {items.map(({ name, selected, to }) => (
        <MenuItem
          component={LinkWithRef}
          key={name}
          to={to}
          selected={selected}
          sx={{
            '&.Mui-selected': {
              backgroundColor: ({ palette }) =>
                alpha(palette.primary.main, palette.action.selectedOpacity),
              color: 'primary.main',
            },
          }}
          onClick={handleClose}
        >
          <ListItemText
            primary={name}
            primaryTypographyProps={{ sx: { fontSize: pxToRem(14) } }}
          />
        </MenuItem>
      ))}
    </Box>
  );
}

export { SproutDevLinks };
