type AnyObject = { [k: string]: any };

export function transformKeys(
  obj: any,
  keyTransformFn: (key: string) => string
): any {
  if (Array.isArray(obj)) {
    return obj.map((value) => transformKeys(value, keyTransformFn));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result: AnyObject, key: string) => ({
        ...result,
        [keyTransformFn(key)]: transformKeys(obj[key], keyTransformFn),
      }),
      {}
    );
  }
  return obj;
}

/**
 * Extracts all possible leaf-node paths from an object type as dot-notation strings.
 *
 * @template T - The object type to extract paths from
 * @returns A union type of all possible leaf-node paths in dot notation
 *
 * @example
 * type User = {
 *   profile: {
 *     name: string;
 *     address: {
 *       city: string;
 *       zipCode: number;
 *     }
 *   };
 *   settings: {
 *     theme: string;
 *   }
 * };
 *
 * // Results in: "profile.name" | "profile.address.city" | "profile.address.zipCode" | "settings.theme"
 * type ValidPaths = LeafPaths<User>;
 */
export type LeafPaths<T extends object> = {
  [K in keyof T]: K extends string
    ? T[K] extends object
      ? `${K}.${LeafPaths<T[K]>}`
      : K
    : never;
}[keyof T];
