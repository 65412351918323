import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Layout } from 'components/CommonTool/Layout.tsx';
import {
  ButtonWithTranslation,
  LinkWithTranslation,
  TypographyWithTranslation,
} from 'components/with-translation.tsx';
import { LinkWithRef } from 'components/Link.tsx';
import consistencyChecksRoute from 'pages/ConsistencyChecks/consistency-checks.route.tsx';

import { BodyLayout } from './BodyLayout.tsx';

interface FallbackUIProps {
  error: Error | null;
  resetError: () => void;
}

function FallbackUI({ error, resetError }: FallbackUIProps) {
  return (
    <Layout
      header={<div />}
      body={
        <BodyLayout
          canvas={
            <Box sx={{ p: 3 }}>
              <TypographyWithTranslation
                i18nKey="fallbackUI.heading"
                variant="h3"
              />
              <TypographyWithTranslation
                i18nKey="fallbackUI.description"
                variant="body1"
                sx={{ color: 'text.secondary', my: 2 }}
              />
              <Typography variant="body2" sx={{ display: 'none' }}>
                {typeof error === 'string' ? error : error?.message}
              </Typography>
              <ButtonWithTranslation
                variant="contained"
                i18nKey="fallbackUI.action"
                onClick={resetError}
              />

              <LinkWithTranslation
                i18nKey="fallbackUI.returnToListView"
                component={LinkWithRef}
                to={consistencyChecksRoute.createPath()}
                sx={{ ml: 1 }}
              />
            </Box>
          }
          fields={null}
        />
      }
    />
  );
}

export { FallbackUI };
