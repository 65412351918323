import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { PERFORMANCE_CREATE_USER_PAGE } from 'constants/translation-keys';

const path = '/u/create';
const PerformanceCreateUserPage = loadable(
  lazy(() => import('./PerformanceCreateUser'))
);

const performanceCreateUserRoute = {
  path,
  createPath: () => generatePath(path),
  element: <PerformanceCreateUserPage />,
  permission: ROUTE_PERMISSIONS.miCreateUser,
  i18nNamespace: PERFORMANCE_CREATE_USER_PAGE,
};

export default performanceCreateUserRoute;
