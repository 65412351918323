// layout sizes
export const LAYOUT_HEIGHTS = {
  header: 70,
  filtersToolbar: 72,
};

// misc constants
export const SKIP_INPUT_SUFFIX = '-skipped';
export const DELETED_INPUT_SUFFIX = '-deleted';

// field filter constants
export const ALL = 'all';
export const INVALID = 'invalid';
export const VALID = 'valid';
export const OPTIONS = [
  {
    label: 'form.allItems',
    value: ALL,
  },
  {
    label: 'form.invalidItems',
    value: INVALID,
  },
  {
    label: 'form.validItems',
    value: VALID,
  },
];

// hardcoding these because AXA want these disabled, but still want to see them
// and there's no way for backend to configure this
export const DISABLED_ITEMS = ['document_group', 'document_provider'];

// reject reasons dialog
export const OTHER_REASON = '${other}';
