/**
 *  Note: this is for us SuperAdmins only
 * Do not copy this as an example
 */
import type { XMLCallbacks } from 'api/api';
import api, { getAuthorizationHeader, makeApiLink } from 'api/api';

import { transformFilePresignedUploadUrlResponse } from './transformers/files.ts';
import { FilePresignedUploadUrlApiResponse } from './transformers/types/files.ts';

type FilesUpload = {
  document: Blob;
  upload_data: FileUploadData;
};

type FileUploadData = {
  claim_id: string;
  client_claim_id?: string;
  client_document_type?: string;
  metadata?: string;
};

type FilesReupload = {
  file_id: string;
  env: string;
  upload_data: FileUploadData;
};

export function postFilesUpload(payload: FilesUpload) {
  const formData = new FormData();
  const uploadData = {
    claim_id: payload.upload_data.claim_id,
    client_claim_id: payload.upload_data.client_claim_id,
    client_document_type: payload.upload_data.client_document_type || null,
  };

  if (payload.upload_data.metadata) {
    // @ts-ignore
    uploadData.metadata = JSON.parse(`${payload.upload_data.metadata}`);
  }

  formData.append('file', payload.document);
  formData.append('upload_data', JSON.stringify(uploadData));
  const accessToken = getAuthorizationHeader();

  const options = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: accessToken || '',
    },
  } as RequestInit;

  return fetch(makeApiLink('files/upload'), options).then((response) =>
    response.text()
  );
}

export const OCR = 'OCR';
export const POLICY = 'POLICY';

export const postFilesUploadXML = (
  payload: FilesUpload,
  callbacks: XMLCallbacks,
  extractionType: 'OCR' | 'POLICY' = 'OCR'
) => {
  const formData = new FormData();
  const uploadData = {
    claim_id: payload.upload_data.claim_id,
    client_claim_id: payload.upload_data.client_claim_id,
    client_document_type: payload.upload_data.client_document_type || null,
  };

  if (payload.upload_data.metadata) {
    // @ts-ignore
    uploadData.metadata = JSON.parse(`${payload.upload_data.metadata}`);
  }

  formData.append('file', payload.document);
  formData.append('upload_data', JSON.stringify(uploadData));

  return api.xmlRequest(
    makeApiLink(`files/upload?extraction_type=${extractionType}`),
    'POST',
    formData,
    callbacks
  );
};

export const postFilesReupload = (payload: FilesReupload) => {
  const { file_id, env, upload_data } = payload;
  const newPayload = {
    file_id: file_id,
    upload_data: {
      claim_id: upload_data.claim_id || '',
      client_claim_id: upload_data.client_claim_id || null,
      client_document_type: upload_data.client_document_type || null,
    }
  };

  if (upload_data.metadata) {
    // @ts-ignore
    newPayload.upload_data.metadata = JSON.parse(upload_data.metadata);
  }
  return api.post(
    makeApiLink(`files/reupload?fetch_from=${env}`),
    newPayload
  );
};

export const getFilesPagesPresignedUploadUrl = async ({
  pageId,
  dataUri,
}: {
  pageId: string;
  dataUri: string;
}) => {
  const response = await api.get<FilePresignedUploadUrlApiResponse>(
    makeApiLink(
      `files/pages/presigned-upload-url?object_name=${encodeURIComponent(pageId)}.jpg`
    )
  );
  return {
    response: transformFilePresignedUploadUrlResponse(response),
    dataUri,
  };
};

export type FilesCrop = {
  bounding_box: {
    top_left: [number, number];
    top_right: [number, number];
    bottom_left: [number, number];
    bottom_right: [number, number];
  };
  metadata: {
    old_page_id: string;
    new_page_id: string;
  };
};

export type FilesCropPayload = {
  crops: FilesCrop[];
};

export const postFilesCrop = ({
  id,
  payload,
}: {
  id: string;
  payload: FilesCropPayload;
}) => {
  return api.post(makeApiLink(`files/${id}/crop`), payload);
};
