import { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

function ConsistencyChecksLayout() {
  const { addToQueue, removeFromQueue } = useIntercept();

  return <Outlet context={{ addToQueue, removeFromQueue }} />;
}

const queue = new Map<string, VoidFunction>();

function useIntercept() {
  const location = useLocation();
  const path = `${location.pathname}${location.search}`;
  const prevLocation = useRef(path);

  const addToQueue = (id: string, value: VoidFunction) => {
    queue.set(id, value);
  };

  const removeFromQueue = (id: string) => {
    queue.delete(id);
  };

  const clearQueue = () => {
    queue.clear();
  };

  const flushQueue = () => {
    if (queue.size) {
      queue.forEach((value) => {
        value();
      });

      clearQueue();
    }
  };

  if (prevLocation.current !== path) {
    flushQueue();
    prevLocation.current = path;
  }

  return {
    addToQueue,
    removeFromQueue,
  };
}

export default ConsistencyChecksLayout;
