import type { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { WithTranslationRoot } from 'components/with-translation.tsx';
import { DASHBOARD_PAGE } from 'constants/translation-keys.ts';
import { NAVBAR } from 'theme/layout.ts';

import { NavBarButton } from './NavBarButton.tsx';
import { NavBarLarge } from './NavBarLarge.tsx';
import { NavBarMini } from './NavBarMini.tsx';

interface NavBarV2Props {
  isMiniNavBarOpen: boolean;
  setLocalStore: Dispatch<SetStateAction<boolean>>;
  toggleMiniNavbar: VoidFunction;
}

function NavBarV2({
  isMiniNavBarOpen,
  setLocalStore,
  toggleMiniNavbar,
}: NavBarV2Props) {
  return (
    <WithTranslationRoot namespace={DASHBOARD_PAGE}>
      <>
        <Box
          sx={{
            flexShrink: 0,
            transition: ({ transitions }) =>
              transitions.create('width', {
                duration: transitions.duration.shorter,
              }),
            width: isMiniNavBarOpen
              ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              : NAVBAR.DASHBOARD_WIDTH,
          }}
        >
          <Paper
            sx={{
              bgcolor: 'background.default',
              borderColor: 'divider',
              borderWidth: 1,
              borderRightStyle: 'solid',
              inset: 0,
              position: 'fixed',
              width: isMiniNavBarOpen
                ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
                : NAVBAR.DASHBOARD_WIDTH,
              zIndex: ({ zIndex }) => zIndex.drawer - 1,
            }}
          >
            {isMiniNavBarOpen ? <NavBarMini /> : <NavBarLarge />}
          </Paper>
        </Box>

        <NavBarButton
          isMiniNavBarOpen={isMiniNavBarOpen}
          setLocalStore={setLocalStore}
          toggleMiniNavbar={toggleMiniNavbar}
        />
      </>
    </WithTranslationRoot>
  );
}

export { NavBarV2 };
