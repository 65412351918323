import type { ChangeEvent } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  DialogContentTextWithTranslation,
  DialogTitleWithTranslation,
  LoadingButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import { CLOSE_ICON, SQUARE_MINUS_ICON } from 'constants/public-icons';

import type { RejectDialogProps } from './types.ts';
import { OTHER_REASON } from './constants.ts';
import { RejectReasonDropDown } from './RejectReasonDropDown.tsx';
import { StartIcon } from './StartIcon.tsx';

function RejectDialog({
  closeRejectModal,
  handleReject,
  isOpen,
  loading,
}: RejectDialogProps) {
  const { t } = useTranslationRoot();
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [error, setError] = useState<{ invalid: boolean; message?: string }>({
    invalid: false,
  });

  function clearError() {
    setError({ invalid: false, message: undefined });
  }

  function handleOtherReasonChange(event: ChangeEvent<HTMLInputElement>) {
    setOtherReason(event.currentTarget.value);
  }

  function handleChange(event: SelectChangeEvent) {
    clearError();
    setReason(event.target.value);
  }

  function onReject() {
    if (reason) {
      const text =
        reason === OTHER_REASON ? `${t(reason)}: ${otherReason}` : reason;
      handleReject(text);
    } else {
      setError({
        invalid: true,
        message: 'rejectReason.errorMessage',
      });
    }
  }

  function closeModal() {
    closeRejectModal();
    setReason('');
    clearError();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="reject-dialog-title"
    >
      <DialogTitleWithTranslation
        id="reject-dialog-title"
        i18nKey="rejectDialog.title"
      />
      <DialogContent>
        <DialogContentTextWithTranslation
          id="reject-dialog-description"
          sx={{ mb: 2 }}
          i18nKey="rejectDialog.description"
        />
        <Stack spacing={3}>
          <RejectReasonDropDown
            error={error}
            onChange={handleChange}
            value={reason}
          />
          {reason === OTHER_REASON ? (
            <TextField
              id="reason-freetext"
              label={t('rejectReason.otherReason')}
              variant="outlined"
              autoFocus
              onChange={handleOtherReasonChange}
              value={otherReason}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButtonWithTranslation
          variant="contained"
          color="error"
          startIcon={
            <StartIcon height={17} src={SQUARE_MINUS_ICON} width={17} />
          }
          onClick={onReject}
          loading={loading}
          autoFocus
          i18nKey="rejectDialog.rejectDocument"
        />
        <LoadingButtonWithTranslation
          variant="outlined"
          color="inherit"
          startIcon={<StartIcon height={17} src={CLOSE_ICON} width={17} />}
          onClick={closeModal}
          i18nKey="rejectDialog.cancelButton"
        />
      </DialogActions>
    </Dialog>
  );
}

export { RejectDialog };
