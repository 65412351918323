import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { BOOK_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { FILES_PAGE } from 'constants/translation-keys';

const path = '/f';
const Files = loadable(lazy(() => import('./Files.tsx')));

const filesRoute = {
  path,
  createPath: () => createPath({ pathname: path }),
  icon: BOOK_ICON,
  element: <Files />,
  permission: ROUTE_PERMISSIONS.files,
  i18nNamespace: FILES_PAGE,
};

export default filesRoute;
