import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { generalConfig } from 'config.ts';

import { Version } from './Version.tsx';
import { Copyright } from './Copyright.tsx';
import { NavBarLogo } from './NavBarLogo.tsx';
import { NavListV2 } from './NavListV2.tsx';
import { AccountButtonV2 } from './AccountButtonV2.tsx';
import { LanguageButtonV2 } from './LanguageButtonV2.tsx';

function NavBarLarge() {
  const { disableNavDocuments } = generalConfig;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        height: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack sx={{ maxHeight: 'calc(100% - 169px)', width: 1 }}>
        <NavBarLogo />

        <NavListV2 removeDocuments={disableNavDocuments} />
      </Stack>

      <Stack
        spacing={1}
        useFlexGap
        sx={{
          px: 4,
          width: 1,
        }}
      >
        <LanguageButtonV2 />
        <AccountButtonV2 />
        <Box
          sx={{
            py: 1,
          }}
        >
          <Copyright />
          <Version />
        </Box>
      </Stack>
    </Stack>
  );
}

export { NavBarLarge };
