import { createSelector } from 'reselect';
import { transformUserManagementResponse } from 'api/transformers/user-management.ts';

import { selectState } from './utils.ts';

export const selectUserManagementResponse = createSelector(
  selectState,
  transformUserManagementResponse
);

export const selectUserManagementData = createSelector(
  selectUserManagementResponse,
  (response) => response.data
);

export const selectUserManagementDataLength = createSelector(
  selectUserManagementResponse,
  (response) => response.data.length
);

export const selectUserManagementTotal = createSelector(
  selectUserManagementResponse,
  (response) => response.total
);
