import { useQueryClient } from '@tanstack/react-query';
import api, { makeApiLink } from 'api/api';
import { CommentsApiResponse } from 'api/transformers/types/comments';
import { COMMENTS, CURRENT_USER, MakePage } from 'constants/api-endpoints';
import { selectCommentsData } from 'state/selectors/comments';
import { Comment, Comments } from 'types/Comments';
import { CurrentUser } from 'types/CurrentUser';
import { useGet, useOptimisticMutation, Meta } from 'utils/react-query';

function makeCommentsListUrl(
  entityId: string,
  claimId: string | null,
  createdBy?: string
) {
  const page: MakePage = {
    list: `${COMMENTS}\\?entity_id=:entity_id`,
    params: { entity_id: entityId },
  };

  if (claimId) {
    page.list = `${page.list}&claim_id=${claimId}`;
    page.params = { ...page.params, claim_id: claimId };
  }

  if (createdBy) {
    page.list = `${page.list}&created_by=${createdBy}`;
    page.params = { ...page.params, created_by: createdBy };
  }

  return page;
}

export function useGetComments({
  entityId,
  claimId,
  createdBy,
}: {
  entityId: string;
  claimId: string | null;
  createdBy?: string;
}) {
  const { list, params } = makeCommentsListUrl(entityId, claimId, createdBy);
  return useGet<CommentsApiResponse, Comment[]>({
    url: list,
    params: params,
    prefix: COMMENTS,
    select: selectCommentsData,
  });
}

function addComment({
  entityId,
  claimId,
  content,
}: {
  entityId: string;
  claimId: string | null;
  content: string;
}) {
  return api.post(makeApiLink(COMMENTS), {
    entity_id: entityId,
    claim_id: claimId,
    content: content,
  });
}

export function useAddComment({
  entityId,
  claimId,
  createdBy,
  meta,
}: {
  entityId: string;
  claimId: string | null;
  createdBy?: string;
  meta: Meta;
}) {
  const queryClient = useQueryClient();
  const { list, params } = makeCommentsListUrl(entityId, claimId, createdBy);
  const queryKey = [COMMENTS, list, params];

  return useOptimisticMutation({
    fn: addComment,
    url: COMMENTS,
    prefix: COMMENTS,
    updater: (oldData, newData) => {
      const comments = queryClient.getQueryData<Comments>(queryKey);
      const lastCommentId = comments?.comments
        ? comments.comments.length + 1
        : 1;
      const currentUser = queryClient.getQueryData<CurrentUser>([CURRENT_USER]);

      if (comments && currentUser) {
        const newComment = {
          id: Number(lastCommentId),
          entity_id: newData.entityId,
          claim_id: newData.claimId,
          content: newData.content,
          created_by: currentUser.email,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };
        queryClient.setQueryData(queryKey, {
          comments: [newComment, ...comments.comments],
        });
      }
      return oldData;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [COMMENTS],
      });
    },
    meta,
  });
}
