import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { toast } from 'components/toast';

import type { FormProps, FormValues } from './types.ts';
import { useKeyboardShortcuts } from './useKeyboardShortcuts.ts';
import { getInitialErrors } from './utils.ts';
import { generateSchema } from './form-schema.ts';
import { FormContent } from './FormContent.tsx';

function Form(props: FormProps) {
  // feature flags
  const enableValidationResults = useFeatureFlag('enableHILValidationResults');

  const { t } = useTranslationRoot();
  const formRef = useKeyboardShortcuts();

  const { defaultValues = {}, document, list, onSubmit, resources } = props;
  const { validationResults } = document;
  const initialErrors = enableValidationResults
    ? getInitialErrors({
        list,
        resources,
        validationResults,
      })
    : undefined;
  const methods = useForm<FormValues>({
    context: { list, resources },
    defaultValues,
    errors: initialErrors,
    resolver: (values, context, options) => {
      const schema = generateSchema(context);
      return yupResolver(schema)(values, context, options);
    },
  });
  const { handleSubmit, setError } = methods;

  const handleFormValidationFailure = () => {
    if (initialErrors) {
      Object.entries(initialErrors).forEach(([key, value]) => {
        if (value) {
          setError(key, { type: value.type as string, message: value.message });
        }
      });
    }
    toast.error(t('form.onSchemaFail'));
  };

  const handleFormSubmit = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(handleFormSubmit, handleFormValidationFailure)}
        ref={formRef}
      >
        <FormContent {...props} />
      </form>
    </FormProvider>
  );
}

export { Form };
