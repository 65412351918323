import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { LoadingButtonWithTranslation } from 'components/with-translation.tsx';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { NEXT_ICON } from 'constants/public-icons';

import type { NextButtonProps } from './types.ts';

function NextButton({ onNext }: NextButtonProps) {
  const showButton = useFeatureFlag('enableHILSkipDocument');

  if (showButton) {
    return (
      <LoadingButtonWithTranslation
        data-testid="next-document-button"
        variant="contained"
        color="warning"
        startIcon={<SvgIconStyle height={17} src={NEXT_ICON} width={17} />}
        onClick={onNext}
        i18nKey="form.nextDocumentBtn"
      />
    );
  }

  return null;
}

export { NextButton };
