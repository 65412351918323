import type { ReactNode } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { HilTool } from 'components/HilTool/HiLTool';
import { Page } from 'components/Page';
import {
  TypographyWithTranslation,
  WithTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { RESOURCE_ID } from 'constants/route-keys';
import { DOCUMENT_CORRECTION_PAGE } from 'constants/translation-keys';

import { DocumentCorrectionV3 } from './DocumentCorrectionV3.tsx';

function DocumentCorrection() {
  const enableHilToolV3 = useFeatureFlag('enableHilToolV3');
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');

  const params = useParams();
  const documentId = params[RESOURCE_ID];

  const Root = ({ children }: { children: ReactNode }) => (
    <WithTranslationRoot namespace={DOCUMENT_CORRECTION_PAGE}>
      <Page
        i18nKeyTitle="meta.title"
        sx={{
          px: enableNewSiteLayout && enableHilToolV3 ? 0 : 2,
          py:
            enableNewSiteLayout && enableHilToolV3
              ? 0
              : !enableNewSiteLayout && enableHilToolV3
                ? 0
                : !enableNewSiteLayout && !enableHilToolV3
                  ? 0
                  : 2,
        }}
      >
        <Outlet />
        {children}
      </Page>
    </WithTranslationRoot>
  );

  if (!documentId) {
    return (
      <Root>
        <TypographyWithTranslation
          variant="body1"
          i18nKey="common.loadDocumentError"
        />
      </Root>
    );
  }

  const Component = enableHilToolV3 ? DocumentCorrectionV3 : HilTool;

  return (
    <Root>
      <Component documentId={documentId} />
    </Root>
  );
}

export default DocumentCorrection;
