import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

// Set the workerSrc property to the path of the PDF.js worker
GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

export async function loadPDF(url: string) {
  try {
    const pdf = await getDocument(url).promise;

    return {
      numPages: pdf.numPages,
      getPage: (page: number) => pdf.getPage(page + 1),
    };
  } catch (e) {
    console.error(e);

    return {
      numPages: 0,
      getPage: () => null,
    };
  }
}
