import type { UseFormGetValues } from 'react-hook-form';

import { getRelatedField } from './get-related-fields.ts';
import type { FormValues, Resources } from '../types.ts';

interface GetSearchRequestBody {
  getValues: UseFormGetValues<FormValues>;
  otherFields: Record<string, string>;
  resources: Resources;
  searchKey: string;
  listIds: string[];
}

function getSearchRequestBody({
  getValues,
  listIds,
  otherFields,
  resources,
  searchKey,
}: GetSearchRequestBody) {
  return (inputValue: string) => {
    const searchRequestBody = Object.entries(otherFields || {}).reduce(
      (acc, [sproutaiKey, requestAttributes]) => {
        const foundId = getRelatedField({
          listsIds: { current: listIds },
          resources,
          sproutaiKey,
        });
        // the logic below is to handle the case when we need to look up to other
        // observation fields but is not needed at the moment.
        // || getRelatedField({ listsIds: fieldsListIds, resources, sproutaiKey, });

        if (foundId && getValues(foundId)) {
          return {
            ...acc,
            [requestAttributes]: getValues(foundId),
          };
        }

        return acc;
      },
      {} as Record<string, string>
    );

    return {
      [searchKey]: inputValue,
      ...searchRequestBody,
    };
  };
}

export { getSearchRequestBody };
