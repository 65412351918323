import { useFormContext, useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { toTitleCase } from 'utils/string.ts';

import { DELETED_INPUT_SUFFIX } from './constants.ts';
import type { LineItemValueProps } from './types.ts';

function LineItemValue({ id, clientKey }: LineItemValueProps) {
  const { control, getValues } = useFormContext();
  const deleted = useWatch({ control, name: `${id}${DELETED_INPUT_SUFFIX}` });

  return (
    <Stack
      key={id}
      direction="row"
      spacing={0.5}
      sx={{
        mr: 1,
        textDecoration: deleted ? 'line-through' : 'none',
        textDecorationThickness: 2,
        '&:last-child': {
          mr: 0,
        },
      }}
    >
      <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 'bold' }}>
        {toTitleCase(clientKey)}:
      </Typography>
      <Typography variant="body1" sx={{ fontSize: 14 }}>
        {getValues(id)}
      </Typography>
    </Stack>
  );
}

export { LineItemValue };
