import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Label } from 'components/Label';
import { STAGE_LABEL_COLOR_MAP, statusTranslation } from 'constants/claims';
import { CLAIMS_PAGE } from 'constants/translation-keys';

import { ComponentType } from './metadataGenerators.tsx';

interface TextProps {
  title: string;
  value: string;
}

type StageLabelProps = { content: string };

type MetadataRenderers = {
  [ComponentType.TEXT]: (content: TextProps) => ReactNode;
  [ComponentType.STAGE_LABEL]: (content: StageLabelProps) => ReactNode;
  [ComponentType.CUSTOM]: ({ content }: { content: ReactNode }) => ReactNode;
};

const metadataRenderers: MetadataRenderers = {
  [ComponentType.TEXT]({ title, value }: TextProps) {
    return (
      <Box>
        <Typography variant="body1" sx={{ fontSize: 12 }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: 10,
          }}
        >
          {value}
        </Typography>
      </Box>
    );
  },
  [ComponentType.STAGE_LABEL]({ content }: StageLabelProps) {
    return (
      <Label
        i18nKey={statusTranslation[content]}
        variant="ghost"
        namespace={CLAIMS_PAGE}
        color={STAGE_LABEL_COLOR_MAP[content]}
      />
    );
  },
  [ComponentType.CUSTOM]({ content }: { content: ReactNode }) {
    return content;
  },
};

export { metadataRenderers };
