import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { NAVBAR } from 'theme/layout.ts';

interface SiteLayoutV2Props {
  children: ReactNode;
  isMiniNavBarOpen: boolean;
  navbar: ReactNode;
}

function SiteLayoutV2({
  children,
  isMiniNavBarOpen,
  navbar,
}: SiteLayoutV2Props) {
  return (
    <Stack direction="row" sx={{ minHeight: 1 }}>
      <Box
        component="aside"
        sx={{
          position: 'relative',
        }}
      >
        {navbar}
      </Box>
      <Box
        component="main"
        sx={{
          flex: 1,
          width: `calc(100% - ${
            isMiniNavBarOpen
              ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              : NAVBAR.DASHBOARD_WIDTH
          }px)`,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export { SiteLayoutV2 };
