import type { KeyboardEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getOsKey } from 'utils/client.ts';

import type { FormProps, FormValues } from './types.ts';
import { FormContent } from './FormContent.tsx';

function Form(props: FormProps) {
  const { defaultValues, onSubmit } = props;
  const methods = useForm<FormValues>({ defaultValues });

  const handleFormSubmit = (data: FormValues) => onSubmit(data);

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      const osKey = getOsKey();
      const mainKey = osKey === 'Cmd' ? event.metaKey : event.ctrlKey;

      if (mainKey) {
        methods.handleSubmit(handleFormSubmit)();
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        onKeyDown={handleKeyDown}
      >
        <FormContent {...props} />
      </form>
    </FormProvider>
  );
}

export { Form };
