import {
  isLineLevelItem,
  isNotLineLevelItem,
  isNotValidItem,
  isValidItem,
} from 'state/selectors/documents';
import type { DocumentContent } from 'types/Documents';

import type { List, Resources } from './types';

function isValidNullItem(content: DocumentContent) {
  return typeof content.valid === 'object' && content.valid === null;
}

function getItems(conditions: (item: DocumentContent) => boolean) {
  return (list: List, resources: Resources) => {
    const newList: string[] = [];

    list.forEach((id) => {
      const item = resources[id];
      if (conditions(item)) {
        newList.push(id);
      }
    });

    return newList;
  };
}

const getIdentifiedItems = getItems(
  (item) =>
    isNotLineLevelItem(item) && (isValidItem(item) || isValidNullItem(item))
);

const getUnidentifiedItems = getItems(
  (item) => isNotLineLevelItem(item) && isNotValidItem(item)
);

function getLineItems(list: List, resources: Resources) {
  function isLineItemsAllValid(items: List) {
    return items.every((id) => resources[id].valid);
  }

  const items = getItems((item) => isLineLevelItem(item))(list, resources);

  let lineItems: { [key: string]: List } = {};
  let identifiedLineItems: List = [];
  let unidentifiedLineItems: List = [];

  items.forEach((id) => {
    const item = resources[id];
    const lineIdx = String(item.lineIdx);

    if (typeof lineItems[lineIdx] === 'undefined') {
      lineItems = {
        ...lineItems,
        [lineIdx]: [],
      };
    }

    lineItems[lineIdx].push(id);
  });

  Object.values(lineItems).forEach((items) => {
    const isValid = isLineItemsAllValid(items);

    if (isValid) {
      identifiedLineItems = [...identifiedLineItems, ...items];
    } else {
      unidentifiedLineItems = [...unidentifiedLineItems, ...items];
    }
  });

  return { unidentifiedLineItems, identifiedLineItems };
}

function initialiseItems(list: List, resources: Resources) {
  const unidentifiedItems = getUnidentifiedItems(list, resources);
  const identifiedItems = getIdentifiedItems(list, resources);
  const { unidentifiedLineItems, identifiedLineItems } = getLineItems(
    list,
    resources
  );

  return {
    identifiedItems,
    identifiedLineItems,
    unidentifiedItems,
    unidentifiedLineItems,
  };
}

function getGroupedLineItems(list: List, resources: Resources) {
  return list.reduce<{ [key: string]: List }>((a, id) => {
    const item = resources[id];
    const lineIdx = String(item.lineIdx);
    let acc: { [key: string]: string[] } = { ...a };

    // store array of ids with the same lineIdx into an object
    if (typeof acc[lineIdx] === 'undefined') {
      acc = {
        ...acc,
        [lineIdx]: [],
      };
    }

    acc[lineIdx].push(id);

    return acc;
  }, {});
}

export { getGroupedLineItems, initialiseItems };
