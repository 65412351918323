import { useEffect } from 'react';
import { ErrorBoundary } from '@rollbar/react';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { toast } from 'components/toast';
import {
  lockClaim,
  useLockClaim,
  usePatchOptimisticClaimObservations,
  useUnlockClaim,
  useValidateClaim,
} from 'state/queries/claims.ts';
import {
  getClaimLastLockedBy,
  getClaimLocked,
} from 'state/selectors/claims.ts';
import { convertArrayToObject } from 'utils/array.ts';
import {
  addToFunctionQueue,
  flushFunctionQueue,
  removeFromFunctionQueue,
} from 'utils/function-queue.ts';
import { transformKeys } from 'utils/object.ts';
import { toCamelCase } from 'utils/string.ts';

import type { FormValues, ConsistencyCheckToolProps } from './types.ts';
import { Form } from './Form.tsx';
import {
  reducer,
  updateField,
  updateValidationResults,
  useInternalState,
} from './state.ts';
import { initialiseState } from './utils/initialise-state.ts';
import { getConsistencyResults } from './utils/get-consistency-results.ts';
import { FallbackUI } from './FallbackUI.tsx';
import { getDefaultValues } from './utils/get-default-values.ts';
import { LockedDialog } from './LockedDialog.tsx';
import { validationHandler } from './utils/validation-handler.ts';
import { getConsistencyResultsError } from './utils/get-consistency-results-error.ts';
import { getAllFieldsError } from './utils/get-all-fields-error.ts';
import { transformFormToState } from './utils/transform-values.ts';

/**
 * Usage:
 * <ReviewTool claim={claim} onSuccess={onSuccess} onFailure={onFailure} />
 */

function ConsistencyCheckTool({
  claim,
  codeMappings,
  currentUser,
  observations,
  onSuccess,
}: ConsistencyCheckToolProps) {
  const { t } = useTranslationRoot();

  const { id } = claim;
  const lockClaimAction = useLockClaim(id);
  const unlockClaimAction = useUnlockClaim(id);
  const validateObservations = useValidateClaim(id);
  const patchObservations = usePatchOptimisticClaimObservations(id, {
    successMessage: t('form.submitSuccess') as string,
    loadingMessage: t('form.submitLoading') as string,
    errorMessage: (error) =>
      t('form.submitFail', {
        errorMsg:
          (error as unknown as { reason_message: string }).reason_message ||
          (error as unknown as { reason_code: string }).reason_code,
      }),
    claimId: id,
  });

  useEffect(() => {
    if (!getClaimLocked(claim)) {
      const { id } = claim;
      lockClaimAction.mutate(id);

      addToFunctionQueue(id, () => {
        unlockClaimAction.mutate({ id });
      });

      const interval = setInterval(() => {
        void lockClaim(id);
      }, 1000 * 60);

      return () => {
        flushFunctionQueue();
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastLockedBy = getClaimLastLockedBy(claim);
  const locked = getClaimLocked(claim);
  const isLockedByCurrentUser = locked && lastLockedBy === currentUser.email;
  const initialState = initialiseState(observations);
  const { dispatch, getState } = useInternalState({ claim, ...initialState });
  const defaultValues = getDefaultValues(getState());

  const onSubmit = (data: FormValues) => {
    const { id } = claim;
    const state = getState();

    const newState = Object.entries(data).reduce((acc, [fieldId, value]) => {
      const { valueType } = state.resources[fieldId];

      return reducer(
        acc,
        updateField(fieldId, transformFormToState(valueType, value))
      );
    }, state);

    const observations = Object.values(newState.observations);

    validateObservations.mutate(observations, {
      onSuccess: (response) => {
        const { results } = transformKeys(response, toCamelCase);

        const handleValidation = validationHandler({
          successFn: () =>
            patchObservations.mutate(
              { id, observations },
              {
                onSuccess: () => {
                  removeFromFunctionQueue(id);
                  onSuccess('submit', id);
                },
              }
            ),
          failureFn: () => {
            toast.error(t('form.reviewValidationErrors'));
            dispatch(updateValidationResults(results));
          },
        });

        handleValidation(results);
      },
      onError: () => {
        toast.error(t('form.submitValidationError'));
      },
    });
  };

  // TODO: refactor this
  const state = getState();
  const { fieldsListIds, resources } = state;
  const urls = Object.keys(state.documents).flatMap((id) => [
    ...Object.values(state.documents[id]),
  ]);
  const thumbnails = Object.entries(state.documents).reduce(
    (acc, [observationId, urls]) => {
      const thumbnails = Object.values(urls).map((url) => ({
        observationId,
        title: state.observations[observationId].title,
        url,
      }));

      return [...acc, ...thumbnails];
    },
    [] as { observationId: string; title: string; url: string }[]
  );

  const codeMappingsDict = convertArrayToObject(
    codeMappings || [],
    'triggerField'
  );

  // this is important as we don't want the form to randomly change
  // the validation results may get updated so we'll react to that
  const args = {
    claim: state.claim,
    fieldsListIds,
    resources,
  };

  return (
    <>
      <Form
        codeMappings={codeMappingsDict}
        defaultValues={defaultValues}
        isLockedByCurrentUser={isLockedByCurrentUser}
        isSubmitting={patchObservations.isPending}
        onSubmit={onSubmit}
        urls={urls}
        thumbnails={thumbnails}
        {...state}
        allFieldsErrors={getAllFieldsError(args)}
        consistencyListIds={getConsistencyResults(args)}
        consistencyResultsErrors={getConsistencyResultsError(args)}
      />
      <LockedDialog
        lastLockedBy={lastLockedBy}
        lockedByCurrentUser={isLockedByCurrentUser}
        locked={locked}
      />
    </>
  );
}

function WithErrorBoundary(props: ConsistencyCheckToolProps) {
  return (
    <ErrorBoundary fallbackUI={FallbackUI}>
      <ConsistencyCheckTool {...props} />
    </ErrorBoundary>
  );
}

export { WithErrorBoundary as ConsistencyCheckTool };
