import { Outlet } from 'react-router-dom';

import { HitLContextProvider } from './hitl-context';

function DocumentCorrectionsLayout() {
  return (
    <HitLContextProvider>
      <Outlet />
    </HitLContextProvider>
  );
}

export default DocumentCorrectionsLayout;
