import { generalConfig } from 'config';
import { useMemo, useState } from 'react';
import { ErrorBoundary } from '@rollbar/react';
import dayjs from 'dayjs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import type { Metric } from 'types/Metrics';
import { Page } from 'components/Page';
import { GenericErrorFallback } from 'components/GenericErrorFallback';
import { DateRangePickerV2 } from 'components/DateRangePickerV2.tsx';
import { WithTranslationRoot } from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { PERFORMANCE_PAGE } from 'constants/translation-keys';
import { useGetMetrics } from 'state/queries/metrics';
import { convertArrayToObject } from 'utils/array';

import type { Resources } from './types';
import { OtherPerformanceMetrics } from './OtherPerformanceMetrics';
import { DEFAULT_ITEM } from './DocumentTypeSelector';
import { ClaimsPerformance } from './ClaimsPerformance';
import { OcrPerformance } from './OcrPerformance';
import { HumanInTheLoop } from './HumanInTheLoop';
import { DocumentFilterForm } from './DocumentFilterForm';
import { TrafficLight } from './TrafficLight';
import { DocumentPerformance } from './DocumentPerformance';

type PerformanceFiltersState = {
  startDate: Date;
  endDate: Date;
  documentType: string;
};

const currentStartTime = dayjs().toDate();
currentStartTime.setUTCHours(0, 0, 0, 0);
const currentEndTime = dayjs().toDate();
currentEndTime.setUTCHours(23, 59, 59);

const ocrMetrics = [
  'AvgOcrIdentifiedFields',
  'AvgInitialDocumentProcessingTime',
  'AvgOcrIdentifiedKeyFields',
];
const claimsTotalMetrics = [
  'SumClaimsDoneFirstTime',
  'SumClaimsDone',
  'AvgClaimsNoAction5d',
];
const claimsMetrics = [
  'SumClaimsDoneLT24h',
  'SumClaimsDoneLT5d',
  'SumClaimsDoneGT5d',
];
const documentMetrics = [
  'SumDocumentsAccepted',
  'SumDocumentsPresented',
  'SumDocumentsProcessedFirstTime',
];
const hilTopMetrics = ['AvgOverallProcessingTime'];
const hilBottomMetrics = [
  'SumAvgDocumentsInHil',
  'SumDocumentsSentToHil',
  'SumDocumentsFromHilToDone',
];
const trafficLightMetrics = [
  'SumRecommendedAction00',
  'SumRecommendedAction10',
  'SumRecommendedAction20',
  'SumRecommendedAction21',
  'SumRecommendedAction30',
  'SumRecommendedAction311',
  'SumRecommendedAction312',
  'SumRecommendedAction32',
];

function filterMetrics(list: string[]) {
  return ({ name }: Metric) => list.includes(name);
}

function getName({ name }: Metric) {
  return name;
}

function Performance() {
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');
  const { showClaimsPerformance } = generalConfig;

  const [filters, setFilters] = useState<PerformanceFiltersState>({
    startDate: currentStartTime,
    endDate: currentEndTime,
    documentType: 'all',
  });

  const { data, isError, isPending, isSuccess } = useGetMetrics({
    startTime: filters.startDate,
    endTime: filters.endDate,
    documentType:
      filters.documentType === DEFAULT_ITEM.value
        ? undefined
        : filters.documentType,
  });

  function updateStartDate(value: Date) {
    setFilters((state) => ({
      ...state,
      startDate: value,
    }));
  }
  function updateEndDate(value: Date) {
    setFilters((state) => ({
      ...state,
      endDate: value,
    }));
  }
  function updateDocumentType(value: string) {
    setFilters((state) => ({
      ...state,
      documentType: value,
    }));
  }

  const { endDate, startDate } = filters;

  const documentFilterComponent = (
    <DocumentFilterForm
      documentType={filters.documentType}
      updateDocumentType={updateDocumentType}
    />
  );

  const dateRangeComponent = (
    <DateRangePickerV2
      updateStartDate={updateStartDate}
      updateEndDate={updateEndDate}
      startDate={startDate}
      endDate={endDate}
      size="lg"
    />
  );

  const {
    claimsList,
    claimsTotalList,
    documentList,
    hilList,
    hilMainList,
    ocrList,
    resources,
    trafficLightList,
  } = useMemo(() => {
    if (isSuccess && data) {
      const result: {
        [key: string]: any;
        resources: Resources;
      } = {
        list: data.map(({ name }) => name),
        resources: convertArrayToObject(data, 'name'),
      };

      const listMap = {
        claimsList: claimsMetrics,
        claimsTotalList: claimsTotalMetrics,
        documentList: documentMetrics,
        hilList: hilTopMetrics,
        hilMainList: hilBottomMetrics,
        ocrList: ocrMetrics,
        trafficLightList: trafficLightMetrics,
      };

      Object.entries(listMap).forEach(([key, list]) => {
        result[key] = data.filter(filterMetrics(list)).map(getName);
      });

      return result;
    }

    return {
      claimsList: [],
      claimsTotalList: [],
      documentList: [],
      hilList: [],
      hilMainList: [],
      list: [],
      ocrList: [],
      resources: {},
      trafficLightList: [],
    };
  }, [data, isSuccess]);

  return (
    <ErrorBoundary fallbackUI={GenericErrorFallback}>
      <WithTranslationRoot namespace={PERFORMANCE_PAGE}>
        <Page
          sx={{
            pt: enableNewSiteLayout ? 3 : 0,
            '@page': {
              height: '210mm',
              my: 10,
              mx: 0,
              pageBreakAfter: 'always',
              width: '297mm',
            },
            '@media print': {
              px: 15,
            },
          }}
          i18nKeyTitle="meta.title"
        >
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                size={{
                  xs: 12,
                  md: 8,
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                    sx={{
                      display: {
                        md: 'none',
                      },
                    }}
                  >
                    {documentFilterComponent}
                  </Grid>

                  <Grid
                    sx={{
                      display: {
                        md: 'none',
                      },
                    }}
                    size={12}
                  >
                    {dateRangeComponent}
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 12,
                    }}
                  >
                    <OcrPerformance
                      list={ocrList}
                      resources={resources}
                      isSuccess={isSuccess}
                    />
                  </Grid>
                  {showClaimsPerformance ? ( // hide this for Axa
                    <Grid
                      size={{
                        xs: 12,
                        md: 12,
                      }}
                    >
                      <TrafficLight
                        list={trafficLightList}
                        resources={resources}
                      />
                    </Grid>
                  ) : null}
                  {showClaimsPerformance ? ( // hide this for Axa
                    <Grid
                      size={{
                        xs: 12,
                        md: 12,
                      }}
                    >
                      <ClaimsPerformance
                        list={claimsList}
                        totalList={claimsTotalList}
                        isPending={isPending}
                        resources={resources}
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    size={{
                      xs: 12,
                      md: 12,
                    }}
                  >
                    <OtherPerformanceMetrics
                      data={data}
                      isSuccess={isSuccess}
                      isError={isError}
                      isLoading={isPending}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                size={{
                  xs: 12,
                  md: 4,
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block',
                      },
                    }}
                    size={12}
                  >
                    {documentFilterComponent}
                  </Grid>
                  <Grid
                    sx={{
                      display: {
                        xs: 'none',
                        md: 'block',
                      },
                    }}
                    size={12}
                  >
                    {dateRangeComponent}
                  </Grid>

                  <Grid size={12}>
                    <DocumentPerformance
                      list={documentList}
                      resources={resources}
                    />
                  </Grid>

                  <Grid size={12}>
                    <HumanInTheLoop
                      list={hilList}
                      mainList={hilMainList}
                      isSuccess={isSuccess}
                      resources={resources}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </WithTranslationRoot>
    </ErrorBoundary>
  );
}

export default Performance;
