import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { Layout } from 'components/CommonTool/Layout.tsx';

import type { FormProps } from './types.ts';
import { useFilterItems } from './useFilterItems.ts';
import { Header } from './Header.tsx';
import { ItemFiltersToolbar } from './ItemFiltersToolbar.tsx';
import { FormItems } from './FormItems.tsx';
import { getAllSkippedIds } from './utils.ts';

function FormContent({
  canvasNode,
  defaultValues = {},
  document,
  historicalResources,
  isRejecting,
  isSaving,
  isSubmitting,
  list,
  lockedByCurrentUser,
  onDocumentTypeChange,
  onNext,
  onReject,
  onSave,
  resources,
  updateStore,
}: FormProps) {
  const filterItems = useFilterItems();
  const hasItems = list.length > 0;

  const { getValues, setValue } = useFormContext();

  const handleFormSave = () => {
    const data = getValues();
    onSave(data);
  };

  const setAllSkipped = (value: boolean) => {
    const allSkippedIds = getAllSkippedIds(defaultValues);
    allSkippedIds.forEach((id) => setValue(id, value));
  };

  return (
    <Layout
      header={
        <Header
          submitDisabled={
            isSaving || isRejecting || !hasItems || !lockedByCurrentUser
          }
          document={document}
          isRejecting={isRejecting}
          isSaving={isSaving}
          isSubmitting={isSubmitting}
          list={list}
          onDocumentTypeChange={onDocumentTypeChange}
          onNext={onNext}
          onReject={onReject}
          onSave={handleFormSave}
          rejectDisabled={isSaving || isSubmitting || !lockedByCurrentUser}
          resources={resources}
          saveDisabled={
            isSubmitting || isRejecting || !hasItems || !lockedByCurrentUser
          }
        />
      }
      body={
        <Grid container sx={{ height: 1, width: 1 }}>
          <Grid size={7}>{canvasNode}</Grid>
          <Grid
            size={5}
            sx={{
              borderLeft: ({ palette }) => `1px solid ${palette.divider}`,
              height: 1,
              position: 'relative',
            }}
          >
            <Stack>
              {hasItems ? (
                <>
                  <ItemFiltersToolbar
                    filterItemsBy={filterItems.filterItemsBy}
                    setFilterItemsBy={filterItems.setFilterItemsBy}
                    setAllSkipped={setAllSkipped}
                  />
                  <FormItems
                    filterItemsBy={filterItems.filterItemsBy}
                    historicalResources={historicalResources}
                    list={list}
                    resources={resources}
                    updateStore={updateStore}
                  />
                </>
              ) : (
                <Box sx={{ p: 2 }}>
                  <TypographyWithTranslation
                    i18nKey="form.noItems"
                    variant="body1"
                  />
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}

export { FormContent };
