import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import performanceCreateUserRoute from 'pages/PerformanceCreateUser/performanceCreateUser.route';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { PERFORMANCE_MANAGE_USERS_PAGE } from 'constants/translation-keys';
import { USER_MANAGEMENT } from 'constants/public-icons';

const path = '/u';
const PerformanceManageUsersPage = loadable(
  lazy(() => import('pages/PerformanceManageUsers/PerformanceManageUsers'))
);

const performanceManageUsersRoute = {
  path,
  createPath: () => generatePath(path),
  icon: USER_MANAGEMENT,
  children: [
    {
      index: true,
      element: <PerformanceManageUsersPage />,
      i18nNamespace: PERFORMANCE_MANAGE_USERS_PAGE,
    },
    performanceCreateUserRoute,
  ],
  permission: ROUTE_PERMISSIONS.miManageUser,
};

export default performanceManageUsersRoute;
