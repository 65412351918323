import Tooltip from '@mui/material/Tooltip';
import {
  LoadingButtonWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { CIRCLE_CHECK_ICON } from 'constants/public-icons';
import { getOsKey } from 'utils/client.ts';

interface SubmitButtonProps {
  disabled?: boolean;
  loading?: boolean;
}

function SubmitButton({ disabled, loading }: SubmitButtonProps) {
  const { t } = useTranslationRoot();
  const osKey = getOsKey();
  const isApple = osKey === 'Cmd';

  return (
    <Tooltip
      title={t('form.shortcutKeySubmit', { key: isApple ? '⌘' : 'Ctrl' })}
    >
      <span>
        <LoadingButtonWithTranslation
          variant="contained"
          color="primary"
          data-testid="submit-button"
          disabled={disabled}
          loading={loading}
          loadingPosition="start"
          i18nKey="form.submitButton"
          startIcon={
            <SvgIconStyle height={17} src={CIRCLE_CHECK_ICON} width={17} />
          }
          type="submit"
          sx={{
            boxShadow: (theme) => theme.shadows[5],
          }}
        />
      </span>
    </Tooltip>
  );
}

export { SubmitButton };
