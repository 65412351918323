import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TOOL_LAYOUT_HEADER_HEIGHT } from 'components/CommonTool/constants.ts';

interface BodyLayoutProps {
  canvas: ReactNode;
  fields: ReactNode;
}

function BodyLayout({ canvas, fields }: BodyLayoutProps) {
  return (
    <Stack direction="row" sx={{ height: 1 }}>
      <Box sx={{ flex: '1 1 calc(100% - 500px)', overflow: 'hidden' }}>
        {canvas}
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.neutral',
          flexBasis: '40%',
          height: `calc(100vh - ${TOOL_LAYOUT_HEADER_HEIGHT}px)`,
          maxWidth: 500,
        }}
      >
        {fields}
      </Box>
    </Stack>
  );
}

export { BodyLayout };
