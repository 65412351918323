import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { CONFIGURATION_PAGE } from 'constants/translation-keys';
import { Page } from 'components/Page';
import { WithTranslationRoot } from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { AWS_APPCONFIG_ICON, USER_MANAGEMENT } from 'constants/public-icons.ts';
import appConfigRoute from 'pages/AppConfig/appConfigRoute.route';
import userManagementRoute from 'pages/UserManagement/userManagement.route';

function ConfigurationPage() {
  const navigate = useNavigate();

  return (
    <WithTranslationRoot namespace={CONFIGURATION_PAGE}>
      <Page i18nKeyTitle="meta.title">
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ mb: 2 }}>
            Configuration
          </Typography>
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Card
                sx={{
                  cursor: 'pointer',
                  p: 2,
                  textAlign: 'center',
                }}
                onClick={() => {
                  navigate(appConfigRoute.createPath());
                }}
              >
                <Typography variant="body1">AWS AppConfig</Typography>
                <SvgIconStyle
                  src={AWS_APPCONFIG_ICON}
                  height={100}
                  width={100}
                />
              </Card>
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <Card
                sx={{
                  cursor: 'pointer',
                  p: 2,
                  textAlign: 'center',
                }}
                onClick={() => {
                  navigate(userManagementRoute.createPath());
                }}
              >
                <Typography variant="body1">User Management</Typography>
                <SvgIconStyle src={USER_MANAGEMENT} height={100} width={100} />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </WithTranslationRoot>
  );
}

export default ConfigurationPage;
