import { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CLASSIFICATION_PAGE } from 'constants/translation-keys.ts';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { LoadingUI } from 'components/ClassificationTool/LoadingUI.tsx';
import { RETRY_ICON } from 'constants/public-icons.ts';
import { useGetFirstPage } from 'state/queries/pages.ts';
import classificationRoute from 'pages/Classification/classification.route.tsx';
import { Header } from 'components/CommonTool/Header';
import { Layout } from 'components/CommonTool/Layout.tsx';
import { Metadata } from 'components/CommonTool/Metadata';
import { text } from 'components/CommonTool/metadataGenerators.tsx';

function Classification() {
  const { t } = useTranslationRoot(CLASSIFICATION_PAGE);
  const { data, isError, isPending, refetch } = useGetFirstPage();

  const header = (
    <Header
      leftColumn={
        <Metadata
          getContent={[
            text({
              title: t('form.task'),
              value: t('form.pageClassification'),
            }),
          ]}
        />
      }
      rightColumn={null}
    />
  );

  let body: ReactNode = null;

  if (isError) {
    body = (
      <Box
        sx={{
          textAlign: 'center',
          p: 2,
        }}
      >
        <Typography variant="body2">{t('form.failedToLoadPage')}</Typography>
        <Button
          onClick={() => refetch()}
          startIcon={<SvgIconStyle src={RETRY_ICON} />}
          variant="contained"
          sx={{ mt: 1 }}
        >
          {t('form.tryAgain')}
        </Button>
      </Box>
    );
  } else if (isPending) {
    body = (
      <Box>
        <LoadingUI />
      </Box>
    );
  } else if (!data) {
    body = (
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="body2">
          {t('form.noMorePagesToClassify')}
        </Typography>
      </Box>
    );
  }

  if (!body && data?.id) {
    return <Navigate to={classificationRoute.createPath(data.id)} />;
  } else {
    return <Layout header={header} body={body} />;
  }
}

export default Classification;
