import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { HiddenInput } from 'components/form/HiddenInput.tsx';
import { ScrollableContainer } from 'components/ScrollableContainer.tsx';
import { TypographyWithTranslation } from 'components/with-translation.tsx';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import {
  isHighLevelItem,
  isLineLevelItem,
  isNotLineLevelItem,
  isNotValidItem,
} from 'state/selectors/documents.ts';
import { not } from 'utils/not.ts';

import type { FormItemsProps } from './types.ts';
import { INVALID, VALID } from './constants.ts';
import { getItem, getItemDocumentType, getItems } from './utils.ts';
import { InputField } from './InputField.tsx';
import { LineItems } from './LineItems.tsx';

function FormItems({
  filterItemsBy,
  historicalResources,
  list,
  resources,
  updateStore,
}: FormItemsProps) {
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');

  const {
    hasValidItems,
    hasInvalidItems,
    highLevelItemsIds,
    documentTypeItemsIds,
    documentLevelItemsIds,
    lineLevelItemsId,
  } = useMemo(() => {
    const documentItemsIds = getItems(isNotLineLevelItem)({ list, resources });
    const findHighLevelItemIds = getItems(isHighLevelItem);
    const findDocumentTypeItemIds = getItems(getItemDocumentType);
    const findLineLevelItemIds = getItems(isLineLevelItem);
    const args = {
      list: documentItemsIds,
      resources,
    };

    const findItem = getItem(resources);

    return {
      hasValidItems: getItems(not(isNotValidItem))(args).length > 0,
      hasInvalidItems: getItems(isNotValidItem)(args).length > 0,
      highLevelItemsIds: findHighLevelItemIds(args),
      documentTypeItemsIds: findDocumentTypeItemIds(args),
      documentLevelItemsIds: documentItemsIds.filter(
        (id) =>
          !findItem(isHighLevelItem)(id) && !findItem(getItemDocumentType)(id)
      ),
      lineLevelItemsId: findLineLevelItemIds({ list, resources }),
    };
  }, [list, resources]);

  return (
    <>
      {highLevelItemsIds.map((id) => (
        <HiddenInput key={id} name={id} />
      ))}
      {documentTypeItemsIds.map((id) => (
        <HiddenInput key={id} name={id} />
      ))}
      <ScrollableContainer offset={enableNewSiteLayout ? 0 : 24}>
        <Stack sx={{ p: 2, pt: 0 }}>
          {filterItemsBy === INVALID && !hasInvalidItems && (
            <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
          )}
          {filterItemsBy === VALID && !hasValidItems && (
            <TypographyWithTranslation i18nKey="form.noItems" variant="body1" />
          )}
          {documentLevelItemsIds.map((id) => (
            <InputField
              key={id}
              filterItemsBy={filterItemsBy}
              historicalResources={historicalResources}
              id={id}
              resources={resources}
            />
          ))}
          <LineItems
            filterItemsBy={filterItemsBy}
            historicalResources={historicalResources}
            list={lineLevelItemsId}
            resources={resources}
            updateStore={updateStore}
          />
        </Stack>
      </ScrollableContainer>
    </>
  );
}

export { FormItems };
