import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTooltip } from 'components/customHooks/useTooltip';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { BASELINE_RECEIPT_ICON } from 'constants/public-icons';
import type { Document } from 'types/Documents';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { formatDocumentDate } from 'utils/date';

interface MetadataTooltipProps {
  document: Document;
}

function MetadataTooltip({ document }: MetadataTooltipProps) {
  const { t } = useTranslationRoot();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const {
    clientClaimId,
    clientDocumentId,
    clientDocumentType,
    clientSessionId,
    correlationId,
    lastUpdatedBy,
    lastUpdatedOn,
  } = document;

  const fields = [
    {
      label: 'metadataTooltip.correlationId',
      value: correlationId,
    },
    {
      label: 'metadataTooltip.clientDocumentId',
      value: clientDocumentId,
    },
    {
      label: 'metadataTooltip.clientSessionId',
      value: clientSessionId,
    },
    {
      label: 'metadataTooltip.clientClaimId',
      value: clientClaimId,
    },
    {
      label: 'metadataTooltip.documentType',
      value: clientDocumentType,
    },
    {
      label: 'metadataTooltip.operator',
      value: lastUpdatedBy,
    },
    {
      label: 'metadataTooltip.lastUpdatedOn',
      value: formatDocumentDate(lastUpdatedOn),
    },
  ];

  return (
    <>
      <IconButtonAnimate
        aria-label={t('metadataTooltip.title') as string}
        size="large"
        isOpen={isOpen}
        onClick={handleOpen}
      >
        <SvgIconStyle src={BASELINE_RECEIPT_ICON} />
      </IconButtonAnimate>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isOpen}
        onClose={handleClose}
        anchorEl={element}
        sx={{ mt: 1.5 }}
      >
        <Box
          sx={{
            maxWidth: '425px',
            minWidth: { md: '375px', sm: '320px' },
            p: 2,
            width: 1,
          }}
        >
          <TypographyWithTranslation
            i18nKey="metadataTooltip.title"
            variant="h6"
            fontWeight="normal"
            sx={{
              mb: 2,
            }}
          />
          <Stack spacing={1}>
            {fields.map(({ label, value }) => (
              <Stack
                key={label}
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                <TypographyWithTranslation
                  i18nKey={label}
                  variant="body1"
                  sx={{ fontSize: 12, mr: 0.5 }}
                />
                <Typography variant="body1" sx={{ fontSize: 12 }}>
                  {value || t('metadataTooltip.noValueSupplied')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

export { MetadataTooltip };
