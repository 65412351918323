import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocales } from 'locales/useLocales';
import { useTooltip } from 'components/customHooks/useTooltip';
import { IconButtonAnimate } from 'components/IconButtonAnimate';
import { LanguageIcon } from 'components/icons/LanguageIcon';

import { LanguageTooltip } from './LanguageTooltip.tsx';

interface LanguageButtonV2Props {
  mini?: boolean;
}

function LanguageButtonV2({ mini }: LanguageButtonV2Props) {
  const { currentLanguage } = useLocales();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  const button = (
    <IconButtonAnimate
      isOpen={isOpen}
      onClick={handleOpen}
      data-testid="language-selector-button"
    >
      <LanguageIcon
        colors={currentLanguage.colors}
        text={currentLanguage.value}
        sx={{
          borderRadius: '50%',
          height: 40,
          width: 40,
        }}
      />
    </IconButtonAnimate>
  );

  return (
    <>
      {mini ? (
        button
      ) : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          role="button"
          onClick={handleOpen}
          sx={{
            borderRadius: 24,
            cursor: 'pointer',
            transition: ({ transitions }) =>
              transitions.create('background-color', {
                duration: transitions.duration.shortest,
              }),
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          {button}
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {currentLanguage.label}
          </Typography>
        </Stack>
      )}

      <LanguageTooltip
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        disableArrow
        element={element}
        handleClose={handleClose}
        isOpen={isOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      />
    </>
  );
}

export { LanguageButtonV2 };
