import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface InputWrapperProps {
  title: string;
  children: ReactNode;
}

function InputWrapper({ title, children }: InputWrapperProps) {
  return (
    <Box>
      <Typography variant="subtitle2">{title}</Typography>
      {children}
    </Box>
  );
}

export { InputWrapper };
