import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { CONFIGURATION_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CONFIGURATION_PAGE } from 'constants/translation-keys';
import appConfigRoute from 'pages/AppConfig/appConfigRoute.route.tsx';
import userManagementRoute from 'pages/UserManagement/userManagement.route.tsx';

import ConfigurationLayout from './ConfigurationLayout.tsx';

const configurationPath = '/configuration';
const ConfigurationPage = loadable(
  lazy(() => import('./ConfigurationPage.tsx'))
);

const configurationRoute = {
  path: configurationPath,
  createPath: () => generatePath(configurationPath),
  element: <ConfigurationLayout />,
  icon: CONFIGURATION_ICON,
  permission: ROUTE_PERMISSIONS.configuration,
  i18nNamespace: CONFIGURATION_PAGE,
  children: [
    {
      element: <ConfigurationPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.configuration,
      i18nNamespace: CONFIGURATION_PAGE,
    },
    appConfigRoute,
    userManagementRoute,
  ],
};

export default configurationRoute;
