import type { SelectChangeEvent } from '@mui/material/Select';
import { ControllerWithDropDown } from 'components/form/ControllerWithDropDown';
import { COMMON } from 'constants/translation-keys';
import { useRoles } from 'components/customHooks/useRoles';

import { useTranslationRoot } from './with-translation';

interface RoleSelectorProps {
  defaultValue?: string;
  name: string;
  onChange?: (e: SelectChangeEvent<HTMLInputElement>) => void;
}

function RoleSelectorWithController(props: RoleSelectorProps) {
  const { t } = useTranslationRoot(COMMON);
  const items = useRoles();

  return (
    <ControllerWithDropDown
      {...props}
      htmlFor="role-selector"
      label={t('rolesDropdown.label')}
      menuItems={items}
      namespace={COMMON}
      size="medium"
      name={props.name}
    />
  );
}

export { RoleSelectorWithController };
