import type { Page } from 'types/Pages.ts';
import { toast } from 'components/toast';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { Metadata } from 'components/CommonTool/Metadata.tsx';
import { text } from 'components/CommonTool/metadataGenerators.tsx';
import { postPagesReclassify } from 'state/queries/pages.ts';

import { Form } from './Form.tsx';

interface ClassificationToolProps {
  page: Page;
  onSuccess: (pageId: string) => void;
  onFailure?: (pageId: string, error: Error) => void;
}
function ClassificationTool({
  page,
  onSuccess,
  onFailure,
}: ClassificationToolProps) {
  const { t } = useTranslationRoot();

  const handleSubmit = ({ classification }: { classification?: string }) => {
    // fallback to the current classification if not provided
    const payload = [
      {
        pageId: page.id,
        classification: classification
          ? {
              pageType: classification,
            }
          : page.classification?.pageType
            ? { pageType: page.classification.pageType }
            : null,
      },
    ];

    toast.promise(postPagesReclassify(payload), {
      loading: t('form.submitting'),
      success: () => {
        onSuccess(page.id);
        return t('form.success');
      },
      error: (error) => {
        onFailure?.(page.id, error);
        return t('form.error');
      },
    });
  };

  const metadataNode = (
    <Metadata
      getContent={[
        text({
          title: t('form.task'),
          value: t('form.pageClassification'),
        }),
        text({
          title: t('form.clientClaimId'),
          value: page.clientClaimId,
        }),
      ]}
    />
  );

  return (
    <Form
      classification={page.classification?.pageType}
      url={page.url}
      metadataNode={metadataNode}
      onSubmit={handleSubmit}
    />
  );
}

export { ClassificationTool };
