import type { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import type { Document } from 'types/Documents';

import { MetadataTooltip } from './MetadataTooltip';
import { GoBackToClaimButton } from './GoBackToClaimButton.tsx';

interface DocumentToolbarProps {
  children?: ReactNode;
  document: Document;
  fromClaimView?: boolean;
}

function DocumentToolbar({
  children,
  document,
  fromClaimView,
}: DocumentToolbarProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: ({ palette }) => palette.background.paper,
        borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
        height: 85,
        px: 2,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MetadataTooltip document={document} />
        {children}
      </Stack>
      {!fromClaimView && <GoBackToClaimButton claimId={document.claimId} />}
    </Stack>
  );
}

export { DocumentToolbar };
