import { createSelector } from 'reselect';
import { transformCommentsResponse } from 'api/transformers/comments';

import { selectState } from './utils';

export const selectCommentsResponse = createSelector(
  selectState,
  transformCommentsResponse
);

export const selectCommentsData = createSelector(
  selectCommentsResponse,
  ({ comments }) => comments || []
);
