import type { ContentWithId, ListIds, Resources } from '../types.ts';

export function getItems(conditions: (item: ContentWithId) => boolean) {
  return ({ list, resources }: { list: ListIds; resources: Resources }) =>
    list.reduce<string[]>((acc, id) => {
      const item = resources[id];
      if (conditions(item)) {
        acc.push(id);
      }
      return acc;
    }, []);
}
