import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslationRoot } from 'components/with-translation';
import { COMMON } from 'constants/translation-keys';

import { useRoles } from './customHooks/useRoles';

function RoleSelector({
  name,
  defaultRole,
  onChange,
}: {
  name: string;
  defaultRole: string;
  onChange: (key: string, value: string) => void;
}) {
  const { t } = useTranslationRoot(COMMON);
  const roles = useRoles();

  return (
    <Select
      name={name}
      sx={{ fontSize: 14, width: '100%' }}
      size="small"
      value={defaultRole}
      onChange={(e) => onChange(name, e.target.value)}
    >
      {roles?.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
}

export { RoleSelector };
