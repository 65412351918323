import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { FILE_DOCUMENT_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { PAGES_PAGE } from 'constants/translation-keys';

const path = '/pa';
const Pages = loadable(lazy(() => import('./Pages.tsx')));

const pagesRoute = {
  path,
  createPath: () => createPath({ pathname: path }),
  icon: FILE_DOCUMENT_ICON,
  element: <Pages />,
  permission: ROUTE_PERMISSIONS.pages,
  i18nNamespace: PAGES_PAGE,
};

export default pagesRoute;
