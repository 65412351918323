import { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import type { Document } from 'types/Documents';
import { updateWindowPerformanceObject } from 'analytics/utils.ts';
import { HIL_TOOL_START } from 'analytics/events.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { TypographyWithTranslation } from 'components/with-translation';
import { HilReadOnly } from 'components/HilReadOnly/HilReadOnly.tsx';
import {
  AWAITING_REVIEW,
  REVIEW_IN_PROGRESS,
} from 'constants/document-stage.ts';
import { useLocales } from 'locales/useLocales';
import {
  useGetDocument,
  useGetDocumentContent,
  useGetDocumentHistoryVersion,
} from 'state/queries/documents';
import { useCurrentUserEmail } from 'state/queries/current-user';
import { preWarmImageCache } from 'utils/image-cache.ts';
import { getDocumentStage } from 'state/selectors/documents.ts';

import { FormHandler } from './FormHandler';
import { convertArrayToState } from './utils.ts';

interface HilToolProps {
  closeClaimHilTool?: VoidFunction;
  documentId: string;
  fromClaimView?: boolean;
}

interface HilToolWithDocumentProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  originalStage: string;
  resolvedLanguage: string;
}

function HilTool({
  closeClaimHilTool,
  documentId,
  fromClaimView = false,
}: HilToolProps) {
  const { resolvedLanguage } = useLocales();
  const documentQuery = useGetDocument(documentId, resolvedLanguage);
  const currentUserEmailQuery = useCurrentUserEmail();

  if (documentQuery.isPending || currentUserEmailQuery.isPending) {
    return <LoadingIcon />;
  }

  if (documentQuery.isError || currentUserEmailQuery.isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="common.loadDocumentError"
      />
    );
  }

  const document = documentQuery.data;

  // preload images
  preWarmImageCache(document.urls);

  return (
    <Card sx={{ overflow: 'initial', contain: 'paint' }}>
      <ComponentGateway
        closeClaimHilTool={closeClaimHilTool}
        currentUserEmail={currentUserEmailQuery.data}
        document={document}
        fromClaimView={fromClaimView}
        resolvedLanguage={resolvedLanguage}
        initialStage={getDocumentStage(document)}
      />
    </Card>
  );
}

interface ComponentGatewayProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  initialStage: string;
  resolvedLanguage: string;
}

// if stage is originally awaiting review or review in progress,
// render the editable version
function ComponentGateway({ initialStage, ...props }: ComponentGatewayProps) {
  const [originalStage] = useState(initialStage);
  const isAwaitingReview = originalStage === AWAITING_REVIEW;
  const isReviewInProgress = originalStage === REVIEW_IN_PROGRESS;
  const isEditable = isAwaitingReview || isReviewInProgress;

  if (isEditable) {
    return <HilToolWithDocument {...props} originalStage={originalStage} />;
  }

  return <HilReadOnly {...props} />;
}

function HilToolWithDocument(props: HilToolWithDocumentProps) {
  const t1 = performance.now();
  const { document, resolvedLanguage } = props;
  const options = { id: document.id, language: resolvedLanguage };
  const { data: content } = useGetDocumentContent(options);
  // try loading history, if available otherwise let HIL user proceed
  const documentHistoryVersionQuery = useGetDocumentHistoryVersion(document.id);

  const { list, resources } = useMemo(() => {
    if (content?.length) {
      return convertArrayToState(content);
    }

    return { list: [], resources: {} };
  }, [content]);

  useEffect(() => {
    updateWindowPerformanceObject(HIL_TOOL_START);
  }, []);

  return (
    <FormHandler
      {...props}
      list={list}
      resources={resources}
      specificHistoryData={documentHistoryVersionQuery.data}
      startPerformanceMeasurement={t1}
    />
  );
}

export default HilTool;
export { HilTool };
