import {
  FiltersClaimsApiResponse,
  FiltersDocumentsApiResponse,
} from 'api/transformers/types/filters.ts';
import type { FiltersClaims, FiltersDocuments } from 'types/Filters';
import {
  appendStagesToUrl,
  FILTERS_CLAIMS,
  FILTERS_CLAIMS_STAGE,
  FILTERS_DOCUMENTS,
  FILTERS_DOCUMENTS_STAGE,
} from 'constants/api-endpoints';
import {
  transformFiltersClaimsResponse,
  transformFiltersDocumentsResponse,
} from 'api/transformers/filters';
import { useGet } from 'utils/react-query';

export function useGetFiltersClaims(stage?: string | null) {
  const endpoint = stage ? FILTERS_CLAIMS_STAGE : FILTERS_CLAIMS;

  return useGet<FiltersClaimsApiResponse, FiltersClaims[]>({
    url: endpoint,
    select: transformFiltersClaimsResponse,
    params: {
      stage,
    },

    gcTime: Infinity,
  });
}

export function useGetFiltersDocuments(stage?: string | string[] | null) {
  const endpoint =
    typeof stage === 'string'
      ? FILTERS_DOCUMENTS_STAGE
      : Array.isArray(stage)
        ? appendStagesToUrl(FILTERS_DOCUMENTS, stage)
        : FILTERS_DOCUMENTS;

  return useGet<FiltersDocumentsApiResponse, FiltersDocuments[]>({
    url: endpoint,
    select: transformFiltersDocumentsResponse,
    params: {
      stage,
    },

    gcTime: Infinity,
  });
}
