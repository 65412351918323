import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import { ThumbnailImage } from 'components/ThumbnailImage';
import { LazyImage } from 'components/Image.tsx';

import type { ThumbnailItemProps, ThumbnailWithPageIdx } from './types.ts';
import { canvasCustomEvents } from './event-actions.ts';

function ThumbnailItem({ data, index, style }: ThumbnailItemProps) {
  const theme = useTheme();
  const matchIndex = ({ pageIdx }: ThumbnailWithPageIdx) =>
    pageIdx === activePage;
  const { activePage, position, thumbnails } = data;
  const matchedThumbnail = thumbnails.findIndex(matchIndex);

  const handleClick = () => {
    const pageIdx = thumbnails[index].pageIdx;
    canvasCustomEvents.updatePageEvent(pageIdx);
  };

  return (
    <ListItem
      component="div"
      disablePadding
      style={style}
      sx={{
        aspectRatio: 1,
      }}
    >
      <ListItemButton
        disableGutters
        sx={{
          p: 0,
          mb: 2,
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            ...(position === 'left' ? { pl: 0 } : { pr: 0 }),
            height: 1,
            width: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: 0,
              pt: '100%',
              overflow: 'hidden',
              width: 1,
            }}
          >
            <LazyImage
              data-testid="thumbnail-image"
              src={thumbnails[index].url}
              width={136}
              style={{
                border: '2px solid',
                borderColor:
                  matchedThumbnail === index
                    ? theme.palette.primary.main
                    : 'transparent',
                borderRadius: '8px',

                objectFit: 'contain',
                objectPosition: 'center center',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

export { ThumbnailItem };
