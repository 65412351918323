import type { CheckboxProps } from '@mui/material/Checkbox';
import type { SxProps, Theme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import merge from 'lodash.merge';
import { useTranslationRoot } from 'components/with-translation';

interface ControllerWithSingleCheckboxProps {
  label?: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  checkboxProps: CheckboxProps;
  name: string;
  namespace?: string;
  rules?: {
    required?: boolean;
  };
  sx?: SxProps<Theme>;
}

function ControllerWithSingleCheckbox({
  checkboxProps,
  label,
  labelPlacement,
  name,
  namespace,
  sx,
  ...props
}: ControllerWithSingleCheckboxProps) {
  const { control } = useFormContext();
  const { t } = useTranslationRoot(namespace);

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...merge({}, field, checkboxProps)}
              checked={value}
              onChange={(e) => {
                field.onChange(e.target.checked);
                checkboxProps.onChange?.(e, e.target.checked);
              }}
            />
          }
          label={label ? t(label) : undefined}
          labelPlacement={labelPlacement || 'top'}
          sx={sx}
        />
      )}
    />
  );
}

export { ControllerWithSingleCheckbox };
export type { ControllerWithSingleCheckboxProps };
