// TODO: maybe deprecate this for utils/function-queue.ts
const queue = new Map();

function addToUnlockQueue(id: string, callback: VoidFunction) {
  if (!queue.has(id)) {
    queue.set(id, callback);
  }
}

function flushUnlockQueue() {
  if (queue.size) {
    queue.forEach((callback) => callback());
    queue.clear();
  }
}

function removeFromUnlockQueue(id: string) {
  if (queue.has(id)) {
    queue.delete(id);
  }
}

export { addToUnlockQueue, flushUnlockQueue, removeFromUnlockQueue };
