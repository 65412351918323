import type { DocumentValueType } from 'types/Documents.ts';

export function transformStateToForm(
  valueType: DocumentValueType,
  value: string
) {
  switch (valueType) {
    case 'str':
      return value;
    case 'int':
      return Number(value);
    case 'bool':
      return value.toLowerCase() === 'true';
    default:
      return value;
  }
}

export function transformFormToState(
  valueType: DocumentValueType,
  value: string | number | boolean
) {
  switch (valueType) {
    case 'str':
      return value as string;
    case 'int':
      return String(value as number);
    case 'bool':
      return value ? 'True' : 'False';
    default:
      return value as string;
  }
}
