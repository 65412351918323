import type { WheelEvent } from 'react';
import type { BaseTextFieldProps } from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useTranslationRoot } from 'components/with-translation';

type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
type ColorFn = (invalid?: boolean) => Color;
type CustomTextFieldProps = Omit<BaseTextFieldProps, 'color'>;
export interface ControllerWithTextFieldProps extends CustomTextFieldProps {
  color?: Color | ColorFn;
  controllerProps?: Record<string, any>;
  name: string;
  [key: string]: any;
}

function ControllerWithTextField({
  color,
  controllerProps,
  defaultValue,
  error: propError,
  label,
  name,
  onBlur,
  onChange,
  type = 'text',
  ...props
}: ControllerWithTextFieldProps) {
  const { control } = useFormContext();
  const { t } = useTranslationRoot();

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error, invalid } = fieldState;

        return (
          <TextField
            {...field}
            color={typeof color === 'function' ? color(invalid) : color}
            error={propError ?? invalid}
            aria-invalid={invalid}
            fullWidth
            helperText={error?.message && t(error.message)}
            id={name}
            label={label}
            type={type}
            {...props}
            slotProps={{
              formHelperText: {
                sx: {
                  whiteSpace: 'break-spaces',
                  ...props.slotProps?.formHelperText?.sx,
                },
              },
            }}
            onBlur={(e) => {
              field.onBlur();
              onBlur?.(e);
            }}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            onWheel={
              // stop scrolling on number fields to prevent value from
              // incrementing or decrementing
              type === 'number'
                ? (e: WheelEvent<HTMLDivElement>) =>
                    (e.target as HTMLElement).blur()
                : undefined
            }
          />
        );
      }}
      {...controllerProps}
    />
  );
}

export { ControllerWithTextField };
