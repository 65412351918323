import { useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { ARROW_FORWARD_ICON } from 'constants/public-icons.ts';
import { toTitleCase } from 'utils/string.ts';

import type { ThumbnailSinglePanelProps } from './types.ts';
import { ThumbnailItem } from './ThumbnailItem.tsx';
import { DEFAULT_PANEL } from './constants.ts';

function ThumbnailSinglePanel({
  activePage,
  disableBackButton = false,
  group,
  handleChange,
  isActivePanel,
  position,
  thumbnails,
}: ThumbnailSinglePanelProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current && isActivePanel) {
      const rect = containerRef.current.getBoundingClientRect();
      setHeight(rect.height + 16);
    }
  }, [group, isActivePanel]);

  return (
    <Stack spacing={1} sx={{ height: 1 }}>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          py: 1,
        }}
      >
        {!disableBackButton && (
          <IconButton
            onClick={(e) => handleChange?.(e, DEFAULT_PANEL, true)}
            sx={{ position: 'absolute', left: position === 'right' ? -8 : 0 }}
          >
            <SvgIconStyle
              src={ARROW_FORWARD_ICON}
              height={14}
              width={14}
              sx={{
                pointerEvents: 'none',
                transform: 'rotate(180deg)',
              }}
            />
          </IconButton>
        )}
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: ({ typography }) => typography.pxToRem(12),
            maxWidth: 100,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transform: position === 'left' ? 'translateX(8px)' : undefined,
            whiteSpace: 'nowrap',
          }}
        >
          {group ? toTitleCase(group) : ''}
        </Typography>
      </Stack>
      <Box ref={containerRef} sx={{ flex: 1 }}>
        {!!height && (
          <VariableSizeList
            itemData={{ activePage, position, thumbnails }}
            itemSize={() => 152}
            height={height}
            itemCount={thumbnails.length}
            width="100%"
          >
            {ThumbnailItem}
          </VariableSizeList>
        )}
      </Box>
    </Stack>
  );
}

export { ThumbnailSinglePanel };
