import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { useTranslationRoot } from 'components/with-translation';
import { ARROW_DOWNWARD_ICON } from 'constants/public-icons';
import { useGetDocumentTypes } from 'state/queries/documents.ts';
import { unique } from 'utils/array.ts';

function DocumentTypeSelect() {
  const { t } = useTranslationRoot();
  const { control } = useFormContext();

  const [enabled, setEnabled] = useState(false);
  const { data, isError, isPending, isFetching } = useGetDocumentTypes(enabled);

  const handleOpen = () => {
    setEnabled(true);
  };

  const handleClose = () => {
    setEnabled(false);
  };

  const options =
    isError || isPending
      ? []
      : unique(data.documentTypes.map(({ documentGroup }) => documentGroup));

  return (
    <Controller
      control={control}
      render={({ field, fieldState }) => {
        const { error, invalid } = fieldState;

        return (
          <>
            <Autocomplete
              {...field}
              open={enabled}
              onOpen={handleOpen}
              onClose={handleClose}
              loading={isFetching}
              size="small"
              popupIcon={<SvgIconStyle src={ARROW_DOWNWARD_ICON} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={invalid}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      placeholder: t(
                        'form.overrideClassificationPlaceholder'
                      ) as string,
                      endAdornment: (
                        <>
                          {isFetching && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    },
                  }}
                />
              )}
              options={options}
              key={field.value}
              onChange={(_, data) => {
                field.onChange(data);
              }}
              data-testid="classification-override"
            />
            {invalid && (
              <FormHelperText error={invalid}>
                {error?.message || t('form.fieldRequired')}
              </FormHelperText>
            )}
          </>
        );
      }}
      name="classification"
    />
  );
}

export { DocumentTypeSelect };
