import { ReactNode } from 'react';

export enum ComponentType {
  TEXT = 'TEXT',
  STAGE_LABEL = 'STAGE_LABEL',
  CUSTOM = 'CUSTOM',
}

type TextAction = {
  type: ComponentType.TEXT;
  title: string;
  value: string;
};

type StageLabelAction = {
  type: ComponentType.STAGE_LABEL;
  content: string;
};

type CustomComponentAction = {
  type: ComponentType.CUSTOM;
  content: ReactNode;
};

export type MetadataAction =
  | TextAction
  | StageLabelAction
  | CustomComponentAction;

export function text(content: { title: string; value: string }): TextAction {
  return {
    type: ComponentType.TEXT,
    ...content,
  };
}

export function stageLabel(content: string): StageLabelAction {
  return {
    type: ComponentType.STAGE_LABEL,
    content,
  };
}

export function renderCustomComponent(
  content: ReactNode
): CustomComponentAction {
  return {
    type: ComponentType.CUSTOM,
    content,
  };
}
