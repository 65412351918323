import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { USER_MANAGEMENT_CREATE_USER_PAGE } from 'constants/translation-keys';
import { postCreateUser } from 'state/queries/user-management';
import { toast } from 'components/toast';
import { WithTranslationRoot } from 'components/with-translation';
import { ADD_USER_TAB } from 'pages/UserManagement/UserManagement.tsx';

import { CreateUserForm } from './CreateUserForm';
import type { CreateUserFormValues } from './CreateUserForm';

function UserManagementCreateUser() {
  const { t } = useTranslation(USER_MANAGEMENT_CREATE_USER_PAGE);

  function handleSubmit(
    { email, givenName, familyName, signupUserRole }: CreateUserFormValues,
    onSubmitSuccess: () => void
  ) {
    const payload = {
      email,
      givenName,
      familyName,
      signupUserRole,
    };

    toast.promise(postCreateUser(payload), {
      loading: t('createUserForm.submitLoading'),
      success: () => {
        onSubmitSuccess();
        return t('createUserForm.submitSuccess');
      },
      error: t('createUserForm.submitError'),
    });
  }

  return (
    <WithTranslationRoot namespace={USER_MANAGEMENT_CREATE_USER_PAGE}>
      <TabPanel key="addUser" value={ADD_USER_TAB}>
        <CreateUserForm onSubmit={handleSubmit} />
      </TabPanel>
    </WithTranslationRoot>
  );
}

export default UserManagementCreateUser;
