import type { ContentWithId, Resources } from '../types.ts';

export function getItem(
  resources: Resources
): (condition) => (id: string) => ContentWithId {
  return (condition) => (id) => condition(resources[id]);
}

export function getDocumentGroup(item: ContentWithId) {
  return item.sproutaiKey === 'document_group';
}
