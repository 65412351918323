import { KeyboardEvent, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getOsKey } from 'utils/client.ts';

import { FormContent } from './FormContent.tsx';

interface FormProps {
  classification?: string;
  url: string;
  metadataNode: ReactNode;
  onSubmit: (data: FormValues) => void;
}

export interface FormValues {
  classification?: string;
}

function Form({ classification, url, metadataNode, onSubmit }: FormProps) {
  const methods = useForm({
    defaultValues: {
      classification: undefined,
    },
  });

  const handleFormSubmit = (data: FormValues) => {
    onSubmit(data);
    methods.reset();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      const osKey = getOsKey();
      const mainKey = osKey === 'Cmd' ? event.metaKey : event.ctrlKey;

      if (mainKey) {
        methods.handleSubmit(handleFormSubmit)();
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        onKeyDown={handleKeyDown}
      >
        <FormContent
          classification={classification}
          url={url}
          metadataNode={metadataNode}
        />
      </form>
    </FormProvider>
  );
}

export { Form };
