import Typography from '@mui/material/Typography';
import { generalConfig } from 'config.ts';

function Version() {
  return (
    <Typography
      component="p"
      variant="caption"
      sx={{
        color: 'grey.600',
        fontSize: ({ typography }) => typography.pxToRem(10),
      }}
    >
      {`v${generalConfig.platformVersion}`}
    </Typography>
  );
}

export { Version };
