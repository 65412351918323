import { useMemo, useState } from 'react';
import { CanvasToolV2 } from 'components/CanvasToolV2';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';

import type { FormValues, HilFormProps, List, Resources } from './types.ts';
import {
  generateHistoryResources,
  initialiseForm,
  normaliseContent,
  transformFormValuesToState,
} from './utils.ts';
import { Form } from './Form.tsx';

function HilForm({
  currentUserEmail,
  document,
  documentHistory,
  isRejecting,
  isSaving,
  isSubmitting,
  lockedByCurrentUser,
  onDocumentTypeChange,
  onImageLoadComplete,
  onNextDocument,
  onReject,
  onSave,
  onSubmit,
}: HilFormProps) {
  // feature flags
  const enableThumbnails = useFeatureFlag('enableHILThumbnails');

  const [{ list, resources }, setState] = useState(
    normaliseContent(document.content)
  );

  const { defaultValues } = useMemo(
    () => initialiseForm({ list, resources }),
    [list, resources]
  );
  const historicalResources = useMemo(
    () => generateHistoryResources(list, resources, documentHistory),
    [documentHistory, list, resources]
  );

  const updateStore = (newList: List, newResources: Resources) => {
    setState((prevState) => ({
      list: [...prevState.list, ...newList],
      resources: { ...prevState.resources, ...newResources },
    }));
  };

  const handleFormAction = (data: FormValues, action: string) => {
    const content = transformFormValuesToState({
      values: data,
      list,
      resources,
    });

    if (action === 'SUBMIT') {
      onSubmit(content);
      return;
    }

    if (action === 'SAVE') {
      onSave(content);
      return;
    }
  };

  const handleSubmit = (props: FormValues) => handleFormAction(props, 'SUBMIT');

  const handleSave = (props: FormValues) => handleFormAction(props, 'SAVE');

  const canvasNode = (
    <CanvasToolV2
      bottomOffset={24}
      urls={document.urls}
      hasThumbnails={enableThumbnails}
      imageLoadFinishEvent={onImageLoadComplete}
      order={{
        canvas: 1,
        thumbnails: 0,
      }}
      thumbnails={
        enableThumbnails
          ? document.urls
            ? Object.keys(document.urls).map((item) => ({
                url: document.urls[item],
                documentId: document.id || 'id',
                documentGroup: document.documentType || '',
              }))
            : []
          : undefined
      }
    />
  );

  return (
    <Form
      canvasNode={canvasNode}
      currentUserEmail={currentUserEmail}
      defaultValues={defaultValues}
      document={document}
      historicalResources={historicalResources}
      isRejecting={isRejecting}
      isSaving={isSaving}
      isSubmitting={isSubmitting}
      list={list}
      lockedByCurrentUser={lockedByCurrentUser}
      onDocumentTypeChange={onDocumentTypeChange}
      onNext={onNextDocument}
      onReject={onReject}
      onSave={handleSave}
      onSubmit={handleSubmit}
      resources={resources}
      updateStore={updateStore}
    />
  );
}

export { HilForm };
