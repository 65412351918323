import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from '@mui/utils/debounce';
import { canvasCustomEvents } from 'components/CanvasToolV2/event-actions.ts';
import { postCodeMapping } from 'state/queries/lookup.ts';

import type { InputDropDownProps } from './types.ts';
import { InputDropDownOption } from './InputDropDownOption.tsx';
import { getRelatedFieldId } from './utils/get-related-fields.ts';
import { getSearchRequestBody } from './utils/get-search-request-body.ts';

const cache = new Map();

const fetch = debounce(
  ({
    callback,
    inputValue,
    mappingType,
    payload,
  }: {
    callback: (response: { mappings: Record<string, string>[] }) => void;
    inputValue;
    mappingType: string;
    payload: Record<string, string>;
  }) => {
    if (cache.has(inputValue)) {
      const cachedValue = cache.get(inputValue);

      if (cachedValue) {
        callback(cachedValue);
        return;
      }
    }

    cache.set(inputValue, null);
    postCodeMapping({ type: mappingType, values: payload }).then((response) => {
      if (response.mappings?.length) {
        cache.set(inputValue, response);
        callback(response);
      } else {
        cache.set(inputValue, null);
      }
    });
  },
  400
);

function InputDropDown({
  boundingBox,
  codeMapping,
  currentObservationListIds,
  fieldsListIds,
  pageIdx,
  id,
  label,
  resources,
  valid,
}: InputDropDownProps) {
  const theme = useTheme();
  const { control, getValues, setValue } = useFormContext();
  const watchedValue = useWatch({ control, name: id });

  const hasLoaded = useRef(false);
  const defaultValue = getValues(id);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setV] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(defaultValue);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Record<string, string>[]>([]);

  const {
    autopopulate,
    mappingType,
    otherFields,
    searchKey,
    triggerField,
    dropdown,
  } = codeMapping;

  const autocompleteDict = useMemo(() => {
    return Object.entries(autopopulate || {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [value]: key,
      }),
      {}
    );
  }, [autopopulate]);
  const mappingKey = autocompleteDict[triggerField] || triggerField;
  const generateSearchRequestBody = getSearchRequestBody({
    getValues,
    listIds: currentObservationListIds,
    otherFields,
    resources,
    searchKey,
  });

  const findRelatedFieldId = useMemo(() => {
    return getRelatedFieldId({
      currentObservationListIds,
      resources,
      triggerItem: resources[id],
      allObservationListIds: fieldsListIds,
    });
  }, [currentObservationListIds, fieldsListIds, id, resources]);

  useLayoutEffect(() => {
    if (!hasLoaded.current) {
      hasLoaded.current = true;
      return;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let active = true;

    const payload = generateSearchRequestBody(inputValue);

    setIsLoading(true);

    fetch({
      inputValue,
      mappingType,
      payload,
      callback: ({ mappings }) => {
        setIsLoading(false);
        if (active) {
          setOptions(
            mappings.length
              ? mappings
              : [
                  {
                    [mappingKey]: inputValue,
                  },
                ]
          );
        }
      },
    });

    setValue(id, inputValue);

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    if (watchedValue !== inputValue) {
      setInputValue(watchedValue);
      setV(watchedValue);
    }
    // eslint-disable-next-line
  }, [watchedValue]);

  const handleOpen = () => {
    setOpen(true);
    setIsLoading(true);

    const payload = generateSearchRequestBody(inputValue);

    fetch({
      inputValue,
      mappingType,
      payload,
      callback: ({ mappings }) => {
        setIsLoading(false);

        if (mappings) {
          setOptions(
            mappings.length
              ? mappings
              : [
                  {
                    [mappingKey]: inputValue,
                  },
                ]
          );
        } else {
          setOptions([{ [mappingKey]: inputValue }]);
        }
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  return (
    <Autocomplete
      loading={isLoading}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      includeInputInList
      isOptionEqualToValue={(option, value) => option === value}
      value={value}
      onChange={(_, option) => {
        const newValue = option[mappingKey] ?? option;
        setV(newValue);
        setInputValue(newValue);

        Object.entries(option).forEach(([key, value]) => {
          const mappingKey = autopopulate[key] || key;
          const foundId = findRelatedFieldId(mappingKey);
          const existsInAutopopulate = autocompleteDict[mappingKey];

          if (foundId && existsInAutopopulate) {
            setValue(foundId, value);
          }
        });
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      freeSolo
      size="small"
      filterOptions={(x) => {
        return x;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onFocus={() => {
            canvasCustomEvents.addCrop({
              boundingBox,
              id,
              pageIdx,
              valid: valid || valid === null,
              overrideColor: theme.palette.info.main,
            });
          }}
          slotProps={{
            inputLabel: { shrink: true },
            formHelperText: {
              sx: {
                color: 'error.main',
                mt: 0.5,
                whiteSpace: 'break-spaces',
              },
            },
          }}
        />
      )}
      options={options.map((option) => option)}
      getOptionLabel={(option) => {
        return option[mappingKey] ?? option;
      }}
      renderOption={(props, option, state) => {
        const { key, ...optionProps } = props;

        return (
          <InputDropDownOption
            key={`${key}-${state.index}`}
            optionRenderValues={dropdown}
            option={option}
            optionProps={optionProps}
          />
        );
      }}
      disablePortal
      sx={{
        '& + .MuiAutocomplete-popper': {
          boxShadow: (theme) => theme.customShadows.dropdown,
        },
      }}
    />
  );
}

export { InputDropDown };
