import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import {
  CIRCLE_CHECK_ICON,
  CLOSE_CIRCLE_FILL_ICON,
} from 'constants/public-icons.ts';

import type {
  ConsistencyListIds,
  ConsistencyResultProps,
  ConsistencyResultWithErrorHandlingProps,
} from './types.ts';
import { ConsistencyResultItem } from './ConsistencyResultItem.tsx';
import { ControlledAccordion } from './ControlledAccordion.tsx';

function ConsistencyResult(props: ConsistencyResultProps) {
  const { t } = useTranslationRoot();
  const { consistencyListIds, consistencyResultsErrors } = props;
  const { clearErrors, setError } = useFormContext();

  useEffect(() => {
    clearErrors();

    Object.entries(consistencyResultsErrors).forEach(([id, error]) => {
      setError(id, {
        type: error.type,
        message: error.message.join('\n'),
      });
    });
  }, [clearErrors, consistencyResultsErrors, setError]);

  const lists = consistencyListIds.reduce(
    (acc, list) => {
      const [, result] = list;
      if (result === 'failed') {
        acc[0].push(list);
      } else {
        acc[1].push(list);
      }
      return acc;
    },
    [[] as ConsistencyListIds, [] as ConsistencyListIds]
  );

  return (
    <>
      {lists.map((list, index) => (
        <ControlledAccordion
          key={index}
          title={
            index === 0
              ? t('form.failedValidations')
              : t('form.passedValidations')
          }
          defaultExpanded={index === 0}
        >
          <Paper
            elevation={0}
            sx={{ backgroundColor: 'background.default', py: 2 }}
          >
            <Stack spacing={2} sx={{ pl: 1.5, pr: 2 }}>
              {list.length ? (
                list.map(([title, result, ids], index) => (
                  <ConsistencyResultWithErrorHandling
                    key={`${title}-${index}`}
                    {...props}
                    title={title}
                    ids={ids}
                    result={result}
                  />
                ))
              ) : (
                <Alert severity="warning">{t('form.noValidationRules')}</Alert>
              )}
            </Stack>
          </Paper>
        </ControlledAccordion>
      ))}
    </>
  );
}

function ConsistencyResultWithErrorHandling({
  codeMappings,
  ids,
  title,
  fieldsListIds,
  getPageRef,
  observations,
  resources,
  result,
}: ConsistencyResultWithErrorHandlingProps) {
  // const hasError =
  //   result === 'failed' && ids.some((id) => consistencyResultsErrors[id]);
  const hasError = result === 'failed';

  return (
    <Stack
      key={title}
      spacing={2}
      sx={{
        borderLeft: '5px solid',
        borderColor: hasError ? 'error.main' : 'grey.5008',
        pl: 1.5,
      }}
    >
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Box sx={{ height: 24, width: 24, mr: 0.5 }}>
          <SvgIconStyle
            height={24}
            width={24}
            src={hasError ? CLOSE_CIRCLE_FILL_ICON : CIRCLE_CHECK_ICON}
            sx={{ color: hasError ? 'error.main' : 'success.main' }}
          />
        </Box>
        <Typography
          variant="subtitle1"
          color={hasError ? 'error' : 'success'}
          sx={{ whiteSpace: 'break-spaces' }}
        >
          {title}
        </Typography>
      </Stack>

      {ids.map((id, index) => (
        <ConsistencyResultItem
          key={`${id}-${index}`}
          codeMappings={codeMappings}
          getPageRef={getPageRef}
          fieldsListIds={fieldsListIds}
          id={id}
          observations={observations}
          resources={resources}
        />
      ))}
    </Stack>
  );
}

export { ConsistencyResult };
