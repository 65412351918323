import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { CLOSE_ICON, REFRESH_ICON } from 'constants/public-icons';
import { TypographyWithTranslation } from 'components/with-translation';

interface HeaderProps {
  handleRefresh: () => void;
  handleClose: () => void;
}

function Header({ handleRefresh, handleClose }: HeaderProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TypographyWithTranslation variant="h5" i18nKey="comments.title" />
      <div>
        <IconButton aria-label="Refresh" size="small" onClick={handleRefresh}>
          <SvgIconStyle src={REFRESH_ICON} />
        </IconButton>
        <IconButton aria-label="Close" size="small" onClick={handleClose}>
          <SvgIconStyle src={CLOSE_ICON} />
        </IconButton>
      </div>
    </Stack>
  );
}

export { Header };
