import { lazy } from 'react';
import { createPath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { HUMAN_IN_THE_LOOP_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { DOCUMENT_CORRECTIONS_PAGE } from 'constants/translation-keys';
import documentCorrectionRoute from 'pages/DocumentCorrection/document-correction.route.tsx';

import DocumentCorrectionsLayout from './DocumentCorrectionsLayout.tsx';

const path = '/d';
const DocumentCorrectionsPage = loadable(
  lazy(() => import('./DocumentCorrections.tsx'))
);

const documentCorrectionsRoute = {
  path,
  createPath: (params?: { search?: string }) =>
    createPath({ pathname: path, ...params }),
  icon: HUMAN_IN_THE_LOOP_ICON,
  element: <DocumentCorrectionsLayout />,
  permission: ROUTE_PERMISSIONS.documentCorrection,
  children: [
    {
      element: <DocumentCorrectionsPage />,
      index: true,
      permission: ROUTE_PERMISSIONS.documentCorrection,
      i18nNamespace: DOCUMENT_CORRECTIONS_PAGE,
    },
    documentCorrectionRoute,
  ],
};

export default documentCorrectionsRoute;
