import { Avatar } from 'components/Avatar.tsx';
import { IconButtonAnimate } from 'components/IconButtonAnimate.tsx';
import { useTooltip } from 'components/customHooks/useTooltip.ts';
import {
  useCurrentUserEmail,
  useCurrentUserFirstName,
  useCurrentUserFullName,
} from 'state/queries/current-user.ts';
import { createAvatar } from 'utils/create-avatar.ts';

import { AccountTooltip } from './AccountTooltip.tsx';

function AccountButton() {
  const fullNameQuery = useCurrentUserFullName();
  const firstNameQuery = useCurrentUserFirstName();
  const emailQuery = useCurrentUserEmail();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();

  return (
    <>
      <IconButtonAnimate isOpen={isOpen} onClick={handleOpen}>
        {firstNameQuery.isSuccess && emailQuery.isSuccess ? (
          <Avatar
            alt={fullNameQuery.isSuccess ? fullNameQuery.data : ''}
            color={
              emailQuery.data
                ? 'default'
                : createAvatar(firstNameQuery.data).color
            }
            src={''}
          >
            {createAvatar(firstNameQuery.data).name}
          </Avatar>
        ) : null}
      </IconButtonAnimate>
      <AccountTooltip
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        element={element}
        handleClose={handleClose}
        isOpen={isOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      />
    </>
  );
}

export { AccountButton };
