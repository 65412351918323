import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { TypographyWithTranslation } from 'components/with-translation';
import { InfoIcon, WarningIcon } from 'theme/overrides/CustomIcons';

import type { InfoTooltipProps } from './types';

function InfoTooltip({ ocrValue, validationMessage }: InfoTooltipProps) {
  const markup = (
    <Stack>
      {!!ocrValue && (
        <Stack>
          <TypographyWithTranslation
            i18nKey="form.ocrValueLabel"
            variant="body2"
            sx={{ color: 'text.secondary', fontWeight: 'bold' }}
          />
          <Typography variant="body2">{ocrValue}</Typography>
        </Stack>
      )}
      {ocrValue && validationMessage ? <Divider sx={{ my: 1 }} /> : null}
      {!!validationMessage && (
        <Stack>
          <TypographyWithTranslation
            i18nKey="form.validation"
            variant="body2"
            sx={{ color: 'text.secondary', fontWeight: 'bold' }}
          />
          <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
            {validationMessage}
          </Typography>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Tooltip
      leaveDelay={500}
      title={markup}
      placement="top-start"
      arrow
      data-testid="info-tooltip"
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 20,
          justifyContent: 'center',
          width: 20,
        }}
      >
        {validationMessage ? (
          <WarningIcon sx={{ color: 'warning.main', width: 20, height: 20 }} />
        ) : (
          <InfoIcon sx={{ color: 'grey.500', width: 20, height: 20 }} />
        )}
      </Box>
    </Tooltip>
  );
}

export { InfoTooltip };
