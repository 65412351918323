import type { ReactNode } from 'react';
import { useLayoutEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';

import { TOOL_LAYOUT_HEADER_HEIGHT } from './constants.ts';

interface LayoutProps {
  header: ReactNode;
  body: ReactNode;
}
function Layout({ header, body }: LayoutProps) {
  const enableNewSiteLayout = useFeatureFlag('enableNewSiteLayout');
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const prevHeight = useRef<number>(0);

  useLayoutEffect(() => {
    if (enableNewSiteLayout) {
      return;
    }

    const PADDING = theme.spacing(3);

    function updateMaxHeight(element: HTMLElement) {
      const rect = element.getBoundingClientRect();

      // HACK: deduct 1px to prevent scrollbar from appearing
      element.style.height = `${window.innerHeight - rect.top - parseInt(PADDING) - 1}px`;
      prevHeight.current = window.innerHeight;
    }

    // Start observing the target node for configured mutations
    if (containerRef.current) {
      updateMaxHeight(containerRef.current);
    }
  }, [theme, enableNewSiteLayout]);

  return (
    <Stack
      direction="column"
      ref={containerRef}
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
          height: `${TOOL_LAYOUT_HEADER_HEIGHT}px`,
          flexShrink: 0,
        }}
      >
        {header}
      </Box>
      <Box sx={{ flexGrow: 1 }}>{body}</Box>
    </Stack>
  );
}

export { Layout };
