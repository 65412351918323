import type { ReactNode } from 'react';
import type { TextFieldProps } from '@mui/material/TextField';
import { filledInputClasses } from '@mui/material/FilledInput';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import dayjs from 'locales/dayjs';
import type {
  Document,
  DocumentContent,
  DocumentValidationResult,
  LineLevelContent,
} from 'types/Documents.ts';
import { BOOL, DATE } from 'constants/value-type.ts';
import { isLineLevelItem, isNotValidItem } from 'state/selectors/documents.ts';
import { generateUuid } from 'utils/generate-uuid.ts';
import { convertArrayToListAndDict, unique } from 'utils/array.ts';

import type {
  BaseProps,
  DocumentContentWithId,
  FormValues,
  List,
  Resources,
  FormatValidationResults,
  GetInitialErrors,
} from './types.ts';
import {
  DELETED_INPUT_SUFFIX,
  DISABLED_ITEMS,
  SKIP_INPUT_SUFFIX,
} from './constants.ts';

export function normaliseContent(content: DocumentContent[] | null) {
  if (content) {
    const contentWithId = content.map((c) => ({
      ...c,
      id: generateUuid(),
    }));
    const [list, resources] = convertArrayToListAndDict<DocumentContentWithId>(
      contentWithId,
      'id'
    ) as [List, Resources];

    return {
      list,
      resources,
    };
  }

  return {
    list: [],
    resources: {},
  };
}

export function initialiseForm({ list, resources }: BaseProps) {
  return {
    defaultValues: getDefaultValues({ list, resources }),
  };
}

export function generateHistoryResources(
  list: List,
  resources: Resources,
  documentHistory: Document | undefined
) {
  if (documentHistory) {
    return list.reduce((acc, id) => {
      const item = resources[id];
      const { content } = documentHistory;
      const found = content?.find(
        (c) =>
          c.sproutaiKey === item.sproutaiKey &&
          c.pageIdx === item.pageIdx &&
          c.lineIdx === item.lineIdx
      );

      if (found) {
        return {
          ...acc,
          [id]: { ...found, id },
        };
      }

      return acc;
    }, {});
  }

  return {};
}

function getDefaultValues({ list, resources }: BaseProps): FormValues {
  const transformValue = (type: string, value: string) => {
    if (type === BOOL) {
      return value === '' ? '' : value === 'True';
    }

    return value;
  };

  return list.reduce((acc, id) => {
    const item = resources[id];
    const invalid = isNotValidItem(item);
    const isLineItem = isLineLevelItem(item);

    const newObj = {
      ...acc,
      [id]: transformValue(resources[id].valueType, resources[id].value),
    };

    if (invalid) {
      const name = `${id}${SKIP_INPUT_SUFFIX}`;
      newObj[name] = false;
    }

    if (isLineItem) {
      const name = `${id}${DELETED_INPUT_SUFFIX}`;
      newObj[name] = item.deleted || false;
    }

    return newObj;
  }, {});
}

export function getItemDocumentType(item: DocumentContent) {
  return item.sproutaiKey === 'document_type';
}

export function getItemValidity(item: DocumentContentWithId) {
  const invalid = isNotValidItem(item);
  const valid = !invalid;

  return {
    invalid,
    valid,
  };
}

export function getVisuallyHiddenItem(
  item: DocumentContentWithId,
  filterItemsBy: 'all' | 'invalid' | 'valid'
) {
  const { invalid, valid } = getItemValidity(item);

  return (
    (filterItemsBy === 'invalid' && valid) ||
    (filterItemsBy === 'valid' && invalid)
  );
}

export function getForcefullyDisabledItems(sproutaiKey: string) {
  return DISABLED_ITEMS.includes(sproutaiKey);
}

export function getInputProps({
  item,
  isVisuallyHidden,
  skipped,
  startAdornment,
}: {
  item: DocumentContentWithId;
  isVisuallyHidden: boolean;
  skipped: boolean;
  startAdornment: ReactNode;
}): TextFieldProps {
  const { clientKey, sproutaiKey } = item;

  // valid item
  const { valid } = getItemValidity(item);

  // disabled
  const forcefullyDisabled = getForcefullyDisabledItems(sproutaiKey);
  const disabled = forcefullyDisabled || skipped;

  return {
    'aria-disabled': skipped,
    color: valid ? 'success' : 'error',
    disabled,
    label: clientKey,
    variant: 'filled',
    size: 'small',
    slotProps: {
      formHelperText: {
        component: 'div',
      },
      htmlInput: { tabIndex: isVisuallyHidden ? -1 : 0 },
      input: {
        startAdornment,
      },
      inputLabel: {
        shrink: true,
        sx: {
          color: valid ? 'success.main' : 'error.main',
        },
      },
    },
    sx: {
      [`& .${filledInputClasses.root}:after`]: {
        transform: 'scaleX(1)',
      },
      [`& .${formHelperTextClasses.root}`]: {
        mt: 0,
        mx: 0,
      },
    },
  };
}

export function transformFormValuesToState({
  list,
  resources,
  values,
}: BaseProps & {
  values: FormValues;
}) {
  const transformValue = (type: string, value: string | boolean) => {
    if (type === DATE) {
      return dayjs(value as string).isValid()
        ? dayjs(value as string)
            .utc(false)
            .toISOString()
        : '';
    }

    if (type === BOOL && typeof value === 'boolean') {
      return value ? 'True' : 'False';
    }

    if (type === BOOL && typeof value === 'string') {
      return value.toLowerCase() === 'true' ? 'True' : 'False';
    }

    return value as string;
  };

  return list.reduce((acc, fieldId) => {
    const { id, ...item } = resources[fieldId];
    const value = transformValue(item.valueType, values[id]);
    const isSkipped = values[`${id}${SKIP_INPUT_SUFFIX}`];
    const isDeleted = values[`${id}${DELETED_INPUT_SUFFIX}`] as boolean;
    const valid = isSkipped ? null : item.valid;

    return [
      ...acc,
      {
        ...item,
        deleted: isDeleted ?? item.deleted,
        valid,
        value,
      },
    ];
  }, [] as DocumentContent[]);
}

export function getItems(conditions: (item: DocumentContent) => boolean) {
  return ({ list, resources }: BaseProps) =>
    list.reduce<string[]>((acc, id) => {
      const item = resources[id];
      if (conditions(item)) {
        acc.push(id);
      }
      return acc;
    }, []);
}

export function getItem(
  resources: Resources
): (condition) => (id: string) => DocumentContent {
  return (condition) => (id) => condition(resources[id]);
}

// function getGroupedLineItems({
//   list,
//   resources,
// }: BaseProps): Map<number, string[]> {
//   return list.reduce((acc, id) => {
//     const item = resources[id] as LineLevelContent;
//     const lineIdx = item.lineIdx;
//
//     if (!acc.has(lineIdx)) {
//       acc.set(lineIdx, []);
//     }
//
//     acc.get(lineIdx)?.push(id);
//     return acc;
//   }, new Map());
// }

type GroupedLineItems = Map<string, Map<number, string[]>>;
function getGroupedLineItemsV2({
  list,
  resources,
}: BaseProps): GroupedLineItems {
  return list.reduce((acc, id) => {
    const item = resources[id] as LineLevelContent;
    const { level, lineIdx } = item;

    // should group by level then by lineIdx
    if (!acc.has(level)) {
      acc.set(level, new Map());
    }
    const levelMap = acc.get(level);

    if (!levelMap?.has(lineIdx)) {
      levelMap.set(lineIdx, []);
    }

    levelMap?.get(lineIdx)?.push(id);

    return acc;
  }, new Map());
}

function getLastLineIdx(
  groupedLineItems: GroupedLineItems
): Map<string, number> {
  return Array.from(groupedLineItems.entries()).reduce(
    (acc, [level, lineItems]) => {
      const lastLineIdx = Math.max(...Array.from(lineItems.keys()));
      acc.set(level, lastLineIdx);
      return acc;
    },
    new Map<string, number>()
  );
}

function getLineValidity(
  groupedLineItems: GroupedLineItems,
  resources: Resources
): Map<string, Map<number, boolean>> {
  return Array.from(groupedLineItems.entries()).reduce(
    (acc, [level, lineItems]) => {
      const lineValidity = Array.from(lineItems.keys()).reduce(
        (lineAcc, lineIdx) => {
          const lineItemIds = lineItems.get(lineIdx) || [];
          const isValid = lineItemIds.every((id) => resources[id].valid);

          lineAcc.set(lineIdx, isValid);
          return lineAcc;
        },
        new Map<number, boolean>()
      );

      acc.set(level, lineValidity);
      return acc;
    },
    new Map<string, Map<number, boolean>>()
  );
}

export function initLineItems(args: BaseProps) {
  const { resources } = args;
  const groupedLineItems = getGroupedLineItemsV2(args);

  return {
    groupedLineItems,
    lastLineIdx: getLastLineIdx(groupedLineItems),
    lineValidity: getLineValidity(groupedLineItems, resources),
  };
}

export function formatValidationMessages({
  resources,
  validationResults,
}: FormatValidationResults) {
  return Object.entries(validationResults).reduce((acc, [key, value]) => {
    if (resources[key]) {
      return {
        ...acc,
        [key]: { message: unique(value).join('\n'), type: 'custom' },
      };
    }

    return acc;
  }, {});
}

function filterValidationResultsByFailedResult(
  validationResults: DocumentValidationResult[]
) {
  return validationResults.filter(({ result }) => result === 'failed');
}

function normaliseValidationResults({
  validationResults,
  list,
  resources,
}: {
  validationResults: DocumentValidationResult[];
  list: List;
  resources: Resources;
}) {
  // given validationResult
  // {
  //   "fields": [
  //     "affiliate_rut",
  //     "beneficiary_rut"
  //   ],
  //   "explanation": "RUTs are the same"
  // }
  // we want to transform it to
  // {
  //   "affiliate_rut": ['RUTs are the same'],
  //   "beneficiary_rut": ['RUTs are the same']
  // }
  // and finally to
  // {
  //   id1: ['RUTs are the same'],
  //   id2: ['RUTs are the same']
  // }
  // since each field uses an id as its key instead of sproutaiKey
  const uniqueByField = validationResults.reduce(
    (acc, curr) => {
      const result = curr.fields.reduce((a, c) => {
        if (!acc[c]) {
          return {
            ...a,
            [c]: [curr.explanation],
          };
        }

        return {
          ...a,
          [c]: [...acc[c], curr.explanation],
        };
      }, {});

      return { ...acc, ...result };
    },
    {} as { [key: string]: string[] }
  );

  return Object.keys(uniqueByField).reduce((acc, curr) => {
    const id = list.find((id) => resources[id].sproutaiKey === curr);

    if (id) {
      return {
        ...acc,
        [id]: uniqueByField[curr],
      };
    }

    return acc;
  }, {});
}

export function getInitialErrors({
  list,
  resources,
  validationResults,
}: GetInitialErrors): { [key: string]: { type: string; message: string } } {
  if (validationResults) {
    const filtered = filterValidationResultsByFailedResult(validationResults);
    const normalised = normaliseValidationResults({
      validationResults: filtered,
      list,
      resources,
    });

    return formatValidationMessages({
      resources,
      validationResults: normalised,
    });
  }

  return {};
}

export function insertNewContentToDocument(
  document: Document,
  content: DocumentContent[]
) {
  return {
    ...document,
    content,
  };
}

export function getAllSkippedIds(defaultValues: FormValues) {
  return defaultValues
    ? Object.keys(defaultValues).filter((key) =>
        key.endsWith(SKIP_INPUT_SUFFIX)
      )
    : [];
}
