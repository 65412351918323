import type { LabelColor } from 'components/Label';
import locale from 'locales/en/pages-page.json';
import type { LeafPaths } from 'utils/object';

export enum EFileStage {
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export const STAGE_LABEL_COLOR_MAP: Record<EFileStage, LabelColor> = {
  [EFileStage.PROCESSING]: 'info',
  [EFileStage.AWAITING_REVIEW]: 'warning',
  [EFileStage.COMPLETED]: 'success',
  [EFileStage.ERROR]: 'error',
};

export const STAGE_TRANSLATION_MAP: Record<
  EFileStage,
  LeafPaths<typeof locale>
> = {
  [EFileStage.PROCESSING]: 'stage.processing',
  [EFileStage.AWAITING_REVIEW]: 'stage.awaitingReview',
  [EFileStage.COMPLETED]: 'stage.completed',
  [EFileStage.ERROR]: 'stage.error',
};
