import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Layout } from 'components/CommonTool/Layout.tsx';
import { Header } from 'components/CommonTool/Header';
import { useTranslationRoot } from 'components/with-translation.tsx';
import { CanvasToolV2 } from 'components/CanvasToolV2';
import { SubmitButton } from 'components/form/SubmitButton';

import { DocumentTypeSelect } from './DocumentTypeSelect';
import { InputWrapper } from './InputWrapper';

interface FormContentProps {
  classification: string | undefined;
  url: string;
  metadataNode: ReactNode;
}

function FormContent({ classification, url, metadataNode }: FormContentProps) {
  const { t } = useTranslationRoot();
  const header = (
    <Header leftColumn={metadataNode} rightColumn={<SubmitButton />} />
  );

  return (
    <Layout
      header={header}
      body={
        <Stack
          sx={{
            flexDirection: 'row',
            height: 1,
          }}
        >
          <Box
            sx={{
              flexBasis: `calc(100% - 388px)`,
              overflow: 'hidden',
            }}
          >
            <CanvasToolV2 urls={[url]} />
          </Box>
          <Stack
            spacing={2}
            sx={{
              flex: '0 0 388px',
              p: 2,
            }}
          >
            <InputWrapper title={t('form.classification')}>
              <Typography variant="body2">{classification}</Typography>
            </InputWrapper>

            <InputWrapper title={t('form.overrideClassification')}>
              <DocumentTypeSelect />
            </InputWrapper>
          </Stack>
        </Stack>
      }
    />
  );
}

export { FormContent };
