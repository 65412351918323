import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { TypographyWithTranslation } from 'components/with-translation';
import { IconButtonAnimate } from 'components/IconButtonAnimate.tsx';
import { useBoolean } from 'components/customHooks/useBoolean.ts';
import { useTooltip } from 'components/customHooks/useTooltip.ts';
import { CLASSIFICATION_ICON, EDIT_ICON } from 'constants/public-icons.ts';
import {
  getIsDocumentAwaitingReview,
  getIsDocumentReviewInProgress,
} from 'state/selectors/documents.ts';

import type { TypeChangerProps } from './types.ts';
import { getItemDocumentType, getItems } from './utils.ts';
import { DocumentTypeDropdown } from './DocumentTypeDropdown.tsx';
import { DocumentTypeModal } from './DocumentTypeModal.tsx';

function DocumentTypeChanger({
  document,
  list,
  onSubmit,
  resources,
}: TypeChangerProps) {
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const { element, handleClose, handleOpen, isOpen } = useTooltip();
  const { getValues } = useFormContext();

  const hasAwaitingReviewStage = getIsDocumentAwaitingReview(document);
  const hasSaveStage = getIsDocumentReviewInProgress(document);
  const isEditable = hasAwaitingReviewStage || hasSaveStage;

  /**
   * some disgusting local logic here
   * if valid is true or null, then it's valid
   * this impacts how the document type changer is enabled/disabled and also
   * whether the items in the form in editable or not
   */
  const findDocumentTypeItemIds = getItems(getItemDocumentType);
  const documentTypeItemId = findDocumentTypeItemIds({ list, resources })[0];
  const item = resources[documentTypeItemId];
  const isValid = item?.valid || item?.valid === null;
  const defaultValue =
    documentTypeItemId && isValid ? getValues(documentTypeItemId) : '';

  const [docType, setDocType] = useState<string>(defaultValue || '');

  const openPopper = (e: MouseEvent<HTMLElement>) => {
    if (isEditable && documentTypeItemId) {
      handleOpen(e);
    }
  };

  const title = (
    <>
      <Stack spacing={2}>
        <TypographyWithTranslation
          i18nKey="form.changeDocumentButtonTitle"
          variant="subtitle1"
          sx={{
            textTransform: 'capitalize',
          }}
        />
        {document.clientDocumentType && isEditable && (
          <Stack>
            <TypographyWithTranslation
              i18nKey="form.receivedDocumentType"
              variant="subtitle2"
              sx={{
                textTransform: 'capitalize',
              }}
            />
            <Typography variant="body2" color="primary">
              {document.clientDocumentType}
            </Typography>
          </Stack>
        )}
        {isEditable && documentTypeItemId && (
          <Stack>
            <TypographyWithTranslation
              i18nKey="form.identifiedDocumentType"
              variant="subtitle2"
              sx={{
                textTransform: 'capitalize',
              }}
            />
            <Typography variant="body2">{defaultValue}</Typography>
          </Stack>
        )}
      </Stack>
      <SvgIconStyle
        src={EDIT_ICON}
        sx={{
          position: 'absolute',
          bottom: 6,
          right: 6,
        }}
      />
    </>
  );

  return (
    <>
      <Tooltip
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white',
            position: 'relative',
          },
        }}
        title={title}
      >
        <Box>
          <IconButtonAnimate size="large" isOpen={isOpen} onClick={openPopper}>
            <SvgIconStyle src={CLASSIFICATION_ICON} />
          </IconButtonAnimate>
        </Box>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={element}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              minWidth: 300,
            },
          },
        }}
      >
        <DocumentTypeDropdown
          handleChange={(event) => {
            event.preventDefault();
            openModal();
            setDocType(event.target.value as string);
          }}
          valid={isValid}
          value={docType}
        />
      </Popover>

      <DocumentTypeModal
        closeModal={closeModal}
        isOpen={isModalOpen}
        onCancel={() => {
          setDocType(defaultValue);
        }}
        onConfirm={() => {
          onSubmit(docType);
        }}
      />
    </>
  );
}

export { DocumentTypeChanger };
