import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import type { Document } from 'types/Documents.ts';
import {
  getEventTimestamp,
  sendAnalyticEvent,
  updateWindowPerformanceObject,
} from 'analytics/utils.ts';
import {
  HIL_OPEN_END,
  HIL_TOOL_GET_NEXT_DOCUMENT_END,
  HIL_TOOL_GET_NEXT_DOCUMENT_START,
  HIL_TOOL_START,
} from 'analytics/events.ts';
import { HilToolV3 } from 'components/HilToolV3';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { LoadingIcon } from 'components/icons/LoadingIcon.tsx';
import { HilReadOnly } from 'components/HilReadOnly/HilReadOnly.tsx';
import { toast } from 'components/toast';
import {
  AWAITING_REVIEW,
  REVIEW_IN_PROGRESS,
} from 'constants/document-stage.ts';
import { HIL_TABLE } from 'constants/table-pagination.ts';
import {
  DOCUMENT_TYPE,
  FILTERS,
  QUERY,
  SEARCH_FIELD,
  SORT,
} from 'constants/route-keys.ts';
import { useLocales } from 'locales/useLocales.ts';
import claimRoute from 'pages/Claim/claim.route.tsx';
import hitlRoute from 'pages/HitL/hitl.route.tsx';
import {
  useGetDocument,
  useGetDocumentHistoryVersion,
  useGetNextDocument,
} from 'state/queries/documents.ts';
import { useCurrentUserEmail } from 'state/queries/current-user.ts';
import { getDocumentStage } from 'state/selectors/documents.ts';
import { preWarmImageCache } from 'utils/image-cache.ts';
import { toFixed } from 'utils/numbers.ts';

import documentCorrectionRoute from './document-correction.route.tsx';

interface DocumentCorrectionV3Props {
  documentId: string;
}

function DocumentCorrectionV3({ documentId }: DocumentCorrectionV3Props) {
  const { resolvedLanguage } = useLocales();
  const currentUserEmailQuery = useCurrentUserEmail();
  const documentQuery = useGetDocument(documentId, resolvedLanguage);

  if (documentQuery.isPending || currentUserEmailQuery.isPending) {
    return <LoadingIcon />;
  }

  if (currentUserEmailQuery.isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="common.loadUserError"
      />
    );
  }

  if (documentQuery.isError) {
    return (
      <TypographyWithTranslation
        variant="body1"
        i18nKey="common.loadDocumentError"
      />
    );
  }

  preWarmImageCache(documentQuery.data.urls);

  return (
    <ComponentGateway
      currentUserEmail={currentUserEmailQuery.data}
      document={documentQuery.data}
      initialStage={getDocumentStage(documentQuery.data)}
      resolvedLanguage={resolvedLanguage}
    />
  );
}

interface ComponentGatewayProps {
  currentUserEmail: string;
  document: Document;
  initialStage: string;
  resolvedLanguage: string;
}

// if stage is originally awaiting review or review in progress,
// render the editable version
function ComponentGateway({ initialStage, ...props }: ComponentGatewayProps) {
  const [originalStage] = useState(initialStage);
  const isAwaitingReview = originalStage === AWAITING_REVIEW;
  const isReviewInProgress = originalStage === REVIEW_IN_PROGRESS;
  const isEditable = isAwaitingReview || isReviewInProgress;

  if (isEditable) {
    return <ToolWithDocument {...props} />;
  }

  return (
    <Card>
      <HilReadOnly {...props} />
    </Card>
  );
}

interface ToolWithDocumentProps {
  currentUserEmail: string;
  document: Document;
  resolvedLanguage: string;
}

function ToolWithDocument(props: ToolWithDocumentProps) {
  const enableHilNextDocument = useFeatureFlag('enableHILNextDocument');

  const navigate = useNavigate();
  const { t } = useTranslationRoot();
  const searchParams = window.localStorage.getItem(HIL_TABLE);

  const t1 = performance.now();
  const { document, resolvedLanguage } = props;
  const { claimId } = document;
  const documentHistoryQuery = useGetDocumentHistoryVersion(document.id);

  const nextDocumentParams = new URLSearchParams(searchParams || '');
  const getNextDocument = useGetNextDocument({
    lang: resolvedLanguage,
    documentTypeFilter: nextDocumentParams.get(DOCUMENT_TYPE),
    order: nextDocumentParams.get(SORT),
    // TODO: add search field
    searchField: nextDocumentParams.get(SEARCH_FIELD),
    searchValue: nextDocumentParams.get(QUERY),
    filters: nextDocumentParams.get(FILTERS),
    claimId,
  });

  const onNextDocument = () => {
    updateWindowPerformanceObject(HIL_TOOL_GET_NEXT_DOCUMENT_START);

    toast.promise(getNextDocument(true), {
      loading: t('form.nextDocumentLoading') as string,
      success: (nextDocument) => {
        sendAnalyticEvent({
          event: HIL_TOOL_GET_NEXT_DOCUMENT_END,
          performance:
            (Date.now() - getEventTimestamp(HIL_TOOL_GET_NEXT_DOCUMENT_START)) /
            1000,
          documentId: document.id,
        });

        if (nextDocument) {
          navigate(documentCorrectionRoute.createPath(nextDocument.id));
          return t('form.nextDocumentSuccess') as string;
        }
        return t('form.nextDocumentNoMoreDocuments') as string;
      },
      error: () => t('form.nextDocumentFail') as string,
    });
  };

  useEffect(() => {
    updateWindowPerformanceObject(HIL_TOOL_START);
  }, []);

  return (
    <HilToolV3
      {...props}
      documentHistory={documentHistoryQuery.data}
      onGetNextDocument={onNextDocument}
      onSuccess={() => {
        if (enableHilNextDocument) {
          return onNextDocument();
        }

        const to = claimId
          ? claimRoute.createPath(claimId)
          : `${hitlRoute.createPath()}?${searchParams}`;
        navigate(to);
      }}
      onImageLoadComplete={() => {
        sendAnalyticEvent({
          event: HIL_OPEN_END,
          performance: toFixed((performance.now() - t1) / 1000, 1),
          documentId: document.id,
        });
      }}
    />
  );
}

export { DocumentCorrectionV3 };
