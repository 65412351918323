import type { ReactNode } from 'react';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { Document, DocumentContent } from 'types/Documents';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { TypographyWithTranslation } from 'components/with-translation';
import { CanvasToolV2 } from 'components/CanvasToolV2';
import { DocumentToolbar } from 'components/HilTool/DocumentToolbar';
import { ToolLayout } from 'components/HilTool/ToolLayout';
import { ScrollableContainer } from 'components/ScrollableContainer.tsx';
import { PAGE_ID } from 'constants/route-keys';
import { useLocales } from 'locales/useLocales';
import {
  useGetDocumentContent,
  useGetDocumentHistorical,
  useGetDocumentHistory,
} from 'state/queries/documents';
import {
  getDocumentErrorMessage,
  getDocumentRejectReason,
  getIsDocumentErrored,
  getIsDocumentCompleted,
  getIsDocumentProcessing,
  getIsDocumentRejected,
  getIsDocumentClosed,
} from 'state/selectors/documents';
import { generateUuid } from 'utils/generate-uuid';

import type { List, Resources } from './types';
import { AuditTrail } from './AuditTrail';
import { StatusMessage } from './StatusMessage';
import { ItemsContent } from './ItemsContent';

type State = {
  list: string[];
  resources: { [key: string]: DocumentContent };
};

interface RootProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  list: List;
  resources: Resources;
}

interface HilToolWithDocumentProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
  resolvedLanguage: string;
}

interface HilReadOnlyProps {
  closeClaimHilTool?: VoidFunction;
  currentUserEmail: string;
  document: Document;
  fromClaimView?: boolean;
}

function convertArrayToState(content: DocumentContent[]) {
  return content.reduce(
    (cur: State, val) => {
      const id = generateUuid();
      return {
        list: [...cur.list, id],
        resources: {
          ...cur.resources,
          [id]: val,
        },
      };
    },
    { list: [], resources: {} }
  );
}

function isMultipage(document: Document) {
  return document.urls ? Object.keys(document.urls).length > 1 : false;
}

// COMPLETED
function HilIsCompleted({
  document,
  fromClaimView,
  list,
  resources,
}: RootProps) {
  const params = useParams();
  const historyParam = params[PAGE_ID];
  const [history, setHistory] = useState(historyParam);
  const { data: historyData, isPending } = useGetDocumentHistory(document.id);
  const historyDataMaps = useMemo(() => {
    if (isPending || !historyData?.length) {
      return [];
    }

    return historyData
      .map((item) => {
        if (item.content) {
          return convertArrayToState(item.content);
        }
        return null;
      })
      .filter(Boolean);
  }, [historyData, isPending]);

  const updateHistory = (value: string | undefined) => {
    setHistory(value);
  };

  if (isPending) {
    return <LoadingIcon />;
  }

  // for COMPLETE stage document let's get the specific history from the
  // array and pass it into the ItemsContent
  const historyListResource = history
    ? historyDataMaps[Number(history)]
    : undefined;
  const hasHistoryData = historyDataMaps.length > 0;
  const itemsContent = (
    <ItemsContent
      isMultipage={isMultipage(document)}
      list={
        hasHistoryData && historyListResource ? historyListResource.list : list
      }
      resources={
        hasHistoryData && historyListResource
          ? historyListResource.resources
          : resources
      }
    />
  );

  const currentDocument =
    history && historyData ? historyData[Number(history)] : document;
  const leftColumn = (
    <Stack sx={{ height: 1 }}>
      <DocumentToolbar document={document} fromClaimView={fromClaimView} />
      <CanvasToolV2 urls={document.urls} />
    </Stack>
  );

  const rightColumn = (
    <>
      <AuditTrail
        fromClaimView={fromClaimView}
        currentHistory={history}
        isVisible
        {...currentDocument}
        historical
        historySize={historyData?.length || 0}
        updateHistory={updateHistory}
      />
      <ScrollableContainer offset={24}>{itemsContent}</ScrollableContainer>
    </>
  );

  return <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />;
}

function HilIsReadOnly({ document, list, resources }: RootProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  // this is not ideal using javascript to handle styling
  // but I'll redo all this in hiltool v3
  useLayoutEffect(() => {
    if (containerRef.current) {
      const children = containerRef.current.children;

      if (children.length > 1) {
        const firstChildHeight = children[0].clientHeight;
        (children[1] as HTMLElement).style.height =
          `calc(100% - ${firstChildHeight}px)`;
      }
    }
  }, []);

  const leftColumn = (
    <Stack sx={{ height: 1 }}>
      <DocumentToolbar document={document} />
      <CanvasToolV2 urls={document.urls} />
    </Stack>
  );
  const itemsContent = (
    <ItemsContent
      isMultipage={isMultipage(document)}
      list={list}
      resources={resources}
    />
  );
  let rightColumn: ReactNode = (
    <ScrollableContainer offset={24}>{itemsContent}</ScrollableContainer>
  );

  if (getIsDocumentErrored(document)) {
    rightColumn = (
      <ScrollableContainer offset={24}>
        <StatusMessage
          titleI18nKey="stage.error"
          message={getDocumentErrorMessage(document)}
        />
        {itemsContent}
      </ScrollableContainer>
    );
  }

  if (getIsDocumentRejected(document)) {
    rightColumn = (
      <ScrollableContainer offset={24}>
        <Stack ref={containerRef} sx={{ height: 1 }}>
          <StatusMessage
            lastUpdatedBy={document.lastUpdatedBy}
            titleI18nKey="stage.rejectReason"
            message={getDocumentRejectReason(document)}
          />
          <Box>{itemsContent}</Box>
        </Stack>
      </ScrollableContainer>
    );
  }

  if (getIsDocumentProcessing(document)) {
    rightColumn = (
      <>
        <Box
          sx={{
            p: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="stage.processing"
            variant="subtitle1"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          />
        </Box>
        {itemsContent}
      </>
    );
  }

  if (getIsDocumentClosed(document)) {
    rightColumn = (
      <>
        <Box
          sx={{
            p: 2,
          }}
        >
          <TypographyWithTranslation
            i18nKey="stage.closed"
            variant="subtitle1"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          />
        </Box>
        {itemsContent}
      </>
    );
  }

  return <ToolLayout leftColumn={leftColumn} rightColumn={rightColumn} />;
}

function HilToolWithDocument(props: HilToolWithDocumentProps) {
  const { document, resolvedLanguage } = props;
  const options = { id: document.id, language: resolvedLanguage };
  const { data: isHistorical } = useGetDocumentHistorical(options);
  const { data: content } = useGetDocumentContent(options);

  const { list, resources } = useMemo(() => {
    if (content?.length) {
      return convertArrayToState(content);
    }

    return { list: [], resources: {} };
  }, [content]);

  const isCompleted = getIsDocumentCompleted(document);

  if (isHistorical || isCompleted) {
    return <HilIsCompleted {...props} list={list} resources={resources} />;
  }

  return <HilIsReadOnly {...props} list={list} resources={resources} />;
}

function HilReadOnly({
  closeClaimHilTool,
  document,
  currentUserEmail,
  fromClaimView = false,
}: HilReadOnlyProps) {
  const { resolvedLanguage } = useLocales();

  return (
    <HilToolWithDocument
      closeClaimHilTool={closeClaimHilTool}
      currentUserEmail={currentUserEmail}
      document={document}
      fromClaimView={fromClaimView}
      resolvedLanguage={resolvedLanguage}
    />
  );
}

export { HilReadOnly };
