import type { MouseEvent } from 'react';
import { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@mui/material/ButtonBase';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { LinkWithRef } from 'components/Link';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { DotIcon } from 'components/icons/DotIcon';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation.tsx';
import {
  ARROW_DOWNWARD_ICON,
  ARROW_FORWARD_ICON,
} from 'constants/public-icons';
import { DASHBOARD_PAGE } from 'constants/translation-keys';
import { STAGE } from 'constants/route-keys.ts';

import type { NavListChildrenProps } from './NavList';
import {
  ListItemIconStyle,
  ListItemStyle,
  ListItemTextStyle,
  MiniNavItem,
} from './style';

interface NavItemRootProps {
  endComponentProps?: any;
  item: NavListChildrenProps;
  open: boolean;
}

interface NavItemSubProps {
  active: boolean;
  item: NavListChildrenProps;
  onOpen?: VoidFunction;
  endComponentProps?: any;
  onClick?: VoidFunction;
}

interface ArrowIconProps {
  open: boolean;
}

interface NavListRootProps {
  list: NavListChildrenProps;

  [k: string]: any;
}

interface NavListSubProps {
  active: boolean;
  list: NavListChildrenProps;
}

function NavItemRoot({
  item,
  open = false,
  endComponentProps,
}: NavItemRootProps) {
  const { t } = useTranslation(DASHBOARD_PAGE);
  const { children, endComponent, icon, path, title } = item;

  return (
    <ListItemStyle
      component={LinkWithRef}
      to={path}
      activeClassName="isActiveRoot"
      state={{
        prevPathname: location.pathname,
      }}
    >
      {icon ? (
        <ListItemIconStyle>
          <SvgIconStyle src={icon} />
        </ListItemIconStyle>
      ) : null}
      <ListItemTextStyle disableTypography primary={t(title)} />
      {endComponent ? endComponent(endComponentProps) : null}
      {children?.length ? <ArrowIcon open={open} /> : null}
    </ListItemStyle>
  );
}

function NavItemSub({
  active = false,
  item,
  onOpen,
  endComponentProps,
  onClick,
}: NavItemSubProps) {
  const { t } = useTranslation(DASHBOARD_PAGE);
  const { children, path, title, endComponent } = item;

  const renderContent = (
    <>
      <ListItemIconStyle>
        <DotIcon active={active} />
      </ListItemIconStyle>
      <ListItemText disableTypography primary={t(title)} />
      {endComponent ? endComponent(endComponentProps) : null}
    </>
  );

  if (children) {
    return (
      <ListItemStyle activeSub={active} onClick={onOpen} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      activeSub={active}
      component={LinkWithRef}
      to={path}
      subItem
      onClick={onClick}
      data-testid={path}
      state={{
        prevPathname: location.pathname,
      }}
    >
      {renderContent}
    </ListItemStyle>
  );
}

function ArrowIcon({ open }: ArrowIconProps) {
  const src = open ? ARROW_DOWNWARD_ICON : ARROW_FORWARD_ICON;

  return <SvgIconStyle src={src} height={16} width={16} sx={{ ml: 1 }} />;
}

function getActive(path: string, pathname: string, end = false) {
  return path ? !!matchPath({ path, end }, pathname) : false;
}

// get active based on STAGE search param
function getSearchParamActive(
  isActive: boolean,
  routePath: string,
  currentPath: string
) {
  if (isActive) {
    const current = new URL(`${window.location.origin}${currentPath}`);
    const route = new URL(`${window.location.origin}${routePath}`);
    const currentPathname = current.pathname;
    const routePathname = route.pathname;
    const currentStage = current.searchParams.get(STAGE);
    const routeStage = route.searchParams.get(STAGE);

    return currentPathname === routePathname && currentStage === routeStage;
  }

  return false;
}

function NavListSub({ active, list }: NavListSubProps) {
  const hasChildren = list.children;
  const { pathname, search } = useLocation();

  if (hasChildren) {
    const newLocation = new URL(`${window.location.origin}${list.path}`);
    const isActive = getActive(newLocation.pathname, pathname, false);

    return (
      <Collapse in={active} timeout="auto">
        <List component="div" disablePadding>
          {(list.children || []).map((item) => {
            const isSearchParamActive = getSearchParamActive(
              isActive,
              item.path,
              pathname + search
            );

            return (
              <NavItemSub
                key={item.title}
                item={item}
                active={!isActive || isSearchParamActive}
                endComponentProps={item.counter ? item.counter : ''}
                onClick={item?.onClick}
              />
            );
          })}
        </List>
      </Collapse>
    );
  }

  return <NavItemSub item={list} active={active} />;
}

function NavListRoot({ list, ...props }: NavListRootProps) {
  const location = useLocation();
  const { pathname } = location;
  const hasChildren = !!list.children?.length;
  const newLocation = new URL(`${window.location.origin}${list.path}`);
  const active = getActive(
    hasChildren ? newLocation.pathname : list.path,
    pathname
  );

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} open={active} {...props} />
        <Collapse in={active} timeout="auto">
          <List
            component="div"
            disablePadding
            data-testid={list.title.toLowerCase()}
          >
            <NavListSub active={active} list={list} />
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemRoot {...props} open={active} item={list} />;
}

function NavMiniRoot({ list }: NavListRootProps) {
  const { t } = useTranslationRoot();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const { icon, path, shortTitle, title } = list;
  const hasChildren = !!list.children?.length;

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <MiniNavItem
      // @ts-ignore
      activeClassName="isActiveRoot"
      component={LinkWithRef}
      key={title}
      to={path}
    >
      <ButtonBase
        id={`${title}-button`}
        aria-controls={isOpen ? `${title}-menu` : undefined}
        aria-haspopup={hasChildren ? 'true' : 'false'}
        aria-expanded={isOpen ? 'true' : undefined}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 0.5,
          pt: 1,
          width: 1,
          zIndex: 1301,
        }}
      >
        <SvgIconStyle src={icon!} sx={{ mb: 0.5 }} />
        <TypographyWithTranslation
          i18nKey={shortTitle}
          variant="caption"
          sx={{
            display: 'block',
            maxWidth: 72,
            overflow: 'hidden',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />
        {hasChildren ? (
          <>
            <SvgIconStyle
              src={ARROW_FORWARD_ICON}
              sx={{
                height: 16,
                mb: 0.5,
                right: 4,
                position: 'absolute',
                top: 13,
                width: 16,
              }}
            />
            <Popper
              id={`${title}-menu`}
              open={isOpen}
              anchorEl={anchorEl}
              placement="right-start"
              transition
              sx={{ zIndex: ({ zIndex }) => zIndex.tooltip + 1 }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left center',
                  }}
                >
                  <Paper elevation={3}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList sx={{ p: 1 }}>
                        {list.children!.map((item) => (
                          <MenuItem
                            key={item.title}
                            onClick={handleClose}
                            component={LinkWithRef}
                            to={item.path}
                            sx={{
                              borderRadius: 1,
                              color: 'grey.600',
                              fontSize: 14,
                              textTransform: 'capitalize',
                              '&:hover': {
                                color: 'text.primary',
                              },
                            }}
                          >
                            {t(item.title)}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : null}
      </ButtonBase>
    </MiniNavItem>
  );
}

export { NavItemRoot, NavItemSub, NavListRoot, NavListSub, NavMiniRoot };
