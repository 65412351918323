import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { useTranslationRoot } from 'components/with-translation';
import { useGetComments } from 'state/queries/comments';

import { Comment } from './Comment';
import { Header } from './Header';
import { NewComment } from './NewComment';

interface ContentProps {
  entityId: string;
  claimId?: string | null;
  setOpen: (value: boolean) => void;
}

function Content({ entityId, claimId = null, setOpen }: ContentProps) {
  const { t } = useTranslationRoot();
  const { data, isLoading, isError, refetch } = useGetComments({
    entityId,
    claimId,
  });

  return (
    <Stack
      direction="column"
      sx={{
        maxHeight: '100vh',
        height: '100%',
        overflow: 'hidden',
        p: 2,
        gap: 2,
      }}
    >
      <Header
        handleRefresh={() => {
          refetch();
        }}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {isLoading ? (
          <LoadingIcon />
        ) : isError ? (
          <Typography variant="body1" color="error">
            {t('comments.failedToLoad')}
          </Typography>
        ) : data && data.length > 0 ? (
          <Stack direction="column" gap={2}>
            {[...data].reverse().map((comment, index) => (
              <Comment
                key={`comment-${comment.id}-${index}`}
                comment={comment}
              />
            ))}
          </Stack>
        ) : (
          <Typography variant="body1">{t('comments.noData')}</Typography>
        )}
      </Box>

      <NewComment entityId={entityId} claimId={claimId} />
    </Stack>
  );
}

export { Content };
