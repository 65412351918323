import { useMemo } from 'react';
import Stack from '@mui/material/Stack';

import type { LineItemsProps } from './types.ts';
import { initLineItems } from './utils.ts';
import { LineItemsGroup } from './LineItemsGroup.tsx';

function LineItems({
  filterItemsBy,
  historicalResources,
  list,
  resources,
  updateStore,
}: LineItemsProps) {
  const { groupedLineItems, lastLineIdx, lineValidity } = useMemo(
    () =>
      initLineItems({
        list,
        resources,
      }),
    [list, resources]
  );
  const numberOfGroupedLineItems = groupedLineItems.size;

  return (
    <Stack spacing={2}>
      {Array.from(groupedLineItems).map(([level, groupedLine]) => {
        const lastLineIndex = lastLineIdx.get(level);
        const lineGroupValidity = lineValidity.get(level);

        if (lastLineIndex !== -1 && !lineGroupValidity?.size) {
          return null;
        }

        return (
          <LineItemsGroup
            key={level}
            // if there is only one group of line items, we don't need to pass
            // level only to show the title in Line Items: {{ title }}
            filterItemsBy={filterItemsBy}
            groupedLineItems={groupedLine}
            historicalResources={historicalResources}
            lastLineIdx={lastLineIndex as number}
            lineValidity={lineGroupValidity as Map<number, boolean>}
            resources={resources}
            title={numberOfGroupedLineItems > 1 ? level : undefined}
            updateStore={updateStore}
          />
        );
      })}
    </Stack>
  );
}

export { LineItems };
