import type { ClaimsValidateResult } from 'types/Claims.ts';

function isValid(results: ClaimsValidateResult[]) {
  return results.every(({ result }) => result === 'passed');
}

function validationHandler({ successFn, failureFn }) {
  return (results) => {
    if (isValid(results)) {
      successFn();
      return;
    } else {
      failureFn();
      return;
    }
  };
}

export { validationHandler };
