import { useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import type { Order } from 'constants/sort.ts';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';
import { WithTranslationRoot } from 'components/with-translation.tsx';
import { PAGE, ROWS_PER_PAGE, SORT } from 'constants/route-keys.ts';
import { SPLITS_PAGE } from 'constants/translation-keys.ts';
import { Page } from 'components/Page.tsx';

import { SplitsTable } from './SplitsTable.tsx';

function Splits() {
  // hooks
  const [searchParams] = useSearchParams();

  // url params
  const initialPage = searchParams.get(PAGE) ?? '0';
  const initialRowsPerPage = searchParams.get(ROWS_PER_PAGE) ?? '10';
  const initialSort = searchParams.get(SORT) as Order;

  // pagination
  const pagination = useAdvancedTablePagination({
    initialPage,
    initialRowsPerPage,
    initialSort,
  });

  return (
    <WithTranslationRoot namespace={SPLITS_PAGE}>
      <Page i18nKeyTitle="meta.title" sx={{ p: 3 }}>
        <Card>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <SplitsTable pagination={pagination} />
          </Paper>
        </Card>
      </Page>
    </WithTranslationRoot>
  );
}

export default Splits;
