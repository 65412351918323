import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'locales/dayjs';
import { DateRangePickerV2 } from 'components/DateRangePickerV2.tsx';

dayjs.extend(timezone);

interface DateRangeFilterProps {
  name: string;
}

function DateRangeFilter({ name }: DateRangeFilterProps) {
  const { getValues, setValue } = useFormContext();
  const formValue = getValues(name);
  const startDate = formValue?.[0]
    ? dayjs.tz(formValue?.[0]).utc().toDate()
    : undefined;
  const endDate = formValue?.[1]
    ? dayjs.tz(formValue?.[1]).utc().toDate()
    : undefined;
  const [state, setState] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate,
    endDate,
  });

  function updateStartDate(value: Date) {
    setState((state) => ({
      ...state,
      startDate: value,
    }));
  }

  function updateEndDate(value: Date) {
    setState((state) => ({
      ...state,
      endDate: value,
    }));
  }

  function onOk(date: Date[]) {
    const start = dayjs(date[0]).startOf('day').utc(true).toISOString();
    const end = dayjs(date[1]).endOf('day').utc(true).toISOString();

    setValue(name, [start, end]);
  }

  return (
    <DateRangePickerV2
      onOk={onOk}
      updateStartDate={updateStartDate}
      updateEndDate={updateEndDate}
      size="sm"
      startDate={state.startDate as Date}
      endDate={state.endDate as Date}
    />
  );
}

export { DateRangeFilter };
