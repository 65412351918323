import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { COMMON } from 'constants/translation-keys';

import { Page } from './Page';

function LoadingScreen() {
  const { t } = useTranslation(COMMON);

  return (
    <Page i18nKeyTitle="loading">
      <Box
        maxWidth="xl"
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          justifyContent: 'center',
          width: 1,
        }}
      >
        <Typography variant="body1" sx={visuallyHidden}>
          {t('loading')}
        </Typography>
        <LoadingIcon />
      </Box>
    </Page>
  );
}

export { LoadingScreen };
