import type { MouseEvent } from 'react';
import type { Theme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import type { SproutFile } from 'types/Files.ts';
import type { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination.ts';
import { AdvancedTable } from 'components/table/AdvancedTable.tsx';
import { LinkWithRef } from 'components/Link.tsx';
import { useTranslationRoot } from 'components/with-translation';
import { REDACTIONS_PAGE } from 'constants/translation-keys';
import redactionRoute from 'pages/Redaction/redaction.route.tsx';
import {
  useGetPagesRedactionData,
  useGetPagesRedactionMetadataSortedColumns,
  useGetPagesRedactionTotal,
} from 'state/queries/pages.ts';
import {
  convertSnakeCaseToCamelCase,
  toTitleCase,
  toCamelCase,
} from 'utils/string.ts';
import { formatDocumentDate } from 'utils/date.ts';

interface RedactionsTableProps {
  pagination: ReturnType<typeof useAdvancedTablePagination>;
}

const dateType = ['last_locked_on', 'last_updated_on'];

function RedactionsTable({ pagination }: RedactionsTableProps) {
  // hooks
  const navigate = useNavigate();
  const { i18n } = useTranslationRoot(REDACTIONS_PAGE);

  // setup
  const { page, rowsPerPage, sort } = pagination;
  const options = {
    order: sort,
    start: page,
    size: rowsPerPage,
  };

  // queries
  const getRedactionsDataQuery = useGetPagesRedactionData(options);
  const getRedactionsTotalQuery = useGetPagesRedactionTotal(options);
  const getRedactionsMetadataSortedColumnsQuery =
    useGetPagesRedactionMetadataSortedColumns(options);

  // functions
  const handleRowClick = ({ id }: SproutFile) => {
    navigate(redactionRoute.createPath(id));
  };

  const columnHeading = (column: string) => {
    return i18n.exists(`table.${toCamelCase(column)}`, {
      ns: REDACTIONS_PAGE,
    })
      ? `table.${toCamelCase(column)}`
      : toTitleCase(column);
  };

  const columns = (sortedColumns: string[] = []) => [
    ...sortedColumns.map((column) => {
      const base = {
        id: convertSnakeCaseToCamelCase(column),
        i18nKey: columnHeading(column),
      };

      if (column === 'page_id') {
        return {
          id: 'id',
          i18nKey: 'table.pageId',
          custom: true,
          renderCell: ({ row }) => {
            const { id } = row;
            const to = redactionRoute.createPath(id);

            return (
              <Link
                data-testid="file-id-link"
                component={LinkWithRef}
                to={to}
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  position: 'sticky',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={linkHandler}
              >
                {id.substring(row.id.length - 6)}
              </Link>
            );
          },
          columnSx: {
            borderRight: ({ palette }: Theme) =>
              `3px solid ${palette.background.default}`,
            left: 0,
            position: 'sticky',
            zIndex: (theme: Theme) => theme.zIndex.appBar + 2,
          },
          rowSx: {
            backgroundColor: 'background.paper',
            borderRight: ({ palette }) => `3px solid ${palette.divider}`,
            fontWeight: 'bold',
            left: 0,
            position: 'sticky',
            zIndex: ({ zIndex }) => zIndex.appBar + 2,
          },
        };
      }

      if (column === 'last_updated_on') {
        return {
          ...base,
          sortable: true,
          i18nKey: 'table.lastUpdatedOn',
          valueFormatter: ({ value }) => {
            return value ? formatDocumentDate(value) : '';
          },
        };
      }

      if (column === 'classification') {
        return {
          ...base,
          i18nKey: 'table.classification',
          valueFormatter: ({ value }) => (value ? value.pageType : ''),
        };
      }

      // not ideal hardcoding these columns but it's the only easy way to identify
      // date type column
      if (dateType.includes(column)) {
        return {
          ...base,
          valueFormatter: ({ value }) => {
            return value ? formatDocumentDate(value) : '';
          },
        };
      }

      return base;
    }),
  ];

  return (
    <AdvancedTable
      columns={columns(getRedactionsMetadataSortedColumnsQuery.data)}
      handleRowClick={handleRowClick}
      isPending={
        getRedactionsDataQuery.isPending ||
        getRedactionsTotalQuery.isPending ||
        getRedactionsMetadataSortedColumnsQuery.isPending
      }
      noItemsLabeli18nKey="table.noItems"
      pagination={pagination}
      rows={getRedactionsDataQuery.data}
      totalCount={getRedactionsTotalQuery.data || 0}
      isError={getRedactionsDataQuery.isError}
      refetch={getRedactionsDataQuery.refetch}
      errorMessage="table.error"
      retryButton="table.retryButton"
    />
  );
}

function linkHandler(e: MouseEvent<HTMLAnchorElement>) {
  e.stopPropagation();
}

export { RedactionsTable };
