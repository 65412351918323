import type { MouseEvent } from 'react';
import type { Column } from 'components/table/AdvancedTable';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { Label } from 'components/Label';
import { Page } from 'components/Page';
import { LinkWithRef } from 'components/Link';
import { WithTranslationRoot } from 'components/with-translation';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination';
import {
  EFileStage,
  STAGE_LABEL_COLOR_MAP,
  STAGE_TRANSLATION_MAP,
} from 'constants/file-stage';
import { FILES_PAGE } from 'constants/translation-keys';
import { AdvancedTable } from 'components/table/AdvancedTable';
import { useGetFilesData, useGetFilesTotal } from 'state/queries/files';
import type { SproutFilesList } from 'types/Files';
import { useIsSuperAdminRole } from 'state/queries/current-user';
import splitRoute from 'pages/Split/split.route';

import { TableTooltip } from './TableTooltip';

const interceptNavigation = (e: MouseEvent<HTMLTableCellElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

function FilesList() {
  const pagination = useAdvancedTablePagination({});
  const { page, rowsPerPage } = pagination as {
    page: number;
    rowsPerPage: number;
  };

  const options = {
    start: page,
    size: rowsPerPage,
  };

  const filesQuery = useGetFilesData(options);
  const filesTotalQuery = useGetFilesTotal(options);
  const isSuperAdminQuery = useIsSuperAdminRole();

  const hasSuperAdminPermission =
    isSuperAdminQuery.isSuccess && isSuperAdminQuery.data;

  return (
    <WithTranslationRoot namespace={FILES_PAGE}>
      <Page i18nKeyTitle="meta.title" sx={{ py: 3, px: 2 }}>
        <Card>
          <AdvancedTable
            columns={
              [
                {
                  id: 'id',
                  i18nKey: 'table.fileId',
                  renderCell: ({ row }) => {
                    const { stage } = row;
                    const isClickable = stage === EFileStage.AWAITING_REVIEW;
                    const idSubstring = row.id.substring(row.id.length - 6);
                    return isClickable ? (
                      <Link
                        component={LinkWithRef}
                        to={splitRoute.createPath(row.id)}
                        sx={{
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          textDecoration: 'none',
                          position: 'sticky',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {idSubstring}
                      </Link>
                    ) : (
                      idSubstring
                    );
                  },
                },
                {
                  id: 'claimId',
                  i18nKey: 'table.claimId',
                },
                {
                  id: 'clientClaimId',
                  i18nKey: 'table.clientClaimId',
                },
                {
                  id: 'stage',
                  i18nKey: 'table.stage',
                  renderCell: ({ row }: any) => (
                    <Label
                      variant="ghost"
                      color={
                        row.locked
                          ? 'default'
                          : STAGE_LABEL_COLOR_MAP[row.stage]
                      }
                      i18nKey={
                        row.locked
                          ? 'table.locked'
                          : STAGE_TRANSLATION_MAP[row.stage]
                      }
                    />
                  ),
                },
                {
                  id: 'lastLockedBy',
                  i18nKey: 'table.lastLockedBy',
                },
                {
                  id: 'actions',
                  i18nKey: '',
                  align: 'right',
                  renderCell: ({ row }) =>
                    hasSuperAdminPermission ? (
                      <Box
                        onClick={interceptNavigation}
                        sx={{ display: 'inline-block' }}
                      >
                        <TableTooltip id={row.id} />
                      </Box>
                    ) : null,
                  rowSx: {
                    width: 1,
                  },
                },
              ] as Column[]
            }
            rows={(filesQuery.data || []) satisfies SproutFilesList}
            rowIdentifier="id"
            isPending={filesQuery.isLoading || filesTotalQuery.isLoading}
            totalCount={filesTotalQuery.data || 0}
            noItemsLabeli18nKey="table.noItems"
            pagination={pagination}
            isError={filesQuery.isError || filesTotalQuery.isError}
            refetch={filesQuery.refetch}
            errorMessage="table.error"
            retryButton="table.retryButton"
          />
        </Card>
      </Page>
    </WithTranslationRoot>
  );
}

export default FilesList;
