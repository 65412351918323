import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { SvgIconStyle } from 'components/SvgIconStyle';
import { ARROW_FORWARD_ICON } from 'constants/public-icons';
import { COMMON } from 'constants/translation-keys.ts';
import { getOsKey } from 'utils/client';

interface ImageNavigationProps {
  nextSlide: VoidFunction;
  noOfImages: number;
  page: number;
  previousSlide: VoidFunction;
  hasPageCounter: boolean;
}

function Navigation({
  nextSlide,
  noOfImages,
  page,
  previousSlide,
  hasPageCounter,
}: ImageNavigationProps) {
  const { t } = useTranslationRoot(COMMON);
  const disableImageNav = noOfImages < 2;

  const key = getOsKey();

  if (noOfImages > 1) {
    return (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
        }}
      >
        {hasPageCounter && (
          <TypographyWithTranslation
            i18nKey="canvas.pageOf"
            namespace={COMMON}
            options={{
              current: isNaN(page) ? 0 : page + 1,
              total: noOfImages,
            }}
            sx={{
              color: ({ palette }) => palette.text.secondary,
              fontSize: 14,
              fontWeight: 'bold',
            }}
          />
        )}
        <ButtonGroup aria-label={t('canvas.ariaImageNavigation') as string}>
          <Tooltip
            title={t('canvas.shortcutKeyPrev', {
              key,
              arrow: '←',
            })}
          >
            <IconButton
              aria-label={t('canvas.previous') as string}
              onClick={previousSlide}
              disabled={disableImageNav}
              data-testid="previous-button"
            >
              <SvgIconStyle
                src={ARROW_FORWARD_ICON}
                sx={{ transform: 'rotate(180deg)' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('canvas.shortcutKeyNext', {
              key,
              arrow: '→',
            })}
          >
            <IconButton
              aria-label={t('canvas.next') as string}
              onClick={nextSlide}
              disabled={disableImageNav}
              data-testid="next-button"
            >
              <SvgIconStyle src={ARROW_FORWARD_ICON} />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Stack>
    );
  }

  return null;
}

export { Navigation };
