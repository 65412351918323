import type { Theme } from '@mui/material/styles';
import type { MouseEvent, ReactNode } from 'react';
import type { Column } from 'components/table/AdvancedTable';
import type { Document } from 'types/Documents';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { Order } from 'constants/sort';
import { useGetDocuments } from 'state/queries/documents';
import { Page } from 'components/Page';
import { DOCUMENT_CORRECTIONS_PAGE } from 'constants/translation-keys';
import {
  useTranslationRoot,
  WithTranslationRoot,
} from 'components/with-translation';
import {
  DOCUMENT_TYPE,
  FILTERS,
  PAGE,
  QUERY,
  ROWS_PER_PAGE,
  SORT,
  STAGE,
} from 'constants/route-keys';
import { LinkWithRef } from 'components/Link';
import documentCorrectionRoute from 'pages/DocumentCorrection/document-correction.route.tsx';
import { generalConfig } from 'config';
import { Label } from 'components/Label';
import { useIsSuperAdminRole } from 'state/queries/current-user';
import { useAdvancedTablePagination } from 'components/customHooks/useAdvancedTablePagination';
import {
  STAGE_LABEL_COLOR_MAP,
  STAGE_TRANSLATION_MAP,
} from 'constants/document-stage';
import { useGetFiltersDocuments } from 'state/queries/filters';
import {
  selectDocumentsMetadataSortedColumns,
  selectDocumentsTotal,
} from 'state/selectors/documents.ts';
import {
  convertSnakeCaseToCamelCase,
  toCamelCase,
  toTitleCase,
} from 'utils/string';
import { formatDocumentDate, isValidISODateString } from 'utils/date';
import { AdvancedFiltering } from 'components/table/AdvancedFiltering';
import { AdvancedSearch } from 'components/table/AdvancedSearch';
import { AdvancedFilterChips } from 'components/table/AdvancedFilterChips';
import { useAdvancedSearch } from 'components/customHooks/useAdvancedSearch';
import { DOCUMENT_CORRECTION_TABLE } from 'constants/table-pagination';
import { useRestoreFilters } from 'components/customHooks/useRestoreFilters';
import { useFeatureFlag } from 'components/customHooks/useFeatureFlag.ts';
import { FilterTableDropDown } from 'components/FilterTableDropDown.tsx';
import { Category } from 'components/table/Category.tsx';
import { FilterOptions } from 'components/table/FilterOptions.tsx';

import { HitLV2Table } from './HitLV2Table';
import { TableTooltip } from './TableTooltip';
import { Operator } from './Operator';
import { useDocumentTypeFilter } from './useDocumentTypeFilter.ts';
import { useRegularDocumentTypesFilters } from './useRegularDocumentTypesFilters.tsx';

const CLIENT_DOCUMENT_ID = 'CLIENT_DOCUMENT_ID';
const DOCUMENT_ID = 'DOCUMENT_ID';

function DocumentCorrections() {
  const { i18n, t } = useTranslationRoot(DOCUMENT_CORRECTIONS_PAGE);
  const location = useLocation();

  // Axa specifically wants to open documents in new tabs
  const { enableOpeningHilInNewTab } = generalConfig;
  const { searchParams } = useRestoreFilters(DOCUMENT_CORRECTION_TABLE);

  // feature flag
  const hasAdvancedFiltering = useFeatureFlag('enableHILAdvancedFiltering');

  // getting saved filters from local storage
  const latestSearchParams = new URLSearchParams(location.search);
  const initialPage = latestSearchParams.get(PAGE) ?? '0';
  const initialSort = latestSearchParams.get(SORT) as Order;
  const initialRowsPerPage = latestSearchParams.get(ROWS_PER_PAGE) ?? '10';
  const stageParam = latestSearchParams.getAll(STAGE);
  const documentTypeParam = latestSearchParams.getAll(DOCUMENT_TYPE);
  const filtersParam = latestSearchParams.get(FILTERS);

  const queryParam = searchParams.get(QUERY);
  const target = enableOpeningHilInNewTab ? '_blank' : '_self';

  // search functionality
  const { handleSearch, searchValue, searchField } = useAdvancedSearch();

  // pagination
  const pagination = useAdvancedTablePagination({
    initialFilters: filtersParam ? (filtersParam as string) : '',
    initialPage,
    initialSort,
    initialRowsPerPage,
  });
  const {
    page,
    rowsPerPage,
    sort,
    deleteDocumentTypeFilterAndUrl,
    updateFiltersAndUrl,
    updateDocumentTypeFiltersAndUrl,
  } = pagination;
  const interceptNavigation = (e: MouseEvent<HTMLTableCellElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // queries
  const documentsQuery = useGetDocuments({
    start: page,
    size: rowsPerPage,
    stage: stageParam || null,
    order: sort,
    searchField: searchField || undefined,
    searchValue: searchValue,
    documentTypeFilter: documentTypeParam,
    filters: filtersParam ? filtersParam : '',
  });
  const isSuperAdminQuery = useIsSuperAdminRole();
  const filtersDocumentsQuery = useGetFiltersDocuments(stageParam);
  const documentTypeFilters = useDocumentTypeFilter();

  // for regular document types filter
  const documentTypesFilterOptions = useRegularDocumentTypesFilters({
    hasAdvancedFiltering,
    stageParam,
  });

  const columnHeading = (column: string) => {
    switch (column) {
      case 'last_updated_by':
        return 'table.operator';
      case 'last_updated_on':
        return 'table.dateTimeProcessed';
      case 'registered_date':
        return 'tableSorted.registeredDate';
      default:
        return i18n.exists(`table.${toCamelCase(column)}`, {
          ns: DOCUMENT_CORRECTIONS_PAGE,
        })
          ? `table.${toCamelCase(column)}`
          : toTitleCase(column);
    }
  };

  const columns = (
    hasSuperAdminPermission = false,
    sortedColumns: string[] = []
  ): Column[] => [
    ...sortedColumns.map((id) => {
      const columnObject: Column = {
        id: convertSnakeCaseToCamelCase(id),
        i18nKey: columnHeading(id),
        custom: true,
      };

      switch (columnObject.id) {
        case 'documentId':
          columnObject.renderCell = ({ row }) => (
            <Link
              data-testid="document-link"
              component={LinkWithRef}
              to={documentCorrectionRoute.createPath(row.id) as string}
              target={target}
              sx={{
                cursor: 'pointer',
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                void documentsQuery.refetch();
              }}
            >
              {row.id}
            </Link>
          );
          columnObject.columnSx = {
            borderRight: ({ palette }: Theme) =>
              `3px solid ${palette.background.default}`,
            left: 0,
            position: 'sticky',
            zIndex: (theme: Theme) => theme.zIndex.appBar + 2,
          };
          columnObject.rowSx = {
            backgroundColor: 'background.paper',
            borderRight: ({ palette }) => `3px solid ${palette.divider}`,
            fontWeight: 'bold',
            left: 0,
            position: 'sticky',
            zIndex: ({ zIndex }) => zIndex.appBar + 2,
          };
          break;
        case 'lastUpdatedBy':
          columnObject.renderCell = ({ row }): ReactNode => (
            <Operator lastUpdatedBy={row.lastUpdatedBy} />
          );
          break;
        case 'lastUpdatedOn':
          columnObject.valueFormatter = ({ value }) =>
            isValidISODateString(value) ? formatDocumentDate(value) : value;
          columnObject.sortable = true;
          break;
        case 'registeredDate':
          columnObject.valueFormatter = ({ value }) =>
            isValidISODateString(value) ? formatDocumentDate(value) : value;
          break;
        case 'stage':
          columnObject.renderCell = ({ row }): ReactNode => (
            <Label
              variant="ghost"
              color={row.locked ? 'warning' : STAGE_LABEL_COLOR_MAP[row.stage]}
              i18nKey={
                row.locked ? 'table.locked' : STAGE_TRANSLATION_MAP[row.stage]
              }
              sx={{
                cursor: 'pointer',
              }}
            />
          );
          break;
      }

      return columnObject;
    }),
    {
      id: 'actions',
      i18nKey: '',
      align: 'right',
      renderCell: ({ row }) =>
        hasSuperAdminPermission ? (
          <Box onClick={interceptNavigation} sx={{ display: 'inline-block' }}>
            <TableTooltip id={row.id} />
          </Box>
        ) : null,
      rowSx: {
        width: 1,
      },
    },
  ];

  const hasSuperAdminPermission =
    isSuperAdminQuery.isSuccess && isSuperAdminQuery.data;

  const sortedColumns = documentsQuery.isSuccess
    ? selectDocumentsMetadataSortedColumns(documentsQuery.data)
    : [];

  const newSearchParams = new URLSearchParams(location.search);
  const advancedColumns = columns(hasSuperAdminPermission, sortedColumns);
  const advancedFilteringMarkup = (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: 2,
        flex: '1 1 auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {hasAdvancedFiltering ? (
        <AdvancedFiltering
          filters={newSearchParams.get(FILTERS)}
          updateFilters={updateFiltersAndUrl}
          filtersQuery={filtersDocumentsQuery}
          docTypeFilters={{
            ...documentTypeFilters,
            markup: (
              <Box
                sx={{
                  py: 2,
                }}
              >
                <Category name={DOCUMENT_TYPE}>
                  <FilterOptions
                    filtersKey={DOCUMENT_TYPE}
                    options={documentTypeFilters.options}
                  />
                </Category>
              </Box>
            ),
          }}
          updateDocTypeFilters={updateDocumentTypeFiltersAndUrl}
        />
      ) : (
        <Box>
          <FilterTableDropDown
            buttonTextKey="table.filterButton"
            options={documentTypesFilterOptions}
            onSelect={(filters) =>
              updateDocumentTypeFiltersAndUrl(filters.join(','))
            }
            filters={documentTypeFilters.filters}
            popoverTitleKey="table.documentType"
          />
        </Box>
      )}
      <AdvancedFilterChips
        filters={newSearchParams.get(FILTERS)}
        filtersQueryData={filtersDocumentsQuery.data || []}
        setFilters={updateFiltersAndUrl}
        docTypeFilters={newSearchParams.getAll(DOCUMENT_TYPE)}
        handleDeleteDocTypeFilter={deleteDocumentTypeFilterAndUrl}
        updateDocumentsFiltersAndUrl={updateDocumentTypeFiltersAndUrl}
      />
    </Stack>
  );

  const advancedSearchMarkup = (
    <AdvancedSearch
      defaultValue={searchValue || queryParam}
      handleSearch={handleSearch}
      hasSuperAdminPermission={hasSuperAdminPermission}
      searchField={searchField}
      menuItems={[
        { value: CLIENT_DOCUMENT_ID, label: t('search.clientDocumentId') },
        { value: DOCUMENT_ID, label: t('search.documentId') },
      ]}
    />
  );

  const navigate = useNavigate();
  const navigateToHilTool = (item: Document) => {
    const { id } = item;
    if (target === '_blank') {
      window.open(documentCorrectionRoute.createPath(id), '_blank')!.focus();
    } else {
      navigate(documentCorrectionRoute.createPath(id));
    }
  };

  return (
    <WithTranslationRoot namespace={DOCUMENT_CORRECTIONS_PAGE}>
      <Page i18nKeyTitle="meta.title" sx={{ p: 3 }}>
        <Card>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <HitLV2Table
              columns={advancedColumns}
              isPending={documentsQuery.isPending}
              noItemsLabeli18nKey="table.notFound"
              pagination={pagination}
              handleRowClick={navigateToHilTool}
              rows={documentsQuery.data?.data || []}
              rowIdentifier="id"
              toolbarStartAdornment={advancedFilteringMarkup}
              toolbarEndAdornment={advancedSearchMarkup}
              totalCount={
                documentsQuery.data
                  ? selectDocumentsTotal(documentsQuery.data)
                  : 0
              }
              isError={documentsQuery.isError}
              refetch={documentsQuery.refetch}
              errorMessage="table.error"
              retryButton="table.retryButton"
            />
          </Paper>
        </Card>
      </Page>
    </WithTranslationRoot>
  );
}

export default DocumentCorrections;
