import Typography from '@mui/material/Typography';

function Copyright() {
  const currentYear = new Date().getFullYear();

  return (
    <Typography
      component="p"
      variant="caption"
      sx={{
        color: 'grey.600',
      }}
    >
      {`©Sprout.ai ${currentYear}. All rights reserved.`}
    </Typography>
  );
}

export { Copyright };
