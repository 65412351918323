import type { PDFPageProxy } from 'pdfjs-dist';

import type { UrlList } from './types.ts';

export async function getPDFSnapShot(
  page: PDFPageProxy,
  outputScale = window.devicePixelRatio || 1
) {
  const scale = 1;
  const viewport = page.getViewport({ scale });

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    canvas.height = viewport.height * outputScale;
    canvas.width = viewport.width * outputScale;

    const transform =
      outputScale !== 1
        ? [outputScale, 0, 0, outputScale, 0, 0]
        : [1, 0, 0, 1, 0, 0];
    const renderContext = {
      canvasContext: context,
      transform,
      viewport,
    };

    try {
      await page.render(renderContext).promise;

      return canvas.toDataURL();
    } catch (e) {
      console.error(e);
      return '';
    }
  }
}

export function calculateRelativePageOffset(
  list: UrlList,
  target: string,
  currentPage: number
) {
  const pageOffset = list.findIndex((item) => item.url === target);
  return currentPage - pageOffset;
}
