import type { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { TypographyWithTranslation } from 'components/with-translation';
import { generateUuid } from 'utils/generate-uuid';
import { useTranslationRoot } from 'components/with-translation';

import type { DocumentData } from './utils';
import { ClaimForm } from './ClaimForm';
import { DocumentForm } from './DocumentForm';
import { SubmitButton } from './SubmitButton';
import { CLAIM_DESCRIPTION_PROPERTY } from './utils';

type FileData = {
  clientDocumentType?: string;
  error: ProgressEvent<EventTarget> | undefined;
  file: File;
  metadata?: Record<string, string>[];
  progress: number;
  thumbnail: string;
};

interface CreateFormProps {
  files: FileData[];
  policyFiles: FileData[];
  handleSubmit: (claimData: {
    claimData: {
      client_claim_id: string;
      properties: Record<string, string> | undefined;
      metadata: Record<string, string> | undefined;
      expected_files: number;
    };
    documentsData: DocumentData[];
    policyDocumentsData: DocumentData[];
  }) => void;
  isSubmitting: boolean;
  setFiles: Dispatch<SetStateAction<FileData[]>>;
  setPolicyFiles: Dispatch<SetStateAction<FileData[]>>;
}

type FormValues = {
  client_claim_id: string;
  properties: Record<string, string>[];
  metadata: Record<string, string>[];
  documents: File[];
  policyDocuments: File[];
};

function CreateForm({
  files,
  policyFiles,
  handleSubmit,
  isSubmitting,
  setFiles,
  setPolicyFiles,
}: CreateFormProps) {
  const { t } = useTranslationRoot();
  const methods = useForm<FormValues>({
    defaultValues: {
      client_claim_id: generateUuid(),
      properties: [{ key: CLAIM_DESCRIPTION_PROPERTY, value: '' }],
      metadata: [],
      documents: [],
      policyDocuments: [],
    },
  });

  const onSubmit = (data: FormValues) => {
    const convertArrayToObject = (array: Record<string, string>[]) =>
      array.reduce((obj, item) => {
        if (!item.key) return obj;
        obj[item.key] = item.value;
        return obj;
      }, {});

    const claimData = {
      client_claim_id: data.client_claim_id,
      properties: data.properties.length
        ? convertArrayToObject(data.properties)
        : undefined,
      metadata: data.metadata.length
        ? convertArrayToObject(data.metadata)
        : undefined,
      expected_files: files.length + policyFiles.length,
    };

    if (claimData.properties?.expected_files) {
      claimData.expected_files = Number(claimData.properties.expected_files);
    };

    const documentsData = files.map(
      ({ file, metadata, clientDocumentType }) => ({
        file,
        client_claim_id: data.client_claim_id,
        client_document_type: clientDocumentType,
        metadata: metadata?.length ? convertArrayToObject(metadata) : undefined,
      })
    );

    const policyDocumentsData = policyFiles.map(
      ({ file, metadata, clientDocumentType }) => ({
        file,
        client_claim_id: data.client_claim_id,
        client_document_type: clientDocumentType,
        metadata: metadata?.length ? convertArrayToObject(metadata) : undefined,
      })
    );

    handleSubmit({ claimData, documentsData, policyDocumentsData });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Box>
              <TypographyWithTranslation
                variant="h6"
                i18nKey="form.claimTitle"
              />
              <TypographyWithTranslation
                variant="body2"
                sx={{ color: 'grey.600' }}
                i18nKey="form.claimDescription"
              />
            </Box>

            <ClaimForm />
          </Stack>

          <Stack spacing={1}>
            <Box>
              <TypographyWithTranslation
                variant="h6"
                i18nKey="form.documentsTitle"
              />
              <TypographyWithTranslation
                variant="body2"
                sx={{ color: 'grey.600' }}
                i18nKey="form.documentsDescription"
              />
            </Box>

            <DocumentForm
              title={t('form.uploadDocumentsLabel')}
              subheader={t('form.uploadDocumentsDesc')}
              buttonLabel={t('form.addDocumentsButton')}
              files={files}
              setFiles={setFiles}
            />
            <DocumentForm
              title={t('form.uploadPolicyDocumentLabel')}
              subheader={t('form.uploadPolicyDocumentDesc')}
              buttonLabel={t('form.addPolicyDocumentButton')}
              files={policyFiles}
              setFiles={setPolicyFiles}
            />
          </Stack>

          <SubmitButton isSubmitting={isSubmitting} />
        </Stack>
      </form>
    </FormProvider>
  );
}

export { CreateForm };
