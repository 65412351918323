import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { loadable } from 'components/loadable';
import { CLASSIFICATION_ICON } from 'constants/public-icons';
import ROUTE_PERMISSIONS from 'constants/route-permissions';
import { CLASSIFICATION_PAGE } from 'constants/translation-keys';
import { RESOURCE_ID } from 'constants/route-keys.ts';

const path = `/cl/:${RESOURCE_ID}`;
const ClassificationPage = loadable(lazy(() => import('./Classification.tsx')));

const classificationRoute = {
  path,
  createPath: (resourceId: string) => generatePath(path, { resourceId }),
  icon: CLASSIFICATION_ICON,
  element: <ClassificationPage />,
  permission: ROUTE_PERMISSIONS.classification,
  i18nNamespace: CLASSIFICATION_PAGE,
};

export default classificationRoute;
