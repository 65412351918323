import { SvgIconStyle } from 'components/SvgIconStyle.tsx';
import { LoadingButtonWithTranslation } from 'components/with-translation.tsx';
import { CIRCLE_CHECK_ICON } from 'constants/public-icons';

import type { SubmitButtonProps } from './types.ts';

function SubmitButton({ disabled, loading }: SubmitButtonProps) {
  return (
    <LoadingButtonWithTranslation
      data-testid="submit-document-button"
      disabled={disabled}
      variant="contained"
      color="primary"
      startIcon={
        <SvgIconStyle height={17} src={CIRCLE_CHECK_ICON} width={17} />
      }
      type="submit"
      loading={loading}
      loadingPosition="start"
      i18nKey="form.submitDocumentBtn"
    />
  );
}

export { SubmitButton };
