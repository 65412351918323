import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import type { LineSingleItemProps } from './types.ts';
import { InputField } from './InputField.tsx';
import { DeleteField } from './DeleteField.tsx';
import { LineNumber } from './LineNumber.tsx';
import { DELETED_INPUT_SUFFIX } from './constants.ts';

function LineSingleItem(props: LineSingleItemProps) {
  const { isValid = false, lineIdx, list } = props;
  const { control } = useFormContext();
  const id = list[0];
  const deleted = useWatch({ control, name: `${id}${DELETED_INPUT_SUFFIX}` });

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: 'center',
        mt: 2,
      }}
    >
      <LineNumber deleted={deleted} lineIdx={lineIdx} isValid={isValid} />
      <Box sx={{ flex: 1 }}>
        <SingleInputField id={id} {...props} deleted={deleted} />
      </Box>
    </Stack>
  );
}

function SingleInputField({
  id,
  historicalResources,
  lineIdx,
  resources,
  deleteLineItem,
  deleted,
}: LineSingleItemProps & { id: string; deleted: boolean }) {
  return (
    <InputField
      key={id}
      defaultSpacing={1}
      historicalResources={historicalResources}
      id={id}
      resources={resources}
      sx={{
        pb: 0,
        '& .MuiInputBase-input': {
          textDecoration: deleted ? 'line-through' : 'none',
          textDecorationThickness: deleted ? '2px' : '0',
        },
      }}
      deleteComponent={
        <Stack
          sx={{
            alignItems: 'center',
            // if isLineItem and item is valid then hide skip checkbox
            display: 'flex',
            flex: `0 0 36px`,
            justifyContent: 'center',
            mt: '6px !important',
          }}
        >
          <DeleteField
            active={deleted}
            onDelete={() => deleteLineItem(lineIdx)}
          />
        </Stack>
      }
    />
  );
}

export { LineSingleItem };
