import type { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import {
  TypographyWithTranslation,
  useTranslationRoot,
} from 'components/with-translation';
import { useGetDocumentTypes } from 'state/queries/documents';

interface DocumentTypeDropdownProps {
  handleChange: (event: SelectChangeEvent<string>) => void;
  valid: boolean;
  value?: string;
}

function DocumentTypeDropdown({
  handleChange,
  valid,
  value,
}: DocumentTypeDropdownProps) {
  const { t } = useTranslationRoot();
  const { data, isError, isPending } = useGetDocumentTypes();

  if (isError) {
    return (
      <TypographyWithTranslation i18nKey="form.errorLoadingDocumentTypes" />
    );
  }

  if (isPending) {
    return <Skeleton variant="rounded" height={48} />;
  }

  const { documentTypes } = data;
  const menuItems = documentTypes.map(({ documentType }) => ({
    label: documentType,
    value: documentType,
  }));

  return (
    <FormControl variant="filled" size="small" fullWidth error={!valid}>
      <InputLabel id="document-type-filter">
        {t('form.changeDocumentButtonTitle')}
      </InputLabel>
      <Select
        labelId="document-type-filter"
        onChange={handleChange}
        value={value}
      >
        {menuItems.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { DocumentTypeDropdown };
