import type { FieldsListIds, Resources } from '../types.ts';
import { transformStateToForm } from './transform-values.ts';

export function getDefaultValues({
  fieldsListIds,
  resources,
}: {
  fieldsListIds: FieldsListIds;
  resources: Resources;
}) {
  return Object.keys(fieldsListIds).reduce((acc, listId) => {
    const ids = fieldsListIds[listId];
    const values = ids.reduce((a, id) => {
      const { value, valueType } = resources[id];

      return {
        ...a,
        [id]: transformStateToForm(valueType, value),
      };
    }, {});

    return {
      ...acc,
      ...values,
    };
  }, {});
}
