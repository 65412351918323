import type { Claim } from 'types/Claims.ts';
import { getClaimValidationResults } from 'state/selectors/claims.ts';

import type { FieldsListIds, Resources } from '../types.ts';

/**
 * getConsistencyResultsError collates specifically the unique errors within
 * a consistency result rule group
 */
export function getConsistencyResultsError({
  claim,
  fieldsListIds,
  resources,
}: {
  claim: Claim;
  fieldsListIds: FieldsListIds;
  resources: Resources;
}) {
  const validationResults = getClaimValidationResults(claim);

  if (!validationResults) {
    return {};
  }

  const result = validationResults.reduce(
    (acc, item) => {
      if (!item.sources?.length) {
        return acc;
      }

      const newAcc = { ...acc };
      const { sources, title } = item;

      if (sources.length === 1) {
        sources.forEach((source) => {
          const { observationId, path } = source;
          const fieldId = fieldsListIds[observationId].find(
            (id) => resources[id].sproutaiKey === path
          );

          if (fieldId) {
            if (!newAcc[fieldId]) {
              newAcc[fieldId] = [];
            }

            newAcc[fieldId].push(title);
          }
        }, {});
      }

      return newAcc;
    },
    {} as Record<string, string[]>
  );

  return Object.entries(result).reduce((acc, [id, messages]) => {
    return {
      ...acc,
      [id]: { message: messages, type: 'custom' },
    };
  }, {});
}
